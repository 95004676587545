import { dutch } from "@shared/definitions";

export const truncateHTML = (text: string, limit: number) => {
  if (!text || text.length <= limit) {
    return text;
  }
  const clean = text.replace(/<(?:.|\n)*?>/gm, '');
  const shortened = clean.substring(0, limit) + '...';
  let decoded = shortened;

  Object.keys(dutch).forEach((key: any) => {
    if (decoded.includes(key)) {
      decoded = decoded.replace(key, dutch[key]);
    }
  });

  return decoded;
};
