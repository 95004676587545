import { Email } from './Email';

export interface ParseInvitesResult {
  research_group_id: string;
  results: Result[];
}

export interface Result {
  input: string;
  email: Email;
  error: string;
  existing_user_id: string;
  existing_respondent_id: string;
}
