import { Media } from "./Media";

export interface MovieShow {
  movie_id: string;
  movie_title: string;
  movie_photo_id: string;
  movie_photo: Media;
  attending: boolean;
  ticketlab_id: string;
  ticketlab_movie_id: string;
  ticketlab_location_id: string;
  global_movie_id: string;
  start: string;
  location: string;
  ticket_url: string;
  shop_ids: string[];
  status: number;
  id: string;
}
