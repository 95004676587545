import { Component, EventEmitter, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { dateBeforeNow } from '@digilize/shared/utils/validators/src';
import { ErrorService } from '../../../services/error.service';
import { Subscription, debounceTime, forkJoin } from 'rxjs';
import { MeProfileService } from '../../../services/me-profile.service';
import { AuthApiService, MeApiService } from '@digilize/shared/data/services/src';
import { EducationService } from '../../../services/education.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class MyDateAdapter extends NativeDateAdapter {
  parse(value: string) {
    let it = value.split('/');
    if (it.length == 3) return new Date(+it[2], +it[1] - 1, +it[0], 12);
  }
}

@Component({
  selector: 'bwc-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
  ],
})
export class GeneralInformationComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @Input() editing = false;
  @Output() formEmit = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() dataStep = new EventEmitter();

  form: FormGroup;
  tempForm: FormGroup;
  countriesOptions = [];
  dataLoaded = false;
  genderCreated = false;
  emailExists = false;
  showLogInCont = false;
  genderList = ['female', 'male', 'other'];
  genderOptions = [];
  selectedLang: string;
  subSink: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private meApiService: MeApiService,
    private authApiService: AuthApiService,
    private authService: AuthService,
    private meProfileService: MeProfileService,
    public errorService: ErrorService,
    private educationService: EducationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.selectedLang = this.translateService.getDefaultLang();

    this.createGenderOptions();
    this.subSink.push(
      this.translateService.onLangChange.subscribe((langChangeEvent) => {
        this.getCountries(langChangeEvent.lang);
        this.createGenderOptions();
      })
    );

    if (this.formGroup !== undefined) {
      this.editing = true;
      this.form = this.createForm();
      this.patchUserData(this.formGroup.value);
      this.getCountries(this.selectedLang, true);
    } else {
      this.form = this.createForm();
      this.getData(this.selectedLang);
    }

    // this.listenToEmailChanges();
    this.form.get('email').valueChanges.subscribe((data) => {
      this.emailExists = false;
    });
  }

  createGenderOptions() {
    this.genderOptions = [];
    this.genderOptions = [
      ...this.genderList.map((item) => ({ text: this.translateService.instant('global.' + item), val: item })),
    ];
    this.genderCreated = true;
  }

  patchUserData(formVals) {
    const vals = { ...formVals };
    vals.birthdayDate = this.formatDate(formVals.birthday);
    this.form.patchValue(vals);
  }

  listenToEmailChanges() {
    this.form
      .get('email')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((value) => {
        this.setEmailExistanceStatus(this.form.value.email);
      });
  }

  redirectToLogIn() {
    this.authService.logout();
  }

  createForm() {
    return this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.minLength(7), Validators.pattern(/[0-9+\- ]/)]],
      city: ['', Validators.required],
      country: ['', Validators.required],
      birthday: ['', [Validators.required, dateBeforeNow()]],
      birthdayDate: [''],
      gender: [''],
    });
  }

  getCountries(lang: string, loaded?) {
    this.educationService.getCountries(lang).subscribe((data: any) => {
      const languages = data.data.map((item) => ({
        text: item.name,
        val: item.code,
      }));
      this.countriesOptions = [...languages];
      if (loaded) {
        this.dataLoaded = true;
      }
    });
  }

  setEmailExistanceStatus(email) {
    if (email) {
      this.authApiService.doesEmailExists(email).subscribe((data) => {
        this.emailExists = data.exists;
      });
    }
  }

  getData(lang: string) {
    forkJoin({
      countriesData: this.educationService.getCountries(lang),
      userData: this.meApiService.getMe(),
    }).subscribe((data: any) => {
      this.countriesOptions = data.countriesData.data.map((item) => ({
        text: item.name,
        val: item.code,
      }));
      this.form.patchValue({
        first_name: data.userData.first_name,
        last_name: data.userData.last_name,
        email: data.userData.email,
        phone: data.userData.phone,
        city: data.userData.city,
        country: data.userData.country,
        birthday: data.userData.birthday,
        birthdayDate: this.formatDate(data.userData.birthday),
        gender: data.userData.gender,
      });

      this.dataLoaded = true;
      // this.setEmailExistanceStatus(data.userData.email);
    });
  }

  public getFormControl(formControlName: string) {
    return this.form.get(formControlName) as FormControl;
  }

  handleFilteredOptionClick(event, name) {
    this.getFormControl(name).setValue(event.val);
  }

  onSubmit() {
    if (this.form.valid) {
      this.sendData(this.form.value);
    }
  }

  onEdit() {
    if (this.form.valid) {
      this.sendData(this.form.value, true);
    }
  }

  sendData(data, close?) {
    const genInfo = {
      ...data,
      nickname: '',
      description: '',
      post_code: '',
      street: '',
      house_number: '',
      street_number: '',
    };

    this.meProfileService.postProfileWithAdress(genInfo).subscribe(
      (resp) => {
        if (close) {
          this.emitClose.emit('gen_info');
          this.formEmit.emit(this.form);
        } else {
          this.dataStep.emit({ previousStep: this.currentStep, step: 4 });
        }
      },
      (error) => {
        if (error.error.error.type === 'already_exists') {
          this.emailExists = true;
        }
      }
    );
  }

  onBirthdateChange(event) {
    const format = this.convertToIsoDateFormat(event.target.value);
    this.form.get('birthday').setValue(format);
    this.form.get('birthday').updateValueAndValidity();
  }

  handleDateSelection(event) {
    const selectedDate = event.value;
    const adjustedDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
    const selectedDateISO = adjustedDate.toISOString();
    const datePartOnly = selectedDateISO.substring(0, selectedDateISO.indexOf('T'));
    this.form.get('birthdayDate').setValue(this.formatDate(datePartOnly));
    const format = this.convertToIsoDateFormat(this.formatDate(datePartOnly));
    this.form.get('birthday').setValue(format);
    this.form.get('birthday').updateValueAndValidity();
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return null;
    } else {
      const date = new Date(dateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
  }

  convertToIsoDateFormat(dateString: string): string {
    const isFullyFilled = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

    if (isFullyFilled) {
      const [day, month, year] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);
      return date.toISOString();
    } else {
      return dateString;
    }
  }

  convertToIsoDateFormat2(dateString: string): string {
    const isFullyFilled = /^\d{2}-\d{2}-\d{4}$/.test(dateString);
    if (isFullyFilled) {
      const [day, month, year] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);
      date.setDate(date.getDate() - 1);
      date.setHours(22, 0, 0, 0);
      const isoString = date.toISOString();
      return isoString.slice(0, 11) + '22:00:00.000Z';
    } else {
      return dateString;
    }
  }

  checkIfDateEmpty(value) {
    if (!value || value.length === 0) {
      return 'dd-mm-yyyy';
    } else {
      return '';
    }
  }

  close() {
    this.emitClose.emit('gen_info');
  }

  goPrev() {
    this.dataStep.emit({ previousStep: this.currentStep, step: 1 });
  }

  ngOnDestroy(): void {
    this.subSink.forEach((sub) => sub.unsubscribe());
  }
}

export interface GeneralInfo {
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  country: string;
  birthday: Date;
  gender: string;
}
