<div class="dropdown-cont" #dropdownContainer (click)="toggleNav()" [ngClass]="{ opened: openMenu }">
  <div class="user-profile-icon">
    <img src="/assets/icons/profile-icon.png" class="profile-icon" />
  </div>
  <img src="/assets/icons/icon-down-b.svg" class="close-icon" />
  <img src="/assets/icons/icon-up-b.svg" class="open-icon" />
</div>

<div class="dropdown-menu" [ngClass]="{ opened: openMenu }">
  <ul>
    <li (click)="saveProfile()" *ngIf="dataLoaded && !accountData.password_created">
      <div class="img-cont"><img src="/assets/icons/change-password-icon.svg" class="menu-icon" /></div>
      {{ 'auth.create_your_password' | translate }}
    </li>
    <li (click)="changePassword()" *ngIf="dataLoaded && accountData.password_created && accountData.activated">
      <div class="img-cont"><img src="/assets/icons/change-password-icon.svg" class="menu-icon" /></div>
      {{ 'auth.change_password' | translate }}
    </li>
    <li (click)="logout()">
      <div class="img-cont"><img src="/assets/icons/logout-icon.svg" class="menu-icon" /></div>
      {{ 'global.logout' | translate }}
    </li>
  </ul>
</div>
