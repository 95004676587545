export interface Profile {
  email: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  description?: string;
  gender?: string;
  birthday?: Date;
}
