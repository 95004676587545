import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataApiResponse,
  ParseInvitesRequest,
  ParseInvitesResult,
  ResearchGroupInviteLinks,
  ResearchGroupInviteLinksResult,
  ResearchGroupLinkRequest,
  ResearchGroupLinkResponse,
  ResearchInvite,
  ResearchRespondent,
} from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ResearchRespondentsApiService {
  constructor(private http: HttpClient) {}

  readonly BASE_RESEARCH_RESPONDENTS_URL = '/api/v1.0/research-respondents';

  getResearchRespondents = (params: any) =>
    this.http.get<DataApiResponse<ResearchRespondent[]>>(`${this.BASE_RESEARCH_RESPONDENTS_URL}`, { params });

  getResearchRespondent = (id: string) =>
    this.http.get<ResearchRespondent>(`${this.BASE_RESEARCH_RESPONDENTS_URL}/${id}`);

  removeResearchRespondent = (id: string) => this.http.delete(`${this.BASE_RESEARCH_RESPONDENTS_URL}/${id}`);

  parseInvites = (parseInvitesBody: ParseInvitesRequest) =>
    this.http.post<ParseInvitesResult>(`${this.BASE_RESEARCH_RESPONDENTS_URL}/invites/parse`, parseInvitesBody);

  addInvites = (addInvitesBody: ResearchInvite) =>
    this.http.post<ParseInvitesResult>(`${this.BASE_RESEARCH_RESPONDENTS_URL}/invites`, addInvitesBody);

  downloadInvites = (group: string) =>
    this.http.get(`${this.BASE_RESEARCH_RESPONDENTS_URL}/invites/csv`, { params: { group }, responseType: 'text' });

  addAnonymousInvites = (researchGroupInviteLinks: ResearchGroupInviteLinks) =>
    this.http.post<ResearchGroupInviteLinksResult>(
      `${this.BASE_RESEARCH_RESPONDENTS_URL}/invites-anonymous`,
      researchGroupInviteLinks
    );

  downloadAnonymousInvites = (group: string, session: string) =>
    this.http.get(`${this.BASE_RESEARCH_RESPONDENTS_URL}/invites-anonymous/csv`, {
      params: { group, session },
      responseType: 'text',
    });

  getInviteLink = (researchGroupLink: ResearchGroupLinkRequest) =>
    this.http.post<ResearchGroupLinkResponse>(`${this.BASE_RESEARCH_RESPONDENTS_URL}/invites-link`, researchGroupLink);
}
