import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchOtherValidator(otherControlName: string) {
  let thisControl: AbstractControl;
  let otherControl: AbstractControl;

  return function matchOtherValidate(control: AbstractControl): ValidatorFn {
    if (!control.parent) {
      return null;
    }

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as AbstractControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true,
      } as any;
    }

    return null;
  };
}
