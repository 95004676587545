export enum EmailVerbEnum {
  Unknown = 0,
  PasswordReset = 1,
  InviteCompany = 2,
  NewAccount = 3,
  Order = 4,
  OrderShopOwner = 5,
  OrderStatus = 6,
  Demo = 7,
  Invite = 8,
  Campaigns = 9,
  CampaignOrder = 10,
  Subscription = 11,
  ProductRequest = 12,
  VoucherAccount = 13,
  VoucherAnonymous = 14,
  AuctionBid = 15,
  AuctionMessage = 16,
  PostDraft = 17,
  ActivateAccount = 18,
  EventInvite = 19,
  QuizAvailable = 20,
  QuizFinishedWinner = 21,
  QuizFinishedShopOwner = 22,
  PasswordForgot = 23,
  FashionShowInvite = 24,
  FashionShowAvailable = 25,
  LoginCode = 26,
  ResearchInvite = 27,
  ResearchRemind = 28,
  Research360Invite = 29,
  ServiceAssigned = 30,
}
