import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Buyer,
  Cart,
  DataApiResponse,
  DeliveryAddress,
  Order,
  OrderRequest,
  PaymentMethod,
  Reservation,
  ReservationRequest,
  ShopAvailabilityRequest,
  Voucher,
} from '@shared/definitions';
import { UUID } from 'angular2-uuid';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  // Session

  createSession = () => this.http.post('/api/v1.0/sessions', { frontend_session_id: UUID.UUID() });

  // Cart

  getCarts = (sessionId: string) => this.http.get<Cart[]>(`/api/v1.0/me/carts?session_id=${sessionId}`);

  getShopAvailability = (availabilityRequest: ShopAvailabilityRequest) =>
    this.http.post(`/api/v1.0/shops/availability`, availabilityRequest);

  // Reservations

  createReservation = (shopId: string, reservation: ReservationRequest) =>
    this.http.post<Reservation>(`/api/v1.0/shops/${shopId}/reservations`, reservation);

  deleteReservation = (reservationId: string) =>
    this.http.delete<{ cart_line_id: string; cart: Cart }>(`/api/v1.0/reservations/${reservationId}`);

  // Payment methods

  getPaymentMethods = (cartId: string) => this.http.get<PaymentMethod[]>(`/api/v1.0/carts/${cartId}/payment-methods`);

  // Delivery

  setDeliveryMethod = (cartId: string, method: number) =>
    this.http.put(`/api/v1.0/carts/${cartId}/delivery-method?method=${method}`, {});

  setBuyer = (cartId: string, buyer: Buyer) => this.http.put(`/api/v1.0/carts/${cartId}/buyer`, buyer);

  setDelivery = (cartId: string, address: DeliveryAddress) =>
    this.http.put(`/api/v1.0/carts/${cartId}/delivery`, address);

  setPayAfter = (cartId: string, value: boolean) =>
    this.http.put(`/api/v1.0/carts/${cartId}/pay-after?payafter=${value}`, {});

  setTimeslot = (cartId: string, timeslot: string) =>
    this.http.put(`/api/v1.0/carts/${cartId}/time-slot?timeSlot=${encodeURIComponent(timeslot)}`, {});

  // Order

  createOrder = (orderRequest: OrderRequest) => this.http.post<Order>(`/api/v1.0/orders`, orderRequest);

  getOrder = (orderId: string) => this.http.get<Order>(`/api/v1.0/orders/${orderId}`);
  getOrderInvoice = (orderId: string) => this.http.get(`/api/v1.0/orders/${orderId}/invoice`, { responseType: 'blob' });

  setOrderStatus = (orderId: string, status: string) =>
    this.http.put(`/api/v1.0/orders/${orderId}/status?status=${status}`, {});

  setOrderPaid = (orderId: string) => this.http.post(`/api/v1.0/orders/${orderId}/paid`, {});

  setOrdersRefund = (payload: { ids: string[] }) => this.http.post(`/api/v1.0/orders/refund`, payload);

  // Admin

  getOrders = (params?) => this.http.get<DataApiResponse<Order[]>>('/api/v1.0/orders', { params: params });

  // Vouchers

  getVouchers = (params?) => this.http.get('/api/v1.0/vouchers', { params: params });

  getVoucher = (voucherId) => this.http.get(`/api/v1.0/vouchers/${voucherId}`);

  setVoucherStatus = (voucher: Voucher) =>
    this.http.put(`/api/v1.0/vouchers/${voucher.id}/status`, {
      id: voucher.id,
      status: voucher.status,
    });

  getBarcode = (voucherId) => this.http.get(`/api/v1.0/vouchers/${voucherId}/barcode`, { responseType: 'blob' });

  downloadVoucher = (voucherId) => this.http.get(`/api/v1.0/vouchers/${voucherId}/pdf`, { responseType: 'blob' });

  resendVoucher = (voucherId) => this.http.post(`/api/v1.0/vouchers/${voucherId}/resend`, {});

  parseVouchers = (codes) => this.http.post('/api/v1.0/products/vouchers/parse', codes);

  addVouchers = (codes) => this.http.post('/api/v1.0/products/vouchers', codes);

  // Comment

  setComment = (cartId, comment: string) => this.http.put(`/api/v1.0/carts/${cartId}/comment`, { comment });

  getVoucherPreview = (body, headers) =>
    this.http.post('/api/v1.0/vouchers/preview', body, { headers, responseType: 'text' });
}
