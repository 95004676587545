<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div class="btn-container">
      <button class="btn btn-primary" (click)="closeDialog(createProductActions.CreateProductFromFeed)">
        <span [inlineSVG]="'/icons/icon-packages.svg'"></span>
        <span>{{ 'product.create_product_from_feed' | translate }}</span>
      </button>
      <button class="btn btn-primary" (click)="closeDialog(createProductActions.CreateProduct)">
        <span [inlineSVG]="'/icons/icon-package.svg'"></span>
        <span>{{ 'product.create_product' | translate }}</span>
      </button>
    </div>
  </div>
</div>
