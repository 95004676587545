import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CategoriesForm,
  ChangePassword,
  ChangePasswordToken,
  DataApiResponse,
  DeliveryAddress,
  Me,
  Profile,
  ResearchRespondent,
  UserService,
} from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class MeApiService {
  readonly BASE_HREF_ME = '/api/v1.0/me';

  constructor(private http: HttpClient) {}

  getMe = () => this.http.get<Me>(this.BASE_HREF_ME);

  updateProfile = (profileForm: Profile) => this.http.post(`${this.BASE_HREF_ME}/profile`, profileForm);

  updateAddress = (address: Partial<DeliveryAddress>) => this.http.post(`${this.BASE_HREF_ME}/address`, address);

  updatePassword = (passwordForm: ChangePassword) =>
    this.http.post(`${this.BASE_HREF_ME}/password-change`, passwordForm);

  changePasswordToken = (changePasswordToken: ChangePasswordToken, captchaHeaders?: HttpHeaders) =>
    this.http.post(`${this.BASE_HREF_ME}/password-change/token`, changePasswordToken, {
      headers: captchaHeaders || {},
    });

  resetPassword = (email: string, captchaHeaders?: HttpHeaders) =>
    this.http.post(`/api/v1.0/auth/password-reset`, { email: email }, { headers: captchaHeaders || {} });

  updatePhoto = (photoId: string) => this.http.post(`${this.BASE_HREF_ME}/photo`, { photo_id: photoId });

  updateInterests = (categoriesForm: CategoriesForm) =>
    this.http.post(`${this.BASE_HREF_ME}/subcategories`, categoriesForm);

  updateTerms = (terms: any) => this.http.post(`${this.BASE_HREF_ME}/agreements`, terms);

  getResearchRespondents(params) {
    return this.http.get<DataApiResponse<ResearchRespondent[]>>(`${this.BASE_HREF_ME}/research-respondents`, {
      params,
    });
  }

  getUserServices(params: { draw?: number; start?: number; length?: number }) {
    return this.http.get<DataApiResponse<UserService[]>>(`${this.BASE_HREF_ME}/services`, {
      params,
    });
  }
}
