import { AppConfig } from '@digilize/shared/definitions/src';

export const appConfig: AppConfig = {
  app: 'be-work-happy-client',
  appTitle: 'Skilld tool',
  email: '',
  gtm: '',
  localStorageAuthPrefix: 'bwc-',
  logo: {
    light: '/logo/logo-light-ft.svg',
    dark: '/logo/logo-dark-ft.svg',
    mark: '/logo/logo-mark-ft.svg',
  },
  language: {
    defaultLanguage: 'nl',
    languages: [
      { code: 'nl', iconFlagPath: 'assets/flags/netherlands.png' },
      { code: 'en', iconFlagPath: 'assets/flags/united-kingdom.png' },
    ],
  },
};
