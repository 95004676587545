import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor() {
    super();
  }

  override handleError(error: any): void {
    if (this.chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    console.error(error);
  }
}
