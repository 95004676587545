import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MediaApiService } from '@digilize/shared/data/services';
import { Media } from '@shared/definitions';
import { isFileSizeCorrect, isImgSizeCorrect } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-drag-drop-photos',
  templateUrl: './drag-drop-photos.component.html',
  styleUrls: ['./drag-drop-photos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DragDropPhotosComponent implements OnInit, AfterViewInit {
  @Output() filesChange: EventEmitter<Media[]> = new EventEmitter<Media[]>();
  @Output() selectImageChange: EventEmitter<Media> = new EventEmitter<Media>();
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  @Input() items: Array<Media> = [];
  @Input() maxItems = 10;
  @Input() maxFileSize = 2; // Mb
  @Input() maxImgRes = 2000; // px;,
  @Input() staticImage: null | Media = null;
  @Input() presentationOnly = false;
  @Input() alternativeLook = false;
  @Input() editorImage: null | Media = null;
  @Input() infoVisible = true;
  @Input() hoverDelete = true;
  @Input() selectedImageId: string;
  @Input() photoEditorEnabled = false;
  target: CdkDropList;
  targetIndex: number;
  source: CdkDropList;
  sourceIndex: number;
  dragIndex: number;
  activeContainer;

  loading = false;
  error;

  ngOnInit(): void {}

  constructor(private viewportRuler: ViewportRuler, private mediaApiService: MediaApiService) {
    this.target = null;
    this.source = null;
  }

  ngAfterViewInit() {
    const phElement = this.placeholder.element.nativeElement;
    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  onFileChange($event) {
    if (this.presentationOnly) return;

    this.error = null;
    const files = $event.target.files;
    if (this.maxItems >= this.items.length + files.length) {
      [...files].forEach((file) => {
        if (!isFileSizeCorrect(file, this.maxFileSize)) {
          this.error = 'file_too_big';
        } else {
          isImgSizeCorrect(file, this.maxImgRes, (callback) => {
            if (callback) {
              this.loading = true;
              this.upload(file);
            } else {
              this.error = 'img_too_big';
            }
          });
        }
      });
    } else {
      this.error = 'too_many_files';
    }
  }

  emitChange() {
    if (!this.presentationOnly) {
      this.items = this.items.map((item, index) => ({ ...item, order: index }));
      this.filesChange.emit(this.items);
    }
  }

  selectImage(item: Media) {
    this.selectImageChange.emit(item);
  }

  upload(file) {
    this.mediaApiService.uploadFile(file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          this.items.push(event.body[0]);
          this.emitChange();
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  removeItem(item) {
    this.items = this.items.filter((obj) => obj !== item);
    this.emitChange();
  }

  dragMoved(e: CdkDragMove) {
    const point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach((dropList) => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped($event) {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.items, this.sourceIndex, this.targetIndex);
    }

    if (!this.presentationOnly) {
      this.filesChange.emit(this.items);
    }
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop === this.placeholder) {
      return true;
    }

    if (drop !== this.activeContainer) {
      return false;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(dropElement.parentElement.children, this.source ? phElement : sourceElement);
    const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, dropIndex > dragIndex ? dropElement.nextSibling : dropElement);

    this.placeholder._dropListRef.enter(
      drag._dragRef,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    );
    return false;
  };

  // Determines the point of the page that was touched by the user
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`
    const point = __isTouchEvent(event) ? event.touches[0] || event.changedTouches[0] : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    };
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}

// Determines whether an event is a touch event
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
