import { Quiz } from './Quiz';

export interface ShopQuiz {
  start: string;
  finish: string;
  like_count: number;
  prize_count: number;
  prizes: any[];
  status: any;
  id: string;
  quiz: Quiz;
  quiz_id: string;
  quiz_name: string;
  photo_id: string;
  session_count: number;
  shop_id: string;
  isSelected?: boolean;
}
