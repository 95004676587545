<form class="form-group" [formGroup]="form" *ngIf="form">
  <div class="row">
    <div class="form-field-container pb-5">
      <div class="form-field-label">
        {{ 'form.field.new_password' | translate }}
      </div>
      <mat-form-field class="form-field" [floatLabel]="'never'">
        <input [type]="hidePwd ? 'password' : 'text'" matInput formControlName="password_new" />
        <span
          class="form-field-icon"
          [ngClass]="{ active: !hidePwd }"
          [inlineSVG]="'/icons/icon-eye.svg'"
          (click)="hidePwd = !hidePwd; $event.preventDefault()"
        ></span>
        <mat-error *ngIf="getFormControl('password_new').invalid && this.form.get('password_new').touched">
          <ng-container *ngIf="getFormControl('password_new').errors['required']">
            {{ 'form.error.required' | translate }}
          </ng-container>
          <ng-container *ngIf="getFormControl('password_new').errors['matchOther']">
            {{ 'form.error.password_not_same' | translate }}
          </ng-container>
          <ng-container *ngIf="getFormControl('password_new').errors['pattern']">
            {{ 'form.error.password_too_easy' | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="form-field-container pb-5">
      <div class="form-field-label">
        {{ 'form.field.new_password_repeat' | translate }}
      </div>
      <mat-form-field class="form-field" [floatLabel]="'never'">
        <input [type]="hideConfirmPwd ? 'password' : 'text'" matInput formControlName="password_confirm" />
        <span
          class="form-field-icon"
          [ngClass]="{ active: !hideConfirmPwd }"
          [inlineSVG]="'/icons/icon-eye.svg'"
          (click)="hideConfirmPwd = !hideConfirmPwd; $event.preventDefault()"
        ></span>
        <mat-error *ngIf="getFormControl('password_confirm').invalid && this.form.get('password_confirm').touched">
          <ng-container *ngIf="getFormControl('password_confirm').errors['required']">
            {{ 'form.error.required' | translate }}
          </ng-container>
          <ng-container *ngIf="getFormControl('password_confirm').errors['matchOther']">
            {{ 'form.error.password_not_same' | translate }}
          </ng-container>
          <ng-container *ngIf="getFormControl('password_confirm').errors['pattern']">
            {{ 'form.error.password_too_easy' | translate }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
