export enum EducationLevels {
  Basisonderwijs = 0,
  Havo = 1,
  HBO = 2,
  MBO = 3,
  VMBO = 4,
  VWO = 5,
  Wo = 6,
}
export enum WorkExperienceLevels {
  student = 0,
  starter = 1,
  experienced = 2,
  expert = 3,
}
