<div class="card card-other" *ngIf="!translation && !img">
  <div class="card-background"></div>
</div>

<a [href]="link" class="card card-other" *ngIf="translation || img">
  <div class="card-background" [lazyLoad]="img"></div>
  <div class="card-body">
    <div class="card-sub-title" *ngIf="translation">
      {{ 'banners.view_more' | translate }}
    </div>
    <div class="card-title dot" *ngIf="translation">
      <span>{{ 'banners.' + translation | translate }}</span>
    </div>
  </div>
</a>
