import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '@digilize/shared/utils/services/src';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-dialog-no-user',
  templateUrl: './dialog-no-user.component.html',
  styleUrls: ['./dialog-no-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogNoUserComponent implements OnInit {
  takeUntil$ = new Subject();

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogNoUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }

  redirect() {
    if (localStorage.getItem('checkout_return')) {
      const returnUrl = localStorage.getItem('checkout_return');
      localStorage.removeItem('checkout_return');
      window.location.href = returnUrl;
    }
  }

  openDialog(type: string) {
    /* TODO: fix circular dependency to make importing signinup dialog possible */
    /*           const data: SignInUpData = {visible: type} as SignInUpData;
      switch (type) {
        case 'signUp':
          this.dialogService.openDialog(SignInUpComponent, {visible: type} as SignInUpData);
          break;
        case 'signIn':
          this.dialogService.openDialog(SignInUpComponent, {visible: type} as SignInUpData);
          break;
    } */
    this.closeDialog();
  }
}
