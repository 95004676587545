<mat-form-field [floatLabel]="myFloatLabel ? myFloatLabel : 'auto'">
  <mat-label>{{ placeholder }} </mat-label>

  <input
    #searchInput
    type="text"
    matInput
    [placeholder]="placeholder"
    [formControl]="filterControl"
    [matAutocomplete]="auto"
    (ngModelChange)="onInputValueChanged()"
    (input)="onInputChange($event)"
    (focus)="onInputFocus()"
    [autocomplete]="suggestionsOff ? 'new-password' : null"
  />

  <mat-autocomplete
    (closed)="autocompleteOpened = false"
    (opened)="autocompleteOpened = true"
    #auto="matAutocomplete"
    (optionSelected)="handleOptionSelected($event)"
  >
    <mat-option
      class="single-select-search-option"
      [ngClass]="{ wide: wideClass }"
      (onSelectionChange)="changeEmit($event, option); clearInput ? clearSearchInput() : null"
      *ngFor="let option of filteredOptions | async"
      [value]="option.text"
    >
      <span [innerHTML]="option.text"></span>
      <span *ngIf="customOption" class="add-custom-value">{{ 'global.add_to_the_system' | translate }}</span>
    </mat-option>
    <div #endOfList></div>
  </mat-autocomplete>
  <ng-container *ngIf="iconPath; else defaultIcon">
    <span
      *ngIf="!staticIcon"
      class="select-arrow"
      matTextSuffix
      [inlineSVG]="iconPath + (autocompleteOpened ? 'up' : 'down') + '.svg'"
    ></span>
    <span *ngIf="staticIcon" class="custom-icon" [inlineSVG]="iconPath"></span>
  </ng-container>
</mat-form-field>

<ng-template #defaultIcon>
  <span
    class="select-arrow"
    matTextSuffix
    [inlineSVG]="'/icons/icon-arrow-' + (autocompleteOpened ? 'up' : 'down') + '.svg'"
  ></span>
</ng-template>
