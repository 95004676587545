import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { CompanyColorsService } from '../../services/company-colors.service';
import { catchError, throwError } from 'rxjs';
import { CompanyApiService } from '@digilize/shared/data/services/src';

@Component({
  selector: 'bwc-manage-start',
  templateUrl: './manage-start.component.html',
  styleUrls: ['./manage-start.component.scss'],
})
export class ManageStartComponent implements OnInit {
  isLoggedIn = false;
  vacancyId: string;
  companyId: string;
  openFormLink: string;
  inviteLink: string;
  dataLoaded = false;
  showResumeBtn = false;
  showTooltip = false;
  singleOption = false;
  isMobileView = false;
  noApplyState = true;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ManageStartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManageStartComponent,
    private authService: AuthService,
    private companyApiServices: CompanyApiService,
    private companyColorsService: CompanyColorsService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.isMobileView = document.documentElement.clientWidth <= 576;
    this.checkLogIn();
    if (this.data) {
      this.vacancyId = this.data.vacancyId;
      this.companyId = this.data.companyId;
      this.openFormLink = this.data.openFormLink;
      this.inviteLink = this.data.inviteLink;

      if (this.vacancyId || this.companyId || this.openFormLink || this.inviteLink) {
        this.noApplyState = false;
      }

      if ((this.vacancyId && this.companyId) || this.openFormLink || this.inviteLink) {
        this.showResumeBtn = true;
        this.singleOption = false;
      }
    }
    this.getCompanyDetails();
  }

  checkLogIn() {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  getCompanyDetails() {
    if (this.companyId) {
      this.companyApiServices
        .getCompanyStyles(this.companyId)
        .pipe(
          catchError((error) => {
            this.dataLoaded = true;
            return throwError(error);
          })
        )
        .subscribe((data: any) => {
          this.companyColorsService.setCompanyColors(data.colors_model);
          this.renderer.addClass(document.body, 'company-colors');
          this.dataLoaded = true;
        });
    } else if (window.location.hostname !== 'localhost') {
      this.companyApiServices
        .getCompanyStylesByDomain()
        .pipe(
          catchError((error) => {
            this.dataLoaded = true;
            return throwError(error);
          })
        )
        .subscribe((data: any) => {
          this.companyColorsService.setCompanyColors(data.colors_model);
          this.renderer.addClass(document.body, 'company-colors');
          this.dataLoaded = true;
        });
    } else {
      this.dataLoaded = true;
    }
  }

  goToResume() {
    if (this.openFormLink) {
      // user get link from open form link
      this.router.navigate(['/skilldpaspoort'], { queryParams: { ofl: this.openFormLink } });
    } else if (this.vacancyId) {
      // user is applying to the vacancy
      this.router.navigate(['/skilldpaspoort'], { queryParams: { apply_to: this.vacancyId, c: this.companyId } });
    } else if (this.inviteLink) {
      // user is redirected to cv flow from invitation link
      this.router.navigate(['/skilldpaspoort'], { queryParams: { invite: this.inviteLink } });
    } else {
      this.router.navigate(['/skilldpaspoort']);
    }

    this.dialogRef.close();
  }

  goToProfile() {
    this.renderer.removeClass(document.body, 'company-colors');
    if (this.isLoggedIn) {
      this.router.navigate(['/profile-check']);
      this.dialogRef.close();
    } else {
      if (this.vacancyId) {
        // user is applying to the vacancy
        const afterLoginRoute = this.router
          .createUrlTree(['/profile-check'], {
            queryParams: {
              apply_to: this.vacancyId,
            },
          })
          .toString();
        this.router.navigate(['/auth'], { state: { afterLoginRoute } });
      } else if (this.openFormLink) {
        // user is applying to the vacancy
        const afterLoginRoute = this.router
          .createUrlTree(['/profile-check'], {
            queryParams: {
              ofl: this.openFormLink,
            },
          })
          .toString();
        this.router.navigate(['/auth'], { state: { afterLoginRoute } });
      } else {
        this.router.navigate(['/auth']);
      }

      this.dialogRef.close();
    }
  }

  showToolTip(ifShow: boolean, mobile?: boolean) {
    if (!this.isMobileView) {
      this.showTooltip = ifShow;
    }
    if (this.isMobileView && mobile) {
      this.showTooltip = ifShow;
    }
  }
}
