import { PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TagQueryDataDisplay, TagQueryDataType } from '@digilize/shared/definitions/src';

@Injectable({ providedIn: 'root' })
export class QueryTagService {
  constructor(private percentPipe: PercentPipe) {}

  getTagInnerHTMLFromAttributes(dataSet: Object, tag: Element): string {
    const data: string = dataSet[tag.getAttribute('data-name')];
    const type: TagQueryDataType =
      (tag.getAttribute('data-type') as TagQueryDataType) || null; /*might be used in future */
    const display: TagQueryDataDisplay = (tag.getAttribute('data-display') as TagQueryDataDisplay) || null;
    const format: string = tag.getAttribute('data-format');

    if (display === 'number') {
      const parsedData: number = Number.parseFloat(data);
      if (isNaN(parsedData)) return data;
      const percent = this.percentPipe.transform(parsedData / 100, format);
      /* we assume user will add % sign in cms if he wants to */
      return percent.replace('%', '');
    } else if (display === 'check' && (data.toLowerCase() === 'false' || data.toLowerCase() === 'true')) {
      const checkValue = JSON.parse(data.toLowerCase());
      return checkValue ? "<img src='assets/icons/icon-check.svg' />" : "<img src='assets/icons/icon-x.svg' />";
    } else if (display === 'button') {
      // place for callback if needed in future
      return null;
    } else {
      return data;
    }
  }
}
