import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FiltersApiService {
  constructor(private http: HttpClient) {}

  getFilters = (params) =>
    this.http.get(`/api/v1.0/filters`, {
      params,
    });

  getFilterNameValues = (filterName, params) =>
    this.http.get(`/api/v1.0/filter/${filterName}/values`, {
      params,
    });
}
