import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit {
  hidecancelBtn = false;
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmComponentData
  ) {}

  ngOnInit(): void {
    if (!this.data.cancelBtn) {
      this.hidecancelBtn = !this.data.cancelBtn;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  handleConfirm() {
    this.dialogRef.close(true);
  }
}

export interface DialogConfirmComponentData {
  title: string;
  descriptionHtml: string;
  confirmBtn: string;
  closeIconPath?: string;
  cancelBtn?: boolean;
}
