import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { addWebp } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-dialog-scan-qr',
  templateUrl: './dialog-scan-qr.component.html',
  styleUrls: ['./dialog-scan-qr.component.scss'],
})
export class DialogScanQrComponent implements OnInit {
  loading = true;
  voucher: any;
  barcode;

  constructor(
    public dialogRef: MatDialogRef<DialogScanQrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    /*    if (this.data.voucherId) {
      this.getVoucher(this.data.voucherId);
    } */
  }

  closeDialog() {
    this.dialogRef.close();
  }

  handleError(error) {
    this.alertService.handleApiError(error.error.error);
    this.loading = false;
  }

  get addWebp() {
    return addWebp();
  }
}
