import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataApiResponse, Event, RecurrenceDate, SearchApiResponse } from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class EventApiService {
  readonly eventBaseUrl = '/api/v1.0/events';
  constructor(private http: HttpClient) {}

  addEvent(event: Omit<Event, 'id'>) {
    return this.http.post<Event>(`${this.eventBaseUrl}`, event);
  }

  editEvent(event: Event) {
    return this.http.put<Event>(`${this.eventBaseUrl}`, event);
  }

  getEvents(start: number, length: number, archived = false, companyId: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('start', start.toString());
    queryParams = queryParams.append('length', length.toString());
    queryParams = queryParams.append('company', companyId);
    if (!archived) {
      queryParams = queryParams.append('event_statuses', '0'); // Draft
      queryParams = queryParams.append('event_statuses', '1'); // Published
    }
    return this.http.get<DataApiResponse<Event[]>>(`${this.eventBaseUrl}`, { params: queryParams });
  }

  getEventTypes() {
    return this.http.get<{ v: string }[]>(`${this.eventBaseUrl}/types`);
  }

  deleteEvent(id: string) {
    return this.http.delete(`${this.eventBaseUrl}/${id}`);
  }

  getEventPublic(id: string) {
    return this.http.get<Event>(`${this.eventBaseUrl}/${id}/public`);
  }

  getEventsPublic() {
    return this.http.get<DataApiResponse<Event[]>>(`${this.eventBaseUrl}/public`);
  }

  getEvent(id: string) {
    return this.http.get<Event>(`${this.eventBaseUrl}/${id}`);
  }

  getRecurrence(id: string, params) {
    return this.http.get<RecurrenceDate>(`${this.eventBaseUrl}/${id}/recurrence`, { params });
  }

  searchEvents(params) {
    const { event_types, ...rest } = params;
    let httpParams = new HttpParams({ fromObject: { ...rest } });
    if (event_types) {
      (event_types as string)
        .split('|')
        .forEach((eventType) => (httpParams = httpParams.append('event_types', eventType)));
    }
    return this.http.get<SearchApiResponse<Event[]>>(`${this.eventBaseUrl}/search`, { params: httpParams });
  }

  addLike(id: string) {
    return this.http.post(`${this.eventBaseUrl}/${id}/like`, null);
  }

  removeLike(id: string) {
    return this.http.delete(`${this.eventBaseUrl}/${id}/like`);
  }

  getLiked() {
    return this.http.get(`${this.eventBaseUrl}/liked`);
  }

  getUsersThatLikeThisEvent(eventId: string) {
    return this.http.get(`${this.eventBaseUrl}/${eventId}/likes`);
  }

  getAttendingUsers(eventId: string) {
    return this.http.get(`${this.eventBaseUrl}/${eventId}/attending`);
  }

  attendEvent(eventId: string) {
    return this.http.post(`${this.eventBaseUrl}/${eventId}/attend`, null);
  }

  leaveEvent(eventId: string) {
    return this.http.delete(`${this.eventBaseUrl}/${eventId}/attend`);
  }

  getAttendingEvents() {
    return this.http.get(`${this.eventBaseUrl}/attending`);
  }

  getShopEvents(shopId: string) {
    return this.http.get(`${this.eventBaseUrl}/${shopId}/events`);
  }

  getExclusiveShopEvents(shopId: string) {
    return this.http.get(`${this.eventBaseUrl}/${shopId}/events/exclusive`);
  }

  getHighlightedEvents() {
    return this.http.get(`${this.eventBaseUrl}/highlighted`);
  }

  getUpcomingEvents(params?) {
    return this.http.get<DataApiResponse<Event[]>>(`${this.eventBaseUrl}/upcoming`, { params });
  }

  getEventUsers(eventId: string) {
    return this.http.get(`${this.eventBaseUrl}/${eventId}/users`);
  }

  parseInvites(id: string) {
    return this.http.post(`${this.eventBaseUrl}/${id}/users/parse`, null);
  }

  addInvites(id: string) {
    return this.http.post(`${this.eventBaseUrl}/${id}/users`, null);
  }

  previewInviteEmail(id: string) {
    return this.http.post(`${this.eventBaseUrl}/${id}/invite/preview`, null);
  }

  resendInvite(id: string) {
    return this.http.post(`${this.eventBaseUrl}/${id}/resend`, null);
  }

  acceptDeclineInvite(id: string) {
    return this.http.post(`${this.eventBaseUrl}/${id}/respond`, null);
  }

  deleteUserEvent(id: string, userId: string) {
    return this.http.delete(`${this.eventBaseUrl}/${id}/users/${userId}`);
  }

  deleteEventInvite(id: string) {
    return this.http.delete(`${this.eventBaseUrl}/${id}/invite`);
  }

  bulkEdit() {
    return this.http.post(`${this.eventBaseUrl}/invite/bulk`, null);
  }

  exportInvites() {
    return this.http.post(`${this.eventBaseUrl}/invite/export`, null);
  }
}
