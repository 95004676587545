<div class="card card-campaign" *ngIf="campaign">
  <div
    class="card-background"
    [lazyLoad]="campaign.media?.length > 0 ? campaign.media[0]?.url1x + '?w=350&h=350&m=2' + addWebp : ''"
  >
    <div class="form-field-container" *ngIf="!campaign.price">
      <mat-checkbox
        class="checkbox"
        [(ngModel)]="campaign.is_selected"
        (change)="setCampaign($event.checked)"
        [disabled]="loading"
      ></mat-checkbox>
    </div>
  </div>
  <div class="card-body d-flex align-items-start flex-column">
    <div class="card-head w-100">
      <div class="card-title dot">
        <span>{{ campaign.name }}</span>
      </div>
      <div class="card-description">
        {{ campaign.description }}
      </div>
    </div>
    <div class="mt-auto w-100">
      <div class="row">
        <div class="col-12">
          <div class="socials">
            <div *ngIf="campaign.platforms">
              <img
                class="social-img"
                *ngFor="let platform of campaign.platforms?.split('|')"
                [lazyLoad]="'/assets/social/' + platform.toLowerCase() + '.png'"
              />
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <div class="row">
            <div class="col-6 align-self-center price text-left" *ngIf="campaign.price">
              {{ campaign.price | currency: 'EUR' }}
            </div>
            <div class="col-6 align-self-center warning text-left" *ngIf="isCampaignAlertVisible() && !campaign.price">
              <span class="mr-1" inlineSVG="/icons/icon-alert-triangle.svg"></span>
              <span>{{ 'campaigns.photo_required' | translate }}</span>
            </div>
            <div
              class="align-self-center ml-auto text-md-right"
              [ngClass]="[isCampaignAlertVisible() ? 'col-6' : 'col-12']"
            >
              <a
                [routerLink]="['../campaigns', shopId, campaign.id]"
                target="_blank"
                class="btn btn-primary d-block d-md-inline w-100"
              >
                {{ 'global.open' | translate }}
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-6 align-self-center warning text-left" *ngIf="isCampaignAlertVisible() && campaign.price">
              <span class="mr-1" inlineSVG="/icons/icon-alert-triangle.svg"></span>
              <span>{{ 'campaigns.photo_required' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
