import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyColorsService {
  constructor() {}

  setCompanyColors(colors) {
    document.documentElement.style.setProperty('--bwh-background-c', colors.background);
    document.documentElement.style.setProperty('--bwh-background-secondary-c', colors.background_secondary);
    document.documentElement.style.setProperty('--bwh-primary-c', colors.primary);
    document.documentElement.style.setProperty('--bwh-primary-text-c', colors.primary_text);
    document.documentElement.style.setProperty('--bwh-secondary-c', colors.secondary);
    document.documentElement.style.setProperty('--bwh-secondary-text-c', colors.secondary_text);
  }
}
