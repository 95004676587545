import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeolocationApiService {
  constructor(private http: HttpClient) {}

  getUserIp = () => this.http.get('https://geolocation-db.com/json/');
}
