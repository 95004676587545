import { ProductType } from '../enums/ProductType';
import { Media } from './Media';
import { ProductAdditionalCost } from './ProductAdditionalCost';
import { ProductDescriptor } from './ProductDescriptor';
import { ProductGroup } from './ProductGroup';
import { ProductPrice } from './ProductPrice';
import { ProductVariant } from './ProductVariant';
import { Shop } from './Shop';

export interface Product {
  id: string;
  company_id: string;
  name: string;
  subtitle: string;
  description: string;
  url: string;
  maker: string;
  tags: string;
  label: string;
  type: ProductType;
  available_from: string;
  available_to: string;
  sku: string;
  barcode: string;
  instances_infinite: boolean;
  instances_allow_exceed_amount: boolean;
  instances_available: number;
  instances_amount: number;
  instances_amount_difference: number;
  instances: number; // 0 - Unknown 1 = Unavailable 2 = Available
  max_quantity_per_order: number;
  current_price: ProductPrice;
  media_ids: string[];
  media: Media[];
  prices: ProductPrice[];
  shop_ids: string[];
  shops: Shop[];
  variants: ProductVariant[];
  additional_costs: ProductAdditionalCost[];
  descriptors: ProductDescriptor[];
  regular_amount?: number; // Only for front usage
  custom_label: string; // Only for front usage
  group_ids: string[];
  groups: ProductGroup[];
  highlighted_to: string;
  highlighted_to_error: false;
  liked: boolean;
  voucher_description: string;
  voucher_available_to: string;
  voucher_photo_id: string;
  voucher_photo: string;
  voucher_generate_codes: boolean;
  upsell_ids: string[];
  import_feed_entry_id: string;
  voucher_single_code: string;
  voucher_discount: string;
}

export enum ProductInstances {
  Unknown = 0,
  Available = 1,
  Unavailable = 2,
}
