import { ENTER, TAB, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import {
  Alert,
  AlertType,
  ALERT_CUSTOM_POST_ADDED,
  ALERT_POST_INACTIVE_SHOP,
  Media,
  PostsOverview,
  Shop,
} from '@shared/definitions';
import { CampaignApiService, ShopApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert'; 

@Component({
  selector: 'lib-dialog-draft-post',
  templateUrl: './dialog-draft-post.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDraftPostComponent implements OnInit {
  loading = true;
  shopId;
  campaignId;
  companyId;
  separator = '|';
  minDate = moment().add(1, 'day').toDate(); // Min date for post is 24h from now
  posts: PostsOverview; // posts - templates, shop_posts - planned posts
  activePost: any;
  nextPostTemplatedId;
  prevPostTemplatedId;
  shops: Shop[] = [];
  selectedShops: Shop[] = [];
  shopSearchValue = new UntypedFormControl();
  listSeparator = '|';
  draftPostId;
  platforms = [
    {
      key: 'Facebook',
      value: true,
    },
    {
      key: 'Instagram',
      value: true,
    },
    {
      key: 'Tiktok',
      value: true,
    },
  ];

  formDraftPost: UntypedFormGroup;
  hashtags: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, TAB, COMMA];

  constructor(
    public dialogRef: MatDialogRef<DialogDraftPostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campaignApiService: CampaignApiService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private shopApiService: ShopApiService
  ) {}

  ngOnInit(): void {
    this.shopId = this.data.shopId;
    this.campaignId = this.data.campaignId;
    this.companyId = this.data.companyId;
    this.draftPostId = this.data.postId;
    this.getShops(this.companyId);

    if (this.draftPostId) {
      this.getDraftPost(this.draftPostId);
    } else {
      this.setFormAndInitialData();
    }

    this.setFormAndInitialData(this.data);
  }

  getShops(companyId) {
    this.shopApiService.getShops({ company: companyId }).subscribe(
      (response: any) => {
        this.shops = response.data;
        const defaultShop = this.shops.find((shop) => shop.id === this.shopId);
        if (defaultShop) {
          this.addShop(defaultShop);
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  removeShop(shop: Shop) {
    this.selectedShops = this.selectedShops.filter((obj) => obj.id !== shop.id);
  }

  addShop(shop: Shop) {
    if (shop && !this.selectedShops.some((obj) => obj.id === shop.id)) {
      this.selectedShops.push(shop);
    }
  }

  setFormAndInitialData(post?) {
    if (post) {
      this.formDraftPost = new UntypedFormGroup({
        title: new UntypedFormControl(post.title, [Validators.required]),
        body: new UntypedFormControl(post.body),
        hashtags: new UntypedFormControl(post.hashtags),
        planned: new UntypedFormControl(post.planned),
        platforms: new UntypedFormControl(post.platforms, [Validators.required]),
        media: new UntypedFormControl(post.media),
        media_ids: new UntypedFormControl(post.media_ids),
      });
    } else {
      this.formDraftPost = new UntypedFormGroup({
        title: new UntypedFormControl('', [Validators.required]),
        body: new UntypedFormControl(''),
        hashtags: new UntypedFormControl(''),
        planned: new UntypedFormControl(''),
        platforms: new UntypedFormControl('', [Validators.required]),
        media: new UntypedFormControl(''),
        media_ids: new UntypedFormControl(''),
      });
    }
    this.loading = false;
  }

  onSubmit() {
    this.loading = true;
    const draftPost = this.getDraftPostData();
    if (this.draftPostId) {
      this.campaignApiService.editDraftPost({ ...draftPost, id: this.draftPostId }).subscribe(
        (response: any) => {
          this.loading = false;
          this.closeDialog();
        },
        (error) => {
          this.handlePostError(error);
        }
      );
    } else {
      this.campaignApiService.addDraftPost(draftPost).subscribe(
        (response: any) => {
          this.loading = false;
          this.alertService.setAlert(ALERT_CUSTOM_POST_ADDED);
          this.closeDialog();
        },
        (error) => {
          this.handlePostError(error);
        }
      );
    }
  }

  getDraftPost(draftPostId) {
    this.campaignApiService.getDraftPost(draftPostId).subscribe(
      (response: any) => {
        this.setFormAndInitialData(response);
      },
      (error) => {}
    );
  }

  getDraftPostData() {
    const shopIds = this.selectedShops.map((shop) => shop.id);
    const formData = this.formDraftPost.getRawValue();
    delete formData.media;
    return { shop_ids: shopIds, ...this.formDraftPost.getRawValue() };
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  addHashtag($event: MatChipInputEvent) {
    const hashtags = this.getHashtags();
    if (($event.value || '').trim() && !hashtags.some((obj) => obj === $event.value)) {
      hashtags.push($event.value);
    }
    $event.input.value = '';
    this.updateFormWithHashtags(hashtags.join(this.listSeparator));
  }

  updateFormWithHashtags(hashtag: string) {
    this.formDraftPost.get('hashtags').setValue(hashtag);
  }

  removeHashtag(keyword) {
    const hashtag = this.getHashtags().filter((k) => k !== keyword);
    this.updateFormWithHashtags(hashtag.join(this.listSeparator));
  }

  getHashtags(): string[] {
    const controlValue = this.formDraftPost.get('hashtags').value;
    return controlValue ? controlValue.split(this.listSeparator) : [];
  }

  getFormControl(name) {
    return this.formDraftPost.get(name);
  }

  getFormObject() {
    return this.formDraftPost.getRawValue();
  }

  updateFormWithImages(event: Media[]) {
    const mediaId = [];
    event.forEach((obj: Media) => {
      mediaId.push(obj.id);
    });
    this.getFormControl('media').setValue(event);
    this.getFormControl('media_ids').setValue(mediaId);
  }

  getSocialPlatforms(): string[] {
    const controlValue = this.formDraftPost.get('platforms').value;
    return controlValue ? controlValue.split(this.listSeparator) : [];
  }

  updatePlatforms(key, checked) {
    if (checked) {
      const platforms = this.getSocialPlatforms();
      if ((key || '').trim() && !platforms.some((obj) => obj === key)) {
        platforms.push(key);
      }
      this.formDraftPost.get('platforms').setValue(platforms.join(this.listSeparator));
    } else {
      const platforms = this.getSocialPlatforms().filter((k) => k !== key);
      this.formDraftPost.get('platforms').setValue(platforms.join(this.listSeparator));
    }
  }

  handlePostError(error) {
    this.loading = false;
    if (error.error.error.info?.kind === 'range_exceeded') {
      const alert: Alert = {
        type: AlertType.Error,
        title: {
          message: 'Error',
          translated: false,
        },
        message: {
          message: `${this.translateService.instant('alert.error.kind.range_exceeded')} (min: ${moment(
            error.error.error.info?.min
          ).format('DD-MM-YYYY HH:mm')} max: ${moment(error.error.error.info?.max).format('DD-MM-YYYY HH:mm')})`,
          translated: false,
        },
      };
      this.alertService.setAlert(alert);
    } else if (error.error.error.info?.kind === 'inactive' && error.error.error.info?.field === 'shop_id') {
      this.alertService.setAlert(ALERT_POST_INACTIVE_SHOP);
    } else {
      this.alertService.handleApiError(error.error.error);
    }
  }

  cleanNulls(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === '') {
        delete obj[key];
      }
    });
    return obj;
  }
}
