import { Media } from './Media';

export interface QuizQuestionAnswer {
  id: string;
  quiz_question_id: string;
  photo_id: string;
  photo: Media;
  text: string;
  explanation: string;
  score: number;
  valid: boolean;
  order: number;
  tenant_id: string;
}
