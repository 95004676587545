import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class FilterPresetApiService {
  constructor(private http: HttpClient) {}

  createFilterPreset = (params: any) => this.http.post('/api/v1.0/filter-preset', params);

  getFilterPreset = (params) =>
    this.http.get(`/api/v1.0/filter-preset`, {
      params,
    });

  getFilterPresetById = (id) => this.http.get(`/api/v1.0/filter-preset/${id}`);

  removeFilterPreset = (id) => this.http.delete(`/api/v1.0/filter-preset?id=${id}`);

  checkFilterPresetName = (params) =>
    this.http.get(`/api/v1.0/filter-preset/name-exists`, {
      params,
    });
}
