import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EducationService {
  constructor(private http: HttpClient) {}

  getEducationMy = () => this.http.get('/api/v1.0/education/my');

  putEducationMy = (req: any) => this.http.put('/api/v1.0/education/my', req);

  getEducation = (searchValue: string) =>
    this.http.get('/api/v1.0/education/', {
      params: {
        'search.value': searchValue,
        length: 15,
      },
    });

  getSchoolsData = (searchValue: string, code: string) =>
    this.http.get('/api/v1.0/education/schools', {
      params: {
        'search.value': searchValue,
        country: code,
        length: 15,
      },
    });

  getCategoriesData = (searchValue: string, code: string) =>
    this.http.get('/api/v1.0/education/categories', {
      params: {
        'search.value': searchValue,
        country: code,
        length: 15,
      },
    });

  // getLevels = () => this.http.get('/api/v1.0/education/levels');

  getLevels = (searchValue: string, code: string) =>
    this.http.get('/api/v1.0/education/levels', {
      params: {
        'search.value': searchValue,
        country: code,
        length: 15,
      },
    });

  getCategories = () => this.http.get('/api/v1.0/education/categories');

  // getCategories = (searchValue: string) =>
  // this.http.get('/api/v1.0/education/categories', {
  //   params: {
  //     'search.value': searchValue,
  //     length: 10,
  //   },
  // });

  // getCountries = () => this.http.get('/api/v1.0/education/countries');

  getCountries = (lang) =>
    this.http.get('/api/v1.0/education/countries', {
      params: {
        Language: lang,
      },
    });
}
