import { Component } from '@angular/core';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { I18nService } from '@digilize/shared/feature/modules/i18n/src';

@Component({
  selector: 'bwc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  token = {
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwMjQ0MmEwMi0yZGMyLTQ1MzItODVhYS1lMmI1YWUyODQ2MGMiLCJzdWIiOiIwOGRiNzAxMi0xM2JjLTRjNzYtOGZlYy04ZDk1YTRkNDMzOGQiLCJpYXQiOiIxNjg3MTAyNzE0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVXNlciIsIm5iZiI6MTY4NzEwMjcxNCwiZXhwIjoxNjg3MTg5MTE0LCJpc3MiOiJodHRwczovL3d3dy5nb29nbGUuY29tLyIsImF1ZCI6Imh0dHBzOi8vd3d3Lmdvb2dsZS5jb20vIn0.dU5P6Btgy_2Lodj8_meYJhYh0AyLZfwnZh-YW7QVj8c',
    refresh_token:
      'gTVY5l7uqU8tl8cxMd7lofxNduRt7QbWBTQUE7iqIcySRZZTm4A0fRI9B72mVEHfwofgYQqVcVm4dwZ012R7wsiM99tFUmMcmYPDoZcXY2TJCmlDbCzab8azfGPifMAMOThKkVDwXZjFD8FKTJoqiMDBbGstEH9RbGYDB7AP0szI3ldMLPBqa1TXWuIDBx1OdhfctvLmSVdxjVyj95ybQEUSwlOTZB7RLETxtgabik3IZdFdg26jtBgC6roccibFfp5PBmmcmRpTtQKmdK8fDyhqYbj1vUa7PorZGn3ZIE8pt1nEeeAzcs1UJx4u9Bqnm8jhgomDNr0v9euQyBkI15HmbXXDGA',
  };

  constructor(private i18nService: I18nService, private authService: AuthService) {
    this.i18nService.init();
    // this.authService.setSession(this.token);
  }
}
