import { Media } from "./Media";
import { VoucherEmailStatus } from "../enums/VoucherEmailStatus";


export interface Voucher {
  id: string;
  product_id: string;
  cart_line_id: string;
  order_line_id: string;
  code: string;
  code_type: number;
  status: number;
  product_name: string;
  buyer_email: string;
  buyer_first_name: string;
  buyer_last_name: string;
  buyer_display_name: string;
  buyer_email_id: string;
  external_email_status: VoucherEmailStatus;
  order_paid_at: string;
  order_inserted: string;
  voucher_available_to: string;
  voucher_description: string;
  photo_id: string;
  photo: Media;
}

export enum VoucherCode {
  SystemGeneratedCodes = 'systemGeneratedCodes',
  ExternalCodes = 'externalCodes',
  SingleCode = 'singleCode'
}

export const VoucherCodeOptions = [
  {
      key: 'product.voucher_code.system_generated_codes',
      value: VoucherCode.SystemGeneratedCodes
  },
  {
      key: 'product.voucher_code.external_codes',
      value: VoucherCode.ExternalCodes
  },
  {
      key: 'product.voucher_code.single_code',
      value: VoucherCode.SingleCode
  },
];
