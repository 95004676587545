import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-layout-radial',
  templateUrl: './dialog-layout-radial.component.html',
  styleUrls: ['./dialog-layout-radial.component.scss'],
})
export class DialogLayoutRadialComponent implements OnInit {
  @Input() btnText = '';
  @Input() disableSubmit = false;
  @Input() minHeight = '300px';
  @Input() dialogRef: MatDialogRef<any>;
  @ContentChild('content') content!: TemplateRef<any>;
  @ContentChild('actions') actions!: TemplateRef<any>;
  /*  TODO: rename close */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  closeDialog() {
    this.close.emit();
    this.dialogRef.close();
  }

  handleConfirm() {
    this.confirm.emit();
  }
}
