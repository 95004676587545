import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-invitation-preview',
  templateUrl: './dialog-invitation-preview.component.html',
  styleUrls: ['./dialog-invitation-preview.component.scss'],
})
export class DialogInvitationPreviewComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogInvitationPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const dstFrame = document.getElementById('preview-iframe') as any;
    const dstDoc = dstFrame.contentDocument || dstFrame.contentWindow.document;
    dstDoc.write(this.data.htmlPreview);
  }
}
