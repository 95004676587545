import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FileStepData } from '@digilize/shared/definitions/src/lib/interfaces/FileStepData';
import { fileTypes } from '@digilize/shared/definitions/src/lib/interfaces/fileTypes';
import { FILETYPES } from '@digilize/shared/definitions/src/lib/constants/fileTypes';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { CvService } from '../../../services/cv.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'bwc-uploaded-cv',
  templateUrl: './uploaded-cv.component.html',
  styleUrls: ['./uploaded-cv.component.scss'],
  animations: [trigger('fadeInOut', [state('void', style({ opacity: 0 })), transition('void <=> *', animate(300))])],
})
export class UploadedCvComponent implements OnInit {
  @Input() uploadData: FileStepData;
  @Input() openFormLink: string;
  @Input() inviteLink: string;
  @Input() vacancyId: string;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() currentStep: number;
  @Input() previousStep: number;
  @Input() steps: number;
  @Output() dataStep = new EventEmitter();

  fileTypes: fileTypes[] = FILETYPES;
  uploadedFile: File;
  loading = false;
  options: AnimationOptions = {
    path: './assets/animations/animation-checking.json',
    loop: false,
  };

  constructor(private cvService: CvService, private authService: AuthService) {}

  ngOnInit(): void {
    this.uploadedFile = this.uploadData.file;
  }

  removeFile() {
    const data = { previousStep: this.currentStep, step: 1 };
    this.dataStep.emit(data);
  }

  changeStep(data: FileStepData) {
    this.dataStep.emit(data);
  }

  startCheckingCV() {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', this.uploadedFile);
    if (this.openFormLink) {
      formData.append('OpenLinkId', this.openFormLink);
    }
    if (this.inviteLink) {
      formData.append('InvitationId', this.inviteLink);
    }
    if (this.vacancyId) {
      formData.append('VacancyId', this.vacancyId);
    }

    this.cvService
      .uploadCV(formData)
      .pipe(
        catchError((err) => {
          this.loading = false;
          console.error('CV upload failed:', err);
          return throwError(err);
        })
      )
      .subscribe((resp: any) => {
        this.authService.setSession({
          token: resp.anonymous_user_token,
          refresh_token: resp.anonymous_user_refresh_token,
        });
        this.loading = false;
        const data = { previousStep: this.currentStep, step: 3 };
        this.changeStep(data);
      });
  }

  goPrev() {
    this.dataStep.emit({ previousStep: this.currentStep, step: 1 });
  }
}
