<div class="children-same-height" *ngIf="sameHeightElements">
  <ng-container *ngFor="let option of options">
    <button
      class="btn btn-secondary option"
      [ngClass]="{ active: option.val === control.value }"
      (click)="handleClick(option)"
    >
      <span [ngStyle]="{ textAlign: align }" class="wide-span" *ngIf="withInnerHTML" [innerHTML]="option.text"></span>
      <span [ngStyle]="{ textAlign: align }" class="wide-span" *ngIf="!withInnerHTML">{{ option.text }}</span>
      <div
        *ngIf="option.tooltip && option.tooltip !== ''"
        #tooltip="matTooltip"
        (click)="tooltip.toggle(); $event.stopPropagation()"
        [matTooltip]="option.tooltip"
        class="info-icon"
        inlineSVG="/icons/icon-info.svg"
      ></div>
    </button>
  </ng-container>
</div>
<div class="layout" *ngIf="!sameHeightElements">
  <ng-container *ngFor="let option of options">
    <button
      class="btn btn-secondary option"
      [ngClass]="{ active: option.val === control.value }"
      (click)="handleClick(option)"
    >
      <span *ngIf="withInnerHTML" [innerHTML]="option.text"></span>
      <span *ngIf="!withInnerHTML">{{ option.text }}</span>
      <div
        *ngIf="option.tooltip && option.tooltip !== ''"
        #tooltip="matTooltip"
        (click)="tooltip.toggle(); $event.stopPropagation()"
        [matTooltip]="option.tooltip"
        class="info-icon"
        inlineSVG="/icons/icon-info.svg"
      ></div>
    </button>
  </ng-container>
</div>
