import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Answer } from '@digilize/shared/definitions/src';
import { getFirstControlWithError, stripHTML } from '@digilize/shared/utils/helpers/src';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'lib-multi-dropdown-autocomplete',
  templateUrl: './multi-dropdown-autocomplete.component.html',
  styleUrls: ['./multi-dropdown-autocomplete.component.scss'],
})
export class MultiDropdownAutocompleteComponent implements OnInit {
  @Input() state: string[] = [];
  _answers: Answer[];
  @Input() set answers(answers: Answer[]) {
    this._answers = answers.map((answer) => ({ ...answer, text: stripHTML(answer.text) }));
  }
  @Input() formGroup: FormGroup = new FormGroup({ mockControl1: new FormControl(), mockControl2: new FormControl() });
  @Input() placeholder: string;
  @ViewChild('autoInput') autoInput: ElementRef;

  filterAnswers: Observable<Answer[]>;
  filterString = '';
  autoCompleteControl: FormControl;
  selectedAnswers$: Observable<Answer[]>;
  public getFirstControlWithError = getFirstControlWithError;
  constructor() {}
  ngOnInit(): void {
    this.setControl();
  }

  setControl() {
    this.selectedAnswers$ = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      map((checkboxes: Record<string, boolean>) => {
        return Object.entries(checkboxes)
          .filter(([id, value]) => value)
          .map(([id]) => this._answers.find((answer) => answer.val === id));
      })
    );
    this.autoCompleteControl = new FormControl();
    this.filterAnswers = this.autoCompleteControl.valueChanges.pipe(
      startWith<string>(''),
      map((value) => (typeof value === 'string' ? value : this.filterString)),
      map((filter) => this.filter(filter))
    );
  }

  filter = (filter: string): Answer[] => {
    if (filter.length <= 0) {
      return [...this._answers];
    }

    return this._answers.filter((option) => {
      return option.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    });
  };

  handleLabelClick(event: Event) {
    event.stopPropagation();
    setTimeout(() => {
      this.autoInput.nativeElement.focus();
      this.autoCompleteControl.setValue('');
      this.formGroup.controls[Object.keys(this.formGroup.controls)[0]].updateValueAndValidity();
    }, 0);
  }

  removeChip = (data: Answer): void => {
    this.formGroup.markAllAsTouched();
    this.formGroup.controls[data.val].setValue(false);
    this.formGroup.controls[Object.keys(this.formGroup.controls)[0]].updateValueAndValidity();
  };
}
