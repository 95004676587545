// Must be in duch
export const ORDER_USER_STATUS = [
  {
    key: 'In afwachting',
    value: 'In afwachting',
  },
  {
    key: 'In behandeling',
    value: 'In behandeling',
  },
  {
    key: 'Verzonden',
    value: 'Verzonden',
  },
  {
    key: 'Afgeleverd',
    value: 'Afgeleverd',
  },
  {
    key: 'Geannuleer',
    value: 'Geannuleer',
  },
];
