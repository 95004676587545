import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-smile-input',
  templateUrl: './smile-input.component.html',
  styleUrls: ['./smile-input.component.scss'],
})
export class SmileInputComponent implements OnInit {
  @Input() formControl: AbstractControl;
  @Output() chose = new EventEmitter<string>();
  smiles: {text: string, smileSrc: string;}[] = [
    {text: 'Awful', smileSrc: 'disappointed.svg'},
    {text: 'Bad', smileSrc: 'angry-3.svg'},
    {text: 'Not good', smileSrc: 'sad-1.svg'},
    {text: 'Neutral', smileSrc: 'thinking-1.svg'},
    {text: 'Okay', smileSrc: 'happy.svg'},
    {text: 'Good', smileSrc: 'happy-2.svg'},
    {text: 'Great', smileSrc: 'happy-3.svg'},
  ];
  constructor() {}

  ngOnInit(): void {}

  handleClick(option: string) {
    this.formControl.setValue(option);
    this.chose.emit(option);
  }
}
