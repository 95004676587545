<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitClose)="close()"
  (emitPrev)="goPrev()"
></bwh-lib-step-control-bar>
<div class="text-center">
  <div class="main-header">
    <h1 class="main-title">{{ 'global.soft_skills' | translate }}</h1>
  </div>
  <div class="scrollable-content">
    <div class="second-header">
      <h3 class="select-txt">{{ 'global.soft_skills_select' | translate }}</h3>
      <h5 class="sugestions-txt">{{ 'global.soft_skills_sugest' | translate }}</h5>
    </div>
    <div class="skills-container">
      <bwh-lib-skills-list
        class="added-list"
        [form]="form"
        [skillsArrayName]="'skillsArray'"
        [otherArrayName]="'toAddSkillsArray'"
        [removeAction]="true"
        [maxItems]="maxItems"
      ></bwh-lib-skills-list>
      <span class="line"></span>
      <bwh-lib-skills-list
        class="proposed-list"
        [form]="form"
        [skillsArrayName]="'toAddSkillsArray'"
        [otherArrayName]="'skillsArray'"
        [addAction]="true"
        [maxItems]="maxItems"
      ></bwh-lib-skills-list>
    </div>
  </div>
  <!-- <div class="skills-sum">{{ this.skillsArray.length }} {{ 'global.skills_selected' | translate }}</div> -->
  <ul class="next-button-container">
    <!-- <li><button (click)="checkForm()">check form</button></li> -->
    <li *ngIf="prevBut === true">
      <button class="btn w-md h-lg primary prev-btn" type="button" (click)="goPrev()">
        {{ 'global.previous' | translate }}
      </button>
    </li>
    <li>
      <button
        *ngIf="!editing"
        class="btn w-md h-lg secondary"
        type="submit"
        (click)="onSubmit()"
        [disabled]="!formValid"
      >
        {{ 'global.next_button' | translate }}
      </button>
      <button *ngIf="editing" class="btn w-md h-lg secondary" type="submit" (click)="onEdit()" [disabled]="!formValid">
        {{ 'global.save' | translate }}
      </button>
    </li>
  </ul>
</div>
