import { EmailVerbEnum } from '../enums';
import { LanguageCode } from '../types';

export interface EmailVerbWithTags {
  verb: EmailVerb;
  tags: EmailTag[];
}

export interface EmailVerbsWithTags {
  verbs: EmailVerb[];
  tags: Record<keyof typeof EmailVerbEnum, EmailTag>;
}

export interface EmailTag {
  name: string;
  description: string;
  tag: string;
  group: string;
  group_order: number;
}

export interface EmailVerb {
  id: string;
  type: EmailVerbEnum;
  translation_ids: string[];
  translations: EmailVerbTranslation[];
  company_ids: string[];
  research_ids: string[];
  research_group_ids: string[];
  name: string;
  is_default: boolean;
  inserted: Date;
  is_empty: boolean;
  tenant_id: string;
}

export interface EmailVerbTranslation {
  email_verb_id: string;
  language: LanguageCode;
  subject: string;
  preheader: string;
  title: string;
  text: string;
  button1_text: string;
  button2_text: string;
  button3_text: string;
  button1_url: string;
  button2_url: string;
  button3_url: string;
  is_empty: boolean;
  id: string;
}
