<div class="photo-editor-container container-lg page-dashboard">
  <h1 class="page-title dot">{{ "photo_editor.title" | translate }} <span *ngIf="loading">loading</span></h1>
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner class="primary" [diameter]="30"></mat-spinner>
  </div>
  <div class="row mt-4">
    <div class="col-12 col-xl-6">
      <div class="photo-scale-wrapper">
        <div class="photo-container" [ngClass]="editAction"
          (mousemove)="onMouseMove($event)"
          (mousedown)="onMouseDown($event)"
          (mouseup)="onMouseUp()"
          (dragstart)="onDragStart($event)"
          (wheel)="isAction(editActions.Zoom) && onWheel($event)"
          (pinch)="onPinch($event)"
          (pinchend)="onPinchEnd()"
          (touchmove)="onTouchMove($event)"
          (touchstart)="onTouchStart($event)"
          (touchend)="onTouchEnd($event)">
          <img [lazyLoad]="framePhoto?.url1x" class="frame"/>
          <div class="photo-wrapper">
            <img [lazyLoad]="selectedImage?.url1x" [defaultImage]="'/assets/img/empty.webp'" class="user-photo"/>
          </div>
          <div *ngIf="textData && frameLabel" class="text-label" [ngStyle]="{'top': textData.y + 'px', 'left': textData.x + 'px',
            'color': textData.color || defaultTextColor, 'text-align': labelTextAlign(textData.horizontal_alignment),
            'width': textData.width + 'px', 'height': textData.height + 'px' }">{{ frameLabel }}</div>
          <div *ngIf="logoData && shopPhotoUrl" [lazyLoad]="shopPhotoUrl" class="logo-img" [ngStyle]="{'top': logoData.y + 'px', 'left': logoData.x + 'px',
            'width': logoData.width + 'px', 'height': logoData.height + 'px' }"></div>
          <ng-container *ngIf="selectedImage?.url1x && !actionButtonsHideOnSave">
            <div class="action-btns" [class.hidden]="zoomBtnHover" [class.events-off]="actionButtonsDisabled">
              <button class="action-btn xs-hide" (mouseenter)="!isMobile && onZoomMouseEnter()" (click)="activateEditAction($event, editActions.Zoom)" (touchstart)="activateEditAction($event, editActions.Zoom)" [class.active]="isAction(editActions.Zoom)">
                <span inlineSVG="/icons/icon-zoom.svg"></span>
              </button>
              <button class="action-btn" (click)="activateEditAction($event, editActions.Move)" (touchstart)="activateEditAction($event, editActions.Move)" [class.active]="isAction(editActions.Move)">
                <span inlineSVG="/icons/icon-move.svg"></span>
              </button>
              <button class="action-btn" (click)="activateEditAction($event, editActions.Rotate)" (touchstart)="activateEditAction($event, editActions.Rotate)" [class.active]="isAction(editActions.Rotate)">
                <span inlineSVG="/icons/icon-rotate.svg"></span>
              </button>
            </div>
            <div class="zoom-slider-container" (mouseleave)="!isMobile && onZoomMouseLeave($event)" [class.hidden]="!zoomBtnHover">
              <span class="mr-1" inlineSVG="/icons/icon-zoom.svg"></span>
              <mat-slider [min]="minScale" [max]="maxScale" step="0.2" [(ngModel)]="scale" (input)="onZoomSlider($event)"></mat-slider>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div *ngIf="form" class="photo-form-container mt-4 mt-xl-0">
        <p class="title">{{ "photo_editor.image_label" | translate }}</p>
        <p class="title-secondary">{{ "photo_editor.select_image" | translate }}</p>
        <lib-drag-drop-photos
          [items]="getFormControl('media').value ? getFormControl('media').value : []"
          [infoVisible]="false"
          (filesChange)="updateForm($event)"
          [selectedImageId]="selectedImage?.id"
          (selectImageChange)="selectImage($event)"
          [hoverDelete]="false"
          [maxImgRes]="4000"
          [maxFileSize]="20">
          <div *ngIf="false" class="choose-photos-btn-container">
            <button type="button" class="choose-photos-btn" (click)="openChoosePhotosDialog()">
              <div class="edit-post-icon" [inlineSVG]="'/icons/icon-edit-post.svg'"></div>
              <div class="mt-2">{{ 'photo_editor.choose_image_from_library' | translate }}</div>
            </button>
          </div>
        </lib-drag-drop-photos>
        <p class="title mt-5">{{ "photo_editor.text_label" | translate }}</p>
        <p class="title-secondary">{{ "photo_editor.add_text" | translate }}</p>
        <div class="text-input-wrapper form-field-container" [formGroup]="form">
          <mat-form-field class="form-field">
            <input matInput type="text" formControlName="text"/>
          </mat-form-field>
        </div>
        <div class="editor-footer">
          <p class="cta-btn" (click)="saveEditorImageAsDraft()">{{ "photo_editor.save_as_draft" | translate }}</p>
          <div class="btn-wrapper" (click)="saveEditorImage()">
            <button class="btn-primary">{{ "photo_editor.save" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
