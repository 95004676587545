import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService, CampaignApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { getCaptchaHeaders } from '@digilize/shared/utils/helpers/src';
import { DialogService } from '@digilize/shared/utils/services/src';
import { APP_CONFIG, ENV_CONFIG } from '@digilize/shared/utils/tokens';
import { TranslateService } from '@ngx-translate/core';
import {
  Alert,
  AlertType,
  AppConfig,
  EnvConfig,
  ErrorKind,
  PageType,
  PossibleApiPrefix,
  RegisterForm,
  SnackbarType,
  Token,
} from '@shared/definitions';
import * as moment from 'moment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { DialogPagesComponent } from './dialog-pages/dialog-pages.component';
import { DialogUpdateFbDetailsComponent } from './dialog-update-fb-details/dialog-update-fb-details.component';
import {
  DialogLostSessionComponent,
  DialogLostSessionComponentData,
} from './dialog-lost-session/dialog-lost-session.component';

@Injectable({ providedIn: 'root' })
export class AuthService {
  TOKEN_EXPIRATION = 1; // In minutes
  REFRESH_TOKEN_EXPIRATION = 60 * 24 * 365 * 100; // In minutes (100years)
  USER_INTERACTED_WITH_APP = false;
  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvConfig,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private authApiService: AuthApiService,
    private cookieService: CookieService,
    private dialogService: DialogService,
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private campaignApiService: CampaignApiService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private snackbarService: SnackbarService
  ) {
    window.addEventListener('click', () => {
      this.USER_INTERACTED_WITH_APP = true;
    });
  }

  isLoggedIn = () => {
    return this.getRefreshToken();
  };

  getAccessToken(apiPrefix: PossibleApiPrefix = 'api') {
    const token = localStorage.getItem(this.patchWithAppLocalAuthPrefix('token', apiPrefix));
    const tokenExpiration = localStorage.getItem(this.patchWithAppLocalAuthPrefix('tokenExpiration', apiPrefix));
    if (Number(tokenExpiration) > moment.now()) {
      return token;
    }
    return null;
  }

  getRefreshToken(apiPrefix: PossibleApiPrefix = 'api') {
    const token = localStorage.getItem(this.patchWithAppLocalAuthPrefix('refresh_token', apiPrefix));
    const tokenExpiration = localStorage.getItem(this.patchWithAppLocalAuthPrefix('refreshTokenExpiration', apiPrefix));

    if (Number(tokenExpiration) > moment.now()) {
      return token;
    }

    return null;
  }

  logout(logoutRoute: string | null = '/') {
    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('token'));
    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('tokenExpiration'));

    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('refresh_token'));
    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('refreshTokenExpiration'));

    this.cookieService.delete('session_id', '/');
    if (logoutRoute) {
      window.location.href = logoutRoute;
    }
  }

  softLogout(logoutRoute: string | null = '/') {
    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('token'));
    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('tokenExpiration'));

    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('refresh_token'));
    localStorage.removeItem(this.patchWithAppLocalAuthPrefix('refreshTokenExpiration'));

    this.cookieService.delete('session_id', '/');
    if (logoutRoute) {
      this.router.navigate([logoutRoute]);
    }
  }

  refreshAccessToken(apiPrefix: PossibleApiPrefix = 'api') {
    const token: Token = {
      token: localStorage.getItem(this.patchWithAppLocalAuthPrefix('token', apiPrefix)),
      refresh_token: localStorage.getItem(this.patchWithAppLocalAuthPrefix('refresh_token', apiPrefix)),
    };
    const refreshExpiration = localStorage.getItem(
      this.patchWithAppLocalAuthPrefix('refreshTokenExpiration', apiPrefix)
    );

    if (refreshExpiration && Number(refreshExpiration) > moment.now()) {
      return this.authApiService.refreshToken(token, apiPrefix).pipe(
        tap(
          (newToken: any) => {
            this.setSession(newToken, null, apiPrefix);
          },
          (error) => {
            // Logout if can't get refresh token from API
            const isAdminPanel = window.location.href.includes('/admin');
            if (error.status === 429) {
              this.snackbarService.openSnackbarWithApiError(error.error.error, 20000);
              return of(null);
            }
            if (isAdminPanel && this.USER_INTERACTED_WITH_APP) {
              const dialog = this.dialogService.openDialog(DialogLostSessionComponent);
              dialog.afterClosed().subscribe(() => this.softLogout('/auth'));
            } else {
              this.logout();
              window.location.reload();
            }
          }
        )
      );
    }

    return of(null);
  }

  refreshAccessTokenManualRetry(apiPrefix: PossibleApiPrefix = 'api') {
    const token: Token = {
      token: localStorage.getItem(this.patchWithAppLocalAuthPrefix('token', apiPrefix)),
      refresh_token: localStorage.getItem(this.patchWithAppLocalAuthPrefix('refresh_token', apiPrefix)),
    };
    const refreshExpiration = localStorage.getItem(
      this.patchWithAppLocalAuthPrefix('refreshTokenExpiration', apiPrefix)
    );

    if (refreshExpiration && Number(refreshExpiration) > moment.now()) {
      return this.authApiService.refreshToken(token, apiPrefix).pipe(
        tap(
          (newToken: any) => {
            this.setSession(newToken, null, apiPrefix);
          },
          (error) => {
            this.dialogService.openDialog<DialogLostSessionComponentData>(DialogLostSessionComponent, {
              data: { closeable: false },
              disableClose: true,
            });
            if (error.status === 429) {
              this.snackbarService.openSnackbarWithApiError(error.error.error, 20000);
              return of(null);
            }
          }
        )
      );
    }

    return of(null);
  }

  setSession(token: Token, callback = null, apiPrefix: PossibleApiPrefix = 'api') {
    const tokenExpiration = moment().add(this.TOKEN_EXPIRATION, 'minutes');
    const refreshTokenExpiration = moment().add(this.REFRESH_TOKEN_EXPIRATION, 'minutes');

    if (token.token) {
      localStorage.setItem(this.patchWithAppLocalAuthPrefix('token', apiPrefix), token.token);
      localStorage.setItem(
        this.patchWithAppLocalAuthPrefix('tokenExpiration', apiPrefix),
        JSON.stringify(tokenExpiration.valueOf())
      );
    }

    if (token.refresh_token) {
      localStorage.setItem(this.patchWithAppLocalAuthPrefix('refresh_token', apiPrefix), token.refresh_token);
      localStorage.setItem(
        this.patchWithAppLocalAuthPrefix('refreshTokenExpiration', apiPrefix),
        JSON.stringify(refreshTokenExpiration.valueOf())
      );
    }
    this.authApiService.getMe();

    // Claim cart session
    if (callback) {
      const claimCallback = () => {
        if (localStorage.getItem('checkout_return')) {
          const returnUrl = localStorage.getItem('checkout_return');
          localStorage.removeItem('checkout_return');
          window.location.href = returnUrl;
        } else {
          callback();
        }
      };

      const sessionId = this.cookieService.get('session_id');
      if (sessionId) {
        this.authApiService.claimCartSession(sessionId).subscribe(
          (response: any) => {
            claimCallback();
          },
          (error) => {
            claimCallback();
          }
        );
      } else {
        claimCallback();
      }
    }
  }

  facebookRedirect() {
    const isAdminPanel = window.location.href.includes('/admin') ? true : false;
    const client = this.envConfig.facebookAppId;
    // For facebook redirect_uri
    const redirectUrl = encodeURIComponent(window.location.origin + (isAdminPanel ? '/admin' : '') + '/');
    const returnUrl = window.location.pathname; // For redirect after success login
    localStorage.setItem('redirectUrl', redirectUrl);
    localStorage.setItem('returnUrl', returnUrl);
    const scope = 'public_profile,email';
    window.location.href = `https://graph.facebook.com/oauth/authorize?client_id=${client}&redirect_uri=${redirectUrl}&scope=${scope}`;
  }

  // Connect pages

  pagesRedirect(shopId: string, pageType: PageType) {
    const client = this.envConfig.facebookAppId;
    const redirectUrl = encodeURIComponent(window.location.origin + '/admin');
    const returnUrl = window.location.pathname;
    let scope;
    let state;
    localStorage.setItem('shopId', shopId);
    localStorage.setItem('redirectUrl', redirectUrl);
    localStorage.setItem('returnUrl', returnUrl);

    if (pageType === PageType.Facebook) {
      scope = 'public_profile,pages_show_list,pages_manage_posts';
      state = 'pages_facebook';
    } else if (pageType === PageType.Instagram) {
      scope = 'public_profile,pages_show_list,pages_manage_posts,instagram_basic,instagram_content_publish';
      state = 'pages_instagram';
    }

    window.location.href = `https://graph.facebook.com/oauth/authorize?client_id=${client}&redirect_uri=${redirectUrl}&scope=${scope}&state=${state}`;
  }

  handleFbPages(code: string, pageType: PageType) {
    const redirectUrl = localStorage.getItem('redirectUrl');
    const returnUrl = localStorage.getItem('returnUrl').replace('/admin', '');
    const shopId = localStorage.getItem('shopId');

    this.authApiService.getPages(pageType, code, redirectUrl).subscribe(
      (response) => {
        this.dialogService.openDialog(DialogPagesComponent, {
          data: {
            pages: response.data,
            shopId: shopId,
            pageType: pageType,
          },
        });
        if (response.warning.type === 'facebook_revoked') {
          const alert: Alert = {
            type: AlertType.Warning,
            title: {
              message: 'Warning',
              translated: false,
            },
            message: {
              message: this.translateService.instant('social_media_connection.some_shops_no_longer_available'),
              translated: false,
            },
          };
          this.alertService.setAlert(alert);
        }
      },
      (error) => {
        if (error.error?.error?.info?.kind === ErrorKind.INSTAGRAM_PAGE_ID_NOT_FOUND) {
          // No connection between selected Business Account and page
          const alert: Alert = {
            type: AlertType.Warning,
            title: {
              message: 'Warning',
              translated: false,
            },
            message: {
              message: this.translateService.instant('social_media_connection.no_business_connection', {
                pageType: pageType,
              }),
              translated: false,
            },
          };
          this.alertService.setAlert(alert);
        } else {
          this.alertService.handleApiError(error.error.error);
        }
        this.router.navigate([returnUrl]);
      }
    );
  }

  handleFbCode(params: any, app: string) {
    if (params.code) {
      const code = params.code;
      const redirectUrl = localStorage.getItem('redirectUrl');
      const returnUrl = localStorage.getItem('returnUrl');
      const updateDetails = JSON.parse(localStorage.getItem('updateDetails'));

      if (updateDetails) {
        const registerForm: RegisterForm = {
          email: updateDetails.email,
          first_name: updateDetails.first_name,
          last_name: updateDetails.last_name,
          password: null,
          password_confirm: null,
          facebook_code: params.code,
          facebook_redirect_uri: decodeURIComponent(localStorage.getItem('redirectUrl')),
        };
        localStorage.removeItem('updateDetails');
        this.recaptchaV3Service
          .execute('register')
          .pipe(
            switchMap((captchaToken) => {
              return this.authApiService.register(
                registerForm,
                getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey)
              );
            })
          )
          .subscribe(
            (response: any) => {
              this.setSession(response, () => {
                const acquisitionId = this.cookieService.get('acquisition_id');
                if (acquisitionId) {
                  this.registerAcquisition(acquisitionId, redirectUrl, returnUrl, app);
                } else {
                  window.location.href =
                    app === 'dashboard' || this.appConfig.app === 'flixit'
                      ? '/admin/auth'
                      : decodeURIComponent(redirectUrl) + returnUrl;
                }
              });
            },
            (error) => {
              if (error.error.error.type === ErrorKind.CAPTCHA_INVALID) {
                this.snackbarService.openSnackbar(SnackbarType.Error, {
                  duration: 15000,
                  data: { msg: 'alert.custom.seems_you_are_bot', translate: true },
                });
              }
              if (
                error.error.error.type === ErrorKind.VALIDATION_ERROR &&
                error.error.error.info?.kind === ErrorKind.ALREADY_EXISTS
              ) {
                this.dialogService.openDialog(DialogUpdateFbDetailsComponent, {
                  data: {
                    error: 'user_already_exist',
                    ...registerForm,
                  },
                });
              }
            }
          );
      } else {
        this.authApiService.fbLogin(code, redirectUrl).subscribe(
          (response: any) => {
            this.setSession(response, () => {
              const acquisitionId = this.cookieService.get('acquisition_id');
              if (acquisitionId) {
                this.registerAcquisition(acquisitionId, redirectUrl, returnUrl, app);
              } else {
                window.location.href =
                  app === 'dashboard' || this.appConfig.app === 'flixit'
                    ? '/admin/auth'
                    : decodeURIComponent(redirectUrl) + returnUrl;
              }
            });
          },
          (error) => {
            // Some data is missing in facebook account
            if (error.error.error.type === ErrorKind.VALIDATION_ERROR) {
              this.dialogService.openDialog(DialogUpdateFbDetailsComponent);
            } else {
              window.location.href = '/';
            }
          }
        );
      }
    }
  }

  registerAcquisition(id, redirectUrl?, returnUrl?, app?) {
    this.campaignApiService.registerAcquisition(id).subscribe(
      () => {
        this.cookieService.delete('acquisition_id');
        window.location.href =
          app === 'dashboard' || this.appConfig.app === 'flixit'
            ? '/admin/c/0/shops'
            : decodeURIComponent(redirectUrl) + returnUrl;
      },
      (error) => {
        if (error?.status === 400) {
          this.cookieService.delete('acquisition_id');
        }

        if (error.error?.error?.info?.kind === 'already_exists') {
          window.location.href =
            app === 'dashboard' || this.appConfig.app === 'flixit'
              ? '/admin/c/0/shops'
              : decodeURIComponent(redirectUrl) + returnUrl;
        } else {
          this.router.navigate(['niet-gevonden']);
        }
      }
    );
  }

  patchWithAppLocalAuthPrefix(localStorageKey: string, apiPrefix: PossibleApiPrefix = 'api'): string {
    /* to distinguish localstorage keys in certain apps (when deployed on same domain apps can share localstorage)  */
    switch (apiPrefix) {
      case 'api':
        const prefix = this.appConfig.localStorageAuthPrefix || '';
        return `${prefix}${localStorageKey}`;
      case 'api2':
        const prefix2 = this.appConfig.localStorageAuthPrefix2 || '';
        return `${prefix2}${localStorageKey}`;
    }
    const never: never = apiPrefix;
    throw new Error(`${never} is not handled in patchWithAppLocalAuthPrefix`);
  }
}
