import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-speaker-btn',
  templateUrl: './speaker-btn.component.html',
  styleUrls: ['./speaker-btn.component.scss'],
})
export class SpeakerBtnComponent implements OnInit {
  static _id = 0;
  id: number;
  SpeakerBtnComponent = SpeakerBtnComponent;
  @Input() variant: 'small' | 'big' = 'big';
  @Input() audioSrc: string;

  constructor() {
    SpeakerBtnComponent._id = SpeakerBtnComponent._id + 1;
  }

  ngOnInit() {
    this.id = SpeakerBtnComponent._id;
  }

  handleSpeakerClick(el: any) {
    el.play();
  }
}
