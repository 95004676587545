import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeApiService } from '@digilize/shared/data/services/src';
import { ChangePasswordToken, ErrorKind, SnackbarType } from '@digilize/shared/definitions/src';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { CaptchaService } from '@digilize/shared/utils/services/src';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { switchMap } from 'rxjs';

@Component({
  selector: 'lib-dialog-reset-password',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.scss'],
})
export class DialogResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  pwdChanged = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogResetPasswordData,
    private dialogRef: DialogRef<DialogResetPasswordComponent>,
    private profileApiService: MeApiService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private captchaService: CaptchaService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.captchaService.setCaptchaVisibility('visible');
  }

  close(): void {
    this.dialogRef.close();
  }

  resetPassword(): void {
    const { password_new, password_confirm } = this.form.getRawValue();
    const body: ChangePasswordToken = { forgot_token: this.data.forgotToken, password_new, password_confirm };
    this.reCaptchaV3Service
      .execute('changePasswordToken')
      .pipe(
        switchMap((captchaToken) => {
          return this.profileApiService.changePasswordToken(body, this.captchaService.getCaptchaHeaders(captchaToken));
        })
      )
      .subscribe({
        next: () => {
          this.pwdChanged = true;
        },
        error: (error) => {
          if (error.error.error.type === ErrorKind.CAPTCHA_INVALID) {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 15000,
              data: { msg: 'alert.custom.seems_you_are_bot', translate: true },
            });
          }
          throw new Error(error);
        },
      });
  }

  ngOnDestroy(): void {
    this.captchaService.setCaptchaVisibility('hidden');
  }
}

export interface DialogResetPasswordData {
  forgotToken: string;
}
