import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedUiMaterialModule } from '@digilize/shared/ui/material/src';
import { SharedUtilsDirectivesModule } from '@digilize/shared/utils/directives/src';
import { SharedUtilsPipesModule } from '@digilize/shared/utils/pipes/src';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxGlideModule } from 'ngx-glide';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SafePipeModule } from 'safe-pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AbcRadioBtnComponent } from './abc-radio-btn/abc-radio-btn.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AmountComponent } from './amount/amount.component';
import { CardSliderComponent } from './card-slider/card-slider.component';
import { CardAuctionBoardItemComponent } from './cards/card-auction-board-item/card-auction-board-item.component';
import { CardBroadcastComponent } from './cards/card-broadcast/card-broadcast.component';
import { CardCategoryComponent } from './cards/card-category/card-category.component';
import { CardEventComponent } from './cards/card-event/card-event.component';
import { CardOtherComponent } from './cards/card-other/card-other.component';
import { CardPhotoComponent } from './cards/card-photo/card-photo.component';
import { CardProductComponent } from './cards/card-product/card-product.component';
import { CardShopComponent } from './cards/card-shop/card-shop.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipsAutoComponent } from './chips-auto/chips-auto.component';
import { DialogLayoutInnerXComponent } from './dialog-layouts/dialog-layout-inner-x/dialog-layout-inner-x.component';
import { DialogLayoutRadialComponent } from './dialog-layouts/dialog-layout-radial/dialog-layout-radial.component';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';
import { EventIconComponent } from './event-icon/event-icon.component';
import { FormAboutComponent } from './form-about/form-about.component';
import { FormAddressComponent } from './form-address/form-address.component';
import { FormCinemaColorsComponent } from './form-cinema-colors/form-cinema-colors.component';
import { FormCompanyLocationComponent } from './form-company-location/form-company-location.component';
import { FormContactComponent } from './form-contact/form-contact.component';
import { FormDeliveryComponent } from './form-delivery/form-delivery.component';
import { FormExtraCostsComponent } from './form-extra-costs/form-extra-costs.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { MultiSelectComponent } from './form-items/multi-select/multi-select.component';
import { SelectCategoriesComponent } from './form-items/select-categories/select-categories.component';
import { SelectSearchComponent } from './form-items/select-search/select-search.component';
import { SelectComponent } from './form-items/select/select.component';
import { SingleSelectSearchComponent } from './form-items/single-select-search/single-select-search.component';
import { TextEditorComponent } from './form-items/text-editor/text-editor.component';
import { FormKeywordsComponent } from './form-keywords/form-keywords.component';
import { FormOpenHoursComponent } from './form-open-hours/form-open-hours.component';
import { FormPegiComponent } from './form-pegi/form-pegi.component';
import { FormPriceComponent } from './form-price/form-price.component';
import { FormQuizDatesComponent } from './form-quiz-dates/form-quiz-dates.component';
import { FormScanComponent } from './form-scan/form-scan.component';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { TableHeaderTemplateDirective, TableRowTemplateDirective } from './generic-table/generic-table.directive';
import { ImgRadioButtonComponent } from './img-radio-btn/img-radio-btn.component';
import { LoadingColumnAnimationComponent } from './loading-column-animation/loading-column-animation.component';
import { LoadingDotsAnimationComponent } from './loading-dots-animation/loading-dots-animation.component';
import { MobileOverlayComponent } from './mobile-overlay/mobile-overlay.component';
import { MultiAnswerTilesComponent } from './multi-answer-tiles/multi-answer-tiles.component';
import { MultiDropdownAutocompleteComponent } from './multi-dropdown-autocomplete/multi-dropdown-autocomplete.component';
import { MultipleRadioInputsComponent } from './multiple-radio-inputs/multiple-radio-inputs.component';
import { NestedDropdownComponent } from './nested-dropdown/nested-dropdown.component';
import { NotFoundWithImageComponent } from './not-found-with-image/not-found-with-image.component';
import { OrderDndControlComponent } from './order-dnd-control/order-dnd-control.component';
import { ProfileCircleImgComponent } from './profile-circle-img/profile-circle-img.component';
import { ProgressBarTextComponent } from './progress-bar-text/progress-bar-text.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RespondentListComponent } from './respondent-list/respondent-list.component';
import { SingleAnswerTilesComponent } from './single-answer-tiles/single-answer-tiles.component';
import { SmileInputComponent } from './smile-input/smile-input.component';
import { SpeakerBtnComponent } from './speaker-btn/speaker-btn.component';
import { SummaryItemComponent } from './summary-item/summary-item.component';
import { TileRadioBtnComponent } from './tile-radio-btn/tile-radio-btn.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MultiSelectChipsSearchComponent } from './form-items/multi-select-chips-search/multi-select-chips-search.component';
import { DropdownContentComponent } from './dropdown-content/dropdown-content.component';
import { StarsRateComponent } from './stars-rate/stars-rate.component';
import { MultiSelectChipsCheckboxSearchComponent } from './form-items/multi-select-chips-checkbox-search/multi-select-chips-checkbox-search.component';
import { SelectPlainComponent } from './form-items/select-plain/select-plain.component';
import { AddLabelsComponent } from './add-labels/add-labels.component';
import { RemoveLabelsComponent } from './remove-labels/remove-labels.component';
import { NotesComponent } from './notes/notes.component';
import { CreateGroupComponent } from './create-group/create-group.component';

const DIRECTIVES = [TableRowTemplateDirective, TableHeaderTemplateDirective];
const COMPONENTS = [
  SelectComponent,
  SelectPlainComponent,
  SelectCategoriesComponent,
  TextEditorComponent,
  SummaryItemComponent,
  SelectSearchComponent,
  MultiSelectComponent,
  EventIconComponent,
  FormAboutComponent,
  FormAddressComponent,
  FormCinemaColorsComponent,
  FormCompanyLocationComponent,
  FormContactComponent,
  FormDeliveryComponent,
  FormExtraCostsComponent,
  FormHeaderComponent,
  FormKeywordsComponent,
  FormOpenHoursComponent,
  FormPegiComponent,
  FormPriceComponent,
  FormQuizDatesComponent,
  FormScanComponent,
  ProgressBarComponent,
  SingleAnswerTilesComponent,
  SmileInputComponent,
  MultiAnswerTilesComponent,
  MultipleRadioInputsComponent,
  NestedDropdownComponent,
  DropdownListComponent,
  CardSliderComponent,
  CardAuctionBoardItemComponent,
  CardBroadcastComponent,
  CardCategoryComponent,
  CardEventComponent,
  CardOtherComponent,
  CardPhotoComponent,
  CardProductComponent,
  CardShopComponent,
  ChipsAutoComponent,
  TileRadioBtnComponent,
  TimePickerComponent,
  GenericTableComponent,
  MultiDropdownAutocompleteComponent,
  RespondentListComponent,
  LoadingColumnAnimationComponent,
  ProgressBarTextComponent,
  CheckboxComponent,
  SingleSelectSearchComponent,
  FileUploadComponent,
  MultiSelectChipsSearchComponent,
  MultiSelectChipsCheckboxSearchComponent,
  MobileOverlayComponent,
  ImgRadioButtonComponent,
  AccordionComponent,
  LoadingDotsAnimationComponent,
  AbcRadioBtnComponent,
  OrderDndControlComponent,
  AmountComponent,
  DialogLayoutInnerXComponent,
  DialogLayoutRadialComponent,
  DropdownContentComponent,
  StarsRateComponent,
  NotFoundWithImageComponent,
  ProfileCircleImgComponent,
  SpeakerBtnComponent,
  AddLabelsComponent,
  RemoveLabelsComponent,
  NotesComponent,
];
@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, CreateGroupComponent],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    NgxGlideModule,
    HttpClientModule,
    InlineSVGModule,
    NgScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUtilsDirectivesModule,
    SharedUiMaterialModule,
    CKEditorModule,
    SafePipeModule,
    NgxCaptchaModule,
    SharedUtilsPipesModule,
    ColorPickerModule,
    NgxCurrencyModule,
    RouterModule,
    TranslateModule,
    HighchartsChartModule,
    MatExpansionModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    ScrollingModule,
  ],
  exports: [...COMPONENTS, ...DIRECTIVES],
})
export class SharedUiComponentsModule {}
