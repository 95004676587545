import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isFormControlRequired } from '@digilize/shared/utils/helpers';
@Component({
  selector: 'lib-form-contact',
  templateUrl: './form-contact.component.html',
})
export class FormContactComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;
  order = ['phone', 'email', 'website', 'facebook', 'linked_in', 'instagram', 'youtube'];

  constructor() {}

  ngOnInit(): void {
  }

  getFormControl(name) {
    return this.form.get(name);
  }

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }
}
