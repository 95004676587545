<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div [ngClass]="{ 'v-hidden': loading }">
      <div class="dialog-header">
        <h1 class="dialog-title">{{ 'dialog_entrepreneur.entrepreneur?' | translate }}</h1>
      </div>
      <div class="dialog-body pb-0">
        <p class="info mb-0">
          {{ 'dialog_entrepreneur.entrepreneur_copy' | translate }}
        </p>
        <div class="row justify-content-center mb-3">
          <div class="col-10 col-sm-8">
            <img class="img-entrepreneur" [lazyLoad]="'/assets/img/entrepreneur.png'" />
          </div>
        </div>
        <div class="auth-other-actions">
          <div class="d-block text-center mt-2">
            <button class="btn btn-secondary d-block w-100" (click)="closeDialog()">
              {{ 'dialog_entrepreneur.dont_create_company' | translate }}
            </button>
          </div>
          <div class="d-block text-center mt-3">
            <button class="btn btn-secondary d-block w-100" (click)="openCreateCompanyDialog($event)">
              {{ 'dialog_entrepreneur.create_company' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
