import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as htmlToImage from 'html-to-image';
import { from, of } from 'rxjs';
import { catchError, switchMap, timeout } from 'rxjs/operators';
import { DialogChoosePhotosComponent } from '@digilize/shared/feature/components/src/lib/dialogs/dialog-choose-photos/dialog-choose-photos.component';
import { Me, Media, Shop } from '@digilize/shared/definitions/src';
import { DialogService } from '@digilize/shared/utils/services/src';
import { AuthApiService, CampaignApiService, MediaApiService, ShopApiService } from '@digilize/shared/data/services/src';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { isMobile } from '@digilize/shared/utils/helpers/src';

enum PhotoEditActions {
  Zoom = 'zoom',
  Move = 'move',
  Rotate = 'rotate',
  None = ''
}

enum EventTriggerSourceTypes {
  Mouse = 'mouse',
  Touch = 'touch'
}

interface TextData {
  color: string;
  horizontal_alignment: number;
  width: number;
  height: number;
  x: number;
  y: number;
}

interface LogoData {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface EditorMediaAndPhoto {
  draft: boolean;
  media: Media[],
  photo: Media
}

@Component({
  selector: 'lib-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit {
  @Input() data: { media: Media[], media_ids: string[], post: any, shopId: string };
  @Output() saveImages: EventEmitter<EditorMediaAndPhoto> = new EventEmitter<EditorMediaAndPhoto>();
  // @Output() closeEditor: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup = new FormGroup({
    media_ids: new FormControl(),
    media: new FormControl(),
    text: new FormControl('')
  });

  editAction: PhotoEditActions;
  loading: boolean;
  selectedImage: Media | null;
  zoomValue = 1;
  start = { x: 0, y: 0 };
  grabbing = false;
  rotating = false;
  translateX = 0;
  translateY = 0;
  skewX = 0;
  skewY = 0;
  scale = 1;
  rotateDegree = 0;
  readonly minScale = 0.4;
  readonly maxScale = 4;
  readonly scaleStep = 0.2;
  readonly scaleGestureStep = 0.01;
  actionButtonsDisabled = false;
  R2D = 180 / Math.PI;
  angle = 0;
  rotation = 0;
  startAngle = 0;
  center = {
    x: 0,
    y: 0
  };
  lastScale = 1;
  textData: TextData;
  logoData: LogoData;
  framePhoto: Media;
  defaultTextColor: '#000';
  zoomBtnHover: boolean;
  actionButtonsHideOnSave = false;
  me: Me;
  shop: Shop;
  shopPhotoUrl: string;
  draft: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dialogService: DialogService,
    private mediaApiService: MediaApiService,
    private authApiService: AuthApiService,
    private campaignApiService: CampaignApiService,
    private shopApiService: ShopApiService,
  ) {
    this.authApiService.me.subscribe(
      (me: Me) => {
        this.me = me;
      }
    );
  }

  ngOnInit(): void {
    this.setDefaultPhotoEditAction();
    this.setData();
    this.loading = true;
    this.shopApiService.getShop(this.data?.shopId).subscribe((shop: Shop) => {
      this.shop = shop;
      this.shopPhotoUrl = shop.photo?.url1x;
      this.loading = false;
    }, error => {
      console.error(error);
      this.loading = false;
    });
    this.campaignApiService.getPhotoEditorState(this.data?.shopId, this.data?.post?.post_id).subscribe(
      (response: any) => {
        this.setTransformsFromDraft(response);
        this.loading = false;
      }, error => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  setData() {
    if (this.data?.media?.length) {
      this.getFormControl('media')?.setValue(this.data.media);
      this.getFormControl('media_ids')?.setValue(this.data.media_ids);
      this.selectedImage = this.data.media[0];
    }
    if (this.data?.post?.editor_input) {
      this.textData = this.data.post.editor_input.text;
      this.logoData = this.data.post.editor_input.logo;
      this.framePhoto = this.data.post.editor_photo;
    }
  }

  setTransformsFromDraft(draftData) {
    const editorState = JSON.parse(draftData.state);
    this.selectedImage = editorState.editedImage;
    this.getFormControl('media')?.setValue(editorState.media);
    this.getFormControl('text')?.setValue(editorState.text);
    this.scale = editorState.scale;
    this.translateX = editorState.translateX;
    this.translateY = editorState.translateY;
    this.R2D = editorState.R2D;
    this.angle = editorState.angle;
    this.rotation = editorState.rotation;
    this.startAngle = editorState.startAngle;
    this.center = editorState.center;
    this.rotateDegree = editorState.rotateDegree;
    this.setDefaultPhotoEditAction();
    this.setTransform();
  }

  setDefaultPhotoEditAction() {
    this.editAction = this.selectedImage?.url1x ? PhotoEditActions.Move : PhotoEditActions.None;
  }

  onMouseDown(event, eventTriggerSourceType?: EventTriggerSourceTypes) {
    event.preventDefault();
    if (this.isAction(PhotoEditActions.Move)) {
      let offsetX, offsetY;

      if (eventTriggerSourceType && eventTriggerSourceType === EventTriggerSourceTypes.Touch) {
        const photoContainer = this.photoContainerEl;
        const rect = photoContainer.getBoundingClientRect();
        offsetX = (event.touches[0].clientX - window.pageXOffset - rect.left);
        offsetY = (event.touches[0].clientY - window.pageYOffset - rect.top);
      } else {
        offsetX = event.offsetX;
        offsetY = event.offsetY;
      }
      this.start = { x: offsetX - this.translateX, y: offsetY - this.translateY };
      this.grabbing = true;
    }

    if (this.isAction(PhotoEditActions.Rotate)) {
      let clientX, clientY;

      if (eventTriggerSourceType && eventTriggerSourceType === EventTriggerSourceTypes.Touch) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      const imageToZoom = this.imageWrapperEl;
      const rect = imageToZoom.getBoundingClientRect();
      const elTop = rect.top;
      const elLeft = rect.left;
      const elHeight = rect.height;
      const elWidth = rect.width;
      let x;
      let y;
      this.center = {
        x: elLeft + (elWidth / 2),
        y: elTop + (elHeight / 2)
      };
      x = clientX - this.center.x;
      y = clientY - this.center.y;
      this.startAngle = this.R2D * Math.atan2(y, x);
      this.rotating = true;
    }
  }

  onMouseUp() {
    this.grabbing = false;
    this.rotating = false;
    this.actionButtonsDisabled = false;
    if (this.isAction(PhotoEditActions.Rotate)) {
      this.angle += this.rotation;
    }
  }

  onWheel(event, gestureZoomIn?) {
    event.preventDefault();
    const imageToZoom = this.imageWrapperEl;
    let rec = imageToZoom.getBoundingClientRect();
    let notScaledElementX = (event.clientX - rec.x) / this.scale;
    let notScaledElementY = (event.clientY - rec.y) / this.scale;
    let delta = (event.wheelDelta ? event.wheelDelta : -event.deltaY);
    const isGestureZoom = gestureZoomIn === true || gestureZoomIn === false;
    const zoomIn = isGestureZoom ? gestureZoomIn : delta > 0;
    const scaleStep = isGestureZoom ? this.scaleGestureStep : this.scaleStep;
    this.scale = zoomIn ? (this.scale + scaleStep) : (this.scale - scaleStep);
    let triggerTransform = true;

    if (this.scale > this.maxScale) {
      this.scale = this.maxScale;
      triggerTransform = false;
    };

    if (this.scale < this.minScale) {
      this.scale = this.minScale;
      triggerTransform = false;
    };

    if (triggerTransform) {
      const mStep = scaleStep / 2;
      let m = zoomIn ? mStep : -mStep;
      this.translateX += (-notScaledElementX * m * 2) + (imageToZoom.offsetWidth * m);
      this.translateY += (-notScaledElementY * m * 2) + (imageToZoom.offsetHeight * m);
      this.setTransform();
    }
  }

  onZoomSlider(event) {
    const photoContainer = this.photoContainerEl;
    const photoContainerRect = photoContainer.getBoundingClientRect();

    const imageToZoom = this.imageWrapperEl;
    const imgToZoomRect = imageToZoom.getBoundingClientRect();
    const imgContainerCenterX = photoContainerRect.x + photoContainerRect.width / 2;
    const imgContainerCenterY = photoContainerRect.y + photoContainerRect.height / 2;
    let notScaledElementX = (imgContainerCenterX - imgToZoomRect.x) / this.scale;
    let notScaledElementY = (imgContainerCenterY - imgToZoomRect.y) / this.scale;

    const zoomIn = this.scale < event.value;
    this.scale = event.value;
    const mStep = this.scaleStep / 2;
    const m = zoomIn ? mStep : -mStep;

    this.translateX += (-notScaledElementX * m * 2) + (imageToZoom.offsetWidth * m);
    this.translateY += (-notScaledElementY * m * 2) + (imageToZoom.offsetHeight * m);
    this.setTransform();
  }

  resetTransforms() {
    this.scale = 1;
    this.translateX = 0;
    this.translateY = 0;
    this.R2D = 180 / Math.PI;
    this.angle = 0;
    this.rotation = 0;
    this.startAngle = 0;
    this.center = {
      x: 0,
      y: 0
    };
    this.rotateDegree = 0;
    this.setTransform();
  }

  onMouseMove(event, eventTriggerSourceType?: EventTriggerSourceTypes) {
    event.preventDefault();
    if (this.isAction(PhotoEditActions.Move)) {
      if (this.grabbing) {
        this.actionButtonsDisabled = true;
        let offsetX, offsetY;

        if (eventTriggerSourceType && eventTriggerSourceType === EventTriggerSourceTypes.Touch) {
          const photoContainer = this.photoContainerEl;
          const rect = photoContainer.getBoundingClientRect();
          offsetX = (event.touches[0].clientX - window.pageXOffset - rect.left);
          offsetY = (event.touches[0].clientY - window.pageYOffset - rect.top);
        } else {
          offsetX = event.offsetX;
          offsetY = event.offsetY;
        }

        this.translateX = (offsetX - this.start.x);
        this.translateY = (offsetY - this.start.y);
        this.setTransform();
      }
    }

    if (this.isAction(PhotoEditActions.Rotate)) {
      if (this.rotating) {
        let clientX, clientY;

        if (eventTriggerSourceType && eventTriggerSourceType === EventTriggerSourceTypes.Touch) {
          clientX = event.touches[0].clientX;
          clientY = event.touches[0].clientY;
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        var x = clientX - this.center.x,
        y = clientY - this.center.y,
        d = this.R2D * Math.atan2(y, x);
        this.rotation = d - this.startAngle;
        this.rotateDegree = this.angle + this.rotation;
        this.setTransform();
      }
    }
  }

  onPinch(event) {
    this.editAction = PhotoEditActions.Zoom;
    const wheelEvent = new WheelEvent('wheel', {
      clientX: event.center.x,
      clientY: event.center.y,
    });

    let zoomIn;
    if (event.scale < this.lastScale) {
      zoomIn = false;
    } else {
      zoomIn = true;
    }

    this.lastScale = event.scale;
    this.onWheel(wheelEvent, zoomIn);
  }

  onPinchEnd() {
    this.editAction = PhotoEditActions.Move;
    this.lastScale = 1;
  }

  onTouchMove(event) {
    this.onMouseMove(event, EventTriggerSourceTypes.Touch);
  }

  onTouchStart(event) {
    event.preventDefault();
    this.onMouseDown(event, EventTriggerSourceTypes.Touch);
  }

  onTouchEnd(event) {
    this.actionButtonsDisabled = false;
    if (this.isAction(PhotoEditActions.Rotate)) {
      this.angle += this.rotation;
    }
  }

  onDragStart(event) {
    return false;
  }

  setTransform() {
    const imageToZoom = this.imageWrapperEl;
    imageToZoom.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale}) rotate(${this.rotateDegree}deg) translate3d(0,0,0)`;
  }

  setRotate() {
    const imageToZoom = this.document.querySelector('.photo-wrapper img') as HTMLElement;
    imageToZoom.style.transform = `rotate(${this.rotateDegree}deg)`;
  }

  selectImage(image: Media) {
    this.setSelectedImage(image);
  }

  setSelectedImage(image: Media) {
    this.selectedImage = image;
    this.resetTransforms();
  }

  activateEditAction(event, actionType: PhotoEditActions) {
    event.preventDefault();
    event.stopPropagation();
    this.editAction = actionType;
  }

  onZoomMouseEnter() {
    this.editAction = PhotoEditActions.Zoom;
    this.zoomBtnHover = true;
  }

  onZoomMouseLeave(event) {
    this.setDefaultPhotoEditAction();
    this.zoomBtnHover = false;
  }

  updateForm(media: Media[]) {
    const firstPhotoChanged = this.selectedImage?.url1x !== media[0]?.url1x;
    if (firstPhotoChanged) {
      this.resetTransforms();
    }

    const mediaIds: string[] = [];
    media.forEach((obj: Media) => {
      mediaIds.push(obj.id);
    });

    this.getFormControl('media')?.setValue(media);
    this.getFormControl('media_ids')?.setValue(mediaIds);
    const selectedImageExistInUpdatedMedia = this.selectedImage && media.some(media => media.id === this.selectedImage?.id);

    if (!selectedImageExistInUpdatedMedia) {
      this.selectedImage = null;
    }

    if (!this.selectedImage && media?.length) {
      this.selectedImage = media[0];
    }
    this.setDefaultPhotoEditAction();
  }

  openChoosePhotosDialog() {
    const dialog = this.dialogService.openDialog(DialogChoosePhotosComponent);
  }

  addEditorState(imageAfterEdit: Media) {
    const editorData = {
      editedImage: this.selectedImage,
      media: this.getFormControl('media')?.value,
      scale: this.scale,
      translateX: this.translateX,
      translateY: this.translateY,
      R2D: this.R2D,
      angle: this.angle,
      rotation: this.rotation,
      startAngle: this.startAngle,
      center: this.center,
      rotateDegree: this.rotateDegree,
      text: this.getFormControl('text')?.value,
      draft: this.draft,
      imageAfterEdit: imageAfterEdit
    }

    const draftData = {
      shop_id: this.data?.shopId,
      post_id: this.data?.post?.post_id,
      state: JSON.stringify(editorData)
    }

    this.campaignApiService.addPhotoEditorState(draftData).subscribe(
      (response: any) => {
        const mediaAndEditedPhoto = {
          draft: this.draft,
          media: this.getFormControl('media')?.value,
          photo: imageAfterEdit
        } as EditorMediaAndPhoto;
        this.saveImages.emit(mediaAndEditedPhoto);
      }, error => {
        this.loading = false;
      }
    );
  }

  saveEditorImageAsDraft() {
    this.loading = true;
    this.draft = true;
    this.hideButtonsAndSaveImage();
  }

  saveEditorImage() {
    this.loading = true;
    this.draft = false;
    this.hideButtonsAndSaveImage();
  }

  hideButtonsAndSaveImage() {
    this.actionButtonsHideOnSave = true;
    setTimeout(() => {
      this.saveImage();
    }, 10);
  }

  saveImage() {
    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafari) {
      of(true).pipe(
        timeout(1500),
        switchMap(res => from(htmlToImage.toJpeg(this.photoContainerEl, { quality: 1 }))),
        switchMap(res => from(htmlToImage.toJpeg(this.photoContainerEl, { quality: 1 }))),
        switchMap(res => from(htmlToImage.toJpeg(this.photoContainerEl, { quality: 1 }))),
        switchMap(dataUrl => {
          const rand = Math.floor(Math.random() * 1000000);
          const file = this.dataURLtoFile(dataUrl, `framed_${rand}.jpeg`);
          this.upload(file);
          return of(file);
        }),
        catchError(error => {
          console.error(error);
          return of(error);
        }),
      ).subscribe();
    } else {
      htmlToImage.toJpeg(this.photoContainerEl).then((dataUrl) => {
        var img = new Image();
        img.src = dataUrl;
        const rand = Math.floor(Math.random() * 1000000);
        const file = this.dataURLtoFile(dataUrl, `framed_${rand}.jpeg`);
        this.upload(file);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }

  upload(file) {
    this.mediaApiService.uploadFile(file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.loading = true;
        } else if (event instanceof HttpResponse) {
          this.loading = false;
          this.addEditorState(event.body[0]);
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getFormControl(name) {
    return this.form.get(name);
  }

  isAction(action: PhotoEditActions) {
    return action === this.editAction;
  }

  labelTextAlign(alignment: number) {
    if (alignment === 2) {
      return 'right'
    } else if (alignment === 3) {
      return 'center'
    } else {
      return 'left'
    }
  }

  get imageWrapperEl(): HTMLElement {
    return this.document.querySelector('.photo-wrapper') as HTMLElement;
  }

  get photoContainerEl(): HTMLElement {
    return this.document.querySelector('.photo-container') as HTMLElement;
  }

  get isMobile() {
    return isMobile();
  }

  get frameLabel() {
    return this.getFormControl('text')?.value;
  }

  get editActions() {
    return PhotoEditActions;
  }
}
