import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FILETYPES } from '@digilize/shared/definitions/src/lib/constants/fileTypes';
import { fileTypes } from '@digilize/shared/definitions/src/lib/interfaces/fileTypes';
import { FileStepData } from '@digilize/shared/definitions/src/lib/interfaces/FileStepData';
import { APP_CONFIG } from '@digilize/shared/utils/tokens';
import { AppConfig } from '@digilize/shared/definitions/src';
import { CvService } from '@bwc/src/app/services/cv.service';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { Gtag } from 'angular-gtag';
@Component({
  selector: 'bwc-upload-your-cv',
  templateUrl: './upload-your-cv.component.html',
  styleUrls: ['./upload-your-cv.component.scss'],
})
export class UploadYourCvComponent implements OnInit {
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @Input() openFormLink: string;
  @Input() inviteLink: string;
  @Input() vacancyId: string;
  @Output() nextStep = new EventEmitter();
  @ViewChild('fileDropRef') fileDropRef: ElementRef<HTMLInputElement>;

  fileTypes: fileTypes[] = FILETYPES;

  constructor(
    private cvService: CvService,
    private authService: AuthService,
    private gtag: Gtag,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.gtag.event('screen_view', {
      app_name: 'Skilld',
      screen_name: 'Upload your CV step',
    });
  }

  ngOnInit(): void {}

  handleFileUpload(event: Event) {
    const fileList: FileList = this.fileDropRef.nativeElement.files;
    this.getUploadedFile(fileList);
  }

  getUploadedFile(fileList: FileList) {
    if (fileList.length === 0) return;
    const file = fileList[0];
    if (this.fileTypes.some((el) => el.type === file.type)) {
      const data = { previousStep: 1, step: 2, file: file };
      this.goToNextStep(data);
    } else {
      const data = { previousStep: 1, step: 2, error: true };
      this.goToNextStep(data);
      throw new Error('Not supported file format');
    }
  }

  goToNextStep(data: FileStepData) {
    this.nextStep.emit(data);
  }

  manualCv() {
    localStorage.removeItem(this.appConfig.localStorageAuthPrefix + 'token');
    localStorage.removeItem(this.appConfig.localStorageAuthPrefix + 'tokenExpiration');
    localStorage.removeItem(this.appConfig.localStorageAuthPrefix + 'refresh_token');
    localStorage.removeItem(this.appConfig.localStorageAuthPrefix + 'refreshTokenExpiration');

    let req: any = {};

    if (this.openFormLink) {
      req.open_link_id = this.openFormLink;
    }
    if (this.inviteLink) {
      req.invitation_id = this.inviteLink;
    }
    if (this.vacancyId) {
      req.vacancy_id = this.vacancyId;
    }

    this.cvService.manualCV(req).subscribe(
      (resp: any) => {
        this.authService.setSession({
          token: resp.anonymous_user_token,
          refresh_token: resp.anonymous_user_refresh_token,
        });
        this.goToNextStep({ previousStep: 1, step: 3 });
      },
      (err) => {
        console.error('Error during getting manual CV');
      }
    );
  }
}
interface Request {
  params: {
    open_link_id?: string;
    vacancy_id?: string;
    invitation_id?: string;
  };
}
