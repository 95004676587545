<div #content (click)="handleTriggerClick($event)" (appClickOutside)="handleClickOutside()" class="content-wrapper">
  <ng-container *ngTemplateOutlet="trigger || defaultTrigger"> </ng-container>
  <ng-container *ngIf="isListVisible">
    <ul class="list" [ngClass]="dropDirection">
      <ng-container *ngTemplateOutlet="rows"></ng-container>
    </ul>
  </ng-container>
</div>

<ng-template #defaultTrigger>
  <span class="trigger" [ngClass]="{ active: isListVisible }" inlineSVG="/icons/icon-dotted-btn.svg"></span>
</ng-template>
