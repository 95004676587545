<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-8 col-xl-6 pl-md-4">
          <div class="row">
            <ng-container
              *ngTemplateOutlet="input; context: { key: 'mainBackground', label: 'background_color' }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="input; context: { key: 'mainBackgroundFont', label: 'background_color_font' }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="input; context: { key: 'secondaryBackground', label: 'secondary_background' }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="input; context: { key: 'secondaryBackgroundFont', label: 'secondary_background_font' }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="input; context: { key: 'labelBackground', label: 'label_background' }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="input; context: { key: 'labelFont', label: 'label_font_color' }"
            ></ng-container>

            <!-- Input template -->
            <ng-template #input let-key="key" let-label="label">
              <div class="col-12 form-field-container">
                <div class="form-field-label" [ngClass]="{ required: isFormControlRequired(getFormControl(key)) }">
                  {{ 'form.field.' + label | translate }}
                </div>
                <div class="d-flex color-container">
                  <!-- <div class="color-box" [style.background-color]="getFormControl(key).value"></div> -->
                  <!-- <input [(colorPicker)]="coloolr" [style.background]="coloolr"/> -->
                  <div class="color-picker-container">
                    <input
                      class="color-picker"
                      [formControlName]="key"
                      [colorPicker]="getFormControl(key).value"
                      (colorPickerChange)="setColor($event, key)"
                      [style.background]="getFormControl(key).value"
                      [style.color]="getFormControl(key).value"
                    />
                  </div>
                  <div>
                    <span>{{ getFormControl(key).value }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <!-- Input template -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
