import { Inject, Injectable } from '@angular/core';
import { AppConfig, LanguageCode, Replace } from '@digilize/shared/definitions/src';
import { Multilingual } from '@digilize/shared/definitions/src/lib/interfaces/Multilingual';
import { APP_CONFIG } from '@digilize/shared/utils/tokens/src';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  isServiceInitialized = false;
  localeEvent = new Subject<LanguageCode>();

  constructor(private translateService: TranslateService, @Inject(APP_CONFIG) private appConfig: AppConfig) {}

  init(): void {
    if (this.isServiceInitialized) return;
    this.isServiceInitialized = true;
    const langListLower = this.appConfig.language.languages.map((lang) => lang.code.toLowerCase());
    this.translateService.addLangs(langListLower);
    const browserLanguage = this.getFirstBrowserLanguage();
    if (localStorage.getItem('lang')) {
      this.setLang(localStorage.getItem('lang') as LanguageCode);
    } else {
      this.setLang(
        langListLower.includes(browserLanguage)
          ? browserLanguage
          : this.appConfig.language.defaultLanguage.toLowerCase()
      );
    }
  }

  initLazyLoadedModule(translateRef: TranslateService) {
    /* fixes problem described here https://github.com/ngx-translate/core/issues/1193*/
    /* lib has no emitter specific emitter for translation loaded, so we wait for langChange to be sure new translations have been loaded -  */
    translateRef.onLangChange.pipe(take(1)).subscribe((el) => {
      this.isServiceInitialized || this.init();
      this.resetTranslations(translateRef);
    });
    this.isServiceInitialized || this.init();
    this.resetTranslations(translateRef);

    /* this covers language change case*/
    this.localeEvent.subscribe((locale) => translateRef.use(locale));
  }

  private resetTranslations(translateRef: TranslateService) {
    const currentLang = translateRef.currentLang;
    translateRef.currentLang = '';
    translateRef.use(currentLang);
  }
  setLangAndSave(lang: LanguageCode) {
    const langLower = lang.toLowerCase();
    this.setLang(lang);
    localStorage.setItem('lang', langLower);
  }

  setLang(lang: LanguageCode) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.localeEvent.next(lang);
  }

  getTextFromMultilingual(multi: Multilingual, replace?: Replace): string {
    if (!multi) return '';
    const text = multi[this.translateService.currentLang as keyof Multilingual];
    return replace ? text.replace(replace.searchValue, replace.replaceValue) : text;
  }

  private getFirstBrowserLanguage() {
    let nav = window.navigator,
      browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
      i,
      language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language.split('-')[0];
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language.split('-')[0];
      }
    }

    return null;
  }
}
