import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'didDatePass',
  pure: true,
})
export class DidDatePassPipe implements PipeTransform {
  constructor() {}

  transform(maxDate: Date): boolean {
    if (!maxDate) {
      return false;
    }
    return new Date(maxDate) < new Date();
  }
}
