import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  private errorMap: { [key: string]: (c: FormControl) => string } = {
    required: () => 'field_required',
    email: () => 'email_format_required',
    minArrayLength: () => 'error_min_length',
    minLenghtOneLanguage: () => 'error_min_length_one_language',
    noNativeLanguage: () => 'error_select_native_language',
    dateBeforeNow: () => 'error_date_before_now',
    startDate: () => 'error_start_date',
    endDate: () => 'error_end_date',
  };

  public mapErrors(control: FormControl): any[] {
    return Object.keys(control.errors || {}).map((key) => this.errorMap[key](control));
  }
}
