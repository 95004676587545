import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SHOP_TIME_SLOTS } from '@shared/definitions';

@Component({
  selector: 'lib-form-delivery',
  templateUrl: './form-delivery.component.html',
  styleUrls: ['./form-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormDeliveryComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;
  shopTimeSlots = SHOP_TIME_SLOTS;

  order = {
    main: ['delivery', 'pick_up'],
    costs: ['_cost', '_min', '_max'],
    other: ['_allow_pay_at', '_timeslot', '_timeslot_interval', '_timeslot_grace_period_minutes'],
  };

  constructor() {}

  ngOnInit(): void {}

  getFormControl(name): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  isInactive(key) {
    if (key.includes('delivery')) {
      return !this.getFormControl('delivery').value;
    }

    if (key.includes('pick_up')) {
      return !this.getFormControl('pick_up').value;
    }
    throw new Error('Wrong key');
  }

  clearForm(key, value) {
    this.order.costs.forEach((cost) => {
      this.getFormControl(key + cost).setValue(value ? 0 : null);
    });

    this.order.other.forEach((other) => {
      this.getFormControl(key + other).setValue(null);
    });
  }

  handleDigitalCostChange(value) {
    this.getFormControl('digital_cost')?.setValue(value ? 0 : null);
  }

  clearTimeslots(key, value) {
    this.form.get(key + '_timeslot_interval').setValue(value ? 5 : null);
    this.form.get(key + '_timeslot_grace_period_minutes').setValue(value ? 0 : null);
  }
}
