import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bwh-lib-selected-item',
  templateUrl: './selected-item.component.html',
  styleUrls: ['./selected-item.component.scss'],
})
export class SelectedItemComponent {
  @Input() name: string;
  @Input() listIcon: boolean;
  @Input() index: number;
  @Input() openIcon: boolean;
  @Input() expIndex: number;
  @Input() noDelete: boolean;
  @Input() nolistIcon: boolean;
  @Input() twoIcons: boolean;
  @Input() indexIcon: boolean;
  @Input() iconStatus: string;
  @Input() disableAllIcons: boolean;
  @Input() vacancy: boolean;
  @Input() starsRate;
  @Input() ifNative: boolean;
  @Output() actionTriggered = new EventEmitter();
  @Output() changeLangLevel = new EventEmitter();
  @Output() changeNative = new EventEmitter();

  stars = [0, 1, 2, 3, 4];

  constructor() {}

  actionClick(index: number) {
    this.actionTriggered.emit(index);
  }

  starClicked(event) {
    this.changeLangLevel.emit({ index: event.index, rating: event.rating });
  }

  selectAsNative(index: number) {
    this.changeNative.emit(index);
  }
}
