import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Option } from '@digilize/shared/definitions/src';
import { SingleSelectSearchComponent } from '@digilize/shared/ui/components/src/lib/form-items/single-select-search/single-select-search.component';
import { minArrayLengthValidator } from '@digilize/shared/utils/validators/src';
import { forkJoin, throwError } from 'rxjs';
import { ErrorService } from '../../../services/error.service';
import { CvService } from '../../../services/cv.service';
import { SkillsService } from '../../../services/skills.service';
import { createOptions, transformToOption } from '../../../helpers/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccessPopupComponent } from '@bwc/src/app/components/success-popup/success-popup.component';
import { DialogService } from '@digilize/shared/utils/services/src';

export function atLeastOneNativeLanguageValidator(control: AbstractControl): ValidationErrors | null {
  const languagesArray = control as FormArray;
  const nativeLanguageExists = languagesArray.controls.some(
    (languageGroup) => languageGroup.get('is_native').value === true
  );
  return nativeLanguageExists ? null : { noNativeLanguage: true };
}

@Component({
  selector: 'bwc-languages-knowledge',
  templateUrl: './languages-knowledge.component.html',
  styleUrls: ['./languages-knowledge.component.scss'],
})
export class LanguagesKnowledgeComponent implements OnInit {
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() editing: boolean;
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @Input() formGroup: FormGroup;
  @Input() removeProfileCheckClass = true;
  @ViewChild('searchAutocomplete') searchAutocomplete: SingleSelectSearchComponent;
  @Output() dataStep = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitLanguages = new EventEmitter();

  form: FormGroup;
  languagesList: Option[];
  dataLoaded = false;
  vacancyId: string;
  companyId: string;
  openFormLink: string;

  constructor(
    private fb: FormBuilder,
    public errorService: ErrorService,
    private cvService: CvService,
    private skillsService: SkillsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this.formGroup !== undefined) {
      this.form = this.createFormGroup();
      this.getDataEdit();
    } else {
      this.form = this.createFormGroup();
      this.getData();
    }
    this.checkParamsForApplying();
    if (this.removeProfileCheckClass) {
      this.renderer.removeClass(document.body, 'profile-check');
    }
  }

  createFormGroup(): FormGroup<selectedLanguages> {
    return this.fb.group<selectedLanguages>({
      selectedLanguages: this.fb.array<UsersLangs>(
        [],
        [minArrayLengthValidator(1, 'minLenghtOneLanguage'), atLeastOneNativeLanguageValidator]
      ),
    });
  }

  checkParamsForApplying() {
    this.route.queryParams.subscribe((params) => {
      const vacancyId = params['apply_to'];
      const companyId = params['c'];
      this.openFormLink = params['ofl'];
      if (vacancyId) {
        this.vacancyId = vacancyId;
      }
      if (companyId) {
        this.companyId = companyId;
      }
    });
  }

  getDataEdit(): void {
    if (this.formGroup) {
      this.skillsService.getListOfLanguages('').subscribe((data: any) => {
        const langOptions = createOptions(data.data);
        this.processLanguageData(langOptions, this.formGroup.value.languages);
      });
    }
  }

  getData(): void {
    forkJoin({
      userData: this.cvService.getCV(),
      languagesList: this.skillsService.getListOfLanguages(''),
    }).subscribe((data: any) => {
      const langOptions = createOptions(data.languagesList.data);
      this.processLanguageData(langOptions, data.userData.language_skills);
    });
  }

  processLanguageData(languagesList: Option[], userLanguages: UsersLangs[]): void {
    this.languagesList = languagesList.sort((a, b) => a.text.localeCompare(b.text));
    this.languagesList = languagesList.filter(
      (lang) => !userLanguages.some((userLang) => userLang.skill_name === lang.text)
    );

    userLanguages.forEach((item) => {
      const itemFormGroup = this.fb.group({
        skill_id: [item.skill_id],
        skill_name: [item.skill_name],
        val: [item.val],
        rating: [item.rating],
        is_native: [item.is_native],
      });

      this.selectedLanguages.push(itemFormGroup);
    });
    this.dataLoaded = true;
  }

  get selectedLanguages() {
    return this.form.get('selectedLanguages') as FormArray;
  }

  getFormControl(formControlName: string) {
    return this.form.get(formControlName) as FormControl;
  }

  goPrev() {
    if (!this.form.valid) {
      this.dataStep.emit({ previousStep: this.currentStep, step: 9 });
    } else {
      this.saveLanguagesData(this.selectedLanguages.value, false, true);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.saveLanguagesData(this.selectedLanguages.value);
    }
  }

  onEdit() {
    if (this.form.valid) {
      this.saveLanguagesData(this.selectedLanguages.value, true);
    }
  }

  saveLanguagesData(data, close?, ifBack?) {
    const requestBody = { items: data };
    this.skillsService.putLanguagesSkills(requestBody).subscribe(
      (resp) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 9 });
        } else if (close) {
          this.emitLanguages.emit(this.form);
          this.emitClose.emit('languages');
        } else {
          const dialog = this.dialogService.openDialog(SuccessPopupComponent, {
            disableClose: false,
            backdropClass: 'success-backdrop',
            panelClass: 'success-dialog-container',
            width: '948px',
          });
          dialog.afterClosed().subscribe((data) => {
            this.renderer.removeClass(document.body, 'company-colors');
            if (this.vacancyId) {
              this.router.navigate(['/profile-check'], {
                queryParams: { apply_to: this.vacancyId, c: this.companyId, ofl: this.openFormLink },
              });
            } else if (this.openFormLink) {
              this.router.navigate(['/profile-check'], {
                queryParams: { ofl: this.openFormLink },
              });
            } else {
              window.location.href = '/profile-check';
            }
          });
        }
      },
      (error) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 9 });
        }
        throwError('Languages Put error:', error);
      }
    );
  }

  handleFilteredOptionClick(option: Option) {
    const newItem = this.fb.group({
      skill_id: [option.val],
      skill_name: [option.text],
      rating: 0,
      is_native: false,
    });
    this.selectedLanguages.push(newItem);
    this.checkDuplicates();
  }

  checkDuplicates() {
    this.languagesList = this.languagesList.filter(
      (lang) => !this.selectedLanguages.value.some((userLang) => userLang.text === lang.text)
    );
    setTimeout(() => this.searchAutocomplete.clearSearchInput(), 0);
  }

  removeLanguage(index: number) {
    const langToAdd = transformToOption(this.selectedLanguages.at(index).value);
    this.selectedLanguages.removeAt(index);
    this.languagesList = [...this.languagesList, langToAdd];
    this.languagesList = this.sortArrayByKey(this.languagesList, 'text');
  }

  changeLangLevel(event) {
    this.selectedLanguages.at(event.index).get('rating').setValue(event.rating);
  }

  changeNative(index: number) {
    const control = this.selectedLanguages.at(index).get('is_native');
    const controlValue = control.value;

    control.setValue(!controlValue);

    if (!controlValue) {
      this.selectedLanguages.at(index).get('rating').setValue(5);
    } else {
      this.selectedLanguages.at(index).get('rating').setValue(0);
    }
  }

  changePositionInLangList(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.selectedLanguages.controls, event.previousIndex, event.currentIndex);
    }
  }

  close() {
    this.emitClose.emit('languages');
  }

  checkForm() {
    console.log('form', this.form);
  }

  sortArrayByKey(arr, key: string) {
    return arr.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }
}

interface selectedLanguages {
  selectedLanguages: FormArray;
}

interface UsersLangs {
  skill_id: string;
  skill_name: string;
  val: string;
  rating: number;
  is_native: boolean;
}
