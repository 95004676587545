export type TagQueryDataType =
  | 'String'
  | 'Decimal'
  | 'Float'
  | 'Double'
  | 'Integer'
  | 'Guid'
  | 'DateTime'
  | 'DateTimeOffset'
  | 'Time'
  | 'tag'
  | 'Boolean';
