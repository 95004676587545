<div class="card card-shop" *ngIf="!shop">
  <div class="card-background"></div>
</div>

<div class="card card-shop" (click)="onCardClick($event)" *ngIf="shop">
  <div
    class="card-background"
    [lazyLoad]="shop.photo?.url1x ? shop.photo?.url1x + '?w=480&h=480&m=2' + addWebp : ''"
  ></div>
  <div class="card-body" [ngStyle]="{ 'margin-bottom': '-' + hiddenPart.offsetHeight + 'px' }">
    <div
      class="card-title dot"
      [ngStyle]="{ 'margin-bottom': (shop.street && shop.city) || shop.description ? '' : '10px' }"
    >
      <span>{{ shop.name }}</span>
    </div>
    <div class="card-address" *ngIf="shop.street && shop.city">
      <span class="address-icon" [inlineSVG]="'/icons/icon-address.svg'"></span>
      {{ shop.street }} <ng-container *ngIf="shop.house_number">{{ shop.house_number }}</ng-container
      >, {{ shop.city }}
    </div>
    <div #hiddenPart class="xs-hide sm-hide md-hide">
      <div class="card-description" *ngIf="shop.description" [innerHTML]="shop.description"></div>
      <a [routerLink]="['/bedrijf', shop.permalink]" class="btn btn-card xs-hide sm-hide md-hide">
        {{ 'global.open' | translate }}
      </a>
    </div>
  </div>
</div>
