<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="form-field-container">
        <mat-form-field class="form-field" [floatLabel]="'never'">
          <mat-placeholder>{{ 'global.add_keywords' | translate }}</mat-placeholder>
          <mat-chip-list #keywordList>
            <mat-chip
              *ngFor="let keyword of getKeywords()"
              [selectable]="true"
              [removable]="true"
              (removed)="removeKeyword(keyword)"
            >
              {{ keyword }}
              <span matChipRemove class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
            </mat-chip>
            <input
              [matChipInputFor]="keywordList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="false"
              (matChipInputTokenEnd)="addKeyword($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
