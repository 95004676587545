<div class="amount">
  <button (click)="handleDecrement()" [disabled]="decrementLimit || (decrementLimit === 0 && value === decrementLimit)">
    <span inlineSVG="/icons/icon-thick-minus.svg"></span>
  </button>
  <div class="value-container">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValue"></ng-container>
  </div>

  <button [disabled]="maxAmount <= value && maxAmount !== null" (click)="handleIncrement()">
    <span inlineSVG="/icons/icon-thick-plus.svg"></span>
  </button>
  <input type="text" [value]="value" (change)="onValueChange($event)" [disabled]="disabled" />
</div>

<ng-template #defaultValue>
  <p>{{ value }}</p>
</ng-template>
