import { Media } from "./Media";

export interface Company {
  id?: string;
  name: string;
  photo_id?: string;
  photo?: Media;
  official_name?: string;
  company_group_id: string;
}
