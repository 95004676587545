export const PRODUCT_AVAILABILITY = [
  {
    key: 'product.via_own_website',
    value: 1,
  },
  {
    key: 'product.this_shop',
    value: 2,
  },
];
