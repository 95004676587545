import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../services/error.service';
import { AuthApiService } from '@digilize/shared/data/services/src';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'bwc-account-forms',
  templateUrl: './account-forms.component.html',
  styleUrls: ['./account-forms.component.scss'],
})
export class AccountFormsComponent implements OnInit {
  @Input() type: string;
  @Input() closeBut: boolean;
  @Input() dontCheckAuth = false;
  @Output() emitClose = new EventEmitter();

  loginForm: FormGroup;
  forgotPassForm: FormGroup;
  resetPassForm: FormGroup;
  formType = FormType;
  goBack = false;

  constructor(
    private fb: FormBuilder,
    public errorService: ErrorService,
    private authApiService: AuthApiService,
    private authService: AuthService
  ) {
    this.type = FormType.LogIn;
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn() && !this.dontCheckAuth) {
      window.location.href = '/profile-check';
    } else {
      this.createForms();
    }
  }

  createForms() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.forgotPassForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.resetPassForm = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    });
  }

  resetFormGroup(): void {
    if (this.type === FormType.LogIn) {
      this.loginForm.reset();
    } else if (this.type === FormType.ForgotPass) {
      this.forgotPassForm.reset();
    } else if (this.type === FormType.ResetPass) {
      this.resetPassForm.reset();
    }
  }

  signIn() {
    this.authApiService.login(this.loginForm.value).subscribe(
      (resp: any) => {
        this.authService.setSession(resp, () => {
          window.location.href = '/profile-check';
        });
      },
      (err) => {
        console.error('Login error: ', err);
      }
    );
  }

  forgotPass() {
    console.log('forgotPass', this.forgotPassForm.value.email);
    const token = this.authService.getAccessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.authApiService.passwordForgot(this.forgotPassForm.value.email, headers).subscribe(
      (response) => {
        console.log('forgotPass success', response);
      },
      (error) => {
        console.error('Error during forgot password request: ', error);
      }
    );
  }

  resetPass() {}

  signUp() {}

  showForgotPassword() {
    this.type = FormType.ForgotPass;
    this.closeBut = true;
    this.goBack = true;
    this.resetFormGroup();
  }

  showSignUp() {}

  close() {
    if (this.goBack) {
      this.goBack = false;
      this.type = FormType.LogIn;
    } else {
      this.emitClose.emit('account_forms');
    }
  }

  getFormControl(formControlName: string) {
    if (this.type === FormType.LogIn) {
      return this.loginForm.get(formControlName) as FormControl;
    } else if (this.type === FormType.ForgotPass) {
      return this.forgotPassForm.get(formControlName) as FormControl;
    } else if (this.type === FormType.ResetPass) {
      return this.resetPassForm.get(formControlName) as FormControl;
    }
    return null;
  }
}

export enum FormType {
  LogIn = 'logIn',
  ForgotPass = 'forgotPass',
  ResetPass = 'resetPass',
}
