import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { AlertBarComponent } from './alert-bar/alert-bar.component';

@NgModule({
  declarations: [AlertBarComponent],
  imports: [CommonModule, InlineSVGModule, TranslateModule],
  exports: [AlertBarComponent],
})
export class SharedFeatureModulesAlertModule {}
