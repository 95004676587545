import { ResearchResultsVisibility, ResearchType360, SurveyStatus } from '../enums';
import { Auth } from './Auth';

export interface ResearchSurveyStart {
  research_id: string;
  research_respondent_id: string;
  research_group_id: string;
  research_group_results_visibility: ResearchResultsVisibility;
  research_faq_url: string;
  system_auth: Auth;
  dms_session_id: string;
  dms_session_status: SurveyStatus;
  invited_after_current: number;
  invited_after_max: number;
  research_group_type360: ResearchType360;
}
