import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthApiService,  ShopApiService } from '@digilize/shared/data/services';
import { ALERT_PRODUCT_REQUEST_SUCCESS, Me, ProductRequest } from '@shared/definitions';
import { AlertService } from '@digilize/shared/feature/modules/alert';

@Component({
  selector: 'lib-dialog-request-info',
  templateUrl: './dialog-request-info.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogRequestInfoComponent implements OnInit {
  loading = false;
  form = new UntypedFormGroup({
    shop_id: new UntypedFormControl('', [Validators.required]),
    product_id: new UntypedFormControl('', [Validators.required]),
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl(''),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  me: Me;
  order = ['first_name', 'last_name', 'email', 'phone', 'message'];

  constructor(
    public dialogRef: MatDialogRef<DialogRequestInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authApiService: AuthApiService,
    private alertService: AlertService,
    private shopApiService: ShopApiService
  ) {
    this.authApiService.me.subscribe((me: Me) => {
      this.me = me;
    });
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.data?.shop_id && this.data?.product_id) {
      this.getFormControl('shop_id').setValue(this.data.shop_id);
      this.getFormControl('product_id').setValue(this.data.product_id);

      if (this.me) {
        this.getFormControl('first_name').setValue(this.me.first_name);
        this.getFormControl('last_name').setValue(this.me.last_name);
        this.getFormControl('email').setValue(this.me.email);
        this.getFormControl('phone').setValue(this.me.phone);
      }

      this.loading = false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      const productRequest: ProductRequest = this.form.getRawValue();

      this.shopApiService.sendRequestInfo(productRequest).subscribe(
        (response) => {
          this.alertService.setAlert(ALERT_PRODUCT_REQUEST_SUCCESS);
          this.closeDialog();
        },
        (error) => {
          this.alertService.handleApiError(error.error.error);
          this.closeDialog();
        }
      );
    }
  }
}
