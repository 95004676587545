import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { addWebp } from '@digilize/shared/utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { Product, PRODUCT_LABELS, ProductInstances, Shop } from '@shared/definitions';

@Component({
  selector: 'lib-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['../card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardProductComponent implements OnInit {
  @Input() product: Product;
  @Input() shop: Shop;
  @Input() displayShopName: boolean;
  @Input() slider: boolean;
  ProductInstances = ProductInstances;
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  getLabel(label): string {
    if (this.product.instances === ProductInstances.Available) {
      return this.translateService.instant('product.label.sold_out');
    } else {
      return PRODUCT_LABELS.some((obj) => obj.value === label)
        ? this.translateService.instant('product.label.' + label)
        : label;
    }
  }

  get addWebp() {
    return addWebp();
  }
}
