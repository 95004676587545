
export interface Session {
  frontend_session_id: string;
  shop_id: string;
  source: string;
  campaign: string;
  platform: string;
  browser: string;
  language: string;
  ip_address: string;
  device: string;
  device_type: string;
  device_os: string;
  device_os_version: string;
  device_user_agent: string;
  device_browser: string;
  device_browser_version: string;
}
