export interface Email {
  email: string;
  first_name: string;
  infix: string;
  last_name: string;
  language: string;
  display_name: string;
  formatted: string;
}

export interface ForgotEmail {
  email: string;
}
