import { QuizQuestion } from './QuizQuestion';
import { QuizQuestionAnswer } from './QuizQuestionAnswer';
import { QuizSessionScore } from './QuizSessionScore';
import { QuizTheme } from './QuizTheme';

export interface QuizSessionState {
  shop_quiz_id: string;
  shop_quiz_session_id: string;
  start: Date;
  finish: string;
  score: QuizSessionScore;
  question: QuizQuestion;
  answer: QuizQuestionAnswer;
  answers_valid: QuizQuestionAnswer[];
  themes: QuizTheme[];
}
