<div class="chip-list-wrapper">
  <mat-chip-list #chipList>
    <ng-container *ngFor="let answer of selectedAnswers$ | async">
      <mat-chip class="cardinal-colors">
        {{ answer.text }}
        <button matChipRemove (click)="removeChip(answer)">
          <span class="icon-remove" [inlineSVG]="'/icons/icon-close-no-color.svg'"></span>
        </button>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

<mat-form-field class="full-width">
  <input
    matInput
    #autoInput
    type="text"
    [placeholder]="placeholder || ('decision_tree.select' | translate)"
    [matAutocomplete]="auto"
    [formControl]="autoCompleteControl"
  />
</mat-form-field>

<mat-autocomplete [formGroup]="formGroup" #auto="matAutocomplete">
  <mat-option *ngFor="let answer of filterAnswers | async">
    <label class="label-wrapper" (click)="handleLabelClick($event)">
      <mat-checkbox [formControl]="formGroup.controls[answer.val] | formControl">
        {{ answer.text }}
      </mat-checkbox>
    </label>
  </mat-option>
</mat-autocomplete>

<mat-error *ngIf="formGroup.touched && getFirstControlWithError('min', formGroup) as control">
  {{ control.errors?.message }}
</mat-error>
<mat-error *ngIf="formGroup.touched && getFirstControlWithError('max', formGroup) as control">
  {{ control.errors?.message }}
</mat-error>
