import { Media } from "./Media";


export interface ProductGroup {
  photo_id: string;
  photo: Media;
  aliases_list: string[];
  aliases: string;
  name: string;
  formatted?: string; // Only for frontend usage
  permalink: string;
  start: string;
  finish: string;
  id: string;
}
