<div class="card card-auction-item" *ngIf="marketplaceAuction">
  <div
    *ngIf="marketplaceAuction.media && marketplaceAuction.media[0] && marketplaceAuction.media[0].url1x; else noImg"
    [lazyLoad]="marketplaceAuction.media[0].url1x"
    class="img-container"
  >
    <div class="labels-container">
      <div *ngIf="marketplaceAuction.status !== AuctionStatus.Active" class="img-label-red status-label">
        {{ statusDisplayName(marketplaceAuction.status) | translate }}
      </div>
      <div *ngIf="marketplaceAuction.label && marketplaceAuction.status === 0" class="img-label-primary item-label">
        {{ marketplaceAuction.label }}
      </div>
    </div>
  </div>
  <ng-template #noImg>
    <div class="img-container no-img-container"></div>
  </ng-template>
  <div class="auction-board-item-details-container">
    <div>
      <h2 class="mb-0 dot auction-title" *ngIf="marketplaceAuction.title">{{ marketplaceAuction.title }}</h2>
      <ul class="card-top-bar">
        <li class="font-weight-bold" *ngIf="isValueTruthyOrZero(marketplaceAuction.type)">
          {{ getAuctionTypeName(marketplaceAuction.type) | translate }}
        </li>
        <li class="dot-break" *ngIf="isValueTruthyOrZero(marketplaceAuction.type)"></li>
        <li *ngIf="marketplaceAuction.inserted">{{ marketplaceAuction.inserted | date: 'mediumDate' }}</li>
        <li *ngIf="isValueTruthyOrZero(marketplaceAuction.delivery_method)" class="dot-break"></li>
        <li *ngIf="isValueTruthyOrZero(marketplaceAuction.delivery_method)">
          {{ getDeliveryTypeName(marketplaceAuction.delivery_method) | translate }}
        </li>
      </ul>
      <div
        class="item-description"
        *ngIf="marketplaceAuction.description"
        [innerHTML]="getTextWithLimit(marketplaceAuction.description)"
      ></div>
    </div>
    <div class="card-footer">
      <div
        *ngIf="
          marketplaceAuction.price &&
            (marketplaceAuction.price_type === priceTypes.AskingPrice ||
              marketplaceAuction.price_type === priceTypes.PricePerUnit);
          else noPrice
        "
        class="font-weight-bold card-price"
      >
        <span>{{ marketplaceAuction.price | currency: 'EUR' }}</span>
        <span *ngIf="marketplaceAuction.price_type === priceTypes.PricePerUnit" class="price-unit">
          / {{ (priceUnitDisplayValues[marketplaceAuction.unit] | translate) || '' }}</span
        >
      </div>
      <ng-template #noPrice>
        <div class="font-weight-bold card-price">
          {{ priceTypesDisplayValues[marketplaceAuction.price_type] | translate }}
        </div>
      </ng-template>
      <div>
        <a [routerLink]="['/marketplace', marketplaceAuction.id]" class="btn btn-card">
          {{ 'global.open' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
