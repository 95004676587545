import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isFormControlRequired } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-form-cinema-colors',
  templateUrl: './form-cinema-colors.component.html',
  styleUrls: ['./form-cinema-colors.component.scss'],
})
export class FormCinemaColorsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;

  constructor() {}

  ngOnInit(): void {}

  getFormControl(name) {
    return this.form.get(name);
  }

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }

  setColor(event, key: string) {
    this.getFormControl(key).setValue(event);
  }
}
