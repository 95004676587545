export interface QuizSessionScore {
  total: number;
  started: number;
  answered: number;
  scored: number;
  score: number;
  elapsed: number;
  is_complete: boolean;
  min_score: number;
  is_passed: boolean;
}
