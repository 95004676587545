<div class="dialog-panel message-dialog">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'marketplace.send_msg_dialog.send_msg' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <div class="form-field-container">
          <div class="form-field-label">
            {{ 'form.field.name' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput formControlName="name" required />
            <mat-error *ngIf="getFormControl('name').invalid">
              <ng-container *ngIf="getFormControl('name').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field-container">
          <div class="form-field-label">
            {{ 'form.field.message' | translate }}
          </div>
          <mat-form-field class="form-field form-field-message" [floatLabel]="'never'">
            <textarea
              matInput
              formControlName="text"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="10"
              maxlength="500"
              required
            ></textarea>
            <mat-error *ngIf="getFormControl('text').invalid">
              <ng-container *ngIf="getFormControl('text').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.send' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
