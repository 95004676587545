import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-multiple-radio-inputs',
  templateUrl: './multiple-radio-inputs.component.html',
  styleUrls: ['./multiple-radio-inputs.component.scss'],
})
export class MultipleRadioInputsComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({ mockControl1: new FormControl(), mockControl2: new FormControl() });
  @Input() questions: Question[];
  @Input() withInnerHTML = false;
  constructor() {}

  ngOnInit(): void {}
}
export interface Question {
  question: string;
  answers: { text: string; val: string }[];
  formControlName: string;
}
