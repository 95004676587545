import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@digilize/shared/feature/modules/auth/src/lib/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate, CanActivateChild {
  redirectRoute = '/';
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      const customLoginRedirectRoute = route.data.customLoginRedirectRoute;
      const queryParams = route.queryParams;
      if (queryParams['apply_to'] && queryParams['c']) {
        const applyToParam = queryParams['apply_to'];
        const companyIdParam = queryParams['c'];
        const redirectUrlTree = this.router.createUrlTree([customLoginRedirectRoute], {
          queryParams: {
            apply_to: applyToParam,
            c: companyIdParam,
          },
        });

        this.router.navigateByUrl(redirectUrlTree);
      } else if (queryParams['uid'] && route.routeConfig.path === 'profile-check') {
        return true;
      } else if (customLoginRedirectRoute) {
        this.router.navigate([customLoginRedirectRoute]);
      } else {
        this.router.navigate(['/']);
      }
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state); // Reuse the same logic as canActivate
  }
}
