import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedFeatureComponentsModule } from '@digilize/shared/feature/components/src';
import { LangComponentsModule } from '@digilize/shared/feature/modules/i18n/src';
import { SharedUiMaterialModule } from '@digilize/shared/ui/material/src';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SharedUtilsPipesModule } from '@digilize/shared/utils/pipes/src';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { EducationStageComponent } from './education-stage/education-stage.component';
import { PipesModule } from '../pipes/pipes.module';
import { ControlBarComponent } from './control-bar/control-bar.component';
import { YourExperienceComponent } from './your-experience/your-experience.component';
import { AccountFormsComponent } from './account-forms/account-forms.component';
import { ManagePasswordComponent } from './manage-password/manage-password.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { BeWorkHappyUiComponentsModule } from '@digilize/be-work-happy/ui/components';
import { ManageStartComponent } from './manage-start/manage-start.component';
import { LinkExpiredComponent } from './link-expired/link-expired.component';
import { SuccessPopupComponent } from './success-popup/success-popup.component';

@NgModule({
  declarations: [
    EducationStageComponent,
    ControlBarComponent,
    YourExperienceComponent,
    AccountFormsComponent,
    ManagePasswordComponent,
    DropdownMenuComponent,
    ManageStartComponent,
    LinkExpiredComponent,
    SuccessPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedUiMaterialModule,
    LazyLoadImageModule,
    TranslateModule,
    CdkAccordionModule,
    SharedUiComponentsModule,
    NgxCaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    SharedUtilsPipesModule,
    InlineSVGModule,
    RouterModule,
    SharedFeatureComponentsModule,
    LangComponentsModule,
    PipesModule,
    LottieModule.forRoot({ player: () => player }),
    BeWorkHappyUiComponentsModule,
  ],
  exports: [
    CommonModule,
    SharedUiMaterialModule,
    LazyLoadImageModule,
    TranslateModule,
    CdkAccordionModule,
    SharedUiComponentsModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    SharedUtilsPipesModule,
    InlineSVGModule,
    RouterModule,
    EducationStageComponent,
    ControlBarComponent,
    YourExperienceComponent,
    AccountFormsComponent,
    ManagePasswordComponent,
    DropdownMenuComponent,
  ],
})
export class SharedModule {}
