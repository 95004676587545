import { ShortType } from "../types/ShortType";

export const shortRouting: Record<ShortType, string> = {
  acquisition: '/campagne',
  broadcast: '/',
  category: '/',
  fashion_show: '/',
  group: '',
  shop: '/',
  subcategory: '/',
};
