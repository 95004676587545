import { Media } from './Media';
import { QuizQuestionAnswer } from './QuizQuestionAnswer';

export interface QuizQuestion {
  id: string;
  photo_id: string;
  photo: Media;
  audio_id: string;
  audio: Media;
  name: string;
  type: QuizQuestionType;
  description: string;
  max_seconds: number;
  available_from: string;
  available_to: string;
  theme_ids: string[];
  theme_names: string[];
  answer_ids: string[];
  answers: QuizQuestionAnswer[];
  tenant_id: string;
}

export enum QuizQuestionType {
  Single = 0,
  Text = 1,
  Order = 2,
}
