<div class="text-center" *ngIf="dataLoaded">
  <div class="main-header">
    <h1 class="main-title">
      {{ 'auth.signin' | translate }}
    </h1>
    <h3 class="main-subtitle">
      <ng-container *ngIf="noApplyState">
        {{ 'auth.start_info_no_apply' | translate }}
      </ng-container>
      <ng-container *ngIf="!noApplyState">
        {{ 'auth.start_info' | translate }}
      </ng-container>
      <div
        class="i-icon"
        (mouseenter)="showToolTip(true)"
        (mouseleave)="showToolTip(false)"
        (click)="showToolTip(true, true)"
      >
        <span [inlineSVG]="'/icons/i-icon.svg'"></span>
      </div>
    </h3>
  </div>
  <ul class="next-button-container not-fixed" [ngClass]="{ 'single-option': singleOption }">
    <li>
      <button class="btn w-lg h-lg secondary" type="submit" (click)="goToResume()">
        {{ 'auth.start_building_my_resume' | translate }}
      </button>
    </li>
    <li>
      <button class="btn w-lg h-lg secondary bwh-btn" type="submit" (click)="goToProfile()">
        <span [inlineSVG]="'/icons/Skilld_icoon_pos.svg'"></span>{{ 'auth.log_in_with_bwh' | translate }}
      </button>
    </li>
  </ul>
</div>
<div class="info-tooltip" *ngIf="showTooltip">
  <span class="close-icon" (click)="showTooltip = false" [inlineSVG]="'/icons/close-icon.svg'"></span>
  <div>
    <span>{{ 'tooltip.bwh_info1t' | translate }}</span>
    {{ 'tooltip.bwh_info1' | translate }}
  </div>
  <div>
    <span>{{ 'tooltip.bwh_info2t' | translate }}</span>
    {{ 'tooltip.bwh_info2' | translate }}
  </div>
  <div>
    <span>{{ 'tooltip.bwh_info3t' | translate }}</span>
    {{ 'tooltip.bwh_info3' | translate }}
  </div>
  <div>
    <span>{{ 'tooltip.bwh_info4t' | translate }}</span>
    {{ 'tooltip.bwh_info4' | translate }}
  </div>
  <div>
    <span>{{ 'tooltip.bwh_info5t' | translate }}</span>
    {{ 'tooltip.bwh_info5' | translate }}
  </div>
</div>
