import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  RendererStyleFlags2,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { Option } from '@digilize/shared/definitions/src';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'lib-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  @Input() options: string[] | Option[] | any[] = [];
  @Input() control: FormControl;
  @Input() optionObj: boolean;
  @Input() translation: boolean;
  @Input() translationPrefix: string;
  @Input() iconPath: string;
  @Input() openIconPath: string;
  @Input() myFloatLabel: FloatLabelType;
  @Input() placeholder = 'global.select';
  @Input() showTooltip = false;
  @Output() changed = new EventEmitter<any>();

  selectedChips = [];

  @HostListener('click', ['$event'])
  handleWrongOverlayPosition() {
    if (this.options.length === 0) return;
    const overlayPane = document.querySelector('.cdk-overlay-pane');
    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
    this.renderer.setStyle(overlayPane, 'transform', 'none', flags);
  }

  constructor(private renderer: Renderer2, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.selectedChips = this.control.value;
    this.control.valueChanges.subscribe((value: any) => {
      this.selectedChips = value;
    });
  }

  compareArrays(arr1: any, arr2: any): boolean {
    return arr1 && arr2 && arr1.id === arr2.id;
  }

  selected(option: string | Option) {
    this.changed.emit(option);
  }

  onChipRemoved(chip: string) {
    const option = this.control.value;
    this.removeFirst(option, chip);
    this.control.setValue(['']); // this one is required to to remove chips properly
    this.control.setValue(option); // To trigger change detection
    this.changed.emit(option);
  }

  private removeFirst(array: string[], toRemove: string): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  checkIfShowTooltip(option) {
    if (this.showTooltip) {
      if (this.translation) {
        if (this.translationPrefix) {
          return this.translateService.instant(this.translationPrefix + (this.optionObj ? option.text : option));
        } else {
          return this.optionObj ? option.text : option;
        }
      } else {
        return this.optionObj ? option.text : option;
      }
    } else {
      return null;
    }
  }
}
