import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { isFormControlRequired } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-form-scan',
  templateUrl: './form-scan.component.html',
  styleUrls: ['./form-scan.component.scss'],
})
export class FormScanComponent implements OnInit {
  open = true;
  @Input() form: UntypedFormGroup = new FormGroup({ name: new FormControl(), products: new FormControl() });
  @Input() header: string;
  @Input() required: boolean;
  @Input() productsOptions: string[];
  constructor() {}

  ngOnInit(): void {}

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }
}
