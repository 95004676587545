<mat-accordion [ngClass]="{ opened: panelOpenState }">
  <mat-expansion-panel
    [ngClass]="{ 'with-border': !noBorder, 'no-radius': noRadius }"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    hideToggle
  >
    <mat-expansion-panel-header [ngClass]="{ 'header-expanded': panelOpenState, 'hover-header': hoverHeaderBg }">
      <mat-panel-title class="arrow-header" *ngIf="noArrowsHeader; else noArrowsHeaderTemplate">
        <ng-container *ngTemplateOutlet="header || defaultHeaderTemplate"></ng-container>
        <span
          class="arrow desktop"
          [inlineSVG]="'/icons/icon-chevron-arrow.svg'"
          [ngClass]="{ opened: panelOpenState }"
        ></span>
        <span
          class="arrow mobile"
          [inlineSVG]="'/icons/icon-chevron-arrow.svg'"
          [ngClass]="{ opened: panelOpenState }"
        ></span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p>
      <ng-container *ngTemplateOutlet="body"></ng-container>
    </p>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #defaultHeaderTemplate>
  <p>
    {{ headerText }}
  </p>
</ng-template>

<ng-template #noArrowsHeaderTemplate>
  <mat-panel-title class="no-arrows-header">
    <ng-container *ngTemplateOutlet="header || defaultHeaderTemplate"></ng-container>
  </mat-panel-title>
</ng-template>
