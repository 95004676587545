
export interface ProductRequest {
  shop_id: string;
  product_id: string;
  first_name: string;
  last_name: string;
  phone: string;
  message: string;
  email: string;
}
