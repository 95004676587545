import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'lib-chips-auto',
  templateUrl: './chips-auto.component.html',
  styleUrls: ['./chips-auto.component.scss'],
})
/**
 * @param {boolean} unique enables logic to remove non unique inputs
 * @param {string[]} originalOptions stores options from before control dirty
 * @param {string} control its inner use only control (logic is based on this), picking option makes its value null
 */
export class ChipsAutoComponent implements OnInit, OnChanges {
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  filteredOptions: Observable<string[]>;
  _options: string[];
  originalOptions: string[];
  openDropdownOnClick = false;
  control: FormControl = new FormControl(null);
  @Input() unique = true;
  @Input() placeholder = '';
  @Input() positionOfInput: 'bottom' | 'top' = 'bottom';
  @Input() pickedOptions: string[] = [];
  @Input() myFloatLabel: FloatLabelType;
  @Input() requiredInLabel = false;
  @Input() set options(options) {
    this.originalOptions = options;
    this._options = options;
  }
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() emitAddedItem: boolean;
  @Input() emitRemovedItem: boolean;
  @Input() addChipOnFocusOut: boolean;
  @Output() valueChanged = new EventEmitter<string[] | string | any>();
  @Output() removedOption = new EventEmitter<string[] | string | any>();
  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;

  constructor() {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.initControl();
    }
  }

  initControl() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => (option ? this._filter(option) : this._options.slice())),
      map((options: string[]) => options.filter((option) => !this.pickedOptions.includes(option)))
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.addPickedOption(value);
    }
    event.chipInput.clear();
    this.control.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addPickedOption(event.option.viewValue);
    this.optionInput.nativeElement.value = '';
    this.control.setValue(null);
  }

  private _filter(value: string): string[] {
    return this._options.filter((option) => option?.includes(value));
  }

  addPickedOption(option: string) {
    if (this.pickedOptions.includes(option)) return;
    this.pickedOptions.push(option);
    if (this.emitAddedItem) {
      this.valueChanged.emit(option);
    } else {
      this.valueChanged.emit(this.pickedOptions);
    }
  }

  removePickedOption(option: string) {
    const index = this.pickedOptions.indexOf(option);
    if (index >= 0) {
      this.pickedOptions.splice(index, 1);
    }
    if (this.emitRemovedItem) {
      this.removedOption.emit(option);
    } else {
      this.valueChanged.emit(this.pickedOptions);
    }
  }

  handleInputClick() {
    this.openDropdownOnClick = true;
  }

  onInputFocusOut() {
    if (this.addChipOnFocusOut && this.optionInput.nativeElement.value) {
      const value = this.control.value;
      if (value) {
        this.addPickedOption(value);
      }
      this.control.setValue(null);
      if (this.optionInput) {
        this.optionInput.nativeElement.value = '';
      }
    }
  }
}
