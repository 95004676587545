import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DialogService } from '@digilize/shared/utils/services/src';
import { ManagePasswordComponent } from '../manage-password/manage-password.component';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { SharedAccountDataService } from '../../services/shared-account-data.service';
import { AccountData } from '../../models/account';

@Component({
  selector: 'bwc-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit {
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  openMenu = false;
  accountData: AccountData;
  dataLoaded = false;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private sharedAccountDataService: SharedAccountDataService
  ) {}

  ngOnInit(): void {
    this.sharedAccountDataService.getData().subscribe((data: AccountData) => {
      this.accountData = data;
      if (data) {
        this.dataLoaded = true;
      }
    });
  }

  toggleNav() {
    this.openMenu = !this.openMenu;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.dropdownContainer.nativeElement.contains(event.target)) {
      this.openMenu = false;
    }
  }

  changePassword() {
    this.dialogService.openDialog(ManagePasswordComponent, {
      disableClose: false,
      width: '670px',
      data: { type: 'change', email: this.accountData.email },
    });
  }

  saveProfile() {
    const dialogRef = this.dialogService.openDialog(ManagePasswordComponent, {
      disableClose: false,
      width: '670px',
      data: { type: 'create', email: this.accountData.email },
    });
  }

  logout() {
    this.authService.logout('/login');
  }
}
