import { NodeContentAlignment, QuestionAnswerMode, QuestionDisplayMode } from '../enums';

export interface ProcessQuestion {
  text: string;
  variable_id: string;
  answer_mode: QuestionAnswerMode;
  display_mode: QuestionDisplayMode;
  alignment: NodeContentAlignment;
  answers: Answer[];
  short_code: string;
  placeholder_text: string;
  has_selection_limitation: boolean;
  min_selection: number;
  max_selection: number;
  has_range: boolean;
  min: number;
  max: number;
  is_optional: boolean;
}

export interface Answer {
  text: string;
  val: string;
  tooltip: string;
}
