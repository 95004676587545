import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lib-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent implements OnInit {
  @Input() dropDirection: 'right' | 'left' = 'left';
  @Input() closingOnClickInside = true;
  @Output() droplistToggle = new EventEmitter<boolean>();
  @ViewChild('content') childComp: ElementRef;
  @ContentChild('rows') rows!: TemplateRef<any>;
  @ContentChild('trigger') trigger!: TemplateRef<any>;
  isListVisible = false;
  constructor() {}

  /*   ngAfterViewInit(): void {
    this.childComp.nativeElement.addEventListener('click', () => {
      this.isListVisible = !this.isListVisible;
    });
  } */

  ngOnInit(): void {}

  handleTriggerClick(e: Event) {
    e.stopPropagation();
    if (this.closingOnClickInside || this.isListVisible === false) {
      this.isListVisible = !this.isListVisible;
      this.droplistToggle.emit(this.isListVisible);
    }
  }

  handleClickOutside() {
    this.isListVisible = false;
    this.droplistToggle.emit(this.isListVisible);
  }
}
