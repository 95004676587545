import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from '@digilize/shared/definitions/src';
import { EditorMediaAndPhoto } from '../../photo-editor/photo-editor.component';
// import { EditorMediaAndPhoto } from '../../../../../app-admin/app/components/photo-editor/photo-editor.component';
// import { Media } from '../../../../models/media.interface';
@Component({
  selector: 'lib-dialog-photo-editor',
  templateUrl: './dialog-photo-editor.component.html',
  styleUrls: ['./dialog-photo-editor.component.scss'],
})
export class DialogPhotoEditorComponent implements OnInit {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogPhotoEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { media: Media[], media_ids: string[], post: any, shopId: string },
  ) { }

  ngOnInit(): void { }

  closeDialog(images?: EditorMediaAndPhoto) {
    this.dialogRef.close(images);
  }
}
