<div class="panel-header">
  <div class="row">
    <div class="col align-self-center">
      <h2 class="panel-title" [ngClass]="{'dot': dottedTitle}">{{ 'global.' + header | translate }}</h2>
    </div>
    <div class="col-auto align-self-center col-arrow" (click)="collapse()">
      <span class="required" *ngIf="required">{{ 'global.required' | translate }}</span>
      <span class="icon-arrow" [inlineSVG]="'/icons/icon-arrow-' + (open ? 'up' : 'down') + '.svg'"></span>
    </div>
  </div>
</div>
