import { MovieStatus } from "../enums/MovieStatus";


export const MovieStatusOptions = [
  {
    key: 'Unknown',
    value: MovieStatus.Unknown,
  },
  {
    key: 'Imported',
    value: MovieStatus.Imported,
  },
  {
    key: 'Published',
    value: MovieStatus.Published,
  },
  {
    key: 'Archived',
    value: MovieStatus.Archived,
  },
];
