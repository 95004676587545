import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApplicantsApiService {
  constructor(private http: HttpClient) {}

  addBulkLabelsApplicant = (req) => this.http.post('/api/v1.0/applicants/labels/bulkadd', req);

  removeBulkLabelsApplicant = (req) => this.http.delete('/api/v1.0/applicants/labels/bulkremove', { body: req });
}
