import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Option } from '@digilize/shared/definitions/src';
import { FormSectionService } from '../../services/form-section.service';
import { ErrorService } from '../../services/error.service';
import { MONTHS_FORMAT } from '../../consts/month-year-format';
import { createOptions, returnCategories } from '../../helpers/helpers';
import { EducationService } from '../../services/education.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'bwc-education-stage',
  templateUrl: './education-stage.component.html',
  styleUrls: ['./education-stage.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MONTHS_FORMAT },
  ],
})
export class EducationStageComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() countriesOptions;
  @Input() maxEducationSteps;
  @Output() formValidityChanged = new EventEmitter<boolean>();

  isAddEducationDisabled = false;
  date = new FormControl(moment());
  schoolsOptions = [];
  levelOptions = [];
  levelFullOptions = [];
  studyOptions = [];
  educationCategoriesOptions = [];
  schoolsData = [];
  dataLoaded = false;
  setLevel = true;
  eduCatDisabled = false;
  itemClickable = true;
  exp_index: number;
  exp_opened = false;
  optionJustClicked = false;

  constructor(
    private formSection: FormSectionService,
    public errorService: ErrorService,
    private educationService: EducationService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.formValidityChanged.emit(this.form.valid);
    });
    this.getEducationCategories();
    setTimeout(() => this.form.updateValueAndValidity(), 0);
  }

  getEducationCategories() {
    this.educationService.getCategories().subscribe((data: any) => {
      const catData = data.data.map((item) => ({ id: item.id, text: item.name, val: item.id }));
      this.educationCategoriesOptions = catData;
      this.dataLoaded = true;
    });
  }

  addEducation() {
    const newFormGroup = this.formSection.createEducationFormSection({
      school_id: null,
      country: '',
      education_id: null,
      education_name: '',
      education_category_names: [],
      education_level_name: '',
      start_date: '',
      end_date: '',
      is_current: false,
      order: this.formSections.length,
    });
    this.formSections.push(newFormGroup);
    this.checkSectionCount();
    this.resetOrderProperty();
  }

  levelIsGeneral(array, id) {
    return array.some((obj) => obj.id === id && obj.is_general === true);
  }

  handleFilteredOptionClick(option: Option, index, name) {
    this.optionJustClicked = true;
    setTimeout(() => {
      this.optionJustClicked = false;
    }, 500);
    const selectedCountry = this.getFormControl(index, 'country').value;

    if (Array.isArray(name)) {
      this.getFormControl(index, name[0]).setValue(option.text);
      this.getFormControl(index, name[1]).setValue(option.val);
    }

    if (name === 'start_date') this.getFormControl(index, 'end_date').updateValueAndValidity();
    if (name === 'end_date') this.getFormControl(index, 'start_date').updateValueAndValidity();

    if (name === 'country') {
      this.getFormControl(index, name).setValue(option.val);
      this.searchForSchools('', option.val, (data: any) => {
        this.schoolsData = data.data;
        this.schoolsOptions[index] = createOptions(data.data);
      });
    }

    if (name[0] === 'education_level_name') {
      if (this.levelIsGeneral(this.levelFullOptions, option.id)) {
        this.eduCatDisabled = true;

        const overigCat = this.educationCategoriesOptions.find((ob) => ob.text === 'Overig');
        this.getFormControl(index, 'education_categories').setValue(overigCat ? [overigCat] : []);
        this.getFormControl(index, 'education_category_ids').setValue(overigCat ? [overigCat.id] : null);
        this.getFormControl(index, 'education_category_names').setValue(overigCat ? [overigCat.text] : null);
        this.getFormControl(index, 'education_level_is_general').setValue(true);
      } else {
        this.getFormControl(index, 'education_level_is_general').setValue(false);
      }
    }

    if (name === 'education_name') {
      this.getFormControl(index, name).setValue(option.text);
      this.searchForStudy(option.text, (data: any) => {
        this.setLevel = false;
        const dataEducation = data.data.find((item) => item.id === option.id);
        this.getFormControl(index, 'education_category_names').setValue(dataEducation.education_categories);
        this.getFormControl(index, 'education_category_ids').setValue(dataEducation.education_category_ids);
        this.getFormControl(index, 'education_id').setValue(dataEducation.id);
        this.getFormControl(index, 'education_categories').setValue(
          returnCategories(dataEducation.education_category_ids, dataEducation.education_categories)
        );

        if (this.levelOptions[index] === undefined) {
          this.searchForLevels('', selectedCountry, (data: any) => {
            this.levelOptions[index] = createOptions(data.data);
          });
        } else {
          this.levelOptions[index] = [...this.levelOptions[index]];
        }

        if (
          this.educationCategoriesOptions[index] === undefined ||
          this.educationCategoriesOptions[index].length === 0
        ) {
          this.educationService.getCategories().subscribe((data: any) => {
            const catData = data.data.map((item) => ({ id: item.id, text: item.name, val: item.id }));
            this.educationCategoriesOptions[index] = catData;
          });
        } else {
          this.educationCategoriesOptions[index] = { ...this.educationCategoriesOptions[index] };
        }

        this.getFormControl(index, 'education_level_name').setValue(dataEducation.education_level);
        this.getFormControl(index, 'education_level_id').setValue(dataEducation.education_level_id);

        setTimeout(() => {
          this.form.updateValueAndValidity();
        }, 0);
      });
    }
  }

  handleValueChanged(event, index, options, name) {
    const selectedCountry = this.getFormControl(index, 'country').value;

    if (name === 'school_name') {
      this.searchForSchools(event, selectedCountry, (data: any) => {
        this.schoolsData = data.data;
        this.schoolsOptions[index] = createOptions(data.data);
      });

      this.getFormControl(index, name).setValue(event);
    }

    if (name === 'country') {
      if (options) {
        const optionIndex = options.findIndex((item) => item.text === event);
        if (optionIndex !== -1) {
          this.getFormControl(index, name).setValue(options[optionIndex].val);
        } else {
          this.getFormControl(index, name).setValue('');
        }
      }
    }

    if (name === 'education_name') {
      if (this.educationCategoriesOptions[index] === undefined || this.educationCategoriesOptions[index].length === 0) {
        this.educationService.getCategories().subscribe((data: any) => {
          const catData = data.data.map((item) => ({ id: item.id, text: item.name, val: item.id }));
          this.educationCategoriesOptions[index] = catData;
        });
      }

      this.getFormControl(index, name).setValue(event);
      if (!this.optionJustClicked) {
        this.getFormControl(index, 'education_id').setValue(null);
      }

      this.searchForStudy(event, (data: any) => {
        this.studyOptions[index] = createOptions(data.data);
      });
    }

    if (name[0] === 'education_level_name') {
      if (options) {
        const optionIndex = options.findIndex((item) => item.text === event);
        if (optionIndex !== -1) {
          this.getFormControl(index, name[0]).setValue(options[optionIndex].text);
          this.getFormControl(index, name[1]).setValue(options[optionIndex].id);
        } else if (this.setLevel) {
          this.setLevel = true;
          this.getFormControl(index, name[0]).setValue('');
          this.getFormControl(index, name[1]).setValue('');
        }
      }

      this.form.updateValueAndValidity();
    }
  }

  handleInputFocused(index, options, name) {
    if (name === 'school_id') {
      if (!options[index] || options[index].length === 0) {
        const selectedCountry = this.getFormControl(index, 'country').value;
        this.searchForSchools('', selectedCountry, (data: any) => {
          this.schoolsData = data.data;
          this.schoolsOptions[index] = createOptions(data.data);
        });
      }
    }

    if (name === 'education_level_name') {
      const selectedCountry = this.getFormControl(index, 'country').value;
      this.searchForLevels('', selectedCountry, (data: any) => {
        this.levelFullOptions[index] = data.data;
        this.levelOptions[index] = createOptions(data.data);
      });
    }

    if (name === 'education_name') {
      if (!options[index] || options[index].length === 0) {
        this.searchForStudy('', (data: any) => {
          this.studyOptions[index] = createOptions(data.data);
        });
      }
    }
  }

  handleMultiSelectClick(event, index) {
    this.getFormControl(index, 'education_category_names').setValue(event.map((item) => item.text));
    this.getFormControl(index, 'education_category_ids').setValue(event.map((item) => item.id));
  }

  changePositionInJobsList(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.formSections.controls, event.previousIndex, event.currentIndex);
      this.resetOrderProperty();
      const updatedSelectedJobs = [];
      for (const control of this.formSections.controls) {
        updatedSelectedJobs.push(control.value);
      }

      this.formSections.setValue(updatedSelectedJobs);
    }
  }

  resetOrderProperty() {
    for (let i = 0; i < this.formSections.controls.length; i++) {
      const control = this.formSections.controls[i];
      const value = control.value;
      value.order = i;
    }
  }

  editJob(index: number) {
    this.exp_index = this.exp_index === index ? undefined : index;

    if (this.exp_index !== index) {
      setTimeout(() => (this.exp_opened = false), 350);
    } else {
      this.exp_opened = true;
    }
  }

  stillStudingChange(checked: boolean, index, controlName): void {
    if (checked) {
      this.getFormControl(index, controlName).setValue('');
    }
  }

  searchForSchools(seachValue, countryCode, callback: (data: any) => void) {
    this.educationService.getSchoolsData(seachValue, countryCode).subscribe(
      (data: any) => {
        callback(data);
      },
      (err) => {
        console.error('Error getting schools ', err);
      }
    );
  }

  searchForLevels(seachValue, countryCode, callback: (data: any) => void) {
    this.educationService.getLevels(seachValue, countryCode).subscribe(
      (data: any) => {
        callback(data);
      },
      (err) => {
        console.error('Error getting levels ', err);
      }
    );
  }

  searchForStudy(seachValue, callback: (data: any) => void) {
    this.educationService.getEducation(seachValue).subscribe(
      (data: any) => {
        callback(data);
      },
      (err) => {
        console.error('Error getting study ', err);
      }
    );
  }

  searchForCategories(callback: (data: any) => void) {
    this.educationService.getCategories().subscribe(
      (data: any) => {
        callback(data);
      },
      (err) => {
        console.error('Error getting education categories ', err);
      }
    );
  }

  removeItem(index: any) {
    this.formSections.removeAt(index);
    this.checkSectionCount();
  }

  checkSectionCount() {
    const numSections = this.formSections.length;
    this.isAddEducationDisabled = numSections >= this.maxEducationSteps;
  }

  getFormControl(index: number, controlName: string): FormControl {
    const section = this.formSections.at(index) as FormGroup;
    return section.get(controlName) as FormControl;
  }

  getFormArray(index: number, controlName: string): FormArray {
    const section = this.formSections.at(index) as FormGroup;
    return section.get(controlName) as FormArray;
  }

  get formSections() {
    return this.form.get('formSections') as FormArray;
  }

  removeField(i, name, fieldInd) {
    const education_category_names = this.getFormArray(i, name);
    education_category_names.removeAt(fieldInd);
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  validityChecking(index, status) {
    if (status === 'INVALID') {
      return 'error';
    }

    const arrNot = [
      'education_category_ids',
      'education_category_names',
      'education_level_name',
      'education_level_id',
      'education_level_is_general',
      'education_options',
      'end_date',
      'education_name',
      'id',
      'is_current',
      'level_options',
      'order',
      'school_id',
      'school_options',
    ];
    const job = this.formSections.at(index).value;
    const params = Object.keys(job);
    let isCurrentFilled = false;
    let endDateFilled = false;
    let emptyParams = [];

    for (let i = 0; i < params.length; i++) {
      const paramName = params[i];
      const paramValue = job[paramName];
      if (paramName === 'end_date' && paramValue !== '' && paramValue !== null) {
        endDateFilled = true;
      }
      if (paramName === 'is_current' && paramValue === true) {
        isCurrentFilled = true;
      }
      if (!arrNot.includes(paramName) && (paramValue === null || paramValue === '')) {
        emptyParams.push(paramName);
      }
    }

    if (emptyParams.length > 0 || (emptyParams.length === 0 && isCurrentFilled === false && endDateFilled === false)) {
      return 'warning';
    }

    return 'checked';
  }

  setMonthAndYear(date: Moment, i: number, controlName: string, datepicker: MatDatepicker<Moment>) {
    this.getFormControl(i, controlName).setValue(date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
    datepicker.close();
  }
}

export interface SelectedCountry {
  text: string;
  val: string;
}
