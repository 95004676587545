import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ResearchDataApiService {
  readonly BASE_HREF = '/api/v1.0/research-data';
  constructor(private http: HttpClient, private translateService: TranslateService) {}

  getDataFromFullUrl(fullPath: string, label = 'all') {
    /* we need to add /api so interceptor will trigger*/
    const url = new URL(fullPath);
    const apiLink = `/api${url.pathname}`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('label', label.toString());
    queryParams = queryParams.append('language', this.translateService.currentLang);

    return this.http.get(apiLink, { responseType: 'text', params: queryParams });
  }
}
