<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content" *ngIf="shopId">
    <div class="dialog-header">
      <h1 class="dialog-title dot">{{ 'social_media_connection.connect' | translate }}</h1>
    </div>
    <div class="dialog-body">
      <p class="info text-center mb-2">
        {{ 'social_media_connection.info' | translate }}
      </p>
    </div>
    <div class="dialog-bottom">
      <button type="button" class="btn btn-fb d-block w-100" (click)="pagesRedirect('facebook')">
        <span class="icon-fb" [inlineSVG]="'/icons/icon-fb.svg'"></span>
        {{ 'social_media_connection.connect_with_facebook' | translate }}
      </button>
      <button type="button" class="btn btn-insta d-block w-100" (click)="pagesRedirect('instagram')">
        <span class="icon-insta" [inlineSVG]="'/icons/icon-insta.svg'"></span>
        {{ 'social_media_connection.connect_with_instagram' | translate }}
      </button>
    </div>
  </div>
</div>
