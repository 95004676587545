import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private document) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  setCanonicalUrl(url?: string) {
    const oldCan = this.document.getElementById('canonical');
    if (oldCan) {
      oldCan.outerHTML = '';
    }
    const canURL = url === undefined ? this.document.URL : url;
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('id', 'canonical');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', canURL);
  }
}
