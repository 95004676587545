<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form" class="form-photos">
      <lib-drag-drop-photos
        [items]="getFormControl('media').value ? getFormControl('media').value : []"
        (filesChange)="updateForm($event)"
      ></lib-drag-drop-photos>
    </form>
  </div>
</div>
