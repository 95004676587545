import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentSwitcherComponent } from './component-switcher/component-switcher.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ComponentSwitcherComponent],
  exports: [ComponentSwitcherComponent],
})
export class SharedFeatureModulesComponentSwitcherModule {}
