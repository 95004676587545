import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTextByVal',
})
export class GetTextByValPipe implements PipeTransform {
  transform(val: number, arr: List[]): string {
    const found: List = arr.find((item) => item.val === val);
    return found ? found.text : null;
  }
}

interface List {
  text: string;
  val: number;
}
