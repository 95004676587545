<div
  class="container"
  [ngClass]="{
    checked: model === value,
    'not-checked': model !== value,
    disabled: ngControl.disabled,
    correct: styleClass === 'correct',
    error: styleClass === 'error'
  }"
  (click)="select()"
>
  <span class="prefix" *ngIf="charPrefix">{{ charPrefix }}. </span> <span [innerHTML]="innerHTML"></span>
</div>
