import { Email } from './Email';

export interface InviteAfterResponse {
  results: InviteResult[];
  invited_after_current: number;
  invited_after_max: number;
}
export interface InviteResult {
  item: Email;
  error: string;
}
