<div class="dialog-panel dialog-subscription">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>

    <div class="dialog-header">
      <h1 class="dialog-title dot">{{ 'subscription.new_subscription' | translate }}</h1>
    </div>

    <form [ngClass]="{ 'v-hidden': loading || !company || !types }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-body">
        <div class="row">
          <div class="col-12 col-md-5 form-field-container">
            <div class="form-field-label required">
              {{ 'form.field.start_date' | translate }}
            </div>
            <mat-form-field class="form-field" [floatLabel]="'never'">
              <input matInput [matDatepicker]="picker" [formControlName]="'start'" [min]="minDate" required />
              <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-12 form-field-container">
            <div class="form-field-label required">
              {{ 'form.field.select_shop' | translate }}
            </div>
            <!--shops could be wrong, added any after lib-select refactor  -->
            <lib-select
              [value]="form.get('shop_id').value"
              [options]="$any(shops)"
              (valueChange)="form.get('shop_id').setValue($event)"
            ></lib-select>
          </div>

          <div class="col-12 form-field-container">
            <div class="form-field-label required">
              {{ 'form.field.select_subscription_type' | translate }}
            </div>
            <lib-select
              [value]="form.get('subscription_type_id').value"
              [groups]="types"
              (valueChange)="form.get('subscription_type_id').setValue($event); setPeriods($event)"
            ></lib-select>
          </div>

          <div class="col-12 form-field-container" *ngIf="periods.length > 0">
            <div class="form-field-label required">
              {{ 'form.field.renewal' | translate }}
            </div>
            <lib-select
              [value]="form.get('renewal_period').value"
              [options]="periods"
              [translation]="false"
              (valueChange)="form.get('renewal_period').setValue($event)"
            ></lib-select>
          </div>

          <div class="col-12 form-field-container">
            <div class="form-field-label required">
              {{ 'form.field.iban' | translate }}
            </div>
            <mat-form-field class="form-field" [floatLabel]="'never'">
              <input
                matInput
                formControlName="iban"
                [placeholder]="'subscription.iban_placeholder' | translate"
                required
              />
              <mat-error *ngIf="getFormControl('iban').invalid">
                <ng-container *ngIf="getFormControl('iban').errors.required">
                  {{ 'form.error.required' | translate }}
                </ng-container>
                <ng-container *ngIf="getFormControl('iban').errors.iban">
                  {{ 'form.error.invalid_iban' | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 form-field-container">
            <div class="form-field-label">
              {{ 'form.field.invoice_email' | translate }}
            </div>
            <mat-form-field class="form-field" [floatLabel]="'never'">
              <input matInput formControlName="invoice_email" />
              <mat-error *ngIf="getFormControl('invoice_email').invalid">
                <ng-container *ngIf="getFormControl('invoice_email').errors.email">
                  {{ 'form.error.invalid_email' | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 form-field-container">
            <mat-checkbox
              class="auth-checkbox"
              formControlName="agreement_additional"
              *ngIf="companyGroup?.agreement_text || companyGroup?.agreement"
              required
            >
              <span *ngIf="companyGroup?.agreement_text" [innerHTML]="companyGroup?.agreement_text"></span>
              <a
                class="ml-1 d-inline-block"
                *ngIf="companyGroup.agreement"
                [href]="companyGroup.agreement.url1x"
                target="_blank"
              >
                {{ 'subscription.open_agreement' | translate }}
              </a>
            </mat-checkbox>

            <mat-checkbox class="auth-checkbox" formControlName="agreement_direct_debeting" required>
              {{ 'subscription.agreement_direct_debeting' | translate }}
            </mat-checkbox>

            <mat-checkbox class="auth-checkbox" formControlName="agreement_general" required>
              <span [innerHTML]="'subscription.agreement_general' | translate"></span>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="dialog-bottom">
        <div class="row">
          <div class="ml-auto col-12 col-md-4">
            <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
              {{ 'global.confirm' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
