import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-abc-radio-btn',
  templateUrl: './abc-radio-btn.component.html',
  styleUrls: ['./abc-radio-btn.component.scss'],
})
export class AbcRadioBtnComponent implements ControlValueAccessor, OnInit {
  @Input() value: any;
  charPrefix: string | undefined;
  @Input() set index(index: number) {
    /* we assume index wont be bigger than amount of characters */
    this.charPrefix = String.fromCharCode(65 + (index % 65));
  }
  @Input() styleClass: AbcStyleClass = false;
  @Input() innerHTML;
  model: any;
  onChange = (value: any) => {};
  onTouched = () => {};
  constructor(@Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.ngControl.control.valueChanges.subscribe((value) => {
      // Check to ensure the value wasn't already set (Template driven forms)
      if (this.model === value) return;
      this.writeValue(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.model = value;
  }

  select() {
    if (this.model !== this.value) {
      this.model = this.value;
      this.onChange(this.model);
    }
  }
}

export type AbcStyleClass = 'error' | 'correct' | false;
