export enum QuestionDisplayMode {
  Buttons = 1,
  MultiColumn = 2,
  DropDown = 3,
  SearchableDropDown = 4,
  HorizontalRadioButtons = 5,
  VerticalRadioButtons = 6,
  InputField = 7,
  TextBox = 8,
  VerticalButtons = 9,
  None = 10,
}
