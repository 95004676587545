/* eslint-disable @nx/enforce-module-boundaries */
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { capitalizeKeys, createTextValIdIndexArr } from '../../helpers/helpers';
import { Observable, catchError, debounceTime, distinctUntilChanged, forkJoin, of, switchMap, throwError } from 'rxjs';

import { DatePipe } from '@angular/common';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { ALERT_UPDATE_SUCCESS, Me, Notes, SnackbarType } from '@digilize/shared/definitions/src';
import {
  capitalizeKeys,
  createAndObserveIntersection,
  createIdTextValIndexArr,
  trimFgEmptyControls,
} from '@digilize/shared/utils/helpers/src';
import { DialogService } from '@digilize/shared/utils/services/src';
import { TranslateService } from '@ngx-translate/core';
import { DialogConfirmComponent } from '@digilize/shared/feature/components/src';
import { AlertService } from '@digilize/shared/feature/modules/alert/src';
import { FiltersApiService, LoggedInUserDataService, NotesApiService } from '@digilize/shared/data/services/src';
import { SelectPlainComponent } from '../form-items/select-plain/select-plain.component';

@Component({
  selector: 'lib-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, AfterViewInit {
  @ViewChild(SelectPlainComponent) selectPlainComponent!: SelectPlainComponent;
  @ViewChild('endOfList') endOfList: ElementRef;

  candidateNotes: any;
  companyId;
  userId;
  me: Me;
  prefilterType;
  showNoteEditor = false;
  editingNote = false;
  editingNoteId: string;
  userVacanciesLoaded = false;
  notesDataLoaded = false;
  currentDate = new Date();
  isoStringDate = this.currentDate.toISOString();
  filterTypeOptions = ['emails', 'status', 'comments'];
  filterTranslatedTypeOptions = [];
  filterTypeSelectOptions;
  filterContextSelectOptions;
  filterContextOptions: KeyValue[] = [];
  filterContextsBase = ['All'];

  userVacaniesListData: any[] = [];

  noteTypeClassMap = {
    1: 'email-note',
    2: 'status-note',
    3: 'comment-note',
  };
  filters: UntypedFormGroup = new UntypedFormGroup({
    applied_types: new UntypedFormControl([]),
    applied_contexts: new UntypedFormControl({}),
    applied_comment_contexts: new UntypedFormControl({}),
  });

  form = new UntypedFormGroup({
    text: new UntypedFormControl('', Validators.required),
  });

  availableFiltersData;
  filtersData = [];

  constructor(
    public dialogRef: MatDialogRef<NotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotesComponent,
    private notesApiService: NotesApiService,
    private datePipe: DatePipe,
    private snackbarService: SnackbarService,
    private loggedInUserService: LoggedInUserDataService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private filtersApiService: FiltersApiService
  ) {}

  ngOnInit(): void {
    this.setFiltersOptions();
    this.setFilterContextOptions();
    if (this.data.companyId && this.data.userId) {
      this.companyId = this.data.companyId;
      this.userId = this.data.userId;
      this.initFilters();
    }
    this.getAvailableFiltersForUser();
    this.getLoggedUserData();
  }

  setFilterContextOptions() {
    if (this.translateService.currentLang === 'nl') {
      this.filterContextsBase[0] = 'Alle notities';
    }

    this.filterContextOptions = this.filterContextsBase.map((item) => ({ key: item, value: item }));
  }

  ngAfterViewInit(): void {
    createAndObserveIntersection(this.endOfList).subscribe((isVisible) => {
      if (isVisible && this.notesDataLoaded) {
        this.getNotes().subscribe(
          (resp) => {
            if (resp) {
              this.candidateNotes.push(...resp.data);
            }
          },
          (error) => {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 5000,
              data: { msg: 'alert.custom.request_error', translate: true },
            });
            return throwError(error);
          }
        );
      }
    });
  }

  setFiltersOptions() {
    this.filterTypeOptions.map((item) => {
      this.filterTranslatedTypeOptions.push(this.translateService.instant('notes.' + item));
    });
    this.filterTypeSelectOptions = createIdTextValIndexArr(this.filterTranslatedTypeOptions);
    this.filterTypeSelectOptions.map((item) => {
      item.selected = false;
    });
  }

  getAvailableFiltersForUser() {
    this.getAvailableFilters('user-notes')
      .pipe(
        catchError((error) => {
          this.snackbarService.openSnackbar(SnackbarType.Error, {
            duration: 5000,
            data: { msg: 'alert.custom.request_error', translate: true },
          });
          return throwError(error);
        })
      )
      .subscribe((availableFiltersData) => {
        this.availableFiltersData = availableFiltersData;

        if (availableFiltersData.filters.length > 0) {
          const observables = availableFiltersData.filters.map((item) => this.getFilterDataNameValues(item.name));

          forkJoin(observables).subscribe(
            (dataArray: any) => {
              dataArray.forEach((data) => {
                this.filtersData.push(data);
              });

              this.filtersData.forEach((item, index) => {
                item.filter_values.forEach((filter) => {
                  const values = filter.possible_values.data.map((val) => ({
                    ...val,
                    param_name: filter.param_name,
                    filter_name: this.availableFiltersData.filters[index].name,
                  }));

                  //check if not_vacancy_related and change name
                  const indVacNotRel = values.findIndex((obj) => obj.key === 'not_vacancy_related');
                  if (indVacNotRel !== -1) {
                    const notVacancyRelatedObj = values.splice(indVacNotRel, 1)[0];
                    const generalUserNotesKey =
                      this.translateService.currentLang === 'nl' ? 'Algemene notities' : 'General user notes';

                    notVacancyRelatedObj.key = generalUserNotesKey;
                    values.unshift(notVacancyRelatedObj);
                  }

                  this.filterContextOptions.push(...values);
                  this.userVacanciesLoaded = true;
                });
              });
              if (this.data.prefilterType) {
                this.setPrefilter(this.data.prefilterType);
              }
              setTimeout(() => {}, 0);
            },
            (error) => {
              console.error('Error fetching filter data:', error);
              throwError(error);
            }
          );
        }
      });
  }

  getAvailableFilters(type: string): Observable<any> {
    return this.filtersApiService.getFilters({
      typeId: type,
    });
  }

  getFilterDataNameValues(filterName: string): Observable<any> {
    return this.filtersApiService.getFilterNameValues(filterName, { company: this.companyId, user: this.userId });
  }

  getLoggedUserData() {
    this.loggedInUserService.getLoggedInUserData().subscribe((data: Me | null) => {
      if (data) {
        this.me = data;
      }
    });
  }

  private initFilters() {
    this.filters.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(() => this.getNotes({ start: 0 }))
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.candidateNotes = resp.data;
            this.notesDataLoaded = true;
          }
        },
        (error) => {
          this.snackbarService.openSnackbar(SnackbarType.Error, {
            duration: 5000,
            data: { msg: 'alert.custom.request_error', translate: true },
          });
          return throwError(error);
        }
      );
  }

  getNotes(overrideProps: Object = {}): Observable<any> {
    const types = this.returnFilterTypes(trimFgEmptyControls(this.filters));
    const context = { ...this.filters.value.applied_contexts };
    const ifComment = this.filters.value.applied_comment_contexts.comment;
    let encodedReq;

    if (!ifComment) {
      if (context.key !== this.filterContextsBase[0] && context.key !== this.filterContextsBase[1]) {
        context.key = context.param_name;
        const capitalizesEventObj = capitalizeKeys(context);
        const filters = [
          {
            FilterName: context.filter_name,
            Params: [capitalizesEventObj],
          },
        ];

        encodedReq = encodeURIComponent(JSON.stringify(filters));
      }

      return this.notesApiService.getNotes({
        types: types,
        length: 10,
        start: this.candidateNotes?.length || 0,
        company: this.companyId,
        user: this.userId,
        ...(encodedReq && { filters: encodedReq }),
        ...overrideProps,
      });
    } else {
      return of(null);
    }
  }

  editNote(noteId: string, text: string, tags: string[]) {
    this.editingNote = true;
    this.editingNoteId = noteId;
    this.form.get('text').setValue(text);

    const commentsContext = this.filterContextOptions.filter((item) => item.key === tags[0]);

    if (commentsContext.length > 0) {
      this.applied_comment_contexts = commentsContext[0];
      this.selectPlainComponent.onValueChange(commentsContext[0], true);
    } else {
      const notVacancyRelatedTitle =
        this.translateService.currentLang === 'nl' ? 'Niet vacature gerelateerd' : 'Not vacancy related';

      this.applied_comment_contexts = { key: notVacancyRelatedTitle, value: notVacancyRelatedTitle };
      this.selectPlainComponent.onValueChange({ key: notVacancyRelatedTitle, value: notVacancyRelatedTitle }, true);
    }
    this.showNoteEditor = true;
  }

  deleteNote(noteId) {
    const dialog = this.dialogService.openDialog(DialogConfirmComponent, {
      disableClose: false,
      width: '670px',
      data: {
        confirmBtn: this.translateService.instant('global.continue'),
        descriptionHtml: this.translateService.instant('global.confirm_delete_note'),
        closeIconPath: '/icons/close-icon.svg',
      },
    });

    dialog.afterClosed().subscribe((ifYes) => {
      if (ifYes) {
        this.notesApiService
          .deleteNote(noteId)
          .pipe(
            catchError((err) => {
              this.snackbarService.openSnackbar(SnackbarType.Error, {
                duration: 5000,
                data: { msg: 'alert.custom.request_error', translate: true },
              });
              return throwError(err);
            })
          )
          .subscribe((data) => {
            this.candidateNotes = this.candidateNotes.filter((obj) => obj.id !== noteId);
            this.alertService.setAlert(ALERT_UPDATE_SUCCESS);
            setTimeout(() => {
              dialog.close();
            }, 2000);
          });
      }
    });
  }

  returnFilterTypes(filters) {
    return filters.applied_types.map((item) => item.id + 1);
  }

  setPrefilter(type) {
    if (type === 'user') {
      this.applied_contexts = this.filterContextOptions[1];
      this.applied_comment_contexts = this.filterContextOptions[1];
    } else {
      const option = this.filterContextOptions.filter((item) => item.key === type.job_title);

      this.handleSelectContext(option[0]);
    }
  }

  closeEditor() {
    this.showNoteEditor = false;
    this.editingNoteId = '';
    this.editingNote = false;
    this.form.get('text').setValue('');
  }

  updateNote() {
    const contextFilter = this.filters.get('applied_comment_contexts').value;

    let req: any = {
      id: this.editingNoteId,
      text: this.form.get('text').value,
      type: 3,
      creator: this.me.id,
      creator_name: this.me.first_name,
      creator_last_name: this.me.last_name,
      inserted: this.isoStringDate,
      user_id: this.userId,
      company_id: this.companyId,
    };

    if (
      contextFilter.key !== 'All' &&
      contextFilter.key !== 'General user notes' &&
      contextFilter.key !== 'Alle notities' &&
      contextFilter.key !== 'Algemene notities' &&
      contextFilter.key !== 'Not vacancy related' &&
      contextFilter.key !== 'Niet vacature gerelateerd'
    ) {
      req.vacancy_id = contextFilter.value;
    }

    const updateNoteEndpoint =
      contextFilter.key !== 'All' &&
      contextFilter.key !== 'General user notes' &&
      contextFilter.key !== 'Alle notities' &&
      contextFilter.key !== 'Algemene notities' &&
      contextFilter.key !== 'Not vacancy related' &&
      contextFilter.key !== 'Niet vacature gerelateerd'
        ? this.notesApiService.editNoteForVacancy(req)
        : this.notesApiService.editNote(req);

    updateNoteEndpoint
      .pipe(
        catchError((error) => {
          this.snackbarService.openSnackbar(SnackbarType.Error, {
            duration: 5000,
            data: { msg: 'alert.custom.request_error', translate: true },
          });
          return throwError(error);
        })
      )
      .subscribe((data: Notes) => {
        let index = this.candidateNotes.findIndex((obj) => obj.id === this.editingNoteId);
        if (index !== -1) {
          this.candidateNotes.splice(index, 1);
          this.candidateNotes.splice(index, 0, data);
        }
        this.closeEditor();
      });
  }

  saveNote() {
    const contextFilter = this.filters.get('applied_comment_contexts').value;

    let req: any = {
      text: this.form.get('text').value,
      type: 3,
      creator: this.me.id,
      creator_name: this.me.first_name,
      creator_last_name: this.me.last_name,
      inserted: this.isoStringDate,
      user_id: this.userId,
      company_id: this.companyId,
    };

    if (
      contextFilter.key !== 'All' &&
      contextFilter.key !== 'General user notes' &&
      contextFilter.key !== 'Alle notities' &&
      contextFilter.key !== 'Algemene notities'
    ) {
      req.vacancy_id = contextFilter.value;
    }

    const saveNoteEndpoint =
      contextFilter.key !== 'All' &&
      contextFilter.key !== 'General user notes' &&
      contextFilter.key !== 'Alle notities' &&
      contextFilter.key !== 'Algemene notities'
        ? this.notesApiService.createNoteForVacancy(req)
        : this.notesApiService.createNote(req);

    saveNoteEndpoint
      .pipe(
        catchError((error) => {
          this.snackbarService.openSnackbar(SnackbarType.Error, {
            duration: 5000,
            data: { msg: 'alert.custom.request_error', translate: true },
          });
          return throwError(error);
        })
      )
      .subscribe((data: Notes) => {
        this.candidateNotes.unshift(data);
        this.form.get('text').setValue('');
      });
  }

  handleChangedFilter(event) {
    this.applied_types = event;
  }

  handleRemoveFilter(event) {
    this.applied_types = event;
  }

  handleSelectContext(event: any, ifcomment?: boolean) {
    if (ifcomment) {
      event.comment = true;
      this.applied_comment_contexts = event;
    } else {
      if (event.hasOwnProperty('comment')) {
        delete event['comment'];
      }
      this.applied_contexts = event;
      this.applied_comment_contexts = event;
    }
  }

  setStatusOfFilter(control, value, remOrAdd) {
    let arr = this.filters.get(control).value;
    if (remOrAdd) {
      arr.push(value);
      this.filters.patchValue({ control: arr });
    } else {
      const index = arr.indexOf(value);
      arr.splice(index, 1);
      this.filters.patchValue({ control: arr });
    }
  }

  getDate(date: string): string {
    return this.datePipe.transform(new Date(date), 'dd.MM.yyyy HH:mm:ss');
  }

  getFormControl(controlName) {
    return this.form.get(controlName) as FormControl;
  }

  getFiltersFormControl(controlName: string): FormControl {
    return this.filters.get(controlName) as FormControl;
  }

  checkForm() {
    console.log('form', this.form.value);
    console.log('filters', this.filters.value);
  }

  close() {
    this.dialogRef.close();
  }

  getNoteType(match: number): string {
    switch (match) {
      case 0:
        return 'General';
      case 1:
        return this.translateService.instant('notes.emails');
      case 2:
        return this.translateService.instant('notes.status');
      case 3:
        return this.translateService.instant('notes.comments');
    }
  }

  get applied_types() {
    return this.filters.get('applied_types') as FormControl;
  }

  set applied_types(value) {
    this.filters.get('applied_types').setValue(value);
  }

  set applied_contexts(value: KeyValue) {
    this.filters.get('applied_contexts').setValue(value);
  }

  set applied_comment_contexts(value: KeyValue) {
    this.filters.get('applied_comment_contexts').setValue(value);
  }
}
export interface KeyValue {
  key: string;
  value: string;
}
