import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCallback',
  pure: true,
})
export class FilterCallbackPipe implements PipeTransform {
  /* this pipe is meant to be used with non dynamic data.*/ transform(
    items: any[],
    callback: (item: any) => boolean
  ): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter((item) => callback(item));
  }
}
