<span class="icon-close" [inlineSVG]="'/icons/icon-close-current-color.svg'" (click)="closeDialog()"></span>

<div class="container">
  <div class="dialog-content" [ngStyle]="{ 'min-height': minHeight }">
    <ng-container *ngTemplateOutlet="content"> </ng-container>
  </div>
  <div class="dialog-actions">
    <ng-container *ngTemplateOutlet="actions || defaultActions"> </ng-container>
  </div>
</div>
<div>
  <ng-template #defaultActions>
    <button [disabled]="disableSubmit" class="w-100 btn btn-primary" (click)="handleConfirm()">
      <ng-container *ngIf="btnText"> </ng-container>
      {{ btnText || 'global.save' | translate }}
    </button>
  </ng-template>
</div>
