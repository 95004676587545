<div class="dialog-panel message-dialog">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div class="dialog-header mb-5">
      <h1 class="dialog-title text-center">{{ 'product.select_feed_products' | translate }}</h1>
    </div>

    <div class="row filters-row">
      <div class="col-5">
        <div class="form-field-container mb-0">
          <div class="form-field-label">
            {{ 'global.shop' | translate }}
          </div>
          <lib-select
            *ngIf="options.length"
            [options]="options"
            [value]="selectedShopId"
            [translation]="true"
            [placeholder]="'choose shop'"
            (valueChange)="onShopChange($event)"
          ></lib-select>
        </div>
      </div>
      <div class="col-7">
        <div class="form-field-container mb-0">
          <mat-form-field class="form-field form-field-search" [floatLabel]="'never'">
            <span class="icon-search" [inlineSVG]="'/icons/icon-search.svg'"></span>
            <input matInput [(ngModel)]="searchValue" (keyup)="onSearchChange($event)" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="products-list-container" (scroll)="onScroll($event)">
      <ng-container *ngIf="productsList?.length; else noItems">
        <ng-container *ngFor="let product of productsList">
          <div class="product-tab">
            <div class="d-flex">
              <mat-checkbox
                class="checkbox d-block"
                [checked]="product.isChecked"
                (change)="toggleProductChoice($event.checked, product)"
              ></mat-checkbox>
              <div>
                <div class="product-img" [lazyLoad]="product.photo"></div>
              </div>
              <div class="product-info">
                <p class="product-name text-bold">{{ product.title }}</p>
                <p class="product-subtitle">{{ product.subtitle }}</p>
              </div>
            </div>
            <div class="text-bold">{{ product.price | currency: 'EUR' }}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="btn-container mt-3">
      <button [disabled]="!selectedProductIds.length" (click)="createProductsAsDraft()" class="btn btn-secondary mr-2">
        {{ 'product.create_as_draft' | translate }}
      </button>
      <button [disabled]="!selectedProductIds.length" (click)="createProducts()" class="btn btn-primary btn-pink">
        {{ 'product.create' | translate }}
      </button>
    </div>
  </div>
  <ng-template #noItems>
    <div *ngIf="!loading" class="no-items-placeholder">{{ 'global.no_items' | translate }}</div>
  </ng-template>
</div>
