import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-not-found-with-image',
  templateUrl: './not-found-with-image.component.html',
  styleUrls: ['./not-found-with-image.component.scss'],
})
export class NotFoundWithImageComponent implements OnInit {
  @Input() title = '404';
  @Input() description = '404';
  @Input() imgPath: string = null;
  constructor() {}

  ngOnInit() {}
}
