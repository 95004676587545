import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '@digilize/shared/definitions/src';

function StringIsNumber(value) {
  return isNaN(Number(value)) === false;
}

export function enumToArrOfObjs(e) {
  return Object.keys(e)
    .filter(StringIsNumber)
    .map((key) => ({ text: e[key], val: Number(key) }));
}

export function createOptions(data) {
  return data.map((item) => ({
    id: item.id,
    text: item.name,
    val: item.id,
  }));
}

export function transformToOption(data) {
  return {
    id: data.skill_id,
    text: data.skill_name,
    val: data.skill_id,
  };
}

export function mergeData(obj1, obj2) {
  const merged = { ...obj1 };
  for (const key in obj2) {
    if (!merged.hasOwnProperty(key)) {
      merged[key] = obj2[key];
    }
  }
  return merged;
}

export function removePropsFromObj(obj, props) {
  props.forEach((property) => {
    if (obj.hasOwnProperty(property)) {
      delete obj[property];
    }
  });
}

export function removeValidation(formGroup: FormGroup, controlName: string): void {
  const control: AbstractControl = formGroup.get(controlName);
  if (control) {
    control.clearValidators();
    control.updateValueAndValidity();
  }
}

export function translateCountryCodes(codes: any[], translateService: TranslateService): any[] {
  const countriesArr: any[] = [];
  for (const code of codes) {
    countriesArr.push({
      text: translateService.instant('form.countries.' + code.code.toLowerCase()),
      val: code.code,
    });
  }
  return countriesArr;
}

export function returnCategories(education_category_ids: any[], education_category_names: string[]): Option[] {
  const options: Option[] = education_category_names.map((name, index) => {
    const id =
      education_category_ids && education_category_ids[index] !== undefined && education_category_ids[index] !== null
        ? education_category_ids[index]
        : '';
    return {
      id: id,
      text: name,
      val: id,
    };
  });
  return options;
}
