import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bwc-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss'],
})
export class ControlBarComponent implements OnInit {
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() progresBar = false;

  @Output() emitClose = new EventEmitter();
  @Output() emitPrev = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.emitClose.emit();
  }

  prev() {
    this.emitPrev.emit();
  }
}
