import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopApiService } from '@digilize/shared/data/services';
import { ALERT_MESSAGE_SENT_SUCCESS } from '@shared/definitions';
import { AlertService } from '@digilize/shared/feature/modules/alert';

@Component({
  selector: 'lib-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss', '../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogMessageComponent implements OnInit {
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    text: new UntypedFormControl('', [Validators.required]),
  });
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private shopApiService: ShopApiService
  ) {}

  ngOnInit(): void {
    this.getFormControl('name').setValue(this.data.user_name);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      const requestBody = {
        ...this.form.getRawValue(),
        from_email: this.data.email,
        from_display_name: this.data.user_name,
        to_bid_id: this.data.bidId || null,
      };
      this.shopApiService.sendAuctionMessage(this.data.auctionId, requestBody).subscribe(
        (response: any) => {
          this.loading = false;
          this.alertService.setAlert(ALERT_MESSAGE_SENT_SUCCESS);
          this.closeDialog();
        },
        (error) => {
          this.alertService.handleApiError(error.error.error);
          this.loading = false;
          this.closeDialog();
        }
      );
    }
  }
}
