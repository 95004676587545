import { Buyer } from './Buyer';
import { DeliveryAddress } from './Delivery';

export interface OrderRequest {
  cart_id: string;
  buyer: Buyer;
  delivery: DeliveryAddress;
  context?: string;
  payment_method_external_id?: string;
  payment_method_variant_external_id?: string;
  timeslot: string;
  pay_after: boolean;
  comment: { comment: string };
  create_account?: boolean;
}
