import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ProductPrice } from '@shared/definitions';

@Component({
  selector: 'lib-form-price',
  templateUrl: './form-price.component.html',
  styleUrls: ['./form-price.component.scss'],
})
export class FormPriceComponent implements OnInit {
  @Input() form: UntypedFormGroup = new FormGroup({
    regular_amount: new FormControl(),
    available_from: new FormControl(),
    amount: new FormControl(),
    tax_id: new FormControl(),
    name: new FormControl(),
  });
  @Input() header: string;
  @Input() required: boolean;
  @Input() prices: ProductPrice[] = [];
  open = true;
  @Input() taxes: any[] = [];

  priceOrder = ['available_from', 'amount', 'tax_id', 'name'];

  constructor() {}

  ngOnInit(): void {
    if (this.taxes.length > 0) {
      if (this.prices?.length > 0) {
        this.initPrices();
        if (this.prices[0].regular_amount) {
          this.form.get('regular_amount').setValue(this.prices[0].regular_amount);
        }
      } else {
        this.addPrice();
      }
    }
  }

  initPrices() {
    this.prices.forEach((price) => {
      this.addPrice(price);
    });
  }

  addPrice(price?: ProductPrice) {
    const priceForm: UntypedFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(price?.id),
      tax_id: new UntypedFormControl(price?.tax_id ? price.tax_id : this.taxes[0].value, [Validators.required]),
      name: new UntypedFormControl(price?.name),
      available_from: new UntypedFormControl(price?.available_from, [Validators.required]),
      amount: new UntypedFormControl(price?.amount, [Validators.required]),
      regular_amount: new UntypedFormControl(),
    });

    this.getPrices().push(priceForm);
  }

  removePrice(index) {
    this.getPrices().removeAt(index);
  }

  getFormControl(name) {
    return this.form.get(name);
  }

  getPrices(): UntypedFormArray {
    return this.getFormControl('prices') as UntypedFormArray;
  }

  isPriceInactive(price: AbstractControl) {
    if (price.get('id').value && this.prices?.length > 0) {
      const date = new Date(price.get('available_from').value);
      return new Date() >= date;
    } else {
      return false;
    }
  }
}
