import { Pipe, PipeTransform } from '@angular/core';
import { Multilingual } from '@digilize/shared/definitions/src';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getTranslation',
  pure: true,
})
export class GetTranslationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(multi: Multilingual | undefined, errorCb?: (text: string) => void): string {
    const text = multi[this.translateService.currentLang as keyof Multilingual];

    if (!text) {
      console.error('Wrong Multilingual object');
      return '';
    }
    if (errorCb) {
      errorCb(text);
    }
    return text;
  }
}
