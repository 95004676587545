import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopClickPropagation]',
})
export class StopClickPropagationDirective {
  @HostListener('click', ['$event']) public onclick(e: MouseEvent) {
    e.stopPropagation();
  }

  constructor() {}
}
