<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitClose)="close()"
  (emitPrev)="goPrev()"
>
</bwh-lib-step-control-bar>
<div class="selecting-job text-center">
  <div class="main-header">
    <h1 class="main-title">{{ 'global.select_your_job' | translate }}</h1>
  </div>
  <form class="scrollable-content" [formGroup]="form">
    <lib-single-select-search
      class="main-search"
      #searchAutocomplete
      [options]="jobsList"
      [placeholder]="'global.search_for_your_job' | translate"
      [iconPath]="'/icons/search-icon.svg'"
      [staticIcon]="true"
      [myFloatLabel]="'never'"
      [addDebounce]="300"
      [clearInput]="true"
      [waitForOptionsChange]="true"
      [suggestionsOff]="true"
      [detectScrollToEnd]="true"
      (changed)="handleFilteredOptionClick($event)"
      (valueChanged)="handleValueChangedSearch($event)"
      (scrolledToEnd)="handleScrolledToEndSearch($event)"
    >
    </lib-single-select-search>
    <div
      *ngIf="dataLoaded"
      cdkDropList
      [cdkDropListData]="selectedJobs.controls"
      class="selected-jobs scrollbar"
      formArrayName="selectedJobs"
      (cdkDropListDropped)="changePositionInJobsList($event)"
    >
      <div
        cdkDrag
        [cdkDragData]="item"
        *ngFor="let item of selectedJobs.controls; let i = index"
        class="job-item"
        [ngClass]="{ 'item-clickable': itemClickable }"
        (click)="editJob(i)"
      >
        <bwh-lib-selected-item
          cdkDragHandle
          [formGroupName]="i"
          [name]="item.get('position_name').value"
          [listIcon]="true"
          [twoIcons]="true"
          [openIcon]="true"
          [index]="i + 1"
          [expIndex]="exp_index + 1"
          [iconStatus]="validityChecking(i, item.status)"
          (actionTriggered)="removeJob($event)"
        >
        </bwh-lib-selected-item>
        <div class="job-exp-container" [ngClass]="{ opened: i === exp_index }">
          <div class="drag-hide" [ngClass]="{ opened: exp_opened }" cdkDragPreview>
            <bwc-your-experience
              [formGroup]="form"
              [expIndex]="i"
              [jobsList]="jobsList"
              [loadedJobsFullList]="loadedJobsFullList"
              (click)="stopPropagation($event)"
            ></bwc-your-experience>
          </div>
        </div>
      </div>
    </div>

    <button *ngIf="dataLoaded" class="add-job" (click)="addEmptyJob()">
      {{ 'global.add_job' | translate }}
    </button>
    <ul class="next-button-container">
      <!-- <button (click)="checkForm()">check form</button> -->
      <li *ngIf="prevBut === true">
        <button class="btn w-md h-lg primary prev-btn" type="button" (click)="goPrev()">
          {{ 'global.previous' | translate }}
        </button>
      </li>
      <li>
        <button
          *ngIf="!editing"
          class="btn w-md h-lg secondary"
          type="submit"
          [disabled]="!this.form.valid"
          (click)="nextStep()"
        >
          {{ 'global.next_button' | translate }}
        </button>
        <button
          *ngIf="editing"
          class="btn w-md h-lg secondary"
          type="text"
          (click)="onEdit()"
          [disabled]="!this.form.valid"
        >
          {{ 'global.save' | translate }}
        </button>
      </li>
    </ul>
  </form>
</div>
