import { Recipient } from "./Recipient";


export interface InviteAfterRequestBody {
  invite_token: string;
  recipients: Recipient[];
  translations: ResearchGroupInviteUsersTranslation[];
}

export interface ResearchGroupInviteUsersTranslation {
  language: string;
  text: string;
}