import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Acquisition, DetailedPost, Invitation, Post, Subscription, SubscriptionType } from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class CampaignApiService {
  constructor(private http: HttpClient) {}

  // Campaign

  getCampaigns = (companyId: string, shopId: string) =>
    this.http.get('/api/v1.0/campaigns/overview', {
      params: {
        company: companyId,
        shop: shopId,
      },
    });

  getUnplannedCampaigns = (shopId: string) =>
    this.http.get('/api/v1.0/campaigns/unplanned', {
      params: {
        shop: shopId,
      },
    });

  getCampaign = (campaignId: string, companyId: string, shopId: string) =>
    this.http.get(`/api/v1.0/campaigns/${campaignId}`, {
      params: {
        company: companyId,
        shop: shopId,
      },
    });

  selectCampaign = (companyId: string, shopId: string, campaignId) =>
    this.http.post(
      '/api/v1.0/campaigns/select',
      {},
      {
        params: {
          company: companyId,
          shop: shopId,
          campaign: campaignId,
        },
      }
    );

  unselectCampaign = (companyId: string, shopId: string, campaignId) =>
    this.http.post(
      '/api/v1.0/campaigns/unselect',
      {},
      {
        params: {
          company: companyId,
          shop: shopId,
          campaign: campaignId,
        },
      }
    );

  setCampaignStart = (shopId: string, campaigId: string, date: string) =>
    this.http.put(
      '/api/v1.0/campaigns/start',
      {},
      {
        params: {
          shop: shopId,
          campaign: campaigId,
          date: date,
        },
      }
    );

  setCampaignPlanned = (shopId: string, campaigId: string, completed) =>
    this.http.put(
      '/api/v1.0/campaigns/completed',
      {},
      {
        params: {
          shop: shopId,
          campaign: campaigId,
          completed: completed,
        },
      }
    );

  getActiveShops = (companyId: string) => this.http.get(`/api/v1.0/shops/subscription?company=${companyId}`);

  // Posts

  getPosts = (shopId: string, from: string, to: string) =>
    this.http.get('/api/v1.0/campaigns/posts', {
      params: {
        shop: shopId,
        from: from,
        to: to,
      },
    });

  getPost = (postId: string) => this.http.get<DetailedPost>(`/api/v1.0/campaigns/posts/${postId}`);

  editPost = (post: Post) => this.http.put(`/api/v1.0/campaigns/posts`, post);

  addPost = (post: Post) => this.http.post(`/api/v1.0/campaigns/posts`, post);

  // Draft posts

  getDraftPost = (postId: string) => this.http.get(`/api/v1.0/campaigns/post-drafts/${postId}`);

  editDraftPost = (post: Post) => this.http.put(`/api/v1.0/campaigns/post-drafts`, post);

  addDraftPost = (post: Post) => this.http.post(`/api/v1.0/campaigns/post-drafts`, post);

  // Subscriptions

  getSubscriptions = (companyId: string) => this.http.get(`/api/v1.0/subscriptions?company=${companyId}`);

  addSubscription = (subscription: Subscription) => this.http.post('/api/v1.0/subscriptions/add', subscription);

  getSubscriptionTypes = (companyId: string) =>
    this.http.get<SubscriptionType[]>(`/api/v1.0/subscription-types?company=${companyId}`);

  // Acquisition

  getAcquisition = (id: string) => this.http.get(`/api/v1.0/acquisitions/${id}`);

  getAcquisitionByPermalink = (permalink: string) =>
    this.http.get<Acquisition>(`/api/v1.0/acquisitions/permalink/${permalink}`);

  registerAcquisition = (id: string) => this.http.post(`/api/v1.0/acquisitions/${id}/register`, {});

  // Photo editor

  addPhotoEditorState = (draft) => this.http.post(`/api/v1.0/campaigns/posts/editor`, draft);

  getPhotoEditorState = (shopId: string, postId: string) =>
    this.http.get(`/api/v1.0/campaigns/posts/editor`, {
      params: {
        shop: shopId,
        post: postId,
      },
    });

  // Other

  getTips = (companyId: string) => this.http.get(`/api/v1.0/tips?company=${companyId}`);

  setTipSeen = (tipId: string) => this.http.post(`/api/v1.0/tips/${tipId}/seen`, {});

  invite = (invitation: Invitation) => this.http.post('/api/v1.0/forms/invite', invitation);

  demoRequest = (request) => this.http.post('/api/v1.0/forms/demo', request);

  getCompanyGroup = (companyGroupId: string) => this.http.get(`/api/v1.0/company-groups/${companyGroupId}`);
}
