import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginForm } from '@shared/definitions';
@Injectable({
  providedIn: 'root',
})
export class MeProfileService {
  constructor(private http: HttpClient) {}

  login2 = (loginForm: LoginForm, headers?: HttpHeaders) =>
    this.http.post('/api/v2.0/auth', loginForm, {
      headers,
      observe: 'response',
      withCredentials: true,
    });

  login = (loginForm: LoginForm, headers?: HttpHeaders) =>
    this.http.post('/api/v2.0/auth', loginForm, {
      headers,
      observe: 'response',
      // withCredentials: true,
    });

  postProfileWithAdress = (req: any) => this.http.post('/api/v1.0/me/profilewithaddress', req);
}
