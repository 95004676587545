import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'lib-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @ContentChild('header', { read: TemplateRef })
  header?: TemplateRef<any>;
  @ContentChild('body', { read: TemplateRef })
  body: TemplateRef<any>;
  @Input() headerText?: string;
  @Input() noArrowsHeader = false;
  @Input() noBorder = false;
  @Input() noRadius = false;
  @Input() hoverHeaderBg = false;

  public panelOpenState = false;
  constructor() {}

  ngOnInit() {}
}
