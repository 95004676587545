<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitClose)="close()"
  (emitPrev)="goPrev()"
></bwh-lib-step-control-bar>
<div class="text-center">
  <div class="main-header">
    <h1 class="main-title">{{ 'global.how_many_languages' | translate }}</h1>
  </div>
  <div class="scrollable-content">
    <div class="second-header">
      <h3 class="desc-title">{{ 'global.please_indicate_languages_and_mother_tongue' | translate }}</h3>
    </div>
    <form [formGroup]="form">
      <lib-single-select-search
        #searchAutocomplete
        *ngIf="dataLoaded"
        [options]="languagesList"
        [placeholder]="'global.search_language' | translate"
        [iconPath]="'/icons/search-icon.svg'"
        [staticIcon]="true"
        [myFloatLabel]="'never'"
        [clearInput]="true"
        [suggestionsOff]="true"
        (changed)="handleFilteredOptionClick($event)"
      >
      </lib-single-select-search>
      <div
        cdkDropList
        [cdkDropListData]="selectedLanguages.controls"
        class="selected-jobs scrollbar"
        formArrayName="selectedLanguages"
        (cdkDropListDropped)="changePositionInLangList($event)"
      >
        <div
          cdkDrag
          [cdkDragData]="item"
          *ngFor="let item of selectedLanguages.controls; let i = index"
          class="job-item"
        >
          <bwh-lib-selected-item
            [formGroupName]="i"
            [name]="item.get('skill_name').value"
            [disableAllIcons]="true"
            [index]="i + 1"
            [starsRate]="item.get('rating').value"
            [ifNative]="item.get('is_native').value"
            (actionTriggered)="removeLanguage($event)"
            (changeLangLevel)="changeLangLevel($event)"
            (changeNative)="changeNative($event)"
          >
          </bwh-lib-selected-item>
        </div>
        <div class="error-cont" *ngIf="getFormControl('selectedLanguages').touched">
          <span *ngFor="let error of errorService.mapErrors(getFormControl('selectedLanguages'))">
            {{ 'global.' + error | translate }}
          </span>
        </div>
      </div>
      <ul class="next-button-container">
        <!-- <button (click)="checkForm()">check form</button> -->
        <li *ngIf="prevBut === true">
          <button class="btn w-md h-lg primary prev-btn" type="button" (click)="goPrev()">
            {{ 'global.previous' | translate }}
          </button>
        </li>
        <li>
          <button
            *ngIf="!editing"
            class="btn w-md h-lg secondary"
            type="submnit"
            (click)="onSubmit()"
            [disabled]="!this.form.valid"
          >
            {{ 'global.next_button' | translate }}
          </button>
          <button
            *ngIf="editing"
            class="btn w-md h-lg secondary"
            type="submnit"
            (click)="onEdit()"
            [disabled]="!this.form.valid"
          >
            {{ 'global.save' | translate }}
          </button>
        </li>
      </ul>
    </form>
  </div>
</div>
