export interface NewSkillReq {
  name: string;
  type: number;
  profession_id: string;
  education_id: string;
}

export enum SkillType {
  IT = 0,
  Professional = 1,
  Soft = 2,
  Language = 3,
  Hard = 4,
  Knowledge = 5,
  Tasks = 6,
  Soft_T = 7,
  Soft_L2 = 8,
}
