/* eslint-disable @nx/enforce-module-boundaries */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';
import { ALERT_UPDATE_SUCCESS, SnackbarType } from '@digilize/shared/definitions/src';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { AlertService } from '@digilize/shared/feature/modules/alert/src';
import { GroupsApiService } from '@digilize/shared/data/services/src';

@Component({
  selector: 'lib-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
  form: FormGroup;
  label_types = [];
  filterPresetOptions = [];
  currentDate = new Date();
  isoStringDate = this.currentDate.toISOString();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateGroupComponent>,
    private groupsApiService: GroupsApiService,
    private snackbarService: SnackbarService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.label_types = this.data.label_types ?? this.label_types;
    this.filterPresetOptions = this.data.filterPresetOptions ?? this.filterPresetOptions;

    this.form = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      labels: new UntypedFormControl([]),
      auto_update: new UntypedFormControl(false),
      company_id: new UntypedFormControl(this.data.companyId),
      created: new UntypedFormControl(this.isoStringDate),
      filters_preset_id: new UntypedFormControl(''),
      users_in_group: new UntypedFormControl(0),
      users: new UntypedFormControl(this.data.users ? this.data.users : []),
    });
  }

  getFormControl(controlName) {
    return this.form.get(controlName) as FormControl;
  }

  autoUpdateChange(checked: boolean, controlName): void {
    this.getFormControl(controlName).setValue(checked);

    if (!checked) {
      this.form.get('filters_preset_id').setValue('');
    }
  }

  checkForm() {
    console.log('check form', this.form.value);
  }

  next() {
    this.groupsApiService
      .createUserGroup(this.form.value)
      .pipe(
        catchError((err) => {
          this.snackbarService.openSnackbar(SnackbarType.Error, {
            duration: 5000,
            data: { msg: 'alert.custom.request_error', translate: true },
          });
          return throwError(err);
        })
      )
      .subscribe((data) => {
        this.alertService.setAlert(ALERT_UPDATE_SUCCESS);
        setTimeout(() => {
          this.dialogRef.close(true);
        }, 1500);
      });
  }

  close() {
    this.dialogRef.close();
  }
}
