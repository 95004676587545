import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogService {
  dialogChange: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  constructor(private dialog: MatDialog, @Inject(DOCUMENT) private document: Document) {}
  openDialog<T, R = any>(component: ComponentType<any>, customConfig?: Partial<MatDialogConfig<T>>) {
    const dialog = this.dialog.open<ComponentType<any>, T, R>(component, {
      width: '420px',
      panelClass: ['dialog-primary', 'dialog-small'],
      disableClose: true,
      ...customConfig,
    });

    setTimeout(() => {
      const dialogDom = this.document.querySelectorAll('.cdk-global-overlay-wrapper')[0];
      if (dialogDom) {
        dialogDom.scrollTop = 0;
      }
    }, 10);

    this.dialogChange.next(true);
    dialog.afterClosed().subscribe(() => {
      this.dialogChange.next(false);
    });

    return dialog;
  }
}
