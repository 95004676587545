import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { isFormControlRequired } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-form-event-details',
  templateUrl: './form-event-details.component.html',
  styleUrls: ['./form-event-details.component.scss'],
})
export class FormEventDetailsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() bannerUrl: string;
  @Input() photoUrl: string;
  @Input() eventTypes: string[];
  @Input() shopOptionsKeyValue: { key: string; value: any }[];
  socials: string[] = [];
  open = true;
  public editor = ClassicEditor;
  ckconfig = {
    toolbar: ['bold', 'italic', 'link'],
  };
  charCounter;
  charMax = 100;

  constructor() {}

  ngOnInit(): void {
    this.socials = Object.keys((this.form.controls.socials as UntypedFormGroup).controls);
  }

  minDateChange($event) {
    if (this.form.get('available_from').value > new Date(this.form.get('available_to').value)) {
      this.form.get('available_to').setValue(null);
    }
  }

  maxDateChange($event) {
    if (new Date(this.form.get('available_from').value) > this.form.get('available_to').value) {
      this.form.get('available_from').setValue(null);
    }
  }

  onEditorChange($event) {
    this.charCounter = this.htmlToString($event.editor.getData()).length;
    this.validateDescription();
  }

  setCounter() {
    if (this.form.get('description').value) {
      this.charCounter = this.htmlToString(this.form.get('description').value).length;
    }
  }

  validateDescription() {
    const invalid = this.charCounter > this.charMax;
    this.form.get('description').setErrors({ charMax: invalid });
    if (!invalid) {
      this.form.get('description').updateValueAndValidity();
    }
  }

  toggleShop(checked: boolean) {
    if (checked) {
      this.form.get('ticket_url').setValue(null);
      this.form.get('shop_id').setValue(this.shopOptionsKeyValue[0].value);
    } else {
      this.form.get('shop_id').setValue(null);
    }
  }

  htmlToString(html: string): string {
    let text = html.replace(/<[^>]*>/g, '');
    text = text.replace('&nbsp', '');
    return text;
  }

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }

  photoChange(event) {
    this.form.get('photo_id').setValue(event?.id);
  }

  bannerChange(event) {
    this.form.get('banner_id').setValue(event?.id);
  }
}
