import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Alert } from '@shared/definitions';
import { AlertService } from '../alert.service';
@Component({
  selector: 'lib-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertBarComponent implements OnInit {
  alert: Alert;
  show = false;

  constructor(private alertService: AlertService) {
    this.alertService.alertChange.subscribe((alert: Alert) => {
      this.alert = alert;
      this.show = true;
      setTimeout(() => {
        this.closeAlert();
      }, this.alert.time || 2000);
    });
  }

  ngOnInit(): void {}

  closeAlert() {
    this.show = false;
  }
}
