import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '@digilize/shared/data/services/src';

@Injectable()
export class GlobalErrorHandlerWithLog extends ErrorHandler {
  chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(private loggingService: LoggingService) {
    super();
  }

  override handleError(error: any): void {
    if (this.chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    this.loggingService.logException(error); // Manually log exception
    console.error(error);
  }
}
