import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'lib-form-about',
  templateUrl: './form-about.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FormAboutComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;
  public editor = ClassicEditor;
  ckconfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
  };
  charCounter;
  charMax = 1000;

  constructor() {}

  ngOnInit(): void {
    if (this.form) {
      this.setCounter();
    }
  }

  getFormControl(name): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  onEditorChange($event) {
    this.charCounter = this.htmlToString($event.editor.getData()).length;
    this.validateDescription();
  }

  setCounter() {
    if (this.form.get('description').value) {
      this.charCounter = this.htmlToString(this.form.get('description').value).length;
    }
  }

  validateDescription() {
    const invalid = this.charCounter > this.charMax;
    this.form.get('description').setErrors({ charMax: invalid });
    if (!invalid) {
      this.form.get('description').updateValueAndValidity();
    }
  }

  htmlToString(html: string): string {
    let text = html.replace(/<[^>]*>/g, '');
    text = text.replace('&nbsp', '');
    return text;
  }
}
