export interface UsersConfig {
  showingColumns: ShowingColumns;
  options: UserOptions;
  filters: UserFilters;
}

export interface ShowingColumns {
  selecting: boolean;
  name: boolean;
  location: boolean;
  notes: boolean;
  labels: boolean;
  actions: boolean;
}
export interface UserOptions {
  showActionNameIcon: boolean;
  userActionOpenIcon: boolean;
  searchByName: boolean;
  contactUser: boolean;
  contactBulk: boolean;
  labelsBulk: boolean;
  delete: boolean;
  createGroup: boolean;
  addToTheGroup: boolean;
}
export interface UserFilters {
  locationApi: string;
  locationAccessToken: string;
}
