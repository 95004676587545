import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  CampaignApiService } from '@digilize/shared/data/services';
import { Campaign } from '@shared/definitions';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { addWebp } from '@digilize/shared/utils/helpers';
import * as moment from 'moment';

@Component({
  selector: 'lib-dialog-plan-campaign',
  templateUrl: './dialog-plan-campaign.component.html',
  styleUrls: ['../dialog-small.component.scss', './../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogPlanCampaignComponent implements OnInit {
  loading = true;
  campaigns = [];
  shopId;
  companyId;
  selectedCampaign: Campaign;
  formCampaign: UntypedFormGroup = new UntypedFormGroup({
    campaign: new UntypedFormControl('', Validators.required),
    date: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogPlanCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campaignApiService: CampaignApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.shopId = this.data.shopId;
    this.companyId = this.data.companyId;

    if (this.shopId && this.companyId) {
      this.getCampaigns(this.shopId);
    }
  }

  getCampaigns(shopId: string) {
    this.campaignApiService.getUnplannedCampaigns(shopId).subscribe(
      (response: any) => {
        response.forEach((campaign) => {
          this.campaigns.push({
            key: campaign.name,
            value: campaign.id,
            campaign: campaign,
          });
        });

        if (this.campaigns.length > 0) {
          this.setCampaign(this.campaigns[0].value);
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setCampaign(campaignId: string) {
    const campaign = this.campaigns.find((obj) => obj.value === campaignId);
    this.formCampaign.get('campaign').setValue(campaign.value);
    this.selectedCampaign = campaign.campaign;
    this.formCampaign.get('date').setValue(this.selectedCampaign.default_start);
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit() {
    this.loading = true;
    const date = moment(this.formCampaign.get('date').value).toISOString();
    if (this.selectedCampaign.default_start !== date) {
      this.campaignApiService.setCampaignStart(this.shopId, this.formCampaign.get('campaign').value, date).subscribe(
        (response: any) => {
          this.closeDialog({
            campaignId: this.formCampaign.get('campaign').value,
            shopId: this.shopId,
            companyId: this.companyId,
          });
          this.loading = false;
        },
        (error) => {
          this.alertService.handleApiError(error.error.error);
          this.loading = false;
        }
      );
    } else {
      this.closeDialog({
        campaignId: this.formCampaign.get('campaign').value,
        shopId: this.shopId,
        companyId: this.companyId,
      });
      this.loading = false;
    }
  }

  get addWebp() {
    return addWebp();
  }
}
