<mat-form-field class="example-chip-list" [floatLabel]="myFloatLabel ? myFloatLabel : 'auto'">
  <mat-chip-list #chipGrid [ngClass]="{ 'hide-chips': hideChips }">
    <mat-chip
      *ngFor="let chips of selectedChips"
      [matTooltip]="showTooltip ? (optionObj ? chips.text : chips) : null"
      (removed)="remove(chips)"
    >
      {{ optionObj ? chips.text : chips }}
      <img *ngIf="iconPath" class="remove-icon" matChipRemove [src]="iconPath" alt="icon" />
      <button *ngIf="!iconPath" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-list>

  <div class="input-container">
    <input
      #searchInput
      [placeholder]="placeholder"
      [formControl]="inputCtrl"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (ngModelChange)="onInputValueChanged($event)"
      (mousedown)="userInputChange = true; showInputPlaceholder = false; showOptionsList = true; onFocusAction()"
      (blur)="userInputChange = false; showInputPlaceholder = true"
      [readonly]="disableSearch"
    />
    <div
      class="input-search-placeholder"
      *ngIf="
        (inputCtrl.value === null || inputCtrl.value?.length === 0) && showSearchPlaceholder && showInputPlaceholder
      "
    >
      {{ getPlaceholderText() }}
    </div>

    <span
      *ngIf="dropdownIconsPath"
      class="select-arrow"
      [inlineSVG]="dropdownIconsPath + (showOptionsList ? 'up' : 'down') + '.svg'"
    ></span>
  </div>

  <div
    #scrollContainer
    *ngIf="disableHidingList ? true : showOptionsList"
    class="manual-options-list"
    (appClickOutside)="onClickOutside($event)"
    [exception]="searchInput"
  >
    <div
      class="manual-option"
      [ngClass]="{ 'last-item': last }"
      *ngFor="let option of filteredOptions | async; let last = last"
    >
      <mat-checkbox [(ngModel)]="option.selected" [checked]="option.selected" (change)="selected(option)">
        {{ optionObj ? option.text : option }}
      </mat-checkbox>

      <span
        *ngIf="removeItem && (useRemoveFromOptions ? option.toRemove : true)"
        class="remove-icon"
        [inlineSVG]="removeIconPath ? removeIconPath : '/icons/icon-close2.svg'"
        (click)="onRemoveItem(option)"
      ></span>
    </div>
    <div #endOfList></div>
  </div>
</mat-form-field>
