import { ShowStatus } from "../enums/ShowStatus";


export const ShowAvailableStatusOptions = [
  {
    key: 'Unknown',
    value: ShowStatus.Unknown,
    hidden: true,
  },
  {
    key: 'Not Recognized',
    value: ShowStatus.NotRecognized,
    hidden: true,
  },
  {
    key: 'Imported',
    value: ShowStatus.Imported,
  },
  {
    key: 'Published',
    value: ShowStatus.Published,
  },
];
