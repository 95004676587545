<div class="dialog-panel dialog-post">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
      <div class="spinner-container p-absolute" *ngIf="loading">
          <mat-spinner class="primary" [diameter]="30"></mat-spinner>
      </div>
      <div class="dialog-header">
          <h1 class="dialog-title dot">Choose photos from library</h1>
      </div>
  </div>
</div>
