<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <div class="ckeditor-container">
            <div class="editor-label">
              {{ 'global.description' | translate }}
            </div>
            <ckeditor
              class="ckeditor"
              [editor]="editor"
              [config]="ckconfig"
              [formControl]="getFormControl('description')"
              (change)="onEditorChange($event)"
            ></ckeditor>
            <div class="char-counter" [ngClass]="{ error: charCounter > charMax }">
              {{ charCounter }}
            </div>
            <div class="mat-error" *ngIf="getFormControl('description').invalid">
              <ng-container *ngIf="getFormControl('description').errors.charMax">
                {{ 'form.error.description_too_long' | translate }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
