import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dateBeforeOtherCustomErrorValidator } from '../validators/date-before-custom-error.validators';
import { dateAfterOtherCustomErrorValidator } from '../validators/date-after-custom-error.validators';
import { Option } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class FormSectionService {
  constructor(private fb: FormBuilder) {}

  createEducationFormSection(obj: educationStep): FormGroup {
    const formGroup = this.fb.group({
      id: [obj.id],
      order: [obj.order],
      country: [obj.country],
      school_id: [obj.school_id],
      school_name: [obj.school_name],
      school_options: this.fb.array([]),
      education_id: [obj.education_id],
      education_name: [obj.education_name, [Validators.required]],
      education_categories: [obj.education_categories],
      education_category_ids: [obj.education_category_ids],
      education_category_names: [obj.education_category_names, [Validators.required]],
      education_options: this.fb.array([]),
      education_level_name: [obj.education_level_name, [Validators.required]],
      education_level_id: [obj.education_level_id],
      education_level_is_general: [obj.education_level_is_general],
      level_options: this.fb.array([]),
      start_date: [obj.start_date, [dateBeforeOtherCustomErrorValidator('end_date', 'startDate')]],
      end_date: [obj.end_date, [dateAfterOtherCustomErrorValidator('start_date', 'endDate')]],
      is_current: [obj.is_current],
    });
    return formGroup;
  }
}

interface educationStep {
  id?: number;
  order?: number;
  country?: string;
  school_id?: string;
  school_name?: string;
  education_id?: string | null;
  education_name?: string;
  education_categories?: Option[];
  education_category_ids?: string[];
  education_category_names?: string[];
  education_level_name?: string;
  education_level_id?: string;
  education_level_is_general?: boolean;
  start_date?: string;
  end_date?: string;
  is_current?: boolean;
}
