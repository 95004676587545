import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { EnvConfig } from '@digilize/shared/definitions/src';
import { getCaptchaHeaders } from '@digilize/shared/utils/helpers/src';
import { ENV_CONFIG } from '@digilize/shared/utils/tokens/src';

@Injectable({ providedIn: 'root' })
export class CaptchaService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
    @Inject(ENV_CONFIG) private envConfig: EnvConfig
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setCaptchaVisibility(visibility: 'visible' | 'hidden') {
    let count = 0;
    let interval = setInterval(() => {
      const badge = this.document.querySelector('.grecaptcha-badge');
      if (badge) {
        this.renderer.setStyle(badge, 'visibility', visibility);
        clearInterval(interval);
      }
      if (count >= 5) {
        clearInterval(interval);
      }
      count++;
    }, 500); /* it takes some time for captcha to load */
  }

  getCaptchaHeaders(captchaToken): HttpHeaders {
    return getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey);
  }
}
