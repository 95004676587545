import { DeliveryType } from '../enums/DeliveryType';
import { CartSummary } from './CartSummary';
import { Shop } from './Shop';

export interface Cart {
  id: string;
  shop_id: string;
  user_id: string;
  reserved_until: string;
  server_time: string;
  status: string;
  delivery_method: DeliveryType;
  summary: CartSummary;
  shop: Shop;
  pay_after: boolean;
  timeslot: string;
  timeslot_interval: string;
  comment: string;
  delivery_methods: number[];
}
