export const auctionPriceTypeSelectOptions = [
  {
    key: 'marketplace.auction_price_type.asking_price',
    value: 1,
  },
  {
    key: 'marketplace.auction_price_type.best_offer',
    value: 2,
  },
  {
    key: 'marketplace.auction_price_type.to_be_decided',
    value: 3,
  },
  {
    key: 'marketplace.auction_price_type.on_request',
    value: 4,
  },
  {
    key: 'marketplace.auction_price_type.free',
    value: 5,
  },
  {
    key: 'marketplace.auction_price_type.price_per_unit',
    value: 6,
  },
];
