import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { addWebp } from '@digilize/shared/utils/helpers';
import { Event } from '@shared/definitions';

@Component({
  selector: 'lib-card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss'],
})
export class CardEventComponent implements OnInit {
  @Input() event: Event;
  @Output() itemClicked = new EventEmitter<Event>();
  isSameDay: boolean;
  description: string;

  @HostListener('click', ['$event'])
  clicked() {
    this.itemClicked.emit(this.event);
  }

  constructor() {}

  ngOnInit(): void {
    const from: string = new Date(this.event.available_from).toDateString();
    const to: string = new Date(this.event.available_to).toDateString();
    this.isSameDay = from === to;
  }

  get addWebp() {
    return addWebp();
  }
}
