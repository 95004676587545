export interface Subscription {
  shop_id: string;
  shop: string;
  company: string;
  type: string;
  start: string;
  first_renewal_date: string;
  renewal_period: number;
  finish: string;
  id: string;
  is_popular: boolean;
}
