import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { matchOtherValidator, REGEX_PASSWORD } from '@digilize/shared/utils/validators/src';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
  hidePwd = true;
  hideConfirmPwd = true;
  takeUntil$ = new Subject();

  @Output() formInstance: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  form = new FormGroup({
    password_new: new FormControl('', [Validators.required, Validators.pattern(REGEX_PASSWORD)]),
    password_confirm: new FormControl('', [Validators.required, matchOtherValidator('password_new')]),
  });

  constructor() {}
  ngOnInit(): void {
    this.formInstance.emit(this.form);
  }

  getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  ngOnDestroy(): void {
    this.takeUntil$.next(true);
    this.takeUntil$.unsubscribe();
  }
}
