
export interface Publishing {
  has_facebook: boolean;
  has_facebook_error: boolean;
  facebook_name: string;
  facebook_url: string;
  has_instagram: boolean;
  has_instagram_error: boolean;
  instagram_name: string;
  instagram_url: string;
}
