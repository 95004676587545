<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12 col-sm-6 form-field-container align-self-end" *ngFor="let key of order">
          <div
            class="form-field-label"
            *ngIf="key !== 'address_visible'"
            [ngClass]="{ required: isFormControlRequired(getFormControl(key)) }"
          >
            {{ 'form.field.' + key | translate }}
          </div>

          <mat-form-field
            class="form-field"
            [floatLabel]="'never'"
            *ngIf="key !== 'country' && key !== 'address_visible'"
          >
            <input matInput [formControlName]="key" [required]="isFormControlRequired(getFormControl(key))" />
            <mat-error *ngIf="getFormControl(key).invalid">
              <ng-container *ngIf="getFormControl(key).errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>

          <lib-select
            [options]="countries"
            [value]="getFormControl(key).value"
            [errors]="getFormControl(key).errors"
            (valueChange)="countryValueChange($event)"
            *ngIf="key === 'country'"
          ></lib-select>
        </div>
      </div>
    </form>
  </div>
</div>
