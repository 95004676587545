<mat-form-field class="example-chip-list" [floatLabel]="myFloatLabel ? myFloatLabel : 'auto'">
  <mat-chip-list #chipGrid>
    <mat-chip
      *ngFor="let chips of selectedChips"
      [matTooltip]="showTooltip ? (optionObj ? chips.text : chips) : null"
      (removed)="remove(chips)"
    >
      {{ optionObj ? chips.text : chips }}
      <button *ngIf="iconPath" class="remove-icon" matChipRemove>
        <span class="icon-remove" [inlineSVG]="iconPath"></span>
      </button>
      <button *ngIf="!iconPath" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-list>
  <div class="input-container">
    <input
      #searchInput
      [placeholder]="placeholder"
      [formControl]="inputCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (ngModelChange)="onInputValueChanged($event)"
      (focus)="userInputChange = true; showInputPlaceholder = false"
      (blur)="userInputChange = false; showInputPlaceholder = true"
    />
    <div
      class="input-search-placeholder"
      *ngIf="
        (inputCtrl.value === null || inputCtrl.value?.length === 0) && showSearchPlaceholder && showInputPlaceholder
      "
    >
      {{ searchPlaceholderText | translate }}
    </div>
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ optionObj ? option.text : option }}
    </mat-option>
    <div #endOfList></div>
  </mat-autocomplete>
</mat-form-field>
