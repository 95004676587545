import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GroupsApiService {
  constructor(private http: HttpClient) {}

  getCompanyUserGroups = (params) =>
    this.http.get(`/api/v1.0/usergroup`, {
      params,
    });

  getCompanyUsersOfTheGroups = (id: string, params) =>
    this.http.get(`/api/v1.0/usergroup/${id}/users`, {
      params,
    });

  getGroupDetails = (groupId) => this.http.get(`/api/v1.0/usergroup/${groupId}`);

  createUserGroup = (params: any) => this.http.post('/api/v1.0/usergroup', params);

  updateUserGroup = (params: any) => this.http.put('/api/v1.0/usergroup', params);

  removeGroup = (groupId) => this.http.delete(`/api/v1.0/usergroup/${groupId}/remove`);

  addLabelToGroup = (req) => this.http.post('/api/v1.0/usergroup/labels/add', req);

  removeLabelFromGroup = (req) => this.http.delete('/api/v1.0/usergroup/labels/remove', { body: req });

  addUsersToGroup = (req) => this.http.post('/api/v1.0/usergroup/users/add', req);

  removeUsersFromGroup = (req) => this.http.delete('/api/v1.0/usergroup/users/remove', { body: req });
}
