/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { catchError, throwError } from 'rxjs';
import { SnackbarType } from '@digilize/shared/definitions/src';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { ApplicantsApiService, CompanyApiService } from '@digilize/shared/data/services/src';

@Component({
  selector: 'lib-add-labels',
  templateUrl: './add-labels.component.html',
  styleUrls: ['./add-labels.component.scss'],
})
export class AddLabelsComponent implements OnInit {
  selectedNum = 0;
  labels: string[] = [];
  addedLabels: string[] = [];
  usersIds: string[] = [];
  invitationIds: string[] = [];
  ifUsers = false;
  companyId;
  dataLoaded = false;

  constructor(
    private snackbarService: SnackbarService,
    private applicantsApiService: ApplicantsApiService,
    private companyApiService: CompanyApiService,
    public dialogRef: MatDialogRef<AddLabelsComponent>,

    @Inject(MAT_DIALOG_DATA) public data: AddLabelsComponent
  ) {}

  ngOnInit(): void {
    this.labels = this.data.labels;
    this.selectedNum = this.data.selectedNum;
    this.usersIds = this.data.usersIds;
    this.invitationIds = this.data.invitationIds;
    this.ifUsers = this.data.ifUsers ? this.data.ifUsers : this.ifUsers;
    this.companyId = this.data.companyId ? this.data.companyId : null;
    this.dataLoaded = true;
  }

  handleAddLabel(option) {
    this.addedLabels.push(option);
  }

  handleRemoveLabel(option) {
    this.labels.push(option);
    this.labels = [...this.labels];
  }

  applyAdd() {
    if (this.ifUsers) {
      const req = {
        user_ids: this.usersIds,
        invitation_ids: this.invitationIds,
        labels: this.addedLabels,
      };
      this.companyApiService
        .addBulkLabelsToUser(this.companyId, req)
        .pipe(
          catchError((err) => {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 5000,
              data: { msg: 'alert.custom.request_error', translate: true },
            });
            return throwError(err);
          })
        )
        .subscribe((data) => {
          this.dialogRef.close(data);
        });
    } else {
      const req = {
        applicant_ids: this.usersIds,
        labels: this.addedLabels,
      };
      this.applicantsApiService
        .addBulkLabelsApplicant(req)
        .pipe(
          catchError((err) => {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 5000,
              data: { msg: 'alert.custom.request_error', translate: true },
            });
            return throwError(err);
          })
        )
        .subscribe((data) => {
          this.dialogRef.close(data);
        });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
