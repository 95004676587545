import { Media } from "./Media";

export interface CompanyGroup {
  name: string;
  campaign_duration: number;
  renewal_terms: number;
  agreement_text: string;
  agreement_id: string;
  agreement: Media;
  id: string;
}
