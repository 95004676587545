import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {
  /* to make handle drop event on our own we need to prevent drop, leave and over */
  @Output() dndDrop = new EventEmitter();
  @Output() dndOver = new EventEmitter();
  @Output() dndEnter = new EventEmitter();
  @Output() dndLeave = new EventEmitter();

  @HostListener('drop', ['$event']) public ondrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.dndDrop.emit(files);
    }
  }

  @HostListener('dragleave', ['$event']) public onDragleave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.dndLeave.emit(e);
  }

  @HostListener('dragenter', ['$event']) public onDragenter(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.dndEnter.emit(e);
  }

  @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.dndOver.emit(e);
  }

  constructor() {}
}
