<div class="file-upload-container">
  <div class="upload-header">
    <h1 class="upload-title">{{ title }}</h1>
    <h3 class="upload-subtitle" *ngIf="subTitle">{{ subTitle }}</h3>
  </div>
  <div
    class="dnd-container"
    appDnd
    (dndEnter)="isDragOver = true"
    (dndLeave)="isDragOver = false"
    (dndDrop)="handleFileDrop($event)"
    [ngClass]="{ 'drag-over': isDragOver }"
    for="fileDropRef"
  >
    <p class="upload-txt">
      <span>{{ 'global.drag_and_drop' | translate }}</span>
    </p>
    <p class="upload-formats">
      <span *ngFor="let fileType of fileTypes">{{ fileType.label }}</span>
    </p>
  </div>
  <ng-container *ngTemplateOutlet="buttons || defaultButtons"> </ng-container>
  <ng-template #defaultButtons>
    <ul class="upload-buttons">
      <input type="file" #fileDropRef id="fileDropRef" (change)="handleFileUpload($event)" class="d-none" />
      <li>
        <button class="btn w-lg h-lg secondary" (click)="fileDropRef.click()">
          {{ 'global.upload_from_my_device' | translate }}
        </button>
      </li>
    </ul>
  </ng-template>
</div>
