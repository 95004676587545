import { Media } from "./Media";


export interface PaymentMethod {
  name: string;
  variants: any;
  id: string;
  external_id: string;
  photo: Media;
  cost_fixed: number;
  cost_variable: number;
}
