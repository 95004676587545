<div class="stars-container" *ngIf="stars">
  <ng-container *ngFor="let star of stars; let i = index">
    <span
      *ngIf="star < starsRate"
      [inlineSVG]="selectedStarIcon"
      class="star-icon"
      title="Selected star"
      (click)="starClicked(index, star + 1)"
      [matTooltip]="'global.' + titlesDesc + '_' + (i + 1) | translate"
      [matTooltipPosition]="'above'"
    >
    </span>
    <span
      *ngIf="star >= starsRate"
      [inlineSVG]="emptyStarIcon"
      class="star-icon empty-star"
      title="Empty star"
      (click)="starClicked(index, star + 1)"
      [matTooltip]="'global.' + titlesDesc + '_' + (i + 1) | translate"
      [matTooltipPosition]="'above'"
    >
    </span>
  </ng-container>
</div>
