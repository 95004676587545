import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'lib-mobile-overlay',
  templateUrl: './mobile-overlay.component.html',
  styleUrls: ['./mobile-overlay.component.scss'],
})
export class MobileOverlayComponent implements OnInit, OnDestroy {
  @HostBinding('style.left.%') styleLeftPercentage;
  @HostBinding('style.height.px') styleHeightPx;
  @HostBinding('style.top.px') styleTopPx;
  @Input() heightOffset: boolean | number = false;
  private _isOpened = false;
  public set isOpened(isOpened) {
    this._isOpened = isOpened;
    this.styleLeftPercentage = this._isOpened ? 0 : 100;
  }
  public get isOpened(): boolean {
    return this._isOpened;
  }
  private setHeighBindFunc: () => void;
  constructor(private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      this.hide();
    });
  }

  ngOnInit() {
    this.hide();
    if (this.heightOffset) {
      this.setHeighBindFunc = this.setHeightOffset.bind(this);
      this.setHeightOffset();
      this.setResizeListener();
    }
  }

  public toggle() {
    this.isOpened = !this._isOpened;
  }

  public hide() {
    this.isOpened = false;
  }

  public show() {
    this.isOpened = true;
  }

  private setHeightOffset() {
    const height = window.innerHeight - (this.heightOffset as number);
    this.styleHeightPx = height.toString();
    this.styleTopPx = this.heightOffset;
  }

  private setResizeListener() {
    window.addEventListener('resize', this.setHeighBindFunc);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.setHeighBindFunc);
  }
}
