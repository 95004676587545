<div class="text-center">
  <div class="work-stage-container">
    <form [formGroup]="formGroup">
      <div class="container">
        <div class="work-step">
          <ng-container>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="form-label">
                  {{ 'global.form_bwh_job' | translate }}<sup>*</sup>
                  <div
                    class="i-icon"
                    [ngClass]="'mat-tooltip-white-bg'"
                    #tooltip="matTooltip"
                    [matTooltip]="'tooltip.bwh_job' | translate"
                    [matTooltipPosition]="'below'"
                    (click)="tooltip.toggle()"
                  >
                    <!-- <span class="i">i</span> -->
                    <img src="assets/icons/i-icon.svg" />
                  </div>
                </div>
                <lib-single-select-search
                  #searchAutocomplete
                  [options]="jobsList"
                  [control]="getFormControl(expIndex, 'position_name_original', true)"
                  [placeholder]="'global.enter_position_name' | translate"
                  [myFloatLabel]="'never'"
                  [addDebounce]="300"
                  [waitForOptionsChange]="true"
                  [suggestionsOff]="true"
                  [forceControl]="true"
                  [iconPath]="'/icons/icon-arrow-b-'"
                  [ngClass]="{ 'error-field': getFormControl(expIndex, 'position_name_original').status === 'INVALID' }"
                  [detectScrollToEnd]="true"
                  (changed)="setControlValuesFromOption($event, expIndex, ['position_name_original', 'position_id'])"
                  (valueChanged)="createOptionsFromSearch($event, expIndex, 'position_name_original')"
                  (scrolledToEnd)="handleScrolledToEndSearch($event)"
                >
                </lib-single-select-search>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="form-label">{{ 'global.form_official_job_title' | translate }}</div>
                <mat-form-field [floatLabel]="'never'">
                  <input
                    matInput
                    [formControl]="getFormControl(expIndex, 'position_name')"
                    [placeholder]="'global.enter_official_job_title' | translate"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12">
                <div class="form-label">{{ 'global.form_company_name' | translate }}</div>
                <mat-form-field [floatLabel]="'never'">
                  <input
                    matInput
                    [formControl]="getFormControl(expIndex, 'company_name')"
                    [placeholder]="'global.enter_company_name' | translate"
                  />
                </mat-form-field>
                <div class="error-message" *ngIf="getFormControl(expIndex, 'company_name').touched">
                  <span *ngFor="let error of errorService.mapErrors(getFormControl(expIndex, 'company_name'))">
                    {{ 'global.' + error | translate }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-label">{{ 'global.start_date' | translate }}</div>
                <mat-form-field
                  [floatLabel]="'never'"
                  [ngClass]="{ 'error-field': getFormControl(expIndex, 'start_date').status === 'INVALID' }"
                >
                  <input
                    matInput
                    readonly
                    [matDatepicker]="startDatePicker"
                    [placeholder]="'global.enter_start_date' | translate"
                    [value]="getFormControl(expIndex, 'start_date').value"
                    (click)="startDatePicker.open()"
                  />
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker
                    #startDatePicker
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, expIndex, 'start_date', startDatePicker)"
                  ></mat-datepicker>
                </mat-form-field>
                <div class="error-message" *ngIf="!getFormControl(expIndex, 'start_date').pristine">
                  <span *ngFor="let error of errorService.mapErrors(getFormControl(expIndex, 'start_date'))">
                    {{ 'global.' + error | translate }}
                  </span>
                </div>
              </div>
              <div
                class="col-12 col-sm-6"
                [ngClass]="{
                  'disabled-field': getFormControl(expIndex, 'is_current').value
                }"
              >
                <div class="form-label">{{ 'global.end_date' | translate }}</div>

                <mat-form-field
                  [floatLabel]="'never'"
                  [ngClass]="{ 'error-field': getFormControl(expIndex, 'end_date').status === 'INVALID' }"
                >
                  <input
                    matInput
                    readonly
                    [matDatepicker]="endDatePicker"
                    [placeholder]="'global.enter_end_date' | translate"
                    [value]="getFormControl(expIndex, 'end_date').value"
                    (click)="endDatePicker.open()"
                  />
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker
                    #endDatePicker
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, expIndex, 'end_date', endDatePicker)"
                  ></mat-datepicker>
                </mat-form-field>
                <div class="error-message" *ngIf="!getFormControl(expIndex, 'end_date').pristine">
                  <span *ngFor="let error of errorService.mapErrors(getFormControl(expIndex, 'end_date'))">
                    {{ 'global.' + error | translate }}
                  </span>
                </div>
              </div>
              <div class="col-12 checkbox-col">
                <label>
                  <mat-checkbox
                    [formControl]="getFormControl(expIndex, 'is_current')"
                    (change)="stillStudingChange($event.checked, expIndex, 'end_date')"
                  >
                    {{ 'global.still_working_here' | translate }}
                  </mat-checkbox>
                </label>
              </div>
              <div class="col-12">
                <div class="form-label">{{ 'global.your_role_description' | translate }}</div>
                <mat-form-field [floatLabel]="'never'" class="description-cont">
                  <textarea
                    matInput
                    [formControl]="getFormControl(expIndex, 'job_description')"
                    [placeholder]="'global.enter_your_role_description' | translate"
                  ></textarea>
                </mat-form-field>
                <div class="error-message" *ngIf="getFormControl(expIndex, 'job_description').touched">
                  <span *ngFor="let error of errorService.mapErrors(getFormControl(expIndex, 'job_description'))">
                    {{ 'global.' + error | translate }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>
