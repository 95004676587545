<lib-select
  class="lang-select"
  optionImgWidth="16px"
  [noNative]="noNative"
  [noBackground]="true"
  [withTrigger]="true"
  [dropdownIconsPath]="dropdownIconsPath"
  [options]="langOptions"
  [value]="langFormControl.value"
  (valueChange)="handleLangChange($event)"
>
  <div *ngIf="getLangOption(langFormControl.value) as langOption">
    <div [ngSwitch]="langOption.key">
      <img [src]="langOption.img" /> <span>&nbsp;{{ langOption.key }}</span>
    </div>
  </div>
</lib-select>
