import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { InfoSnackbarComponent } from './info-snackbar/info-snackbar.component';
import { SuccessSnackbarComponent } from './success-snackbar/success-snackbar.component';

@NgModule({
  declarations: [SuccessSnackbarComponent, InfoSnackbarComponent, ErrorSnackbarComponent],
  imports: [CommonModule, CommonModule, InlineSVGModule, TranslateModule, MatIconModule],
  exports: [SuccessSnackbarComponent, InfoSnackbarComponent],
})
export class SharedSnackbarModule {}
