import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProfessionsService {
  constructor(private http: HttpClient) {}

  getListOfProfessions = (searchValue: string) =>
    this.http.get('/api/v1.0/professions/', {
      params: {
        'search.value': searchValue,
        length: 10,
      },
    });

  getListOfProfessionsParams = (params) =>
    this.http.get('/api/v1.0/professions/', {
      params,
    });

  getProfessionsMy = () => this.http.get('/api/v1.0/professions/my');

  putProfessionsMy = (req: any) => this.http.put('/api/v1.0/professions/my', req);
}
