import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FloatLabelType } from '@angular/material/form-field';
import { Observable, Subject, debounceTime, delay, distinctUntilChanged, map, startWith } from 'rxjs';
import { Option } from '@digilize/shared/definitions/src';
import { createAndObserveIntersection } from '@digilize/shared/utils/helpers/src';

@Component({
  selector: 'lib-multi-select-chips-search',
  templateUrl: './multi-select-chips-search.component.html',
  styleUrls: ['./multi-select-chips-search.component.scss'],
})
export class MultiSelectChipsSearchComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() options: string[] | Option[] | any[] = [];
  @Input() myFloatLabel: FloatLabelType;
  @Input() iconPath = '/icons/icon-close2.svg';
  @Input() optionObj: boolean;
  @Input() emitRemovedItem: boolean;
  @Input() control: FormControl;
  @Input() waitForOptionsChange: boolean;
  @Input() placeholder = '';
  @Input() addDebounce: number;
  @Input() showTooltip = false;
  @Input() showSearchPlaceholder = false;
  @Input() searchPlaceholderText: string;
  @Input() detectScrollToEnd = false;
  @Input() optionsListLength = 6;
  @Output() changed = new EventEmitter<Option | any>();
  @Output() valueChanged = new EventEmitter<string>();
  @Output() emitRemove = new EventEmitter();
  @Output() scrolledToEnd = new EventEmitter<boolean>();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild('endOfList') endOfList: ElementRef;

  private inputValueChanged = new Subject<string>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new FormControl('');
  filteredOptions: Observable<any[]>;
  selectedChips: any = [];
  userInputChange = false;
  showInputPlaceholder = true;
  blockEmitScrolledToEnd = false;

  @Input() filterFn: (options: Option[]) => Option[] = (options) => options;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.waitForOptionsChange) {
      // for preventing of emitting scrolledToEnd when option list is shorter then visible container
      if (changes.options.currentValue.length > this.optionsListLength) {
        this.blockEmitScrolledToEnd = false;
      }
      this.initControl();
    }
  }

  ngOnInit() {
    if (this.control) {
      this.selectedChips = this.control.value;
      this.control.valueChanges.subscribe((newValue) => {
        this.selectedChips = newValue;
      });
    }

    this.initControl();

    if (this.addDebounce) {
      this.emitDebouncedTimeValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.detectScrollToEnd) {
      createAndObserveIntersection(this.endOfList)
        .pipe(delay(300))
        .subscribe((isVisible) => {
          if (isVisible && !this.blockEmitScrolledToEnd) {
            this.scrolledToEnd.emit(true);
          }
        });

      this.inputCtrl.valueChanges.subscribe(() => {
        this.blockEmitScrolledToEnd = true;
      });
    }
  }

  private initControl() {
    this.filteredOptions = this.inputCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || '')),
      map((options) => this.filterFn(options))
    );
  }

  private emitDebouncedTimeValue() {
    this.inputValueChanged.pipe(debounceTime(this.addDebounce), distinctUntilChanged()).subscribe((value: string) => {
      this.valueChanged.emit(value);
    });
  }

  onInputValueChanged($event) {
    if (this.userInputChange) {
      if (this.addDebounce) {
        this.inputValueChanged.next(this.searchInput.nativeElement.value);
      } else {
        setTimeout(() => this.valueChanged.emit(this.searchInput.nativeElement.value), 0);
      }
    }
  }

  private filter(value: any): Option[] {
    if (typeof value !== 'string') {
      value = value.text;
    }

    if (this.options.length > 0) {
      const filterValue = value.toLowerCase();
      return this.options.filter((option) => option.text.toLowerCase().includes(filterValue));
    } else {
      return [];
    }
  }

  add(event: MatChipInputEvent): void {
    if (this.optionObj) {
      const value = { id: null, text: event.value, val: null };
      if (value) {
        this.changed.emit(value);
        this.selectedChips.push(value);
      }
    } else {
      const value = (event.value || '').trim();
      if (value) {
        this.changed.emit(value);
        this.selectedChips.push(value);
      }
    }

    event.chipInput?.clear();
    this.inputCtrl.setValue(null);
  }

  remove(item: Option): void {
    const index = this.selectedChips.indexOf(item);
    if (index >= 0) {
      this.selectedChips.splice(index, 1);
      if (this.emitRemovedItem) {
        this.emitRemove.emit(item);
      } else {
        this.emitRemove.emit(this.selectedChips);
      }
    }
    this.clearSearchInput();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedChips.push(event.option.value);
    this.changed.emit(event.option.value);
    this.searchInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);

    this.clearSearchInput();

    setTimeout(() => {
      this.showInputPlaceholder = true;
      this.searchInput.nativeElement.blur();
    }, 0);
  }

  clearSearchInput(): void {
    this.inputCtrl.setValue('');
    this.inputCtrl.markAsTouched();
    this.inputCtrl.markAsDirty();
    this.inputCtrl.reset();
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.blur();
    this.autocomplete.closePanel();
  }
}
