<div class="dialog-panel dialog-voucher-parser">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header text-left">
        <h1 class="dialog-title dot">{{ 'vouchers.add_vouchers' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <div class="row invalid-codes" *ngIf="invalidResults.length > 0">
          <div class="col-12">
            {{ 'vouchers.invalid_codes' | translate }}:
            <ul class="invalid-codes-list">
              <li *ngFor="let result of invalidResults">{{ result.code }}: {{ result.error }}</li>
            </ul>
          </div>
        </div>
        <div class="row flex-lg-nowrap">
          <div class="col-12 col-lg-3">
            <div class="form-field-container">
              <div class="form-field-label required">
                {{ 'form.field.code_type' | translate }}
              </div>
              <lib-select [value]="1" [options]="codeTypes" (valueChange)="setType($event)"></lib-select>
            </div>
          </div>
          <div class="col-12 col-lg">
            <div class="form-field-container">
              <div class="form-field-label">
                {{ 'form.field.add_codes' | translate }}
              </div>
              <mat-form-field class="form-field" [floatLabel]="'never'">
                <textarea
                  matInput
                  [formControlName]="'codes'"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="8"
                  cdkAutosizeMaxRows="8"
                ></textarea>
              </mat-form-field>
              <div class="w-100 text-right">
                <button
                  type="button"
                  class="btn btn-secondary mt-3"
                  [disabled]="!form.get('codes').value"
                  (click)="parseCodes()"
                >
                  <span [ngClass]="{ 'v-hidden': parseLoading }">
                    {{ 'vouchers.add_codes' | translate }}
                  </span>
                  <div class="spinner-container p-absolute" *ngIf="parseLoading">
                    <mat-spinner class="primary" [diameter]="15"></mat-spinner>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg">
            <div class="form-field-container">
              <div class="form-field-label">
                {{ 'form.field.parsed_codes' | translate }}
              </div>
              <mat-form-field class="form-field form-field-codes" [floatLabel]="'never'">
                <mat-chip-list #parsedCodesList>
                  <mat-chip
                    *ngFor="let code of parsedCodes"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="removeParsedCode(code)"
                  >
                    {{ code }}
                    <span matChipRemove class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
              <div class="row flex-nowrap voucher-number">
                <div class="col">
                  {{ 'vouchers.number_of_vouchers' | translate }}
                </div>
                <div class="col-auto text-right">
                  {{ counter }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-bottom text-right">
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid || parsedCodes.length === 0">
          <span [ngClass]="{ 'v-hidden': addLoading }">
            {{ 'global.save' | translate }}
          </span>
          <div class="spinner-container p-absolute" *ngIf="addLoading">
            <mat-spinner class="white" [diameter]="15"></mat-spinner>
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
