import { FormControl, FormGroup } from '@angular/forms';

export const getFirstControlWithError = (error: string, fg: FormGroup): FormControl | null => {
  let formControl = null;
  for (const controlKey in fg.controls) {
    if (Object.prototype.hasOwnProperty.call(fg.controls, controlKey)) {
      const control = fg.controls[controlKey];
      if (control.hasError(error)) {
        formControl = control;
      }
    }
  }
  return formControl;
};
