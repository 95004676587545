import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import { DndDirective } from './dnd.directive';
import { StopClickPropagationDirective } from './stop-click-propagation.directive';
import { DynamicTextColorDirective } from './dynamic-text-color.directive';

const DIRECTIVES = [ClickOutsideDirective, DndDirective, StopClickPropagationDirective, DynamicTextColorDirective];

@NgModule({
  imports: [],
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES],
})
export class SharedUtilsDirectivesModule {}
