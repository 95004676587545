export interface ResearchLabelGroup {
  name: string;
  labels: string;
  is_multi_select: boolean;
  is_required: boolean;
  translation_ids: string[];
  translations: ResearchLabelGroupTranslation[];
  id: string;
  tenant_id: string;
}

export interface ResearchLabelGroupTranslation {
  language: string;
  display_name: string;
  id: string;
  tenant_id: string;
}
