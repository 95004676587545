<div class="container scrollbar-light">
  <div class="close-icon" (click)="close()"><img src="/assets/icons/close-icon.svg" /></div>
  <div class="row m-0">
    <div class="col-12">
      <h2 class="page-title">{{ 'global.notes' | translate }}</h2>
      <!-- <button (click)="checkForm()">check form</button> -->
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 top-container" [ngClass]="{ opened: showNoteEditor }">
      <lib-multi-select-chips-checkbox-search
        *ngIf="!showNoteEditor"
        [options]="filterTypeSelectOptions"
        [control]="getFiltersFormControl('applied_types')"
        [myFloatLabel]="'never'"
        [optionObj]="true"
        [showTooltip]="true"
        [disableHidingList]="false"
        [showSearchPlaceholder]="true"
        [searchPlaceholderForAll]="'form.field.all_types'"
        [disableSearch]="true"
        [showSelectedPlaceholder]="true"
        [searchPlaceholderText]="'form.field.select_types'"
        [dropdownIconsPath]="'/icons/icon-arrow-b-'"
        [detectScrollToEnd]="true"
        [blockScrollEmitOnInputFocus]="true"
        [emitSelectedItemsOnSelect]="true"
        [hideChips]="true"
        (changed)="handleChangedFilter($event)"
        (emitRemove)="handleRemoveFilter($event)"
      >
      </lib-multi-select-chips-checkbox-search>

      <lib-select-plain
        *ngIf="!showNoteEditor && userVacanciesLoaded"
        [options]="filterContextOptions"
        [value]="getFiltersFormControl('applied_contexts').value"
        [placeholder]="'global.select_context'"
        (valueChange)="handleSelectContext($event)"
      ></lib-select-plain>

      <button *ngIf="!showNoteEditor" (click)="showNoteEditor = true" class="btn btn-black">
        {{ 'global.create_comment' | translate }}
      </button>
      <div *ngIf="showNoteEditor" class="create-note-container">
        <div class="close-note-editor" (click)="closeEditor()">
          {{ 'global.close_editor' | translate }} <span [inlineSVG]="'/icons/close-icon.svg'"></span>
        </div>
        <lib-text-editor
          *ngIf="userVacanciesLoaded"
          [control]="getFormControl('text')"
          [charMax]="2000"
          [label]="editingNote ? 'global.edit_note' : 'global.create_comment'"
        ></lib-text-editor>
        <lib-select-plain
          [options]="filterContextOptions"
          [value]="getFiltersFormControl('applied_comment_contexts').value"
          [placeholder]="'global.select_context'"
          (valueChange)="handleSelectContext($event, true)"
        ></lib-select-plain>
        <button *ngIf="!editingNote" (click)="saveNote()" class="btn btn-black save-btn" [disabled]="!this.form.valid">
          {{ 'global.save' | translate }}
        </button>
        <button *ngIf="editingNote" (click)="updateNote()" class="btn btn-black save-btn" [disabled]="!this.form.valid">
          {{ 'global.update_note' | translate }}
        </button>
      </div>
    </div>
    <div class="col-12 middle-container scroll-container">
      <div *ngIf="notesDataLoaded" class="notes-container">
        <div *ngIf="candidateNotes?.length === 0" class="no-notes">{{ 'global.no_notes_available' | translate }}</div>
        <ng-container *ngIf="candidateNotes?.length > 0">
          <div class="note-item" *ngFor="let note of candidateNotes" [ngClass]="noteTypeClassMap[note.type]">
            <div *ngIf="note.type === 3" class="action-btns">
              <div class="edit-btn" (click)="editNote(note.id, note.text, note.tags)">
                <span [inlineSVG]="'/icons/edit-icon.svg'"></span>
              </div>
              <div class="delete-btn" (click)="deleteNote(note.id)">
                <span [inlineSVG]="'/icons/delete-button-icon.svg'"></span>
              </div>
            </div>
            <div class="note-main" [ngClass]="{ comment: note.type === 3 }">
              <div *ngIf="note.type === 2" class="note-text">
                {{ 'global.status_changed_new_status' | translate }} <b>{{ note.status }}</b>
              </div>
              <div *ngIf="note.type !== 2" class="note-text" [innerHTML]="note.text"></div>
              <div *ngIf="note.type === 1 && note.status" class="email-status">
                {{ note.status }}
              </div>
            </div>
            <div class="note-footer">
              <div class="note-type">
                #{{ getNoteType(note.type) }}
                <ng-container *ngIf="note.tags.length > 0">#{{ note.tags[0] }} </ng-container>
              </div>
              <div class="note-date-name">
                {{ note.creator_name.charAt(0) }} {{ note.creator_last_name }}
                {{ getDate(note.updated || note.inserted) }}
                <div *ngIf="note.updated" class="edited-info">
                  {{ 'global.created' | translate }}: {{ getDate(note.inserted) }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div #endOfList></div>
    </div>
  </div>
</div>
