import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  constructor(private http: HttpClient) {}

  getLanguageSkills = () => this.http.get('/api/v1.0/skills/my/language-skills');

  getListOfLanguages = (searchValue: string) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': searchValue,
        skill_type: 3,
        // length: 30,
        // draw: 30,
      },
    });

  putLanguagesSkills = (req: any) => this.http.put('/api/v1.0/skills/my/language-skills', req);

  getAvailableSoftSkills = (searchValue: string) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': searchValue,
        skill_type: 2,
        length: 25,
        draw: 25,
      },
    });

  getSoftSkills = () => this.http.get('/api/v1.0/skills/my/soft-skills');

  putSoftSkills = (req: any) => this.http.put('/api/v1.0/skills/my/soft-skills', req);

  postSkillsRank = (req: any) => this.http.post('/api/v1.0/skills/rank', req);

  getProfessionalSkillsByJob = (obj) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': obj.searchValue,
        skill_type: 1,
        start: obj.start,
        length: obj.length,
        draw: obj.length,
        profession: obj.id,
      },
    });

  getProfessionalSkillsByEducation = (obj) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': obj.searchValue,
        skill_type: 1,
        start: obj.start,
        length: obj.length,
        draw: obj.length,
        education: obj.id,
      },
    });

  getProfessionalSkills = () => this.http.get('/api/v1.0/skills/my/professional-skills');

  putProfessionalSkills = (req: any) => this.http.put('/api/v1.0/skills/my/professional-skills', req);

  getTasksSkillsByJob = (obj) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': obj.searchValue,
        skill_type: 6,
        start: obj.start,
        length: obj.length,
        draw: obj.length,
        profession: obj.id,
      },
    });

  getTasksSkillsByEducation = (obj) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': obj.searchValue,
        skill_type: 6,
        start: obj.start,
        length: obj.length,
        draw: obj.length,
        education: obj.id,
      },
    });

  getTasksSkills = () => this.http.get('/api/v1.0/skills/my/task-skills');

  putTasksSkills = (req: any) => this.http.put('/api/v1.0/skills/my/task-skills', req);

  getItSkillsByJob = (obj) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': obj.searchValue,
        skill_type: 0,
        start: obj.start,
        length: obj.length,
        draw: obj.length,
        profession: obj.id,
      },
    });

  getItSkillsByEducation = (obj) =>
    this.http.get('/api/v1.0/skills/', {
      params: {
        'search.value': obj.searchValue,
        skill_type: 0,
        start: obj.start,
        length: obj.length,
        draw: obj.length,
        education: obj.id,
      },
    });

  putItSkills = (req: any) => this.http.put('/api/v1.0/skills/my/it-skills', req);

  // TO CHANGE
  getEducationMy = () => this.http.get('/api/v1.0/education/my');
}
