import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OrderApiService } from '@digilize/shared/data/services';
import { ALERT_UPDATE_SUCCESS } from '@shared/definitions';
import { AlertService } from '@digilize/shared/feature/modules/alert';

@Component({
  selector: 'lib-dialog-voucher-parser',
  templateUrl: './dialog-voucher-parser.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogVoucherParserComponent implements OnInit {
  productId: string;
  loading = true;
  parsedCodes: string[] = [];
  parseLoading;
  addLoading;
  counter = 0;
  invalidResults: any[] = [];
  codeTypes = [
    {
      key: 'QR code',
      value: 1,
    },
    {
      key: 'EAN13 code',
      value: 2,
    },
    {
      key: 'Code 128',
      value: 3,
    },
  ];
  form: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(1, Validators.required),
    codes: new UntypedFormControl(),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogVoucherParserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderApiService: OrderApiService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.data.productId) {
      this.productId = this.data.productId;
      this.loading = false;
    }
  }

  addParsedCodes(results: any[]) {
    results.forEach((result) => {
      if (!this.parsedCodes.some((obj) => obj === result.code) && !result.error) {
        this.parsedCodes.push(result.code);
      }
    });
    this.updateCounter();
  }

  removeParsedCode(code) {
    this.parsedCodes = this.parsedCodes.filter((obj) => obj !== code);
    this.updateCounter();
  }

  updateCounter() {
    this.counter = this.parsedCodes.length;
  }

  setType($event) {
    this.form.get('type').setValue($event);
    this.parsedCodes = [];
    this.invalidResults = [];
    this.updateCounter();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  parseCodes() {
    this.parseLoading = true;
    this.invalidResults = [];
    const codes = {
      id: this.productId,
      ...this.form.getRawValue(),
    };

    this.orderApiService.parseVouchers(codes).subscribe(
      (response: any) => {
        this.parseLoading = false;
        this.addParsedCodes(response.results);
        this.handleInvalidCodes(response.results);
        this.form.get('codes').setValue(null);
      },
      (error) => {
        this.parseLoading = false;
        this.alertService.handleApiError(error.error.error);
      }
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.addLoading = true;
      const codes = {
        id: this.productId,
        codes: this.parsedCodes,
        type: this.form.get('type').value,
      };

      this.orderApiService.addVouchers(codes).subscribe(
        (response: any) => {
          this.handleInvalidCodes(response.results);
          this.parsedCodes = [];
          this.updateCounter();
          this.alertService.setAlert(ALERT_UPDATE_SUCCESS);
          this.addLoading = false;
        },
        (error) => {
          this.alertService.handleApiError(error.error.error);
        }
      );
    }
  }

  handleInvalidCodes(results: any[]) {
    this.invalidResults = [];
    results.forEach((result) => {
      if (result.error) {
        const key = 'alert.error.type.' + result.error;
        const translation = this.translateService.instant(key);
        if (translation !== key && translation !== '') {
          result.error = translation;
        }
        this.invalidResults.push(result);
      }
    });
  }
}
