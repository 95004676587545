export interface Page {
  facebook_user_access_token: string;
  facebook_page_access_token: string;
  facebook_page_name: string;
  facebook_page_id: string;
  facebook_page_picture_url: string;
  instagram_page_id: string;
  instagram_page_username: string;
  instagram_page_name: string;
  instagram_page_picture_url: string;
}
