<div
  class="alert-container"
  *ngIf="alert"
  [ngClass]="{
    'alert-error': alert.type === 'error',
    'alert-success': alert.type === 'success',
    'alert-warning': alert.type === 'warning',
    show: show
  }"
>
  <div class="alert container">
    <div class="row">
      <div class="col-auto align-self-center">
        <div class="alert-icon-container">
          <div *ngIf="alert.type === 'success'" class="alert-icon" [inlineSVG]="'/icons/icon-success.svg'"></div>
          <div
            *ngIf="alert.type === 'error' || alert.type === 'warning'"
            class="alert-icon"
            [inlineSVG]="'/icons/icon-error.svg'"
          ></div>
        </div>
      </div>
      <div class="col p-0 alert-message align-self-center">
        <div class="title" *ngIf="alert.title">
          <ng-container *ngIf="alert.title.translated">
            {{ 'alert.' + alert.title.message | translate }}
          </ng-container>
          <ng-container *ngIf="!alert.title.translated">
            {{ alert.title.message }}
          </ng-container>
        </div>
        <div class="message" *ngIf="alert.message">
          <ng-container *ngIf="alert.message.translated">
            {{ 'alert.' + alert.message.message | translate: alert.message.params }}
          </ng-container>
          <ng-container *ngIf="!alert.message.translated">
            <span [innerHTML]="alert.message.message"></span>
          </ng-container>
        </div>
      </div>
      <div class="col-auto align-self-center">
        <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeAlert()"></div>
      </div>
    </div>
  </div>
</div>
