import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { createAndObserveIntersection } from '@digilize/shared/utils/helpers/src';
import { TableHeaderTemplateDirective, TableRowTemplateDirective } from './generic-table.directive';
import { Sort } from '@angular/material/sort';
@Component({
  selector: 'lib-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent<TItem extends object> implements OnInit, AfterViewInit {
  @Input() rowType: 'collapse' | 'separate' = 'separate';
  @Input() withAction = true;
  @Input() data!: TItem[];
  @ContentChild(TableHeaderTemplateDirective, { read: TemplateRef })
  headers?: TemplateRef<any>;
  @ContentChild(TableRowTemplateDirective, { read: TemplateRef })
  rows?: TemplateRef<any>;
  @ViewChild('endOfList') endOfList: ElementRef;
  @Output() scrolledToEnd = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    createAndObserveIntersection(this.endOfList).subscribe((isVisible) => {
      if (isVisible) {
        this.scrolledToEnd.emit(true);
      }
    });
  }

  sortData(sort: Sort) {
    console.log(sort);
  }
}
