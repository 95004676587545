<div class="dialog-info dialog-info-primary" *ngIf="companyInfo && step === 1">
  <div class="row flex-nowrap">
    <div class="col-auto align-self-center">
      <span class="icon" [inlineSVG]="'/icons/icon-warning.svg'"></span>
    </div>
    <div class="col copy">
      {{ 'company.first_create_company' | translate }}
    </div>
  </div>
</div>
<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'"></div>
  <div class="dialog-content">
    <ng-content select="selector"></ng-content>
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="forms[step]" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'global.' + headers[step] | translate }}</h1>
      </div>
      <div class="dialog-body">
        <!-- STEP 1 -->
        <ng-container *ngIf="step === 1">
          <div *ngIf="withFacebook" class="auth-other-actions">
            <button type="button" class="btn btn-fb d-block w-100" (click)="facebookRedirect()">
              <span class="icon-fb" [inlineSVG]="'/icons/icon-fb.svg'"></span>
              {{ 'auth.signin_with_facebook' | translate }}
            </button>
            <div class="d-block or">
              {{ 'global.or' | translate }}
            </div>
          </div>
          <ng-container *ngFor="let key of signUpFormOrder">
            <ng-container *ngIf="getFormControl(forms[1], key)">
              <div class="form-field-container" [class.no-border]="key === 'gender'">
                <div class="form-field-label">
                  {{ 'form.field.' + key | translate }}
                </div>
                <ng-container *ngIf="key === 'gender'">
                  <div class="mt-0 mb-0 radio-btn-group d-flex">
                    <mat-radio-group [formControlName]="key">
                      <mat-radio-button class="button-white mr-5" [value]="'male'">{{
                        'global.male' | translate
                      }}</mat-radio-button>
                      <mat-radio-button class="button-white mr-5" [value]="'female'">{{
                        'global.female' | translate
                      }}</mat-radio-button>
                      <mat-radio-button class="button-white" [value]="'other'">X</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </ng-container>
                <ng-container *ngIf="key !== 'gender'">
                  <mat-form-field
                    class="form-field"
                    [ngClass]="{ 'pwd-padding': key === 'password' || key === 'password_confirm' }"
                    [floatLabel]="'never'"
                  >
                    <ng-container *ngIf="key !== 'password' && key !== 'password_confirm'">
                      <input matInput [formControlName]="key" required />
                    </ng-container>
                    <ng-container *ngIf="key === 'password' || key === 'password_confirm'">
                      <input
                        #passwordInput="matInput"
                        matInput
                        [type]="hidePwd[key] ? 'password' : 'text'"
                        [formControlName]="key"
                        required
                      />
                      <span
                        class="form-field-icon"
                        [ngClass]="{ active: !hidePwd[key] }"
                        [inlineSVG]="'/icons/icon-eye.svg'"
                        (click)="hidePwd[key] = !hidePwd[key]; $event.preventDefault()"
                      ></span>
                    </ng-container>
                    <mat-error *ngIf="getFormControl(forms[1], key).invalid">
                      <ng-container *ngIf="getFormControl(forms[1], key).errors.email">
                        {{ 'form.error.invalid_email' | translate }}
                      </ng-container>
                      <ng-container *ngIf="getFormControl(forms[1], key).errors.alreadyExist">
                        {{ 'form.error.user_already_exist' | translate }}
                      </ng-container>
                      <ng-container *ngIf="getFormControl(forms[1], key).errors.required">
                        {{ 'form.error.required' | translate }}
                      </ng-container>
                      <ng-container *ngIf="getFormControl(forms[1], key).errors.matchOther">
                        {{ 'form.error.password_not_same' | translate }}
                      </ng-container>
                      <ng-container *ngIf="getFormControl(forms[1], key).errors.pattern">
                        {{ 'form.error.password_too_easy' | translate }}
                      </ng-container>
                    </mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <div class="auth-other-actions">
            <button type="button" class="btn btn-text" (click)="openSigninDialog($event)">
              {{ 'auth.already_have_account' | translate }}
              <span>
                {{ 'auth.signin_here' | translate }}
              </span>
            </button>
          </div>
        </ng-container>
        <!-- STEP 1 -->

        <!-- STEP 2 -->
        <ng-container *ngIf="step === 2">
          <p class="info">
            {{ 'auth.signup_info' | translate: { appTitle: appTitle } }}
          </p>
          <div class="form-field-container">
            <mat-checkbox class="auth-checkbox required" formControlName="terms" required>
              <a href="/terms" target="_blank">
                {{ 'global.terms_and_conditions' | translate }}
              </a>
            </mat-checkbox>
            <mat-checkbox class="auth-checkbox required" formControlName="privacy" required>
              <a href="/privacy" target="_blank">
                {{ 'global.privacy' | translate }}
              </a>
            </mat-checkbox>
            <mat-checkbox class="auth-checkbox" formControlName="newsletter">
              {{ 'global.newsletter' | translate }}
            </mat-checkbox>
            <div class="d-block info">*={{ 'global.required' | translate }}</div>
          </div>
        </ng-container>
        <!-- STEP 2 -->

        <!-- STEP 3 -->
        <ng-container *ngIf="step === 3 && categories.length > 0">
          <div class="category-select">
            <div class="title">
              {{ 'global.select_categories' | translate }}
            </div>
            <lib-select-categories
              [categories]="categories"
              (categoriesChange)="categoriesChange($event)"
            ></lib-select-categories>
          </div>
        </ng-container>
        <!-- STEP 3 -->
      </div>

      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="forms[step].invalid">
          {{ 'auth.continue_signup' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
