import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-lost-session',
  templateUrl: './dialog-lost-session.component.html',
  styleUrls: ['./dialog-lost-session.component.scss'],
})
export class DialogLostSessionComponent implements OnInit, OnDestroy {
  closeable = true;
  constructor(
    public dialogRef: MatDialogRef<DialogLostSessionComponent>,
    private matDialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogLostSessionComponentData
  ) {}

  ngOnInit() {
    if (this.data?.closeable !== undefined) {
      this.closeable = this.data.closeable;
    }
  }
  ngOnDestroy(): void {
    this.matDialog.closeAll();
  }
}

export interface DialogLostSessionComponentData {
  closeable?: boolean;
}
