import { SurveyStatus } from '../enums';
import { ResearchGroup } from './ResearchGroup';

export interface ResearchRespondent {
  research_group_id: string;
  research_respondent_inviter_id: string;
  research_group: ResearchGroup;
  research_respondent_inviter_first_name: string;
  research_respondent_inviter_last_name: string;
  user_id: string;
  is_user_invited: boolean;
  email: string;
  first_name: string;
  last_name: string;
  language: string;
  inserted: Date;
  invite_text: string;
  invite_at: string;
  invite_token: string;
  external_email_id: number;
  external_email_status: 'pending' | 'delivered' | 'opened' | 'clicked' | 'failed';
  external_email_sent: string;
  external_email_error_details: string;
  survey_status: SurveyStatus;
  survey_session_id: string;
  reminders_sent: Date;
  reminder_sent_at: Date;
  invited_after_current: number;
  invited_after_max: number;
  id: string;
}
