export interface SubscriptionType {
  id: string;
  company_group_id: string;
  name: string;
  is_popular: boolean;
  is_hidden: boolean;
  available_from: Date;
  available_to: Date;
  max_posts_per_campaign_period: number;
  price_monthly: number;
  price_bi_monthly: number;
  price_quarterly: number;
  price_half_yearly: number;
  price_yearly: number;
  discount_monthly: number;
  discount_bi_monthly: number;
  discount_quarterly: number;
  discount_half_yearly: number;
  discount_yearly: number;
  tenant_id: string;
}
