import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'monthYear',
})
export class MonthYearPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(date: any): unknown {
    if (date === '') {
      return 'Nu';
    }
    return this.datePipe.transform(date, 'MMMM yyyy');
  }
}
