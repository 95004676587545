import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormHeaderComponent implements OnInit {
  @Input() open;
  @Input() header;
  @Input() required;
  @Input() dottedTitle = true;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  collapse() {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }
}
