import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Option } from '@digilize/shared/definitions/src';
import { SingleSelectSearchComponent } from '@digilize/shared/ui/components/src/lib/form-items/single-select-search/single-select-search.component';
import { minArrayLengthValidator } from '@digilize/shared/utils/validators/src';
import { createOptions } from '../../../helpers/helpers';
import { ProfessionsService } from '../../../services/professions.service';
import { dateBeforeOtherCustomErrorValidator } from '../../../validators/date-before-custom-error.validators';
import { dateAfterOtherCustomErrorValidator } from '../../../validators/date-after-custom-error.validators';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'bwc-select-your-job',
  templateUrl: './select-your-job.component.html',
  styleUrls: ['./select-your-job.component.scss'],
})
export class SelectYourJobComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() itemClickable: boolean;
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @ViewChild('searchAutocomplete') searchAutocomplete: SingleSelectSearchComponent;
  @Output() dataStep = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitExp = new EventEmitter();
  @Output() clickEdit = new EventEmitter();

  form: FormGroup;
  jobsList: Option[] = [];
  exp_index: number;
  exp_opened = false;
  dataLoaded = false;
  editing = false;
  loadedJobsFullList = [];
  searchValue = '';

  constructor(private professionsService: ProfessionsService, private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.formGroup !== undefined) {
      this.editing = true;
      this.form = this.createForm();
      this.addCurrentJobs();
      this.getProfessionsSearchData();
    } else {
      this.form = this.createForm();
      this.getProfessionsSearchData();
      this.getJobs();
    }
  }

  validityChecking(index, status) {
    if (status === 'INVALID') {
      return 'error';
    }
    const arrNot = ['end_date', 'is_current'];
    const includedParams = ['position_name', 'position_name_original', 'company_name', 'start_date'];
    const job = this.selectedJobs.at(index).value;
    const params = Object.keys(job);
    let isCurrentFilled = false;
    let endDateFilled = false;
    let emptyParams = [];

    for (let i = 0; i < params.length; i++) {
      const paramName = params[i];
      const paramValue = job[paramName];
      if (paramName === 'end_date' && paramValue !== '' && paramValue !== null) {
        endDateFilled = true;
      }
      if (paramName === 'is_current' && paramValue === true) {
        isCurrentFilled = true;
      }
      if (
        !arrNot.includes(paramName) &&
        includedParams.includes(paramName) &&
        (paramValue === null || paramValue === '')
      ) {
        emptyParams.push(paramName);
      }
    }
    if (emptyParams.length > 0 || (emptyParams.length === 0 && isCurrentFilled === false && endDateFilled === false)) {
      return 'warning';
    }

    return 'checked';
  }

  addCurrentJobs() {
    const jobsList = this.formGroup.get('work_history').value;
    jobsList.forEach((item) => {
      const endDate = item.end_date === '1970-01-01T00:00:00+00:00' ? '' : item.end_date;
      this.putDataToJobsForm({
        order: item.order,
        position_id: item.position_id,
        position_name: item.position_name,
        position_name_original: item.position_name_original,
        position_alias_id: item.position_alias_id,
        position_alias_name_original: item.position_alias_name_original,
        company_name: item.company_name,
        job_description: item.job_description,
        start_date: item.start_date,
        end_date: endDate,
        is_current: item.is_current,
      });
    });
    this.dataLoaded = true;
  }

  addEmptyJob() {
    this.putDataToJobsForm({
      position_id: null,
      order: this.selectedJobs.length,
      position_name_original: '',
      position_alias_id: null,
      position_alias_name_original: '',
      position_name: '',
      is_current: false,
    });
  }

  createForm(): FormGroup {
    return this.fb.group<SelectedJobs>({
      selectedJobs: this.fb.array<UsersJob>([], [Validators.required, minArrayLengthValidator(1)]),
    });
  }

  getProfessionsSearchData() {
    this.professionsService.getListOfProfessions(this.searchValue).subscribe((data: any) => {
      this.loadedJobsFullList = data.data;
      this.jobsList = createOptions(data.data);
    });
  }

  getJobs() {
    this.professionsService.getProfessionsMy().subscribe((data: any) => {
      data.data.forEach((item) => {
        const endDate = item.end_date === '1970-01-01T00:00:00+00:00' ? '' : item.end_date;
        this.putDataToJobsForm({
          order: item.order,
          position_id: item.position_id,
          position_name: item.position_name,
          position_name_original: item.position_name_original,
          position_alias_id: item.position_alias_id,
          position_alias_name_original: item.position_alias_name_original,
          company_name: item.company_name,
          job_description: item.job_description,
          start_date: item.start_date,
          end_date: endDate,
          is_current: item.is_current,
        });
      });
      this.dataLoaded = true;
    });
  }

  get selectedJobs() {
    return this.form.get('selectedJobs') as FormArray;
  }

  checkForm() {
    console.log('form', this.form);
    console.log('selectedJobs', this.selectedJobs);
  }

  goPrev() {
    if (!this.form.valid) {
      this.dataStep.emit({ previousStep: this.currentStep, step: 3 });
    } else {
      this.saveData(this.form, false, true);
    }
  }

  nextStep() {
    if (this.form.valid) {
      this.saveData(this.form);
    }
  }

  onEdit() {
    if (this.form.valid) {
      this.saveData(this.form, true);
    }
  }

  saveData(form: FormGroup, close?, ifBack?) {
    const educationData = form.value.selectedJobs.map((item) => {
      const newItem = { ...item };
      delete newItem.suggested_it_skills;
      delete newItem.suggested_prof_skills;
      newItem.start_date = new Date(item.start_date).toISOString();
      const endDate = item.end_date === '' ? '1970-01-01T00:00:00+00:00' : item.end_date;
      newItem.end_date = new Date(endDate).toISOString();
      return newItem;
    });

    const requestBody = { items: educationData };

    this.professionsService.putProfessionsMy(requestBody).subscribe(
      () => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 3 });
        } else if (close) {
          this.emitExp.emit(this.selectedJobs);
          this.emitClose.emit('your_experience');
        } else {
          this.dataStep.emit({ previousStep: this.currentStep, step: 5 });
        }
      },
      (error) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 3 });
        }
        throwError('ProfessionsMy error:', error);
        console.error('ProfessionsMy error:', error);
      }
    );
  }

  editJob(index: number) {
    this.exp_index = this.exp_index === index ? undefined : index;

    if (this.exp_index !== index) {
      setTimeout(() => (this.exp_opened = false), 350);
    } else {
      this.exp_opened = true;
    }
  }

  handleFilteredOptionClick(option: Option) {
    const fullObject = this.loadedJobsFullList.find((obj) => obj.id === option.id);
    this.putDataToJobsForm({
      position_id: option.id,
      order: this.selectedJobs.length,
      position_name_original: option.text,
      position_alias_id: fullObject.profession_alias_id ? fullObject.profession_alias_id : null,
      position_alias_name_original: '',
      position_name: option.text,
      is_current: false,
    });
  }

  handleValueChangedSearch(event) {
    this.searchValue = event;
    this.professionsService.getListOfProfessions(event).subscribe((response: any) => {
      const combinedArray = this.loadedJobsFullList.concat(
        response.data.filter((newObj: any) => !this.loadedJobsFullList.some((srcObj) => srcObj.id === newObj.id))
      );
      this.loadedJobsFullList = [...combinedArray];
      this.jobsList = createOptions(response.data);
    });
  }

  handleScrolledToEndSearch(isVisible: boolean) {
    const params = {
      'search.value': this.searchValue,
      start: this.jobsList.length || 0,
    };

    this.getListOfProfessions(params).subscribe((data) => {
      const newOptions = createOptions(data.data);
      const mergedOptions = [...this.jobsList, ...newOptions];
      this.jobsList = mergedOptions;

      const combinedArray = this.loadedJobsFullList.concat(
        data.data.filter((newObj: any) => !this.loadedJobsFullList.some((srcObj) => srcObj.id === newObj.id))
      );
      this.loadedJobsFullList = [...combinedArray];
    });
  }

  private getListOfProfessions(overrideProps: Object = {}): Observable<any> {
    return this.professionsService.getListOfProfessionsParams({
      'search.value': '',
      length: 10,
      ...overrideProps,
    });
  }

  putDataToJobsForm(obj: Job) {
    const itemFormGroup = this.fb.group({
      order: [obj.order],
      position_id: [obj.position_id],
      position_name: [obj.position_name],
      position_name_original: [obj.position_name_original, [Validators.required]],
      position_alias_id: [obj.position_alias_id],
      position_alias_name_original: [obj.position_alias_name_original],
      company_name: [obj.company_name],
      job_description: obj.job_description,
      start_date: [obj.start_date, [dateBeforeOtherCustomErrorValidator('end_date', 'startDate')]],
      end_date: [obj.end_date, [dateAfterOtherCustomErrorValidator('start_date', 'endDate')]],
      is_current: [obj.is_current],
    });

    this.selectedJobs.push(itemFormGroup);
    this.resetOrderProperty();
  }

  getFormControl(index: number, controlName: string): FormControl {
    const section = this.selectedJobs.at(index) as FormGroup;
    return section.get(controlName) as FormControl;
  }

  removeJob(index: number) {
    this.selectedJobs.removeAt(index);
    this.resetOrderProperty();
  }

  changePositionInJobsList(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.selectedJobs.controls, event.previousIndex, event.currentIndex);
      this.resetOrderProperty();
      const updatedSelectedJobs = [];
      for (const control of this.selectedJobs.controls) {
        updatedSelectedJobs.push(control.value);
      }

      this.selectedJobs.setValue(updatedSelectedJobs);
    }
  }

  resetOrderProperty() {
    setTimeout(() => {
      for (let i = 0; i < this.selectedJobs.controls.length; i++) {
        const control = this.selectedJobs.controls[i];
        const value = control.value;
        value.order = i;
      }
    }, 0);
  }

  sortArrayByKey(arr, key: string) {
    return arr.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  close() {
    this.emitClose.emit('your_experience');
  }
}

interface SelectedJobs {
  selectedJobs: FormArray;
}

interface UsersJob {
  id: number;
  text: string;
  val: string;
}

interface Job {
  order?: number;
  position_id?: number;
  position_name?: string;
  position_name_original?: string;
  position_alias_id?: number;
  position_alias_name_original?: string;
  company_name?: string;
  job_description?: string;
  start_date?: string;
  end_date?: string;
  is_current?: boolean;
}
