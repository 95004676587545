<div class="dropdown">
  <div class="dropdown-src">
    <div #content (appClickOutside)="handleClickOutside()" [exception]="secondEl" class="content-wrapper">
      <ng-content></ng-content>
    </div>
    <ul #secondEl [ngClass]="{ block: isListVisible }">
      <ng-container *ngTemplateOutlet="customDroplist || defaultDropdown"> </ng-container>
      <ng-container *ngIf="!customDroplist"> </ng-container>
    </ul>
  </div>
</div>
<ng-template #defaultDropdown>
  <li
    (appClickOutside)="ul.classList.remove('block')"
    (click)="ul.classList.add('block')"
    *ngFor="let dropEl of dropData"
  >
    <span>{{ dropEl.title }}</span>
    <ul #ul [ngClass]="{ 'for-selected': selectedValue }" [class]="dropEl.title">
      <li
        (click)="handleNestClick(dropEl.type, nestEl.value)"
        *ngFor="let nestEl of dropEl.nested"
        [ngClass]="{ 'selected-item': nestEl?.selected }"
      >
        <span> {{ nestEl.text }}</span>
      </li>
    </ul>
  </li>
</ng-template>
