export interface Period {
  name: string;
  company_group_id: string;
  visibility_start: string;
  visibility_finish: string;
  campaign_start: string;
  subscription: boolean;
  email: string;
  id: string;
  max_posts: number;
  current_posts: number;
}
