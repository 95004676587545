<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [header]="header" [open]="open" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open">
    <form [formGroup]="form">
      <div class="row">
        <div class="photo-container">
          <div>
            <p class="mb-0 font-weight-bold">{{ 'events.banner' | translate }}</p>
            <lib-photo-upload
              [hideInfo]="true"
              [layout]="2"
              heightRatioInPercent="50%"
              [photo]="bannerUrl"
              (fileChange)="bannerChange($event)"
            >
              {{ 'events.banner_image_input_description' | translate }} <strong>2MB</strong>
            </lib-photo-upload>
          </div>
          <div>
            <p class="mb-0 font-weight-bold">{{ 'events.event_image' | translate }}</p>
            <lib-photo-upload
              [hideInfo]="true"
              [layout]="2"
              heightRatioInPercent="130%"
              [photo]="photoUrl"
              (fileChange)="photoChange($event)"
            >
              {{ 'events.poster_image_input_description' | translate }} <strong>2MB</strong>
            </lib-photo-upload>
          </div>
        </div>
        <!-- fields -->
        <div class="fields-container">
          <div class="row">
            <div class="form-field-container col-12">
              <!-- Label -->
              <div
                class="form-field-label"
                [ngClass]="{
                  required: isFormControlRequired(form.get('name'))
                }"
              >
                {{ 'form.field.name' | translate }}
              </div>
              <!-- Label -->

              <mat-form-field class="form-field" [floatLabel]="'never'">
                <input matInput formControlName="name" />
                <mat-error *ngIf="form.get('name').invalid">
                  <ng-container *ngIf="form.get('name').errors.required">
                    {{ 'form.error.required' | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="form-field-container col-12 col-sm-6">
              <!-- Label -->
              <div
                class="form-field-label"
                [ngClass]="{
                  required: isFormControlRequired(form.get('available_from'))
                }"
              >
                {{ 'form.field.available_from' | translate }}
              </div>
              <!-- Label -->
              <mat-form-field class="form-field" [floatLabel]="'never'">
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker_from"
                  formControlName="available_from"
                  (dateChange)="minDateChange($event)"
                  readonly
                />
                <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(picker_from)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker_from [showSpinners]="true"> </ngx-mat-datetime-picker>
                <mat-error *ngIf="form.get('available_from').invalid">
                  <ng-container *ngIf="form.get('available_from').errors.required">
                    {{ 'form.error.required' | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="form-field-container col-12 col-sm-6">
              <!-- Label -->
              <div
                class="form-field-label"
                [ngClass]="{
                  required: isFormControlRequired(form.get('available_to'))
                }"
              >
                {{ 'form.field.available_to' | translate }}
              </div>
              <!-- Label -->

              <mat-form-field class="form-field" [floatLabel]="'never'">
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker_to"
                  (dateChange)="maxDateChange($event)"
                  formControlName="available_to"
                />
                <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(picker_to)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker_to [showSpinners]="true"> </ngx-mat-datetime-picker>
                <mat-error *ngIf="form.get('available_to').invalid">
                  <ng-container *ngIf="form.get('available_to').errors.required">
                    {{ 'form.error.required' | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <ng-template #formFieldLabel>
            <div class="form-field-label">
              {{ 'form.field.' + 'external_types' | translate }}
              <ng-container>
                <div
                  class="i-icon"
                  #tooltip="matTooltip"
                  [matTooltip]="'tooltip.product.' + 'external_types' | translate"
                  [matTooltipPosition]="'above'"
                  (click)="tooltip.toggle()"
                >
                  <span class="i">i</span>
                </div>
              </ng-container>
            </div>
          </ng-template>

          <div class="form-field-container">
            <ng-container *ngTemplateOutlet="formFieldLabel"></ng-container>
            <lib-multi-select
              noOptionPlaceholder="events.no_event_types_available"
              [options]="eventTypes"
              [control]="form.get('external_types')  | formControl"
            ></lib-multi-select>
          </div>
          <div class="row m-0">
            <mat-checkbox
              #internalShopCheckbox
              class="checkbox"
              (change)="toggleShop($event.checked)"
              [checked]="form.get('shop_id').value"
            >
              <span [innerHTML]="'form.checkboxes.internal_ticket_shop' | translate"></span>
            </mat-checkbox>
          </div>
          <div class="row">
            <div class="form-field-container col-12 col-sm-8" *ngIf="internalShopCheckbox.checked">
              <div class="form-field-label">
                {{ 'form.field.shop_id' | translate }}
              </div>
              <lib-select
                [options]="shopOptionsKeyValue"
                [value]="form.get('shop_id').value"
                (valueChange)="form.get('shop_id').setValue($event)"
              ></lib-select>
            </div>

            <div class="form-field-container col-12 col-sm-8" *ngIf="!internalShopCheckbox.checked">
              <div
                class="form-field-label"
                [ngClass]="{
                  required: isFormControlRequired(form.get('ticket_url'))
                }"
              >
                {{ 'form.field.ticket_url' | translate }}
              </div>
              <!-- Label -->

              <mat-form-field class="form-field" [floatLabel]="'never'">
                <input placeholder="https://myticketshop.com" matInput formControlName="ticket_url" />
                <mat-error *ngIf="form.get('ticket_url').invalid">
                  <ng-container *ngIf="form.get('ticket_url').errors.required">
                    {{ 'form.error.required' | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <ng-container formGroupName="socials" *ngIf="form">
            <div class="row" *ngFor="let social of socials">
              <div class="form-field-container col-12 col-sm-8">
                <!-- Label -->
                <div
                  class="form-field-label"
                  [ngClass]="{
                    required: isFormControlRequired(form.get('socials').get(social))
                  }"
                >
                  {{ 'form.field.' + social | translate }}
                </div>
                <!-- Label -->

                <mat-form-field class="form-field" [floatLabel]="'never'">
                  <input placeholder="https://mysite.com" matInput [formControlName]="social" />
                  <mat-error *ngIf="form.get('socials').get(social).invalid">
                    <ng-container *ngIf="form.get('socials').get(social).errors.pattern">
                      {{ 'form.error.invalid_link' | translate }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <!-- fields -->
        </div>
      </div>
      <lib-text-editor label="global.description" [control]="form.get('description') | formControl"></lib-text-editor>
    </form>
  </div>
</div>
