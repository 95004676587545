import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function lessThanValidator(minValueControl: AbstractControl, maxValueControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minValue = minValueControl.value;
    const maxValue = maxValueControl.value;

    if (minValue !== null && maxValue !== null && minValue >= maxValue) {
      return { minValueGreaterThanMaxValue: true };
    }

    return null;
  };
}
