import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppConfig, LanguageCode } from '@digilize/shared/definitions/src';
import { I18nService } from '@digilize/shared/feature/modules/i18n/src';
import { APP_CONFIG } from '@digilize/shared/utils/tokens/src';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-lang-droplist',
  templateUrl: './lang-droplist.component.html',
  styleUrls: ['./lang-droplist.component.scss'],
})
export class LangDroplistComponent implements OnInit, OnDestroy {
  @Input() dropdownIconsPath: string;
  @Input() noNative = false;
  langFormControl: FormControl;
  defaultLang = '';
  langOptions;
  constructor(
    private translateService: TranslateService,
    private i18nService: I18nService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {}
  subSink: Subscription[] = [];

  ngOnInit(): void {
    this.subSink.push(
      this.translateService.onLangChange.subscribe((langChangeEvent) => {
        this.langFormControl.setValue(langChangeEvent.lang);
      })
    );

    this.langFormControl = new FormControl((this.defaultLang = this.translateService.getDefaultLang()));
    this.langOptions = this.appConfig.language.languages.map((lang) => {
      return { key: lang.code.toUpperCase(), value: lang.code, img: lang.iconFlagPath };
    });
  }

  handleLangChange(lang: LanguageCode) {
    this.langFormControl.setValue(lang);
    this.i18nService.setLangAndSave(lang);
  }

  getLangOption(lang: LanguageCode) {
    return this.langOptions.find((option) => option.value === lang);
  }

  ngOnDestroy(): void {
    this.subSink.forEach((sub) => sub.unsubscribe());
  }
}
