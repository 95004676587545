export const SUBSCRIPTION_PERIODS = () => {
  return [
    {
      key: 'subscription.period.monthly',
      value: 1,
    },
    {
      key: 'subscription.period.bi_monthly',
      value: 2,
    },
    {
      key: 'subscription.period.quarterly',
      value: 3,
    },
    {
      key: 'subscription.period.half_yearly',
      value: 4,
    },
    {
      key: 'subscription.period.yearly',
      value: 5,
    },
  ];
};
