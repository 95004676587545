import { Component, OnInit, Renderer2 } from '@angular/core';
import { FileStepData } from '@digilize/shared/definitions/src/lib/interfaces/FileStepData';
import { CompanyColorsService } from '../../services/company-colors.service';
import { ActivatedRoute } from '@angular/router';
import { slider } from './animations';
import { CompanyApiService } from '@digilize/shared/data/services/src';

@Component({
  selector: 'bwc-have-resume',
  templateUrl: './have-resume.component.html',
  styleUrls: ['./have-resume.component.scss'],
  animations: [slider],
})
export class HaveResumeComponent implements OnInit {
  step = 1;
  previousStep = 0;
  allSteps = 11;
  uploadData: FileStepData;
  companyId: string;
  vacancyId: string;
  openFormLink: string;
  inviteLink: string;
  animationState = 0;

  constructor(
    private companyColorsService: CompanyColorsService,
    private route: ActivatedRoute,
    private companyApiServices: CompanyApiService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.checkParams();
  }

  private checkParams() {
    this.route.queryParams.subscribe((params) => {
      const companyId = params['c'];
      const vacancyId = params['apply_to'];
      const openFormLink = params['ofl'];
      const inviteLink = params['invite'];
      if (companyId) {
        this.companyId = companyId;
        this.getCompanyStyles();
      } else {
        this.getCompanyStylesByDomain();
      }
      if (vacancyId) {
        this.vacancyId = vacancyId;
      }
      if (openFormLink) {
        this.openFormLink = openFormLink;
      }
      if (inviteLink) {
        this.inviteLink = inviteLink;
      }
    });
  }

  private getCompanyStyles() {
    this.companyApiServices.getCompanyStyles(this.companyId).subscribe((data: any) => {
      this.companyColorsService.setCompanyColors(data.colors_model);
      this.renderer.addClass(document.body, 'company-colors');
    });
  }

  private getCompanyStylesByDomain() {
    this.companyApiServices.getCompanyStylesByDomain().subscribe((data: any) => {
      this.companyColorsService.setCompanyColors(data.colors_model);
      this.renderer.addClass(document.body, 'company-colors');
    });
  }

  changeStep(data: FileStepData) {
    if (data.file || data.error) {
      this.uploadData = data;
    }
    this.animationState = data.step;
    this.previousStep = data.previousStep;
    this.step = data.step;
  }
}
