import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  dataStr = new EventEmitter();
  dataClass = new EventEmitter();

  constructor() {}

  setClass(data: boolean) {
    this.dataClass.emit(data);
  }

  sendMessage(data: string) {
    this.dataStr.emit(data);
  }
}
