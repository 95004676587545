import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { addWebp } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-card-photo',
  templateUrl: './card-photo.component.html',
  styleUrls: ['../card.component.scss'],
})
export class CardPhotoComponent implements OnInit {
  @Input() img: string;
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onItemClick() {
    this.itemClicked.emit(this.img);
  }

  get addWebp() {
    return addWebp();
  }
}
