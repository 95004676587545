import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'bwh-lib-skills-edition',
  templateUrl: './skills-edition.component.html',
  styleUrls: ['./skills-edition.component.scss'],
})
export class SkillsEditionComponent {
  @Input() title: string;
  @Input() form: FormGroup;
  @Input() control: string;
  @Input() editButton = true;
  @Input() cvSharing = false;
  @Input() blockStarClick = false;
  @Input() blockGoToEditionBtn = false;
  @Input() additionalBtnInfo: string;
  @Input() editName: string;
  @Input() infoTxt: string;
  @Input() buttonTxt: string;
  @Input() levelOptions: any;

  @Output() clickEdit = new EventEmitter();
  @Output() addingExp = new EventEmitter();
  @Output() ratingChanged = new EventEmitter();

  stars = [0, 1, 2, 3, 4];

  constructor() {}

  get formArray() {
    return this.form.get(this.control) as FormArray;
  }

  starClicked(event) {
    if (!this.blockStarClick && !this.cvSharing) {
      const thisSkill = this.formArray.at(event.index);
      thisSkill.get('rating').setValue(event.rating);
      this.ratingChanged.emit(thisSkill.value);
    }
  }

  edit() {
    this.clickEdit.emit(this.editName ? this.editName : this.control);
  }

  buttonClick() {
    this.clickEdit.emit(this.editName);
    this.addingExp.emit(true);
  }
}
