import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProcessRequest, ProcessResponse } from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class ProcessorApiService {
  readonly PROCESSOR_BASE_HREF = '/api/v1.0/processor';
  constructor(private http: HttpClient) {}

  startProcess(process: any) {
    return this.http.post<ProcessResponse>(`${this.PROCESSOR_BASE_HREF}/session/start`, process);
  }

  continueProcess(process: ProcessRequest) {
    return this.http.post<ProcessResponse>(`${this.PROCESSOR_BASE_HREF}/process`, process);
  }
}
