import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bwh-lib-step-control-bar',
  templateUrl: './step-control-bar.component.html',
  styleUrls: ['./step-control-bar.component.scss'],
})
export class StepControlBarComponent implements OnInit {
  @Input() langDropdown: boolean;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() progresBar = false;
  @Input() steps: number;
  @Input() currentStep: number;
  @Input() previousStep: number;

  @Output() emitClose = new EventEmitter();
  @Output() emitPrev = new EventEmitter();

  progresNum = 0;

  constructor() {}

  ngOnInit(): void {
    this.getProgress();
  }

  private getProgress() {
    if (this.previousStep) {
      this.progresNum = (this.previousStep / this.steps) * 100;

      setTimeout(() => {
        this.progresNum = (this.currentStep / this.steps) * 100;
      }, 700);
    } else {
      this.progresNum = (this.currentStep / this.steps) * 100;
    }
  }

  close() {
    this.emitClose.emit();
  }

  prev() {
    this.emitPrev.emit();
  }
}
