<div class="mat-form-field-container select-search" #mffContainer>
  <mat-form-field
    class="select-primary"
    [floatLabel]="'never'"
    [ngClass]="{ 'select-open': select.panelOpen }"
    *ngIf="!native"
  >
    <span class="select-arrow" [inlineSVG]="'/icons/icon-arrow-' + (select.panelOpen ? 'up' : 'down') + '.svg'"></span>
    <mat-select
      #select
      [(ngModel)]="value"
      disableOptionCentering
      panelClass="select-options-primary"
      [placeholder]="'global.select' | translate"
      (selectionChange)="onValueChange($event)"
    >
      <input
        type="text"
        [placeholder]="'global.search' | translate"
        class="search-input"
        [(ngModel)]="searchQuery"
        (ngModelChange)="onSearch($event)"
      />
      <mat-option
        *ngIf="optionsFiltered && optionsFiltered.length === 0"
        [ngStyle]="{ width: mffContainer.getBoundingClientRect().width - 1 + 'px' }"
      >
        <div class="text-center text-muted text-italic">No Data</div>
      </mat-option>
      <ng-container *ngIf="optionsFiltered.length > 0">
        <mat-option
          *ngFor="let option of optionsFiltered"
          [value]="option.value"
          [ngStyle]="{ width: mffContainer.getBoundingClientRect().width - 1 + 'px' }"
        >
          <ng-container *ngIf="translate; else noTranslate">
            {{ option.key | translate }}
          </ng-container>
          <ng-template #noTranslate>
            {{ option.key }}
          </ng-template>
        </mat-option>
      </ng-container>
    </mat-select>
    <div class="mat-error" *ngIf="errors">
      <ng-container *ngIf="errors.required">
        {{ 'form.error.required' | translate }}
      </ng-container>
    </div>
  </mat-form-field>

  <mat-form-field class="select-primary" [floatLabel]="'never'" *ngIf="native">
    <span class="select-arrow" [inlineSVG]="'/icons/icon-arrow-down.svg'"></span>
    <select
      #select
      matNativeControl
      [(ngModel)]="value"
      [placeholder]="'global.select' | translate"
      (change)="onValueChange($event, true)"
    >
      <option *ngFor="let option of options" [value]="option.value">
        <ng-container *ngIf="translate; else noTranslate">
          {{ option.key | translate }}
        </ng-container>
        <ng-template #noTranslate>
          {{ option.key }}
        </ng-template>
      </option>
    </select>
    <div class="mat-error" *ngIf="errors">
      <ng-container *ngIf="errors.required">
        {{ 'form.error.required' | translate }}
      </ng-container>
    </div>
  </mat-form-field>
</div>
