<mat-form-field appearance="legacy" class="">
  <label class="label">{{ placeholder }}</label>
  <div class="input-container">
    <input
      [formControl]="hoursControl"
      (keypress)="handleHoursPress($event)"
      #input
      maxlength="2"
      (click)="input.select()"
      (blur)="padZero(hoursControl)"
    />
    <span class="separator">:</span>
    <input
      [formControl]="minutesControl"
      (keypress)="handleMinutesPress($event)"
      #input2
      maxlength="2"
      (click)="input2.select()"
      (blur)="padZero(minutesControl)"
    />
    <!-- mat-form-field requires control inside, but supports only one correctly -->
    <input [ngStyle]="{ display: 'none' }" matInput />
  </div>
  <mat-icon class="icon-clock" matSuffix>
    <span #iconClock (click)="isOpened = !isOpened" [inlineSVG]="'/icons/icon-alarm-clock-no-color.svg'"></span>
  </mat-icon>
</mat-form-field>

<div class="time-picker" *ngIf="isOpened" (appClickOutside)="isOpened = false" [exception]="iconClock">
  <div class="arrow-container">
    <span
      class="arrow up"
      [inlineSVG]="'/icons/icon-chevron-left-no-color.svg'"
      (click)="handleHoursChangeBy(1)"
    ></span>
    <p class="value-container m-0">{{ hoursControl.value }}</p>
    <span
      class="arrow down"
      [inlineSVG]="'/icons/icon-chevron-left-no-color.svg'"
      (click)="handleHoursChangeBy(-1)"
    ></span>
  </div>
  <div class="arrow-container">
    <span
      class="arrow up"
      [inlineSVG]="'/icons/icon-chevron-left-no-color.svg'"
      (click)="handleMinutesChangeBy(1)"
    ></span>
    <p class="value-container m-0">
      {{ minutesControl.value }}
    </p>
    <span
      class="arrow down"
      [inlineSVG]="'/icons/icon-chevron-left-no-color.svg'"
      (click)="handleMinutesChangeBy(-1)"
    ></span>
  </div>
</div>
