import { ShopMovie } from "./ShopMovie";
import { MovieShow } from "./MovieShow";
import { Colors } from "./Colors";
import { EventColors } from "./EventColors";
import { Media } from "./Media";

export interface Event {
  attending: boolean;
  available_from: string;
  available_to: string;
  banner: Media;
  banner_id: string;
  company_id: string;
  city: string;
  colors: EventColors | Colors;
  country: string;
  description: string;
  disliked: boolean;
  exclusive: boolean;
  external_types: any;
  highlighted_to: string;
  house_number: string;
  id: string;
  inserted: string;
  invitation_html: string;
  is_new: boolean;
  lat: number;
  liked: boolean;
  lon: number;
  is_recurring: boolean;
  media_ids: string[];
  media: Media[];
  movie_show_ids: string[];
  movie_shows: MovieShow[];
  name: string;
  national: boolean;
  photo: Media;
  photo_id: string;
  post_code: string;
  shop_id: string;
  shop_movie_ids: string[];
  shop_movies: ShopMovie[];
  shop_name: string;
  shop_permalink: string;
  socials: any;
  status: number;
  street: string;
  ticket_url: string;
  upload: string;
  isSelected: boolean;
}
