export enum AlertType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export interface Alert {
  type: AlertType;
  title: AlertMessage;
  message: AlertMessage;
  time?: number;
}

export interface AlertMessage {
  message: string;
  translated: boolean;
}
