import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Scanner } from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class ScannerApiService {
  readonly scannerBaseUrl = '/api/v1.0/scanners';
  constructor(private http: HttpClient) {}
  addScanner(scanner: Omit<Scanner, 'id'>) {
    return this.http.post<Scanner>(`${this.scannerBaseUrl}`, scanner);
  }
  editScanner(scanner: Scanner) {
    return this.http.put(`${this.scannerBaseUrl}`, scanner);
  }
  getScanners(start: number, length: number, companyId: string) {
    // return this.http.get<{data: Event[], draw: number, recordFiltered: number, recordTotal: number}>(`${this.eventBaseUrl}`, {params:queryParams});
    let queryParams = new HttpParams();
    queryParams = queryParams.append('start', start.toString());
    queryParams = queryParams.append('length', length.toString());
    queryParams = queryParams.append('company', companyId);
    return this.http.get<{ data: Scanner[]; draw: number; recordFiltered: number; recordTotal: number }>(
      `${this.scannerBaseUrl}`,
      { params: queryParams }
    );
  }
  getScanner(id: string) {
    return this.http.get<Scanner>(`${this.scannerBaseUrl}/${id}`);
  }
  removeScanner(id: string) {
    return this.http.delete(`${this.scannerBaseUrl}/${id}`);
  }
  copyScanner(id: string) {
    return this.http.get(`${this.scannerBaseUrl}/${id}/copy`);
  }
  getScannerLoginQR(id: string) {
    //returns png
    return this.http.get(`${this.scannerBaseUrl}/${id}/code`);
  }
}
