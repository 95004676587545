import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert/src';
import { addWebp } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-dialog-voucher',
  templateUrl: './dialog-voucher.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogVoucherComponent implements OnInit {
  loading = true;
  voucher: any;
  barcode;

  constructor(
    public dialogRef: MatDialogRef<DialogVoucherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderApiService: OrderApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    if (this.data.voucherId) {
      this.getVoucher(this.data.voucherId);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getVoucher(voucherId: string) {
    this.orderApiService.getVoucher(voucherId).subscribe(
      (voucher: any) => {
        this.voucher = voucher;
        this.orderApiService.getBarcode(voucherId).subscribe(
          (barcode: any) => {
            const reader = new FileReader();
            reader.readAsDataURL(barcode);
            reader.onload = () => {
              this.barcode = reader.result;
            };
            this.loading = false;
          },
          (error) => {
            this.handleError(error);
          }
        );
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    this.alertService.handleApiError(error.error.error);
    this.loading = false;
  }

  get addWebp() {
    return addWebp();
  }
}
