export interface Socials {
  facebook: string;
  instagram: string;
  linkedin: string;
  youtube: string;
  tiktok: string;
  twitter: string;
  website: string;
  whatsapp: string;
}
