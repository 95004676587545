import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

@Component({
  selector: 'lib-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  isOpened = false;
  @Input() placeholder = 'Time';
  @Input() control: FormControl;
  @Input() value: string;
  @Output() timeChanged = new EventEmitter();
  @ViewChild('input') input: ElementRef;
  hoursControl = new FormControl('');
  minutesControl = new FormControl('');
  constructor() {}

  ngOnInit(): void {
    this.initValue();
    combineLatest([
      this.hoursControl.valueChanges.pipe(
        startWith(this.hoursControl.value),
        filter((val) => {
          if (parseInt(val) > 23) {
            this.hoursControl.setValue('23');
            return false;
          } else {
            return true;
          }
        })
      ),
      this.minutesControl.valueChanges.pipe(
        startWith(this.minutesControl.value),
        filter((val) => {
          if (parseInt(val) > 59) {
            this.minutesControl.setValue('59');
            return false;
          } else {
            return true;
          }
        })
      ),
    ]).subscribe(([hours, minutes]) => {
      const val = `${hours}:${minutes}`;
      this.timeChanged.emit(val);
      if (this.control) {
        this.control.setValue(val);
      }
    });
  }

  initValue() {
    if (this.value) {
      const valueEls = this.value.split(':');
      this.hoursControl.setValue(valueEls[0]);
      this.minutesControl.setValue(valueEls[1]);
    } else {
      this.hoursControl.setValue('12');
      this.minutesControl.setValue('30');
    }
  }

  handleHoursPress(keyEvent: KeyboardEvent) {
    var regex = new RegExp('^[0-9]+$');
    var key = String.fromCharCode(!keyEvent.charCode ? keyEvent.which : keyEvent.charCode);
    if (!regex.test(key)) {
      keyEvent.preventDefault();
      return false;
    }
  }

  handleMinutesPress(keyEvent: KeyboardEvent) {
    var regex = new RegExp('^[0-9]+$');
    var key = String.fromCharCode(!keyEvent.charCode ? keyEvent.which : keyEvent.charCode);
    if (!regex.test(key)) {
      keyEvent.preventDefault();
      return false;
    }
  }

  padZero(control: FormControl) {
    const val = control.value;
    control.setValue(val.padStart(2, '0'));
  }

  handleHoursChangeBy(by: number) {
    let previousValue = parseInt(this.hoursControl.value);
    previousValue = previousValue + by;
    if (previousValue === 24) previousValue = 0;
    if (previousValue === -1) previousValue = 23;
    this.hoursControl.setValue(previousValue.toString());
    this.padZero(this.hoursControl);
  }

  handleMinutesChangeBy(by: number) {
    let previousValue = parseInt(this.minutesControl.value);
    previousValue = previousValue + by;
    if (previousValue === 60) previousValue = 0;
    if (previousValue === -1) previousValue = 59;
    this.minutesControl.setValue(previousValue.toString());
    this.padZero(this.minutesControl);
  }
}
