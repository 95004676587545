import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShopApiService } from '@digilize/shared/data/services';
import { ErrorKind } from '@digilize/shared/definitions/src';
import { AlertService } from '@digilize/shared/feature/modules/alert/src';

@Component({
  selector: 'lib-dialog-check-token',
  templateUrl: './dialog-check-token.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogCheckTokenComponent implements OnInit {
  form = new UntypedFormGroup({
    token: new UntypedFormControl('', [Validators.required]),
  });
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogCheckTokenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shopApiService: ShopApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    this.loading = true;
    const token = this.form.get('token').value;
    this.shopApiService.checkPaymentToken(token).subscribe(
      (response: any) => {
        this.closeDialog({
          token: token,
          data: response,
        });
        this.loading = false;
      },
      (error) => {
        if (error.error.error.type === ErrorKind.PAYMENT_API_KEY_INVALID) {
          this.form.get('token').setErrors({ invalid_token: true });
        } else {
          this.alertService.handleApiError(error.error.error);
        }
        this.loading = false;
      }
    );
  }
}
