<div
  class="item-name-box box-shadow"
  [ngClass]="{
    list: listIcon,
    'indexed-icons': indexIcon,
    'no-list-icon': nolistIcon,
    'all-icons-disables': disableAllIcons,
    vacancy: vacancy,
    'two-icons': twoIcons,
    'error-icon': iconStatus === 'error',
    'warning-icon': iconStatus === 'warning',
    'checked-icon': iconStatus === 'checked'
  }"
>
  <span class="item-name">{{ name }}</span>
  <span class="index-icon" *ngIf="indexIcon">{{ index }}</span>
  <div *ngIf="iconStatus === 'warning'" class="warning-hover-message">
    <div class="message box-shadow-small index-{{ index }}">
      {{ 'form.field.some_fields_are_not_field' | translate }}
    </div>
  </div>
  <span class="list-icon img-icon" *ngIf="listIcon" [inlineSVG]="'/icons/list-icon.svg'"></span>
  <div class="native-container" *ngIf="ifNative !== undefined" [ngClass]="{ native: ifNative }">
    <input type="checkbox" [(ngModel)]="ifNative" (click)="selectAsNative(index - 1)" />
    <span (click)="selectAsNative(index - 1)">{{ 'global.native' | translate }}</span>
  </div>

  <lib-stars-rate
    [index]="index - 1"
    [starsRate]="starsRate"
    [titlesDesc]="'lang_desc_level'"
    [ngClass]="{ 'disabled-stars': ifNative }"
    (changeLevel)="starClicked($event)"
  ></lib-stars-rate>

  <div class="opened-item" *ngIf="openIcon">
    <span *ngIf="expIndex !== index" [inlineSVG]="'/icons/icon-open-down.svg'"></span>
    <span *ngIf="expIndex !== undefined && expIndex === index" [inlineSVG]="'/icons/icon-open-up.svg'"></span>
  </div>

  <span
    *ngIf="!noDelete"
    (click)="actionClick(index - 1)"
    [inlineSVG]="'/icons/delete-icon.svg'"
    class="img-icon"
    alt="Delete item"
  >
  </span>
</div>
