import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Media } from '@shared/definitions';
import { PhotoUploadComponent } from '@digilize/shared/feature/components/src';

@Component({
  selector: 'bwh-lib-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent {
  @Input() form: FormGroup;
  @Input() editButton = true;
  @Input() editPhoto = true;
  @Input() cvSharing = false;
  @Output() editPersonalData = new EventEmitter();

  @ViewChild('file') file: ElementRef<HTMLInputElement>;
  @ViewChild(PhotoUploadComponent)
  private photoUploadComponent: PhotoUploadComponent;

  constructor() {}

  edit() {
    this.editPersonalData.emit();
  }

  triggerFileInput() {
    this.photoUploadComponent.inputFile.nativeElement.click();
  }

  fileChange($event: Media) {
    this.form.get('photo_id').setValue($event?.id);
    this.form.get('photo').setValue($event);
  }

  getFormControl(name: string) {
    return this.form.get(name) as FormControl;
  }
}
