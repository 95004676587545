import { Directive, ElementRef, HostListener, Output, EventEmitter, Inject, PLATFORM_ID, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  exceptionEl: HTMLElement | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, unknown>, private elementRef: ElementRef) {}

  @Input('exception') set Exception(el: HTMLElement) {
    this.exceptionEl = el;
  }

  @Output() appClickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseClick(targetElement) {
    if (isPlatformBrowser(this.platformId)) {
      const clickedInside =
        this.elementRef.nativeElement.contains(targetElement) || this.exceptionEl?.contains(targetElement);
      if (!clickedInside) {
        this.appClickOutside.emit(targetElement);
      }
    }
  }
}
