import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  InviteAfterRequestBody,
  InviteAfterResponse,
  PossibleApiPrefix,
  ResearchSurveyStart,
} from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ResearchSurveyApiService {
  constructor(private http: HttpClient) {}

  readonly BASE_RESEARCH_SURVEYS_URL = '/api/v1.0/research-survey';

  start = (queryParamString: string) => {
    return this.http.get<ResearchSurveyStart>(`${this.BASE_RESEARCH_SURVEYS_URL}/start${queryParamString}`);
  };

  inviteAfter = (inviteAfterBody: InviteAfterRequestBody) =>
    this.http.post<InviteAfterResponse>(`${this.BASE_RESEARCH_SURVEYS_URL}/invite`, inviteAfterBody);

  checkStatus(apiUrl: PossibleApiPrefix = 'api', sessionId: string) {
    return this.http.get<ResearchSurveyStart>(`/${apiUrl}/v1.0/research-survey/check`, {
      params: { id: sessionId },
    });
  }
}
