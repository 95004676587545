import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Category, MAX_WIDTH_LG } from '@shared/definitions';
import { addWebp } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-card-category',
  templateUrl: './card-category.component.html',
  styleUrls: ['../card.component.scss'],
})
export class CardCategoryComponent implements OnInit {
  @Input() category: Category;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onCardClick($event) {
    const isButton = ($event.target as HTMLElement).classList.contains('btn');
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth > MAX_WIDTH_LG && !isButton) {
      $event.preventDefault();
    } else {
      this.router.navigate(['/categorie', this.category.permalink]);
    }
  }

  get addWebp() {
    return addWebp();
  }
}
