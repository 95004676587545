<div class="dialog-panel">
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'auth.reset_password' | translate }}</h1>
      </div>
      <ng-container *ngIf="!resetSuccess; else resetInfo">
        <div class="dialog-body">
          <p class="info">
            {{ 'auth.reset_pwd_info' | translate }}
          </p>
          <div class="form-field-container pb-5">
            <div class="form-field-label">
              {{ 'form.field.email' | translate }}
            </div>
            <mat-form-field class="form-field" [floatLabel]="'never'">
              <input matInput formControlName="email" required />
              <mat-error *ngIf="getFormControl('email').invalid">
                <ng-container *ngIf="getFormControl('email').errors.required">
                  {{ 'form.error.required' | translate }}
                </ng-container>
                <ng-container *ngIf="getFormControl('email').errors.email">
                  {{ 'form.error.invalid_email' | translate }}
                </ng-container>
                <ng-container *ngIf="getFormControl('email').errors.no_user">
                  {{ 'form.error.no_user' | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="auth-other-actions mt-5">
            <button type="button" class="btn btn-text" (click)="handleToSignIn($event)">
              {{ 'auth.already_have_account' | translate }}
              <span>
                {{ 'auth.signin_here' | translate }}
              </span>
            </button>
          </div>
        </div>
        <div class="dialog-bottom">
          <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
            {{ 'global.reset' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #resetInfo>
        <div class="dialog-body">
          <p class="info text-center">
            {{ 'auth.check_your_email' | translate }}
          </p>
        </div>
      </ng-template>
    </form>
  </div>
</div>
