import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuizSessionAnswerRequest, QuizSessionState, ShopQuiz } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ShopQuizzesApiService {
  readonly shopQuizzesBaseUrl = '/api/v1.0/shop-quizzes';
  constructor(private http: HttpClient) {}

  getShopQuizzes = (params) => this.http.get(`${this.shopQuizzesBaseUrl}`, { params });

  getShopQuiz = (quizId) => this.http.get<ShopQuiz>(`${this.shopQuizzesBaseUrl}/${quizId}`);

  assignQuiz = (quiz) => this.http.post(`${this.shopQuizzesBaseUrl}`, quiz);

  editShopQuiz = (quiz) => this.http.put(`${this.shopQuizzesBaseUrl}`, quiz);

  removeShopQuiz = (params) => this.http.get(`${this.shopQuizzesBaseUrl}`, { params });

  updateShopQuizStatus = (params) => this.http.get(`${this.shopQuizzesBaseUrl}`, { params });

  getPublicShopQuiz = (id: string) => this.http.get<ShopQuiz>(`${this.shopQuizzesBaseUrl}/${id}/public`);

  playShopQuizStatus = (id, params: QuizSessionAnswerRequest) =>
    this.http.post<QuizSessionState>(`${this.shopQuizzesBaseUrl}/${id}/play`, params);
}
