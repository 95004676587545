<div class="container">
  <div class="close-icon" (click)="close()"><img src="/assets/icons/close-icon.svg" /></div>
  <div class="row m-0">
    <div class="col-12">
      <h2 class="page-title">{{ 'global.adjust_labels' | translate }}</h2>
      <h3 class="page-title">{{ 'global.adjust_labels_info' | translate }}</h3>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 middle-container">
      <mat-form-field class="chip-list" [floatLabel]="'always'">
        <mat-label>{{ 'global.active_labels' | translate }}</mat-label>
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let pickedOption of labels" (removed)="removePickedOption(pickedOption)">
            {{ pickedOption }}
            <button matChipRemove>
              <span class="icon-remove" [inlineSVG]="'/icons/icon-close-no-color.svg'"></span>
            </button>
          </mat-chip>
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 bottom-container">
      <div class="bottom-left">
        <span>{{ data.selectedNum }}</span> {{ 'global.candidates_selected' | translate }}
      </div>
      <div class="bottom-right">
        <ul>
          <li>
            <button class="btn btn-grey" (click)="close()">{{ 'global.cancel' | translate }}</button>
          </li>
          <li>
            <button class="btn btn-black" (click)="applyRemoved()">{{ 'global.confirm' | translate }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
