import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FloatLabelType } from '@angular/material/form-field';
import { Option } from '@digilize/shared/definitions/src';
import { createAndObserveIntersection } from '@digilize/shared/utils/helpers/src';
import { Subject, debounceTime, delay, distinctUntilChanged, map, startWith } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'lib-single-select-search',
  templateUrl: './single-select-search.component.html',
  styleUrls: ['./single-select-search.component.scss'],
})
export class SingleSelectSearchComponent implements OnInit, OnChanges, AfterViewInit {
  private inputValueChanged = new Subject<string>();
  filteredOptions: Observable<Option[]>;
  filterControl = new FormControl('');
  firstTime = false;
  autocompleteOpened = false;
  blockEmitScrolledToEnd = false;

  @Input() filterFn: (options: Option[]) => Option[] = this.defaultFilterFn;
  @Input() addDebounce: number;
  @Input() clearInput = false;
  @Input() waitForOptionsChange: boolean;
  @Input() setControlValue: boolean;
  @Input() suggestionsOff: boolean;
  @Input() optionByVal: boolean;
  @Output() changed = new EventEmitter<Option>();
  @Output() optionSelected = new EventEmitter<Option>();
  @Output() valueChanged = new EventEmitter<string>();
  @Output() valueSearchChanged = new EventEmitter<string>();
  @Output() inputFocused = new EventEmitter();
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @Input() options: Option[] = [];
  @Input() placeholder = '';
  @Input() iconPath: string;
  @Input() staticIcon = false;
  @Input() myFloatLabel: FloatLabelType;
  @Input() disabledTyping = false;
  @Input() wideClass: boolean;
  @Input() control: FormControl<string>;
  @Input() forceControl: boolean;
  @Input() forceControlByOptions: boolean;
  @Input() detectScrollToEnd = false;
  @Input() optionsListLength = 6;
  @Input() disableSearchOptionFiltering = false;
  @Input() customOption = false; // allow to add typed text as a custom option
  @ViewChild('endOfList') endOfList: ElementRef;
  @Output() scrolledToEnd = new EventEmitter<boolean>();
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.waitForOptionsChange) {
      // for preventing of emitting scrolledToEnd when option list is shorter then visible container
      if (changes.options.currentValue.length > this.optionsListLength) {
        this.blockEmitScrolledToEnd = false;
      }
      this.initControl();

      if (!(this.options.length === 1 && this.options[0].id === null)) {
        this.customOption = false;
      }
    }

    if (changes.options && this.setControlValue) {
      if (this.forceControlByOptions) {
        this.filterControl.setValue(this.control.value);
      } else {
        const selectedOption = this.options.find((option) => option.val === this.control.getRawValue());
        if (selectedOption) {
          this.filterControl.setValue(this.filterFn([selectedOption])[0].text);
        }
      }
    }
  }

  ngOnInit() {
    if (!this.waitForOptionsChange) {
      this.initControl();
    }

    this.setSelectedOption();

    if (this.addDebounce) {
      this.emitDebouncedTimeValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.detectScrollToEnd) {
      createAndObserveIntersection(this.endOfList)
        .pipe(delay(300))
        .subscribe((isVisible) => {
          if (isVisible && !this.blockEmitScrolledToEnd) {
            this.scrolledToEnd.emit(true);
          }
        });

      this.filterControl.valueChanges.subscribe(() => {
        this.blockEmitScrolledToEnd = true;
      });
    }
  }

  private initControl() {
    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(''),
      map((value) => (this.disableSearchOptionFiltering ? this.filter('') : this.filter(value || ''))),
      map((options) => this.filterFn(options))
    );
  }

  private setSelectedOption() {
    if (
      this.forceControl &&
      this.control !== undefined &&
      this.control.value !== null &&
      this.control.value !== undefined
    ) {
      this.filterControl.setValue(this.control.value);
    } else if (
      this.options.length > 0 &&
      this.control !== undefined &&
      this.control.value !== null &&
      this.control.value !== undefined
    ) {
      let selectedOption: any = [];
      if (this.optionByVal) {
        selectedOption = this.options.find((option) => option.val === this.control.getRawValue());
      } else {
        selectedOption = this.options.find((option) => option.text === this.control.getRawValue());
      }

      if (selectedOption) {
        this.filterControl.setValue(this.filterFn([selectedOption])[0].text);
      }
    }
  }

  private emitDebouncedTimeValue() {
    this.inputValueChanged.pipe(debounceTime(this.addDebounce), distinctUntilChanged()).subscribe((value: string) => {
      this.valueChanged.emit(value);
    });
  }

  clearSearchInput(): void {
    this.filterControl.setValue('');
    this.filterControl.markAsTouched();
    this.filterControl.markAsDirty();
    this.filterControl.reset();
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.blur();
    this.autocomplete.closePanel();
  }

  onInputValueChanged() {
    if (this.addDebounce) {
      this.inputValueChanged.next(this.searchInput.nativeElement.value);
    } else {
      setTimeout(() => this.valueChanged.emit(this.searchInput.nativeElement.value), 0);
    }
  }

  handleOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit(event.option.value);
  }

  onInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.valueSearchChanged.emit(inputValue);
    if (this.disabledTyping) {
      this.clearSearchInput();
    }
  }

  onInputFocus() {
    this.inputFocused.emit();
  }

  changeEmit(event: any, option) {
    if (event.isUserInput) {
      this.changed.emit(option);
    }
  }

  private filter(value: string): Option[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.text.toLowerCase().includes(filterValue));
  }

  defaultFilterFn(options: Option[]): Option[] {
    return options;
  }
}
