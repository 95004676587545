import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, AfterViewInit {
  @Input() length: number;
  @Input() disabled = false;
  _current: number;
  @Input() set current(curr: number) {
    this._current = curr > 100 ? 100 : curr;
    if (this.progress) this.calcProgress();
  }
  @Output() back: EventEmitter<number> = new EventEmitter();
  @ViewChild('progress') progress;
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.calcProgress();
  }

  calcProgress() {
    const width = `${(this._current / this.length) * 100}%`;
    this.renderer.setStyle(this.progress.nativeElement, 'width', width);
  }

  handleBack() {
    if (!this.disabled) {
      this.back.emit(this.current - 1);
    }
  }
}
