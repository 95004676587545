import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-form-quiz-dates',
  templateUrl: './form-quiz-dates.component.html',
  styleUrls: ['./form-quiz-dates.component.scss'],
})
export class FormQuizDatesComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() required: boolean;
  @Input() quiz: any;
  open = true;

  constructor() {}

  ngOnInit(): void {}

  getFormControl(name) {
    return this.form.get(name);
  }

  get quizMinDate() {
    return this.quiz && this.quiz.available_from ? new Date(this.quiz.available_from) : null;
  }

  get quizMaxDate() {
    return this.quiz && this.quiz.available_to ? new Date(this.quiz.available_to) : null;
  }
}
