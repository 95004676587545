<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open">
    <form [formGroup]="form" class="form-photos">
      <div class="form-field-container">
        <div class="form-field-label">
          <p>{{ 'form.field.select_location' | translate }}</p>
        </div>
        <lib-select
          [errors]="{ required: groupRequiredWhenNoDetails() }"
          [options]="companiesKeyValue"
          [value]="selectedCompanyLocation"
          (valueChange)="setLocation($event); detailsVisible = false"
        ></lib-select>
      </div>
      <mat-checkbox class="checkbox" (change)="toggleDetails($event.checked)" [checked]="detailsVisible">
        <span [innerHTML]="'form.checkboxes.other_address' | translate"></span>
      </mat-checkbox>

      <ng-container>
        <div class="details-container" [ngClass]="{ 'not-visible': !detailsVisible }">
          <div class="row" *ngFor="let field of locationFields">
            <div class="form-field-container col-12 col-sm-8">
              <!-- Label -->
              <div
                class="form-field-label"
                [ngClass]="{
                  required: isFormControlRequired(form.get(field))
                }"
              >
                {{ 'form.field.' + field | translate }}
              </div>
              <!-- Label -->

              <mat-form-field *ngIf="field !== 'country'; else country" class="form-field" [floatLabel]="'never'">
                <input matInput [formControlName]="field" />
                <mat-error *ngIf="form.get(field).invalid">
                  <ng-container *ngIf="form.get(field).errors.required">
                    {{ 'form.error.required' | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <ng-template #country>
                <lib-select
                  [value]="form.get(field).value"
                  [options]="keyValueCountries"
                  (valueChange)="form.get(field).setValue($event)"
                ></lib-select>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
