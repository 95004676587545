import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, CompanyMenu } from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService {
  constructor(private http: HttpClient) {}

  addCompany = (newCompany: Company) => this.http.post('/api/v1.0/companies', newCompany);

  updateCompany = (companyForm: Company) => this.http.put<Company>(`/api/v1.0/companies/`, companyForm);

  getCompanies = () => this.http.get('/api/v1.0/companies');

  getCompany = (companyId: string) => this.http.get(`/api/v1.0/companies/${companyId}`);

  getCompanyStyles = (companyId) => this.http.get(`/api/v1.0/companies/styles/${companyId}`);

  getCompanyStylesByDomain = () => this.http.get(`/api/v1.0/companies/styles`);

  getUsersPaged = (params) =>
    this.http.get(`/api/v1.0/companies/userspaged`, {
      params,
    });

  contactUsers = (req) => this.http.post('/api/v1.0/companies/users/contact', req);

  sendInvites = (req) => this.http.post('/api/v1.0/companies/invites', req);

  validatesInvites = (req) => this.http.post('/api/v1.0/companies/invites/validate', req);

  addLabelToUser = (companyId, params) => this.http.post(`/api/v1.0/companies/${companyId}/userlabels`, params);

  removeLabelsFromUser = (companyId, params) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/userlabels`, { body: params });

  addBulkLabelsToUser = (companyId, params) =>
    this.http.post(`/api/v1.0/companies/${companyId}/userlabels/bulk`, params);

  removeBulkLabelsFromUser = (companyId, params) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/userlabels/bulk`, { body: params });

  postOpenFormLink = (params) => this.http.post(`/api/v1.0/companies/open-form-link`, params);

  putOpenFormLink = (req: any) => this.http.put('/api/v1.0/companies/open-form-link', req);

  getOpenFormLinkByCompId = (companyId) => this.http.get(`/api/v1.0/companies/open-form-link/company/${companyId}`);

  checkOpenFormLink = (openFormId) => this.http.get(`/api/v1.0/companies/open-form-link/${openFormId}/active`);

  joinOpenFormLink = (openFormId, req?) => this.http.post(`/api/v1.0/companies/open-form-link/${openFormId}/join`, req);

  getCompanyUsers = (companyId: string, includeModerators?: boolean, searchValue?: string) => {
    let params = new HttpParams();
    params = params.set('companyId', companyId);
    if (includeModerators) {
      params = params.set('moderators', 'true');
    }
    if (searchValue) {
      params = params.set('search.value', searchValue);
    }
    return this.http.get(`/api/v1.0/companies/${companyId}/users`, { params });
  };

  inviteCompanyUser = (companyId: string, email: string) =>
    this.http.post(`/api/v1.0/companies/${companyId}/users`, { email, id: companyId });

  removeCompanyUser = (companyId: string, userID: string) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/users/${userID}`);

  removeInvitation = (companyId: string, email: string) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/invite?email=${email}`);

  acceptCompanyInvite = (inviteId: string) => this.http.post(`/api/v1.0/companies/invite/${inviteId}/accept`, {});

  getMenu = (companyId: string) => this.http.get<CompanyMenu>(`/api/v1.0/companies/${companyId}/menu`);
}
