<table [ngClass]="rowType">
  <thead>
    <ng-container *ngTemplateOutlet="headers || defaultHeaderTemplate; context: { $implicit: data }"></ng-container>
  </thead>
  <tbody [ngClass]="{ 'with-action': withAction }">
    <ng-container *ngTemplateOutlet="rows || defaultRowTemplate; context: { $implicit: rows }"></ng-container>
  </tbody>
</table>

<ng-template #defaultHeaderTemplate let-data>
  <th *ngFor="let header of data[0] | keyvalue">{{ header.key }}</th>
</ng-template>
<ng-template #defaultRowTemplate let-row>
  <td *ngFor="let row of row | keyvalue">{{ row.value }}</td>
</ng-template>
<div #endOfList></div>
