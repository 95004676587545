import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-loading-column-animation',
  templateUrl: './loading-column-animation.component.html',
  styleUrls: ['./loading-column-animation.component.scss'],
})
export class LoadingColumnAnimationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
