import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanyApiService } from '@digilize/shared/data/services';
import { AppConfig, Company } from '@shared/definitions';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { isFormControlRequired } from '@digilize/shared/utils/helpers';
import { APP_CONFIG } from '@digilize/shared/utils/tokens';

@Component({
  selector: 'lib-dialog-create-company',
  templateUrl: './dialog-create-company.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogCreateCompanyComponent implements OnInit {
  config: AppConfig;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    official_name: new UntypedFormControl(''),
    street: new UntypedFormControl(''),
    house_number: new UntypedFormControl(''),
    post_code: new UntypedFormControl(''),
    city: new UntypedFormControl(''),
    country: new UntypedFormControl('NL'),
    chamber_of_commerce_number: new UntypedFormControl(''),
    vatid: new UntypedFormControl(''),
    photo_id: new UntypedFormControl(''),
  });
  loading = false;
  appTitle;
  countries = [
    {
      key: 'Nederland',
      value: 'NL',
    },
  ];
  order = [
    'name',
    'photo_id',
    'official_name',
    'street',
    'house_number',
    'post_code',
    'city',
    'country',
    'chamber_of_commerce_number',
    'vatid',
  ];
  showGobackBtn = false;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private companyApiService: CompanyApiService,
    private translateService: TranslateService,
    private alertService: AlertService
  ) {
    this.config = appConfig;
  }

  ngOnInit(): void {
    this.appTitle = this.appConfig.appTitle;
    this.setForm();
    if (this.data) {
      this.showGobackBtn = this.data.showGobackBtn;
    }
  }

  setForm() {
    // Some of fields are required but only in Flixit configuration
    if (this.appConfig.app === 'flixit') {
      const required = ['street', 'house_number', 'post_code', 'city', 'country'];
      required.forEach((key) => {
        this.getFormControl(key).setValidators([Validators.required]);
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    const newCompany: Company = this.form.getRawValue();
    this.loading = true;
    this.companyApiService.addCompany(newCompany).subscribe(
      (response: any) => {
        this.closeDialog();
        window.location.href = `/admin/c/${response.id}`;
      },
      (error) => {
        this.alertService.handleApiError(error.error.error);
        this.loading = false;
      }
    );
  }

  goToLoginPage() {
    this.dialogRef.close('logout');
  }

  getTranslation(key): string {
    return this.translateService.instant(key);
  }

  fileChange($event) {
    this.form.get('photo_id').setValue($event?.id);
  }

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }

  countryValueChange($event) {
    this.getFormControl('country').setValue($event);
  }
}
