export const auctionLocationSelectOptions = [
  {
    key: 'Boschoord',
    value: 'Boschoord',
  },
  {
    key: 'Darp',
    value: 'Darp',
  },
  {
    key: 'Diever',
    value: 'Diever',
  },
  {
    key: 'Dieverbrug',
    value: 'Dieverbrug',
  },
  {
    key: 'Doldersum',
    value: 'Doldersum',
  },
  {
    key: 'Dwingeloo',
    value: 'Dwingeloo',
  },
  {
    key: 'Eemster',
    value: 'Eemster',
  },
  {
    key: 'Frederiksoord',
    value: 'Frederiksoord',
  },
  {
    key: 'Geeuwenbrug',
    value: 'Geeuwenbrug',
  },
  {
    key: 'Havelte',
    value: 'Havelte',
  },
  {
    key: 'Havelterberg',
    value: 'Havelterberg',
  },
  {
    key: 'Leggeloo',
    value: 'Leggeloo',
  },
  {
    key: 'Lhee',
    value: 'Lhee',
  },
  {
    key: 'Lheebroek',
    value: 'Lheebroek',
  },
  {
    key: 'Nijensleek',
    value: 'Nijensleek',
  },
  {
    key: 'Oldendiever',
    value: 'Oldendiever',
  },
  {
    key: 'Oude Willem',
    value: 'Oude Willem',
  },
  {
    key: 'Uffelte',
    value: 'Uffelte',
  },
  {
    key: 'Vledder',
    value: 'Vledder',
  },
  {
    key: 'Vledderveen',
    value: 'Vledderveen',
  },
  {
    key: 'Wapse',
    value: 'Wapse',
  },
  {
    key: 'Wapserveen',
    value: 'Wapserveen',
  },
  {
    key: 'Wateren',
    value: 'Wateren',
  },
  {
    key: 'Wilhelminaoord',
    value: 'Wilhelminaoord',
  },
  {
    key: 'Wittelte',
    value: 'Wittelte',
  },
  {
    key: 'Zorgvlied',
    value: 'Zorgvlied',
  },
  {
    key: 'Niet in Westerveld',
    value: 'Niet in Westerveld',
  },
];

export const auctionTypeSelectOptions = [
  {
    key: 'global.all',
    value: 0,
  },
  {
    key: 'marketplace.auction_type.item_sell',
    value: 1,
  },
  {
    key: 'marketplace.auction_type.item_search',
    value: 2,
  },
  {
    key: 'marketplace.auction_type.service_offer',
    value: 3,
  },
  {
    key: 'marketplace.auction_type.service_needed',
    value: 4,
  },
  {
    key: 'marketplace.auction_type.item_rental',
    value: 5,
  },
  {
    key: 'marketplace.auction_type.trade',
    value: 6,
  },
];
