import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";
export const ALERT_429: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'error.type.too_many_requests',
    translated: true,
  },
};
