import { Media } from './Media';

export interface Quiz {
  available_from: string;
  available_to: string;
  description: string;
  description_company: string;
  description_long: string;
  id: string;
  liked: string;
  name: string;
  photo: Media;
  photo_id: string;
  published: string;
  questions: string;
  rules: any;
  isSelected?: boolean;
  showTrimmedDescription: boolean;
  tenant_id: string;
}
