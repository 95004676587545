import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'libsGetJobNameByIndex',
})
export class GetJobNameByIndexPipe implements PipeTransform {
  transform(index: number, jobs: any[], direction?: string): unknown {
    if (direction === 'next') {
      return jobs[++index]?.job;
    }
    if (direction === 'prev' && index > 0) {
      return jobs[--index]?.job;
    }
  }
}
