import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SurveyPage, SurveyPageWithResearchData } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class PagesApiService {
  readonly BASE_HREF = '/api/v1.0/pages';
  readonly BASE_HREF_V2 = '/api/v2.0/pages';

  constructor(private http: HttpClient) {}

  getByResearch() {
    return this.http.get<{ data: Omit<SurveyPageWithResearchData, 'researches'>[] }>(`${this.BASE_HREF}/by-research`);
  }

  getByResearchV2() {
    return this.http.get<{ data: SurveyPage[] }>(`${this.BASE_HREF_V2}/by-research`);
  }
}
