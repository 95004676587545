<div class="spinner-container p-absolute" *ngIf="loading">
  <mat-spinner class="primary" [diameter]="30"></mat-spinner>
</div>
<div *ngIf="!loading" class="page page-error">
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-12 align-self-center">
        <h1 class="title">
          <span class="code">404</span>
          {{ 'global.not_found' | translate }}
        </h1>
        <div class="info">
          <div class="d-block" *ngIf="url">
            {{ url }}
          </div>
          <div class="d-block" *ngIf="requestId">request_id: {{ requestId }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
