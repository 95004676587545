<div class="my-profile-container" [ngClass]="{ 'popup-opened': popupClass }">
  <div class="menu">
    <div class="logo">
      <img src="/assets/icons/Skilld_logo_pos.svg" />
    </div>
    <ul>
      <li>{{ 'global.my_profile' | translate }}</li>
      <!-- <li routerLink="./professions" routerLinkActive="active">{{ 'global.my_professions' | translate }}</li>
      <li routerLink="./vacancies" routerLinkActive="active">{{ 'global.my_vacanies' | translate }}</li> -->
    </ul>
    <lib-lang-droplist
      class="lang-switcher"
      [noNative]="true"
      [dropdownIconsPath]="'/icons/icon-arrow-b-'"
    ></lib-lang-droplist>
    <bwc-dropdown-menu></bwc-dropdown-menu>
  </div>

  <div class="page-container">
    <router-outlet></router-outlet>
  </div>
</div>
