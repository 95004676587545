<div class="card card-event" *ngIf="event">
  <div class="row m-0">
    <div class="card-event-label" *ngIf="event.is_recurring">
      <span>{{ 'global.multiple_days' | translate }}</span>
    </div>
    <div
      *ngIf="event?.photo?.url1x; else bannerImage"
      class="event-background"
      [ngStyle]="{
        'background-image': 'url(' + event.photo.url1x + '?w=500&h=370&m=2' + addWebp + ')'
      }"
    ></div>
    <ng-template #bannerImage>
      <div
        *ngIf="event?.banner?.url1x; else noImage"
        class="event-background"
        [ngStyle]="{
          'background-image': 'url(' + event.banner.url1x + '?w=500&h=370&m=2' + addWebp + ')'
        }"
      ></div>
    </ng-template>
    <ng-template #noImage>
      <div class="event-background">
        <span class="icon" [inlineSVG]="'/icons/event.svg'"></span>
      </div>
    </ng-template>
  </div>
  <div class="row m-0">
    <div class="card-event-body d-flex flex-column">
      <div class="m-0 p-3 w-100">
        <p class="card-title dot mb-1">
          <span class="font-weight-bold title hyphenate-container hyphenate" lang="nl">{{ event.name }}</span>
        </p>
        <p *ngIf="event.external_types" class="mb-1 icon-container">
          <lib-event-icon *ngFor="let type of event.external_types?.split('|')" [eventType]="type"></lib-event-icon>
          &nbsp;
        </p>
        <p class="mb-1" *ngIf="event.street && event.city">
          <span class="icon" [inlineSVG]="'/icons/no-stroke-icon-address.svg'"></span>
          {{ event.street }}
          <ng-container *ngIf="event.house_number">{{ event.house_number }}</ng-container
          >, {{ event.city }}
        </p>
        <p class="font-weight-bold date mb-1">
          <span *ngIf="!isSameDay"
            >{{ event.available_from | date: 'dd MMM yyyy HH:mm' }}
            <ng-container *ngIf="event.available_to">
              -
              {{ event.available_to | date: 'dd MMM yyyy HH:mm' }}</ng-container
            >
          </span>
          <span *ngIf="isSameDay">
            {{ event.available_from | date: 'dd MMM yyyy HH:mm' }}
            <ng-container *ngIf="event.available_to"> - {{ event.available_to | date: 'HH:mm' }}</ng-container>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
