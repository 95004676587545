import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { I18nService } from '@digilize/shared/feature/modules/i18n/src';
import { TranslateService } from '@ngx-translate/core';
import { PageType } from '@shared/definitions';
import { Subject } from 'rxjs';

import { AuthService } from '../auth.service';

@Component({
  selector: 'lib-dialog-pages-info',
  templateUrl: './dialog-pages-info.component.html',
  styleUrls: ['./dialog-pages-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogPagesInfoComponent implements OnInit {
  takeUntil$ = new Subject();
  shopId: string;

  constructor(
    public dialogRef: MatDialogRef<DialogPagesInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private translateService: TranslateService,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    if (this.data.shopId) {
      this.shopId = this.data.shopId;
    }
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  pagesRedirect(pageType: string) {
    this.authService.pagesRedirect(this.shopId, pageType as PageType);
  }
}
