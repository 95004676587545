import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FloatLabelType } from '@angular/material/form-field';
import { WIDTH_MD } from '@shared/definitions';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() options: SelectionOption[] = [];
  @Input() groups: Record<string, { key: string; value: string; obj: any }[]>;
  @Input() value;
  @Input() errors;
  @Input() disabled = false;
  @Input() dropdownIconsPath: string;
  @Input() translation: boolean;
  @Input() translationPrefix: string;
  @Input() placeholder = 'global.select';
  @Input() customPanelClass: string;
  @Input() noInfix = false;
  @Input() withTrigger = false;
  @Input() stayOpenAfterPick = false;
  @Input() optionImgWidth = '30px';
  @Input() noBackground = false;
  @Input() noUnderline = true;
  @Input() label = null;
  @Input() requiredInLabel = false;
  @Input() translateLabel = false;
  @Input() required = false;
  @Input() offsetToLeft = false;
  @Input() myFloatLabel: FloatLabelType;
  @Input() noNative: boolean;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() openedChange = new EventEmitter<boolean>();
  isContentProjected = true;
  native;

  constructor() {}

  ngOnInit(): void {
    if (this.noNative) {
      this.native = false;
    } else {
      this.native = this.isNative();
    }
  }

  isNative(): boolean {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return windowWidth < WIDTH_MD ? true : false;
  }

  onOpenedChange(event) {
    this.openedChange.emit(event);
  }

  onValueChange($event, native?) {
    this.valueChange.emit(native ? $event.target.value : $event.value);
  }

  unsorted(a: KeyValue<string, string>, b: KeyValue<string, string>) {
    return 0;
  }
}

export interface SelectionOption {
  key: string;
  value: any;
  img?: string;
}
