import { Alert, AlertType } from './alerts.interface';

export interface ApiError {
  type: string;
  request_id?: string;
  info?: {
    kind?: string;
    field?: string;
  };
}

export const ALERT_500: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.internal_server_error',
    translated: true,
  },
};

export const ALERT_429: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'error.type.too_many_requests',
    translated: true,
  },
};

export const ALERT_403: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'error.type.login_again',
    translated: true,
  },
};

// Custom alerts

export const ALERT_UPDATE_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.update_success',
    translated: true,
  },
};

export const ALERT_INVITE_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.invite_sent',
    translated: true,
  },
};

export const ALERT_REQUEST_SENT: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.request_sent',
    translated: true,
  },
};

export const ALERT_CAMPAIGN_BUY_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.campaign_buy_success',
    translated: true,
  },
};

export const ALERT_POST_SAVED: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.post_saved',
    translated: true,
  },
  time: 2000,
};

export const ALERT_POST_UPDATED: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.post_updated',
    translated: true,
  },
  time: 2000,
};

export const ALERT_CUSTOM_POST_ADDED: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.custom_post_added',
    translated: true,
  },
  time: 2000,
};

export const ALERT_TIMESLOT_OPENING_HOURS: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.opening_hours_required_to_set_timeslot',
    translated: true,
  },
};

export const ALERT_MAX_CAMPAIGNS: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.max_campaigns_reached',
    translated: true,
  },
};

export const ALERT_PRODUCT_REQUEST_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.product_request_success',
    translated: true,
  },
};

export const ALERT_MARKETPLACE_ITEM_ADD_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.marketplace_item_add_success',
    translated: true,
  },
};

export const ALERT_MARKETPLACE_BID_ADD_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.bid_added',
    translated: true,
  },
};

export const ALERT_MARKETPLACE_ITEM_EDIT_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.marketplace_item_add_success',
    translated: true,
  },
};

export const ALERT_MARKETPLACE_BID_REMOVE_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.bid_removed',
    translated: true,
  },
};

export const ALERT_MESSAGE_SENT_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.message_sent',
    translated: true,
  },
};

export const ALERT_HIGHLIGHTED_WARNING: Alert = {
  type: AlertType.Warning,
  title: {
    message: 'Warning',
    translated: false,
  },
  message: {
    message: 'custom.highlighted_warning',
    translated: true,
  },
};

export const ALERT_SUBSCRIPTION_INACTIVE_SHOP: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.subscription_inactive_shop',
    translated: true,
  },
};

export const ALERT_POST_INACTIVE_SHOP: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.post_inactive_shop',
    translated: true,
  },
};

export const ALERT_IMPORT_USERS_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.import_users_success',
    translated: true,
  },
};

export const ALERT_DELETE_USERS_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.delete_users_success',
    translated: true,
  },
};

export const ALERT_DELETE_USER_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.delete_user_success',
    translated: true,
  },
};

export const ALERT_EXPORT_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.export_success',
    translated: true,
  },
};

export const ALERT_SEND_INVITATIONS_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.send_invitations_success',
    translated: true,
  },
};

export const ALERT_REMOVE_USER_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.remove_user_success',
    translated: true,
  },
};

// SHOWS
export const ALERT_REMOVE_SHOW_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.remove_show_success',
    translated: true,
  },
};

export const ALERT_ADD_SHOW_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.add_show_success',
    translated: true,
  },
};

// LOOKS

export const ALERT_REMOVE_LOOK_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.remove_look_success',
    translated: true,
  },
};

export const ALERT_ADD_LOOK_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.add_look_success',
    translated: true,
  },
};

export const ALERT_REMOVE_LOOK_PRODUCT_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.remove_look_product_success',
    translated: true,
  },
};

export const ALERT_ADD_LOOK_PRODUCT_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.add_look_product_success',
    translated: true,
  },
};

export const IMPORT_PRODUCTS_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.import_products_success',
    translated: true,
  },
};

export const ALERT_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'Success',
    translated: false,
  },
};

export const ALERT_ERROR: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'Error',
    translated: false,
  },
};
