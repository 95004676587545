export interface Media {
  alt: string;
  extension: string;
  height: number;
  id: string;
  inserted?: string;
  is_extension_image: boolean;
  is_extension_vector: boolean;
  name: string;
  name_without_extension: string;
  title: string;
  url1x: string;
  url2x: string;
  url3x: string;
  width: number;
  order: number;
  hash: string;
}
