<div class="have-resume-step" [@routeAnimations]="animationState">
  <ng-container [ngSwitch]="step">
    <div class="animation-step">
      <bwc-upload-your-cv
        *ngSwitchCase="1"
        [openFormLink]="openFormLink"
        [inviteLink]="inviteLink"
        [vacancyId]="vacancyId"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (nextStep)="changeStep($event)"
      ></bwc-upload-your-cv>
    </div>
    <div class="animation-step">
      <bwc-uploaded-cv
        *ngSwitchCase="2"
        [openFormLink]="openFormLink"
        [inviteLink]="inviteLink"
        [vacancyId]="vacancyId"
        [prevBut]="true"
        [closeBut]="false"
        [uploadData]="uploadData"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
      ></bwc-uploaded-cv>
    </div>
    <div class="animation-step">
      <bwc-general-information
        *ngSwitchCase="3"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-general-information>
    </div>
    <div class="animation-step">
      <bwc-select-your-job
        *ngSwitchCase="4"
        [itemClickable]="true"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-select-your-job>
    </div>
    <div class="animation-step">
      <bwc-add-your-education
        *ngSwitchCase="5"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-add-your-education>
    </div>
    <div class="animation-step">
      <bwc-soft-skills
        *ngSwitchCase="6"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-soft-skills>
    </div>
    <div class="animation-step">
      <bwc-professional-skills
        *ngSwitchCase="7"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-professional-skills>
    </div>
    <div class="animation-step">
      <bwc-it-skills
        *ngSwitchCase="8"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-it-skills>
    </div>
    <div class="animation-step">
      <bwc-tasks
        *ngSwitchCase="9"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-tasks>
    </div>
    <div class="animation-step">
      <bwc-languages-knowledge
        *ngSwitchCase="10"
        [prevBut]="true"
        [closeBut]="false"
        [previousStep]="previousStep"
        [currentStep]="step"
        [steps]="allSteps"
        (dataStep)="changeStep($event)"
        class="fixed-size"
      ></bwc-languages-knowledge>
    </div>
  </ng-container>
</div>
