import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ShopApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { TranslateService } from '@ngx-translate/core';
import { Alert, AlertType, Page, PageType } from '@shared/definitions';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-dialog-pages',
  templateUrl: './dialog-pages.component.html',
  styleUrls: ['./dialog-pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogPagesComponent implements OnInit {
  takeUntil$ = new Subject();

  loading = true;
  pageType: PageType;
  shopId;
  pages: Page[] = [];
  pagesSelect: any[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({
    page: new UntypedFormControl('', Validators.required),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogPagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shopApiService: ShopApiService,
    private alertService: AlertService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initPages();
  }

  initPages() {
    if (this.data.pages && this.data.shopId && this.data.pageType) {
      this.shopId = this.data.shopId;
      this.pages = this.data.pages;
      this.pageType = this.data.pageType;
      this.pages.forEach((obj) => {
        if (this.pageType === PageType.Facebook) {
          this.pagesSelect.push({
            key: obj.facebook_page_name,
            value: obj.facebook_page_id,
            img: obj.facebook_page_picture_url,
          });
        } else if (this.pageType === PageType.Instagram) {
          this.pagesSelect.push({
            key: obj.instagram_page_name,
            value: obj.instagram_page_id,
            img: obj.instagram_page_picture_url,
          });
        }
      });
    }

    if (this.pages && this.data.shopId) {
      if (this.pages.length === 1) {
        // Get 1st page if there's only one
        if (this.pageType === PageType.Facebook) {
          this.form.get('page').setValue(this.pages[0].facebook_page_id);
        } else if (this.pageType === PageType.Instagram) {
          this.form.get('page').setValue(this.pages[0].instagram_page_id);
        }
        this.onSubmit();
      } else if (this.pages.length === 0) {
        // Reset pages when there's no pages
        this.onSubmit(true);
      } else {
        // Display select if there are multiple pages
        this.loading = false;
      }
    }
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit(reset?: boolean) {
    this.loading = true;
    const returnUrl = localStorage.getItem('returnUrl').replace('/admin', '');
    let page: Page;
    if (reset) {
      page = {
        facebook_user_access_token: null,
        facebook_page_access_token: null,
        facebook_page_name: null,
        facebook_page_id: null,
        facebook_page_picture_url: null,
        instagram_page_id: null,
        instagram_page_username: null,
        instagram_page_name: null,
        instagram_page_picture_url: null,
      };
    } else {
      if (this.pageType === PageType.Facebook) {
        page = this.pages.find((obj) => obj.facebook_page_id === this.form.get('page').value);
      } else if (this.pageType === PageType.Instagram) {
        page = this.pages.find((obj) => obj.instagram_page_id === this.form.get('page').value);
      }
    }

    this.shopApiService.connectShopToPage(this.shopId, page, this.pageType).subscribe(
      (response) => {
        this.router.navigate([returnUrl]);
        this.closeDialog();

        if (reset) {
          // Empty list of pages and success request
          const alert: Alert = {
            type: AlertType.Warning,
            title: {
              message: 'Warning',
              translated: false,
            },
            message: {
              message: this.translateService.instant('social_media_connection.no_page_connected', {
                pageType: this.pageType,
              }),
              translated: false,
            },
          };
          this.alertService.setAlert(alert);
        }
      },
      (error) => {
        this.router.navigate([returnUrl]);
        this.handleError(error.error.error);
        this.closeDialog();
      }
    );
  }

  handleError(error: SocialError) {
    let message;

    if (error.info?.kind === 'token_invalid') {
      // Invalid token
      message = this.translateService.instant('social_media_connection.token_expired', { pageType: this.pageType });
    } else if (error.info?.missing?.length === 1 && error.info?.missing[0] === 'instagram_content_publish') {
      // Instagram content publish is only missing permission
      message = this.translateService.instant('social_media_connection.no_instagram_content_publish', {
        pageType: this.pageType,
      });
    } else if (error.info?.missing?.length > 0) {
      // Multiple permissions not granted
      message = this.translateService.instant('social_media_connection.multiple_permissions_not_granted', {
        permissions: error.info?.missing.toString(),
        pageType: this.pageType,
      });
    }

    if (message) {
      const alert: Alert = {
        type: AlertType.Warning,
        title: {
          message: 'Warning',
          translated: false,
        },
        message: {
          message: message,
          translated: false,
        },
      };
      this.alertService.setAlert(alert);
    } else {
      // For some other error
      this.alertService.handleApiError(error);
    }
  }
}

export interface SocialError {
  info: {
    field?: string;
    got?: string[];
    kind?: string;
    missing?: string[];
    required?: string[];
  };
  request_id: string;
  type: string;
}
