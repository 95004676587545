<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div [ngClass]="{ 'v-hidden': loading }">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'global.not_logged_in' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <div class="auth-other-actions mb-5">
          <div class="d-block">
            {{ 'auth.already_have_account' | translate }}
          </div>
          <div class="d-block text-center mt-2">
            <button class="btn btn-secondary" (click)="openDialog('signin')">
              {{ 'global.signin' | translate }}
            </button>
          </div>
        </div>
        <div class="auth-other-actions mb-4">
          <div class="d-block">
            {{ 'auth.dont_have_account' | translate }}
          </div>
          <div class="d-block text-center mt-2">
            <button class="btn btn-secondary" (click)="openDialog('signup')">
              {{ 'global.signup' | translate }}
            </button>
          </div>
        </div>
        <div class="auth-other-actions" *ngIf="data?.guest">
          <div class="d-block or">
            {{ 'global.or' | translate }}
          </div>
          <div class="d-block">
            <button type="button" class="btn btn-text" (click)="redirect()">
              {{ 'auth.continue_as_guest' | translate }}
              <span>
                {{ 'auth.click_here' | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
