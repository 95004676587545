import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { AuthService, SignInUpComponent } from '@digilize/shared/feature/modules/auth/src';
import { DialogService } from '@digilize/shared/utils/services/src';
import { APP_CONFIG, ENV_CONFIG } from '@digilize/shared/utils/tokens';
import { ALERT_403, ALERT_429, ALERT_500, AppConfig, EnvConfig, PossibleApiPrefix } from '@shared/definitions';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

// import { DialogService } from './dialog.service';
/*
Interceptor
- working for all /api/* requests
- handling  500, 429 errors
- proxy to API host
- token injection

For testing http erros use:
const dupReq = request.clone({ url: `http://httpstat.us/500` });
*/

@Injectable()
export class SurveyApiInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvConfig,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    public authService: AuthService,
    private alertService: AlertService,
    private dialogService: DialogService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/api/') > -1 || request.url.indexOf('/api2/') > -1) {
      return this.setRequest(request, next).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status >= 500 && error.status < 600) {
            this.alertService.setAlert(ALERT_500);
          } else if (error.status === 429) {
            this.alertService.setAlert(ALERT_429);
          } else if (error.status === 403) {
            if (
              !this.authService.isLoggedIn() &&
              !request.url.includes('password-change') && // Wrong password when changing password
              !request.url.includes('sessions') && // Claiming session
              !request.url.includes('auth') && // Wrong password in login
              !this.appConfig?.disabledAuthPopup
            ) {
              this.alertService.setAlert(ALERT_403);
              this.dialogService.openDialog(SignInUpComponent);
            }
          }
          return throwError(error);
        })
      );
    }

    return next.handle(request);
  }

  setRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Set proxy for all requests
    const apiType = this.getApiType(request.url);
    request = this.setProxy(request, apiType);
    if (!request) {
      return next.handle(request);
    }

    // Don't use interceptor for refresh and token
    if (request.url.indexOf('auth') !== -1) {
      return next.handle(request);
    }
    const accessExpired = !this.authService.getAccessToken(apiType);
    const refreshExpired = !this.authService.getRefreshToken(apiType);

    // No tokens valid
    if (accessExpired && refreshExpired) {
      return next.handle(request);
    }

    // Access token expired - need to refresh token
    if (accessExpired && !refreshExpired) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.authService.refreshAccessTokenManualRetry(apiType).pipe(
          switchMap((refresh) => {
            this.refreshTokenInProgress = false;
            if (refresh) {
              this.refreshTokenSubject.next(refresh.access);
            }
            return next.handle(this.injectToken(request, apiType));
          })
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request, apiType));
          })
        );
      }
    }

    // Access token valid
    if (!accessExpired) {
      return next.handle(this.injectToken(request, apiType));
    } else {
      return next.handle(request);
    }
  }

  injectToken(request: HttpRequest<any>, apiType: PossibleApiPrefix) {
    const token = this.authService.getAccessToken(apiType);
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  setProxy(request: HttpRequest<any>, apiType: PossibleApiPrefix) {
    if (!apiType) return request;
    return request.clone({
      // url: 'https://httpstat.us/403' // Use for testing error codes
      url:
        this.envConfig.production || this.envConfig.staging
          ? `${this.envConfig[apiType]}${request.url.replace(`/${apiType}`, '')}`
          : request.url,
    });
  }

  getApiType(url: string): PossibleApiPrefix | undefined {
    if (url.split('/').some((urlPart) => urlPart === 'api')) {
      return 'api';
    }
    if (url.split('/').some((urlPart) => urlPart === 'api2')) {
      return 'api2';
    }

    return undefined;
  }
}
