import { Injectable } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CompanyApiService } from './company-api.service';
import { TagsApiService } from './tags-api.service';
import { GroupsApiService } from './groups-api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private companyId: string;
  private usersList: any[] = [];
  private USERS_PER_FETCH: number;
  private GROUPS_PER_FETCH: number;

  constructor(
    private companyApiService: CompanyApiService,
    private tagsApiService: TagsApiService,
    private groupsApiService: GroupsApiService
  ) {}

  getCompanyGroups(overrideProps: Object = {}): Observable<any> {
    return this.groupsApiService.getCompanyUserGroups({
      company: this.companyId,
      length: this.GROUPS_PER_FETCH,
      start: this.usersList?.length || 0,
      ...overrideProps,
    });
  }

  getCompanyLabels(overrideProps: Object = {}): Observable<any> {
    return this.tagsApiService.getTags({
      company: this.companyId,
      tag_type: 0,
      ...overrideProps,
    });
  }

  getUsers(overrideProps: Object = {}): Observable<any> {
    return this.companyApiService.getUsersPaged({
      company: this.companyId,
      Users: true,
      Moderators: false,
      length: this.USERS_PER_FETCH,
      start: this.usersList?.length || 0,
      ...overrideProps,
    });
  }

  selectAll(dataArray: FormArray, selectedAll: boolean): void {
    dataArray.controls.forEach((control) => control.get('isSelected').setValue(!selectedAll));
  }

  clearFormArray(formArray: FormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  addItem(dataArray: FormArray, item: any): void {
    const itemFormGroup = new UntypedFormGroup({
      isSelected: new UntypedFormControl(item.isSelected),
      id: new UntypedFormControl(item.id),
      invitation_id: new UntypedFormControl(item.invitation_id),
      inserted: new UntypedFormControl(item.inserted),
      labels: new UntypedFormControl(item.labels),
      location: new UntypedFormControl(item.location),
      language: new UntypedFormControl(item.language),
      first_name: new UntypedFormControl(item.first_name),
      last_name: new UntypedFormControl(item.last_name),
      notes_count: new UntypedFormControl(item.notes_count),
      phone: new UntypedFormControl(item.phone),
      email: new UntypedFormControl(item.email),
      status: new UntypedFormControl(item.status),
    });

    dataArray.push(itemFormGroup);
  }

  setCompanyId(companyId: string): void {
    this.companyId = companyId;
  }

  setUsersList(usersList: any[]): void {
    this.usersList = usersList;
  }

  setPerFetch(perFetch: number): void {
    this.USERS_PER_FETCH = perFetch;
  }

  setGroupsPerFetch(perFetch: number): void {
    this.GROUPS_PER_FETCH = perFetch;
  }
}
