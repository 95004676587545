import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minArrayLengthValidator(minLength: number, customParam?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const param = customParam ? customParam : 'minArrayLength';
    if (control && control.value && control.value.length < minLength) {
      return { [param]: true };
    }
    return null;
  };
}
