<div class="panel panel-form panel-form-delivery" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="row" *ngFor="let mainKey of order.main">
        <div class="col-12 col-xl-auto col-main-checkbox form-field-container">
          <mat-checkbox
            class="checkbox"
            [formControl]="getFormControl(mainKey)"
            (change)="clearForm(mainKey, $event.checked)"
          >
            {{ 'form.field.' + mainKey | translate }}
          </mat-checkbox>
        </div>
        <div class="col-12 col-xl">
          <div class="row">
            <div
              class="col-12 col-md-6 col-xl-4 form-field-container"
              *ngFor="let key of order.costs"
              [ngClass]="{ inactive: isInactive(mainKey + key) }"
            >
              <div class="form-field-label">
                {{ 'form.field.' + mainKey + key | translate }}
                <ng-container *ngIf="key.includes('min') || key.includes('max')">
                  <div
                    class="i-icon"
                    #tooltip="matTooltip"
                    [matTooltip]="'tooltip.shop.' + mainKey + key | translate"
                    [matTooltipPosition]="'above'"
                    (click)="tooltip.toggle()"
                  >
                    <span class="i">i</span>
                  </div>
                </ng-container>
              </div>
              <mat-form-field class="form-field form-field-currency" [floatLabel]="'never'">
                <span class="currency">€</span>
                <input
                  matInput
                  currencyMask
                  [formControlName]="mainKey + key"
                  [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }"
                  [readonly]="isInactive(mainKey + key)"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row" [ngClass]="{ inactive: isInactive(mainKey) }">
            <div class="col-12 mb-0 form-field-container">
              <mat-checkbox class="checkbox m-0" [formControl]="getFormControl(mainKey + '_allow_pay_at')">
                {{ 'form.field.' + mainKey + '_allow_pay_at' | translate }}
                <div
                  class="i-icon"
                  #tooltip="matTooltip"
                  [matTooltip]="'tooltip.shop.' + mainKey + '_allow_pay_at' | translate"
                  [matTooltipPosition]="'above'"
                  (click)="tooltip.toggle()"
                >
                  <span class="i">i</span>
                </div>
              </mat-checkbox>
            </div>
          </div>

          <div class="row row-minus-margin" [ngClass]="{ inactive: isInactive(mainKey) }">
            <ng-container *ngFor="let key of order.other">
              <div class="col-12 col-xl-4 form-field-container" *ngIf="key === '_timeslot'">
                <mat-checkbox
                  class="checkbox"
                  [formControl]="getFormControl(mainKey + key)"
                  (change)="clearTimeslots(mainKey, $event.checked)"
                >
                  {{ 'form.field.' + mainKey + key | translate }}
                  <div
                    class="i-icon"
                    #tooltip="matTooltip"
                    [matTooltip]="'tooltip.shop.' + mainKey + key | translate"
                    [matTooltipPosition]="'above'"
                    (click)="tooltip.toggle()"
                  >
                    <span class="i">i</span>
                  </div>
                </mat-checkbox>
              </div>

              <div
                class="col-md-6 col-xl-4 form-field-container align-self-center"
                *ngIf="key === '_timeslot_interval' && getFormControl(mainKey + '_timeslot').value"
              >
                <div class="form-field-label">
                  {{ 'form.field.timeslot_interval' | translate }}
                </div>
                <lib-select
                  [options]="shopTimeSlots"
                  [value]="getFormControl(mainKey + key).value"
                  (valueChange)="getFormControl(mainKey + key).setValue($event)"
                ></lib-select>
              </div>

              <div
                class="col-md-6 col-xl-4 form-field-container align-self-center"
                *ngIf="key === '_timeslot_grace_period_minutes' && getFormControl(mainKey + '_timeslot').value"
              >
                <div class="form-field-label">
                  {{ 'form.field.' + mainKey + key | translate }}
                </div>
                <mat-form-field class="form-field form-field-min" [floatLabel]="'never'">
                  <span class="min">min</span>
                  <input matInput type="number" [formControlName]="mainKey + key" />
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-auto col-main-checkbox form-field-container">
          <mat-checkbox
            class="checkbox"
            [formControl]="getFormControl('digital') | formControl"
            (change)="handleDigitalCostChange($event.checked)"
          >
            {{ 'form.field.' + 'digital' | translate }}
          </mat-checkbox>
        </div>
      </div>
    </form>
  </div>
</div>
