import { Directive, Input } from '@angular/core';

/* eslint-disable @angular-eslint/no-input-rename */
interface TableHeaderTemplateContext<TItem extends object> {
  $implicit: TItem[];
}
@Directive({
  selector: 'ng-template[libTableHeader]',
})
export class TableHeaderTemplateDirective<TItem extends object> {
  @Input('libTableHeader') data!: TItem[] | '';

  static ngTemplateContextGuard<TContextItem extends object>(
    dir: TableHeaderTemplateDirective<TContextItem>,
    ctx: unknown
  ): ctx is TableHeaderTemplateContext<TContextItem> {
    return true;
  }
}

interface TableRowTemplateContext<TItem extends object> {
  $implicit: TItem;
}

@Directive({
  selector: 'ng-template[libTableRow]',
})
export class TableRowTemplateDirective<TItem extends object> {
  @Input('libTableRow') data!: TItem[];

  static ngTemplateContextGuard<TContextItem extends object>(
    dir: TableRowTemplateDirective<TContextItem>,
    ctx: unknown
  ): ctx is TableRowTemplateContext<TContextItem> {
    return true;
  }
}
