import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
  selector: 'lib-card-slider',
  templateUrl: './card-slider.component.html',
  styleUrls: ['./card-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardSliderComponent implements OnInit {
  @Input() items = [];
  @Input() showArrows;
  @Input() disabledArrows = false;
  @Input() type;
  @Input() breakpoints;
  @Input() showBullets;
  @ViewChild(NgxGlideComponent) ngxGlide: NgxGlideComponent;
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  changeSlide(param: string) {
    const perView = this.getItemsPerView(this.breakpoints);

    if (this.items.length > perView) {
      if (param === 'next') {
        this.ngxGlide.go('>');
      } else if (param === 'prev') {
        this.ngxGlide.go('<');
      }
    }
  }

  onItemClick($event) {
    this.itemClicked.emit($event);
  }

  getItemsPerView(breakpoints) {
    if (breakpoints) {
      const width = window.innerWidth;
      let breakpoint;
      Object.keys(breakpoints).forEach((res) => {
        if (width <= +res && !breakpoint) {
          breakpoint = breakpoints[res];
        }
      });

      return breakpoint?.perView;
    }
  }
}
