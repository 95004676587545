import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Media } from '@shared/definitions';

@Component({
  selector: 'lib-form-photos',
  templateUrl: './form-photos.component.html',
  styleUrls: ['./form-photos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormPhotosComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;

  constructor() {}

  ngOnInit(): void {}

  getFormControl(name) {
    return this.form.get(name);
  }

  updateForm($event: Media[]) {
    const mediaId = [];

    $event.forEach((obj: Media) => {
      mediaId.push(obj.id);
    });

    this.getFormControl('media').setValue($event);
    this.getFormControl('media_ids').setValue(mediaId);
  }
}
