import { AppConfig, CompanyMenu } from "@shared/definitions";

export const isMenuItemVisible = (config: AppConfig, isAdmin: boolean, companyMenu: CompanyMenu, item: string) => {
    if (['campaigns', 'planning', 'subscriptions'].includes(item) && companyMenu) {
    /*
        Page is visible based on campaigns rules:
          - has_active_non_subscription_campaign_period is true => all 3 items visible
          - company_group_id is empty => all 3 items hidden
          - company_group_id is not empty and no active subscription => only subscription visible
          - company_group_id is not empty and there's active subscription => all 3 items visible
        */
    if (companyMenu.has_active_non_subscription_campaign_period) {
      return true;
    } else if (!companyMenu.company_group_id) {
      return false;
    } else {
      if (item === 'subscriptions') {
        return true;
      } else if (companyMenu.has_active_subscriptions) {
        return true;
      }
      else {
        return false
      }
    }
  } else {
    return true;
  }
};
