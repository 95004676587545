import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgePipe } from './age.pipe';
import { GetTextByValPipe } from './get-text-by-val.pipe';
import { MonthYearPipe } from './month-year.pipe';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [AgePipe, GetTextByValPipe, MonthYearPipe],
  imports: [CommonModule],
  exports: [AgePipe, GetTextByValPipe, MonthYearPipe],
  providers: [DatePipe],
})
export class PipesModule {}
