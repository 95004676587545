<div class="ckeditor-container mt-1">
  <div class="placeholder-container d-flex mb-2">
    <div
      *ngFor="let placeholder of placeholders"
      class="data-placeholder-tag cta-btn"
      (click)="addPlaceholder(placeholder.placeholder)"
    >
      <span *ngIf="translation">{{ 'ck_editor.' + placeholder.label | translate }}</span>
      <span *ngIf="!translation">{{ placeholder.label }}</span>
    </div>
  </div>
  <div *ngIf="label" class="editor-label">
    {{ label | translate }}
  </div>
  <ckeditor
    #editorElement
    class="ckeditor mb-0"
    [editor]="editor"
    [formControl]="control"
    [config]="ckconfig"
    (change)="onEditorChange($event)"
  ></ckeditor>
  <div class="char-counter" *ngIf="charCounter" [ngClass]="{ error: charCounter > charMax }">
    {{ charCounter }}/{{ charMax }}
  </div>
  <div class="mat-error" *ngIf="control.invalid">
    <ng-container *ngIf="control.errors.charMax">
      {{ 'form.error.description_too_long' | translate }}
    </ng-container>
  </div>
</div>
