<bwh-lib-step-control-bar
  *ngIf="type === formType.ForgotPass"
  [closeBut]="closeBut"
  (emitClose)="close()"
></bwh-lib-step-control-bar>

<div class="fixed-size text-center" [ngSwitch]="type">
  <div class="main-header">
    <h1 class="main-title" *ngSwitchCase="formType.LogIn">{{ 'auth.login' | translate }}</h1>
    <h1 class="main-title" *ngSwitchCase="formType.ForgotPass">{{ 'auth.forgot_password' | translate }}</h1>
    <h3 class="main-subtitle" *ngSwitchCase="formType.ForgotPass">
      {{ 'auth.forgot_password_send_mail' | translate }}
    </h3>
    <h1 class="main-title" *ngSwitchCase="formType.ResetPass">{{ 'auth.reset_pwd_info' | translate }}</h1>
    <h3 class="main-subtitle" *ngSwitchCase="formType.ResetPass">{{ 'auth.forgot_password_send_mail' | translate }}</h3>
  </div>
  <form [formGroup]="loginForm" *ngSwitchCase="formType.LogIn">
    <div class="row">
      <div class="col-12">
        <div class="form-label">{{ 'auth.email' | translate }}<sup>*</sup></div>
        <mat-form-field
          [floatLabel]="'never'"
          [ngClass]="{ 'error-field': getFormControl('email').status === 'INVALID' && getFormControl('email').touched }"
        >
          <input matInput formControlName="email" [placeholder]="'auth.enter_email' | translate" />
        </mat-form-field>
        <div class="error-cont" *ngIf="getFormControl('email').touched">
          <span *ngFor="let error of errorService.mapErrors(getFormControl('email'))">
            {{ 'global.' + error | translate }}
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-label">{{ 'auth.password' | translate }}<sup>*</sup></div>
        <mat-form-field
          [floatLabel]="'never'"
          [ngClass]="{
            'error-field': getFormControl('password').status === 'INVALID' && getFormControl('email').touched
          }"
        >
          <input
            matInput
            formControlName="password"
            type="password"
            [placeholder]="'auth.enter_password' | translate"
          />
        </mat-form-field>
        <div class="error-cont" *ngIf="getFormControl('password').touched">
          <span *ngFor="let error of errorService.mapErrors(getFormControl('password'))">
            {{ 'global.' + error | translate }}
          </span>
        </div>
      </div>
      <div class="col-12 forg-pass-cont">
        <div class="forgot-pass" (click)="showForgotPassword()">{{ 'auth.forgot_password' | translate }}</div>
      </div>
    </div>
  </form>
  <form [formGroup]="forgotPassForm" *ngSwitchCase="formType.ForgotPass">
    <div class="row">
      <div class="col-12">
        <div class="form-label">{{ 'auth.email' | translate }}<sup>*</sup></div>
        <mat-form-field
          [floatLabel]="'never'"
          [ngClass]="{ 'error-field': getFormControl('email').status === 'INVALID' && getFormControl('email').touched }"
        >
          <input matInput formControlName="email" [placeholder]="'auth.enter_email' | translate" />
        </mat-form-field>
        <div class="error-cont" *ngIf="getFormControl('email').touched">
          <span *ngFor="let error of errorService.mapErrors(getFormControl('email'))">
            {{ 'global.' + error | translate }}
          </span>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="resetPassForm" *ngSwitchCase="formType.ResetPass">
    <div class="row">
      <div class="col-12">
        <div class="form-label">{{ 'auth.password' | translate }}<sup>*</sup></div>
        <mat-form-field
          [floatLabel]="'never'"
          [ngClass]="{
            'error-field': getFormControl('password').status === 'INVALID' && getFormControl('email').touched
          }"
        >
          <input
            matInput
            formControlName="password"
            type="password"
            [placeholder]="'auth.enter_password' | translate"
          />
        </mat-form-field>
        <div class="error-cont" *ngIf="getFormControl('password').touched">
          <span *ngFor="let error of errorService.mapErrors(getFormControl('password'))">
            {{ 'global.' + error | translate }}
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-label">{{ 'auth.password' | translate }}<sup>*</sup></div>
        <mat-form-field
          [floatLabel]="'never'"
          [ngClass]="{
            'error-field': getFormControl('confirm_password').status === 'INVALID' && getFormControl('email').touched
          }"
        >
          <input
            matInput
            formControlName="confirm_password"
            type="password"
            [placeholder]="'auth.enter_password' | translate"
          />
        </mat-form-field>
        <div class="error-cont" *ngIf="getFormControl('confirm_password').touched">
          <span *ngFor="let error of errorService.mapErrors(getFormControl('confirm_password'))">
            {{ 'global.' + error | translate }}
          </span>
        </div>
      </div>
    </div>
  </form>

  <ul class="next-button-container not-fixed">
    <li>
      <button *ngIf="type === formType.LogIn" class="btn w-lg h-lg secondary" type="submit" (click)="signIn()">
        {{ 'auth.login' | translate }}
      </button>
      <button *ngIf="type === formType.ForgotPass" class="btn w-lg h-lg secondary" type="submit" (click)="forgotPass()">
        {{ 'auth.send' | translate }}
      </button>
      <button *ngIf="type === formType.ResetPass" class="btn w-lg h-lg secondary" type="submit" (click)="resetPass()">
        {{ 'global.reset' | translate }}
      </button>
    </li>
    <li *ngIf="type === formType.LogIn" class="add-info">
      {{ 'global.i_dont_have_bwh_account' | translate
      }}<span (click)="showSignUp()">{{ 'auth.signup' | translate }}</span>
    </li>
  </ul>
</div>
