<div class="img-cont">
  <lib-photo-upload
    [photo]="getFormControl('photo').value ? getFormControl('photo').value.url1x : null"
    [hideInfo]="true"
    (fileChange)="fileChange($event)"
  ></lib-photo-upload>
  <img
    *ngIf="editPhoto && !cvSharing"
    src="/assets/icons/img-edit-icon.svg"
    class="edit-image"
    (click)="triggerFileInput()"
  />
</div>
<div class="data-cont">
  <div class="name">{{ form.controls.first_name.value }} {{ form.controls.last_name.value }}</div>
  <div class="email">{{ form.controls.email.value }}</div>
  <div class="city-age">
    {{ form.controls.city.value }},
    <ng-container *ngIf="form.controls.birthday.value">
      {{ form.controls.birthday.value | getAge }} {{ 'global.years_old' | translate }}</ng-container
    >
  </div>
</div>
<div *ngIf="editButton && !cvSharing" class="edit-cont">
  <!-- <div class="adj-button"><img src="/assets/icons/adjust-icon.svg"></div> -->
  <div class="edit-button" (click)="edit()">
    <img src="/assets/icons/edit-icon.svg" />{{ 'global.edit' | translate }}
  </div>
</div>
