import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetJobNameByIndexPipe } from './get-job-name-by-index.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [GetJobNameByIndexPipe],
  exports: [GetJobNameByIndexPipe],
})
export class BeWorkHappyUtilsPipesModule {}
