import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedItemComponent } from './selected-item/selected-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StepControlBarComponent } from './step-control-bar/step-control-bar.component';
import { SkillsListComponent } from './skills-list/skills-list.component';
import { VacancySummaryViewComponent } from './vacancy-summary-view/vacancy-summary-view.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { SharedFeatureComponentsModule } from '@digilize/shared/feature/components/src';
import { SkillsEditionComponent } from './skills-edition/skills-edition.component';
import { LibPipesModule } from './pipes/pipes.module';
import { SharedUtilsPipesModule } from '@digilize/shared/utils/pipes/src';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { LangComponentsModule } from '@digilize/shared/feature/modules/i18n/src';
import { OffersMainPageComponent } from './offers-main-page/offers-main-page.component';
import { VacancySummaryViewV2Component } from './vacancy-summary-view-v2/vacancy-summary-view-v2.component';
import { GtagModule } from 'angular-gtag';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    DragDropModule,
    MatNativeDateModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatSliderModule,
    MatSortModule,
    SharedFeatureComponentsModule,
    LibPipesModule,
    SharedUtilsPipesModule,
    InlineSVGModule,
    SharedUiComponentsModule,
    LangComponentsModule,
    GtagModule,
  ],
  declarations: [
    SelectedItemComponent,
    StepControlBarComponent,
    SkillsListComponent,
    VacancySummaryViewComponent,
    VacancySummaryViewV2Component,
    PersonalDataComponent,
    SkillsEditionComponent,
    OffersMainPageComponent,
  ],
  exports: [
    SelectedItemComponent,
    StepControlBarComponent,
    SkillsListComponent,
    VacancySummaryViewComponent,
    VacancySummaryViewV2Component,
    OffersMainPageComponent,
    PersonalDataComponent,
    SkillsEditionComponent,
    LibPipesModule,
  ],
})
export class BeWorkHappyUiComponentsModule {}
