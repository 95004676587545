import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnvConfig } from '@digilize/shared/definitions/src';
import { SharedFeatureModulesComponentSwitcherModule } from '@digilize/shared/feature/modules/component-switcher';
import { I18nService } from '@digilize/shared/feature/modules/i18n';
import { NgxCaptchaModule, SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { SharedUiMaterialModule } from '@digilize/shared/ui/material/src';
import { SharedUtilsDirectivesModule } from '@digilize/shared/utils/directives/src';
import { getTranslationSuffix } from '@digilize/shared/utils/helpers/src';
import { SharedUtilsPipesModule } from '@digilize/shared/utils/pipes/src';
import { ENV_CONFIG } from '@digilize/shared/utils/tokens/src';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SafePipeModule } from 'safe-pipe';

import { DialogNoUserComponent } from './dialog-no-user/dialog-no-user.component';
import { DialogPagesInfoComponent } from './dialog-pages-info/dialog-pages-info.component';
import { DialogPagesComponent } from './dialog-pages/dialog-pages.component';
import { DialogPwdForgotComponent } from './dialog-pwd-reset/dialog-pwd-reset.component';
import { DialogResetPasswordComponent } from './dialog-reset-password/dialog-reset-password.component';
import { DialogSigninComponent } from './dialog-signin/dialog-signin.component';
import { DialogSignupComponent } from './dialog-signup/dialog-signup.component';
import { DialogUpdateFbDetailsComponent } from './dialog-update-fb-details/dialog-update-fb-details.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { SignInUpComponent } from './sign-in-up/sign-in-up.component';
import { DialogLostSessionComponent } from './dialog-lost-session/dialog-lost-session.component';

function HttpLoaderFactory(httpClient: HttpClient, envConfig: EnvConfig) {
  const SUFFIX = getTranslationSuffix(envConfig);
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: `assets/i18n/modules/auth/`, suffix: SUFFIX },
    { prefix: `assets/i18n/`, suffix: SUFFIX },
    { prefix: 'assets/i18n/common/alerts-', suffix: SUFFIX },
    { prefix: 'assets/i18n/common/', suffix: SUFFIX },
  ]);
}
const COMPONENTS = [
  DialogSigninComponent,
  DialogPwdForgotComponent,
  DialogPagesInfoComponent,
  DialogSignupComponent,
  DialogUpdateFbDetailsComponent,
  DialogPagesComponent,
  ResetPasswordFormComponent,
  DialogResetPasswordComponent,
  DialogNoUserComponent,
  SignInUpComponent,
  DialogLostSessionComponent,
];
/* Because ngx-translate has poor support for lazy loaded modules,
 we need to import here same translations files as in TranslateModule.forRoot method.
  Otherwise this module prevents loading base translations  */
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    InlineSVGModule,
    HttpClientModule,
    SharedUiMaterialModule,
    SharedUiComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUtilsDirectivesModule,
    SafePipeModule,
    NgxCaptchaModule,
    SharedUtilsPipesModule,
    SharedFeatureModulesComponentSwitcherModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient, ENV_CONFIG] },
      extend: true,
    }),
  ],
  providers: [TranslateService],
  exports: [COMPONENTS],
})
export class SharedFeatureModulesLoginModule {
  constructor(private i18nService: I18nService, private translateService: TranslateService) {
    this.i18nService.initLazyLoadedModule(this.translateService);
  }
}
