<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <!-- <div class="row"> -->
      <!-- <div class="col-12"> -->
      <div class="row">
        <ng-container *ngFor="let key of order; let i = index">
          <!-- <ng-container *ngIf="i<3"> -->
          <div class="col-12 col-sm-6">
            <ng-container *ngTemplateOutlet="formField; context: { key: key }"></ng-container>
          </div>
          <!-- </ng-container> -->
        </ng-container>
      </div>
      <!-- </div> -->
      <!-- <div class="col-12 col-sm-6">
                    <div class="row">
                        <ng-container *ngFor="let key of order; let i=index">
                            <ng-container *ngIf="i>2">
                                <ng-container *ngTemplateOutlet="formField; context: {key: key}"></ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div> -->
      <!-- </div> -->

      <ng-template #formField let-key="key">
        <div class="form-field-container">
          <div class="form-field-label" [ngClass]="{ required: isFormControlRequired(getFormControl(key)) }">
            {{ 'form.field.' + key | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input
              matInput
              [formControlName]="key"
              [placeholder]="key !== 'phone' && key !== 'email' ? ('form.placeholder.url' | translate) : ''"
              [required]="isFormControlRequired(getFormControl(key))"
            />
            <mat-error *ngIf="getFormControl(key).invalid">
              <ng-container *ngIf="getFormControl(key).errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl(key).errors.email">
                {{ 'form.error.invalid_email' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl(key).errors.pattern">
                {{ 'form.error.invalid_link' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </ng-template>
    </form>
  </div>
</div>
