import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dateBeforeNow(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const inputDate = new Date(control.value);
    const currentDate = new Date();

    if (inputDate > currentDate) {
      return { dateBeforeNow: true };
    }

    return null;
  };
}
