import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-loading-dots-animation',
  templateUrl: './loading-dots-animation.component.html',
  styleUrls: ['./loading-dots-animation.component.scss'],
})
export class LoadingDotsAnimationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
