<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitClose)="close()"
  (emitPrev)="goPrev()"
></bwh-lib-step-control-bar>
<div class="text-center">
  <div class="main-header">
    <h1 class="main-title">{{ 'global.education' | translate }}</h1>
  </div>

  <bwc-education-stage
    class="scrollable-content"
    *ngIf="dataLoaded && dataLoaded2"
    [form]="form"
    [countriesOptions]="countriesOptions"
    [maxEducationSteps]="maxEducationSteps"
    (formValidityChanged)="onFormValidityChanged($event)"
  ></bwc-education-stage>

  <ul class="next-button-container">
    <!-- <button (click)="checkForm()">check form</button> -->
    <li *ngIf="prevBut === true">
      <button class="btn w-md h-lg primary prev-btn" type="button" (click)="goPrev()">
        {{ 'global.previous' | translate }}
      </button>
    </li>
    <li>
      <button
        *ngIf="!editing"
        class="btn w-md h-lg secondary"
        type="submit"
        (click)="onSubmit()"
        [disabled]="!formValid"
      >
        {{ 'global.next_button' | translate }}
      </button>
      <button *ngIf="editing" class="btn w-md h-lg secondary" type="submit" (click)="onEdit()" [disabled]="!formValid">
        {{ 'global.save' | translate }}
      </button>
    </li>
  </ul>
</div>
