<button
  (click)="select()"
  [ngClass]="{
    checked: model === value,
    'not-checked': model !== value,
    disabled: isDisabled,
    big: size === 'big',
    normal: size === 'normal'
  }"
  [ngStyle]="{ textAlign: align }"
>
  <ng-container *ngIf="model === value; else nonCheck">
    <div class="circle"></div>
  </ng-container>

  <ng-template #nonCheck>
    <div class="circle"></div>
  </ng-template>
  <ng-content></ng-content>
</button>
