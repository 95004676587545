import { Component, Input, OnInit } from '@angular/core';
import { CartProduct } from '@shared/definitions';
import { addWebp } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss'],
})
export class SummaryItemComponent implements OnInit {
  @Input() product: CartProduct;

  constructor() {}

  ngOnInit(): void {}

  get addWebp() {
    return addWebp();
  }
}