<form [formGroup]="form">
  <div
    class="container"
    [formArrayName]="skillsArrayName"
    [ngClass]="{ 'add-action': addAction, 'swaped-colors': swapChipsColors }"
  >
    <div
      *ngFor="let skill of skillsArray.controls; let i = index"
      class="skill-item"
      [ngClass]="{ 'no-remove': hideRemove, 'no-add': hideAdd }"
      (click)="addAction && !hideAdd ? clickAction(i) : null"
    >
      {{ skill.value.skill_name }}
      <span
        [inlineSVG]="swapChipsColors ? '/icons/close-icon.svg' : '/icons/close-white-icon.svg'"
        class="close-icon"
        *ngIf="removeAction && !hideRemove"
        (click)="removeToIndex ? clickAction(i, skill.value.index) : clickAction(i); emitRemove()"
      >
      </span>
    </div>
  </div>
</form>
