import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { SwitchableComponent } from '@digilize/shared/definitions/src';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-component-switcher',
  templateUrl: './component-switcher.component.html',
  styleUrls: ['./component-switcher.component.scss'],
})
export class ComponentSwitcherComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() components: SwitchableComponent[] = [];
  @Output() closed = new EventEmitter();
  @ViewChildren('dynamic', { read: ViewContainerRef }) dynamic: QueryList<ViewContainerRef>;

  private componentRef: ComponentRef<any>;
  subs: Subscription[] = [];

  constructor(private resolver: ComponentFactoryResolver) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  private loadComponent(): void {
    this.subs.map((sub) => sub.unsubscribe());
    this.dynamic.map((vcr: ViewContainerRef, index: number) => {
      vcr.clear();
      const component = this.components[index];
      if (component.isVisible) {
        const componentFactory = this.resolver.resolveComponentFactory(component.component);
        this.componentRef = vcr.createComponent(componentFactory);
        if (component.props) {
          component.props.map((prop) => {
            this.componentRef.instance[prop.name] = prop.value;
          });
        }
        component.events.map((event) => {
          const sub = (this.componentRef.instance[event.emitter] as EventEmitter<any>).subscribe(() => {
            this.switchTo(event.switchToIndex);
          });
          this.subs.push(sub);
        });
      }
    });
  }

  private switchTo(switchToIndex: number) {
    this.components.forEach((comp, index) => {
      this.components[index].isVisible = index === switchToIndex;
    });
    this.loadComponent();
    if (switchToIndex === -1) this.closed.emit();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
