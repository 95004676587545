import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@digilize/shared/utils/tokens/src';
import { TranslateService } from '@ngx-translate/core';
import { Alert, AlertType, ApiError, AppConfig } from '@shared/definitions';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alert: Alert;
  alertChange: Subject<Alert> = new Subject<Alert>();

  constructor(private translateService: TranslateService, @Inject(APP_CONFIG) private appConfig: AppConfig) {
    this.alertChange.subscribe((alert: Alert) => {
      this.alert = alert;
    });
  }

  setAlert(alert: Alert) {
    this.alertChange.next(alert);
  }

  setSimpleAlert(type: AlertType, title: string, message: string, translated = false) {
    const alert = {
      type,
      title: {
        message: title,
        translated: translated,
      },
      message: {
        message: message,
        translated: translated,
      },
    };

    this.alertChange.next(alert);
  }

  handleApiError(apiError: ApiError) {
    if (apiError) {
      const alert: Alert = {
        type: AlertType.Error,
        title: {
          message: 'Error',
          translated: false,
        },
        message: {
          message: this.getAlertMessage(apiError),
          translated: false,
        },
      };
      this.setAlert(alert);
    }
  }

  getAlertMessage(apiError: ApiError): string {
    let message = '';
    message += `${this.translateService.instant('alert.error.type.' + apiError.type)} `;

    if (apiError.type === 'validation_error' && apiError.info) {
      message += `${this.translateService.instant('alert.error.kind.' + apiError.info.kind)} `;

      if (apiError.info.field) {
        message += `<br> [field: ${apiError.info.field}]`;
      }
    }

    if (apiError.request_id) {
      message += `<br> [id: ${apiError.request_id}]`;
    }

    return this.insertMsgParams(message);
  }

  insertMsgParams(message) {
    return message.replace('{{email}}', this.appConfig.email);
  }
}
