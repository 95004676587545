import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResolverApiService, SEOService } from '@digilize/shared/data/services';
import { APP_CONFIG } from '@digilize/shared/utils/tokens';
import { AppConfig, shortRouting } from '@shared/definitions';

@Component({
  selector: 'lib-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  @Input() url;
  @Input() requestId;
  loading = false;
  constructor(
    private seoService: SEOService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private route: ActivatedRoute,
    private router: Router,
    private resolverApi: ResolverApiService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (this.isUrlInShortLinkFormat(params)) {
        this.tryRedirectFromShortLink(this.route.snapshot.url[0].path);
      }
      if (params.request_id) {
        this.requestId = params.request_id;
      }

      if (params.url) {
        this.url = params.url;
      } else {
        this.url = this.router.url;
      }
    });
  }

  isUrlInShortLinkFormat(params: any): boolean {
    return (
      this.route.snapshot.url.length === 1 &&
      Object.keys(params).length === 0 &&
      this.route.snapshot.url[0].path !== '404'
    );
  }

  tryRedirectFromShortLink(permalink: string) {
    this.loading = true;
    this.resolverApi.resolverByPermalink(permalink).subscribe(
      (res) => {
        if (shortRouting[res.type]) {
          this.router.navigate([shortRouting[res.type], res.id]);
          this.seoService.updateTitle(this.appConfig.appTitle);
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  ngOnInit(): void {}
}
