<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'global.update_details' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <div class="form-field-container" *ngFor="let formControl of form.controls | keyvalue">
          <div class="form-field-label">
            {{ 'form.field.' + formControl.key | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput [formControlName]="formControl.key" required />
            <mat-error *ngIf="getFormControl(formControl.key).invalid">
              <ng-container *ngIf="getFormControl(formControl.key).errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl(formControl.key).errors.email">
                {{ 'form.error.invalid_email' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl(formControl.key).errors.alreadyExist">
                {{ 'form.error.user_already_exist' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.signin' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
