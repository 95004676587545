import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthorizeCompanyToken, Token } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ExternalApiService {
  constructor(private http: HttpClient) {}

  EXTERNAL_BASE_HREF = '/api/v1.0/external';

  externalAuth = (apiSecret: string) =>
    this.http.post<Token>(`${this.EXTERNAL_BASE_HREF}/auth`, { api_secret: apiSecret });

  externalRefreshToken = (authorizeCompanyToken: AuthorizeCompanyToken) =>
    this.http.post(`${this.EXTERNAL_BASE_HREF}/auth/refresh`, authorizeCompanyToken);

  externalGetTreeVersions = () => this.http.get(`${this.EXTERNAL_BASE_HREF}/versions`); //for currently autherized company

  externalGetVersionDefinition = (id: string) => this.http.get(`${this.EXTERNAL_BASE_HREF}/definition/${id}`);
}
