import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CvService {
  constructor(private http: HttpClient) {}

  getCV = () => this.http.get('/api/v1.0/cv');

  getCVbyId = (id: string) => this.http.get(`/api/v1.0/cv/${id}`);

  manualCV = (cv) => this.http.post('/api/v1.0/manual-cv', cv);

  uploadCV = (cv) => this.http.post('/api/v1.0/cv', cv);

  uploadVacancy = (vacancy) => this.http.post('/api/v1.0/vacancy', vacancy);
}
