import { BroadcastType } from "@shared/definitions";

export const getBroadcastIcon = (type: BroadcastType) => {
  switch (type as BroadcastType) {
    case BroadcastType.OtherBroadcast: {
      return 'icon-other-stream';
    }

    case BroadcastType.Page: {
      return 'icon-article';
    }

    case BroadcastType.Youtube: {
      return 'icon-youtube';
    }

    case BroadcastType.Spotify: {
      return 'icon-spotify';
    }

    case BroadcastType.StaticPage: {
      return 'icon-static-page'; //not implemented
    }
  }
};
