<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="'pegi'" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="pegi-container">
        <ng-container *ngFor="let code of pegiCodes">
          <!-- <mat-checkbox class="checkbox d-block" [formControlName]="key">
              {{"form.field.pegi." + key | translate}}
            </mat-checkbox> -->
          <div class="pegi-checkbox cta-btn" (click)="togglePegiCode(code)" [class.active]="isPegiActive(code)">
            <div class="pegi-symbol" [inlineSVG]="'/icons/pegi/pegi-' + code + '.svg'"></div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</div>
