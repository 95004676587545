import { LanguageCode } from '../types';
import { ProcessQuestion } from './ProcessQuestion';

export interface ProcessResponse {
  page: {
    title: string;
    text: string;
  };
  is_page: boolean;
  questions: ProcessQuestion[];
  is_finish: boolean;
  is_last_step: boolean;
  question_number: number;
  progress: number;
  session_id: string;
  cycle_id: string;
  state: ProcessState;
  current_language: LanguageCode;
  redirect: boolean;
  redirect_url: string;
}
export type ProcessState = Record<string, any>;
