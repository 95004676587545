import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @HostBinding('style.pointerEvents') pointerEvents;
  @Input() readOnly = false;
  @Input() initValue; /* when used without form*/
  checked = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit() {
    if (this.readOnly) {
      this.pointerEvents = 'none';
    }
    if (this.initValue) {
      this.checked = this.initValue;
    }
  }

  //Define what should happen in this component, if something changes outside
  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onModelChange(e: boolean) {
    //  bind the changes to the local value
    this.checked = e;

    //Handle what should happen on the outside, if something changes on the inside
    this.onChange(e);
  }
}
