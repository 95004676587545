import { Inject, Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { APP_CONFIG } from '@digilize/shared/utils/tokens/src';
import { TranslateService } from '@ngx-translate/core';
import { ApiError, AppConfig, SnackbarType } from '@shared/definitions';

import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { InfoSnackbarComponent } from './info-snackbar/info-snackbar.component';
import { SuccessSnackbarComponent } from './success-snackbar/success-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private renderer: Renderer2;
  constructor(
    private snackBar: MatSnackBar,
    rendererFactory: RendererFactory2,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  config: MatSnackBarConfig<any> = {
    duration: 5000,
    data: {},
    panelClass: ['snackbar'],
    horizontalPosition: 'right',
    verticalPosition: 'bottom', // wont work cause of global styles for cdk-global-overlay-wrapper,
  };
  openSnackbar(
    snackbarType: SnackbarType,
    config?: MatSnackBarConfig<SnackbarData>,
    positionClass?: SnackbarPositionClass
  ) {
    /* to use this snackbars create classes in application global styling (global-classes) */
    switch (snackbarType) {
      case SnackbarType.Success: {
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          ...this.config,
          panelClass: ['snackbar', 'success-snackbar'],
          ...config,
        });
        break;
      }
      case SnackbarType.Information: {
        this.snackBar.openFromComponent(InfoSnackbarComponent, {
          ...this.config,
          panelClass: ['snackbar', 'info-snackbar'],
          ...config,
        });
        break;
      }
      case SnackbarType.Error: {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          ...this.config,
          panelClass: ['snackbar', 'error-snackbar'],
          ...config,
        });
        break;
      }
    }

    //fixes global styles (legacy projects)
    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;

    const wrappers = Array.from(document.getElementsByClassName('cdk-global-overlay-wrapper'));
    const snackbarOverlayWrapper = wrappers.find((wrapper) => {
      return !!wrapper.querySelector('snack-bar-container');
    });
    this.renderer.setStyle(snackbarOverlayWrapper, 'pointer-events', 'none', flags);
    if (positionClass) {
      const overlayPane = snackbarOverlayWrapper.querySelector('.cdk-overlay-pane');
      this.renderer.addClass(overlayPane, positionClass);
    }
  }

  openSnackbarWithApiError(apiError: ApiError, duration = 10000) {
    if (apiError) {
      const data: SnackbarData = {
        msg: this.getMessage(apiError),
        withoutIcon: true,
        translate: false,
      };
      this.openSnackbar(SnackbarType.Error, { data, duration });
    }
  }
  getMessage(apiError: ApiError): string {
    let message = '';
    message += `${this.translateService.instant('alert.error.type.' + apiError.type)} `;

    if (apiError.type === 'validation_error' && apiError.info) {
      message += `${this.translateService.instant('alert.error.kind.' + apiError.info.kind)} `;

      if (apiError.info.field) {
        message += ` [field: ${apiError.info.field}]`;
      }
    }

    if (apiError.request_id) {
      message += ` [id: ${apiError.request_id}]`;
    }

    return message.replace('{{email}}', this.appConfig.email);
  }
}

export interface SnackbarData {
  msg: string;
  translate: boolean;
  withoutIcon?: boolean;
}

export type SnackbarPositionClass = 'snackbar-bottom';
