import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-delete-bid-confirm',
  templateUrl: './dialog-delete-bid-confirm.component.html',
  styleUrls: ['./dialog-delete-bid-confirm.component.scss'],
})
export class DialogDeleteBidConfirmComponent {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteBidConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  deleteBid() {
    this.closeDialog({ remove: true });
  }
}
