<h2 class="standalone-question" *ngIf="questions.length === 1" [innerHTML]="questions[0].question"></h2>
<table *ngIf="formGroup">
  <thead>
    <tr>
      <th class="blank-header" *ngIf="questions.length > 1"></th>
      <!-- we assume all questions have this same answers -->
      <th *ngFor="let answer of questions[0].answers">
        <span *ngIf="withInnerHTML" [innerHTML]="answer.text"></span>
        <span *ngIf="!withInnerHTML">{{ answer.text }}</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container [formGroup]="formGroup">
      <mat-radio-group
        class="d-table-row"
        *ngFor="let question of questions"
        [formControlName]="question.formControlName"
      >
        <td *ngIf="questions.length > 1" class="question-text-container" [innerHTML]="question.question"></td>
        <td class="answer" *ngFor="let answer of question.answers">
          <mat-radio-button [value]="answer.val"></mat-radio-button>
        </td>
      </mat-radio-group>
    </ng-container>
  </tbody>
</table>
