<mat-form-field
  class="form-field multi-select select-primary trim-padding"
  [floatLabel]="myFloatLabel ? myFloatLabel : 'never'"
  [ngClass]="{ 'select-open': select.panelOpen }"
>
  <span
    class="select-arrow"
    [inlineSVG]="(openIconPath ? openIconPath : '/icons/icon-arrow-') + (select.panelOpen ? 'up' : 'down') + '.svg'"
  ></span>

  <mat-placeholder class="placeholder">{{ placeholder | translate }}</mat-placeholder>

  <mat-select
    #select
    panelClass="select-options-primary"
    [formControl]="control"
    [compareWith]="optionObj ? compareArrays : null"
    (selectionChange)="selected($event.value)"
    multiple
  >
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip
          *ngFor="let option of selectedChips"
          [removable]="true"
          [matTooltip]="checkIfShowTooltip(option)"
          (removed)="onChipRemoved(option)"
        >
          <ng-container *ngIf="translation; else noTranslate">
            {{
              (translationPrefix
                ? translationPrefix + (optionObj ? option.text : option)
                : optionObj
                ? option.text
                : option
              ) | translate
            }}
          </ng-container>
          <ng-template #noTranslate>
            {{ optionObj ? option.text : option }}
          </ng-template>
          <ng-container *ngIf="iconPath; else defaultIcon">
            <span class="remove-icon" matChipRemove [inlineSVG]="iconPath"></span>
          </ng-container>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <mat-option *ngFor="let option of options" [value]="option">
      <ng-container *ngIf="translation; else noTranslate">
        {{
          (translationPrefix
            ? translationPrefix + (optionObj ? option.text : option)
            : optionObj
            ? option.text
            : option
          ) | translate
        }}
      </ng-container>
      <ng-template #noTranslate>
        {{ optionObj ? option.text : option }}
      </ng-template>
    </mat-option>
  </mat-select>

  <div class="mat-error" *ngIf="control.errors">
    <ng-container *ngIf="control.errors.required">
      {{ 'form.error.required' | translate }}
    </ng-container>
  </div>
</mat-form-field>

<ng-template #defaultIcon>
  <mat-icon matChipRemove>cancel</mat-icon>
</ng-template>
