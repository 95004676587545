<div class="dialog-panel dialog-post">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div *ngIf="activePost" class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div class="dialog-header">
      <ng-container [ngSwitch]="activePost.status">
        <h1 *ngSwitchCase="ShopPostStatus.AwaitsCustomer" class="dialog-title dot">
          {{ 'planning.edit_post' | translate }}
        </h1>
        <h1 *ngSwitchCase="ShopPostStatus.Planned" class="dialog-title dot">{{ 'planning.edit_post' | translate }}</h1>
        <h1 *ngSwitchCase="ShopPostStatus.ToBePosted" class="dialog-title dot">
          {{ 'planning.planned_post' | translate }}
        </h1>
        <h1 *ngSwitchDefault class="dialog-title dot">{{ 'planning.planning_post' | translate }}</h1>
      </ng-container>
    </div>
    <div *ngIf="activePost.post_draft_id">
      <div class="draft-images-container">
        <div class="image-box" *ngIf="activePost.photo_facebook_feed" [lazyLoad]="activePost.photo_facebook_feed.url1x">
          <div class="social-img-icon" [lazyLoad]="'/assets/social/facebook.png'"></div>
        </div>
        <div
          class="image-box"
          *ngIf="activePost.photo_facebook_story"
          [lazyLoad]="activePost.photo_facebook_story.url1x"
        >
          <div class="social-img-icon" [lazyLoad]="'/assets/social/facebook.png'"></div>
        </div>
        <div
          class="image-box"
          *ngIf="activePost.photo_instagram_feed"
          [lazyLoad]="activePost.photo_instagram_feed.url1x"
        >
          <div class="social-img-icon" [lazyLoad]="'/assets/social/instagram.png'"></div>
        </div>
        <div
          class="image-box"
          *ngIf="activePost.photo_instagram_reels"
          [lazyLoad]="activePost.photo_instagram_reels.url1x"
        >
          <div class="social-img-icon" [lazyLoad]="'/assets/social/instagram.png'"></div>
        </div>
        <div
          class="image-box"
          *ngIf="activePost.photo_instagram_story"
          [lazyLoad]="activePost.photo_instagram_story.url1x"
        >
          <div class="social-img-icon" [lazyLoad]="'/assets/social/instagram.png'"></div>
        </div>
        <div class="image-box" *ngIf="activePost.photo_tik_tok_feed" [lazyLoad]="activePost.photo_tik_tok_feed.url1x">
          <div class="social-img-icon" [lazyLoad]="'/assets/social/tiktok.png'"></div>
        </div>
      </div>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="formPost" (submit)="onSubmit()">
      <div class="dialog-body selected-post pt-0">
        <div class="row flex-nowrap post-type mb-2">
          <div class="col-12 align-self-center">
            <div class="socials">
              <div *ngIf="activePost.platforms">
                <img
                  class="social-img"
                  *ngFor="let platform of activePost.platforms?.split('|')"
                  [lazyLoad]="'/assets/social/' + platform.toLowerCase() + '.png'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-field-container pl-0">
            <ng-container *ngIf="postDoesntHaveStatus || isEditorVisible; else staticPhoto">
              <lib-drag-drop-photos
                [maxFileSize]="5"
                [alternativeLook]="true"
                [staticImage]="activePost?.photo || null" [editorImage]="!isPostPhotoFromEditor && activePost.editor_photo"
                [items]="formPost.get('media').value && activePost.photos_customer_required ? formPost.get('media').value : []"
                (filesChange)="updateImages($event)"
                (filesChange)="updateImages($event)"
                [photoEditorEnabled]="activePost.editor">
                <div *ngIf="activePost.editor" class="photo-editor-btn-container">
                  <button type="button" class="photo-editor-btn" (click)="openPhotoEditor()">
                    <div class="edit-post-icon" [inlineSVG]="'/icons/icon-edit-post.svg'"></div>
                    <div class="mt-2">{{ 'photo_editor.edit_photo' | translate }}</div>
                  </button>
                </div>
              </lib-drag-drop-photos>
            </ng-container>
            <ng-template #staticPhoto>
              <div class="static-photo" [lazyLoad]="activePost.photo?.url1x"></div>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="col-8 form-field-container">
            <div class="form-field-label">
              {{ 'form.field.post_title' | translate }}
            </div>
            <mat-form-field class="form-field readonly" [floatLabel]="'never'">
              <input matInput [formControlName]="'title'" readonly />
            </mat-form-field>
          </div>
          <div class="form-field-container col-12 col-sm-8 col-lg-4">
            <div class="form-field-label">
              {{ 'form.field.start_date' | translate }}
            </div>
            <mat-form-field
              class="form-field"
              [floatLabel]="'never'"
              [class.readonly]="activePost.status > 0"
              [class.error-margin]="
                formPost.get('planned').errors?.matDatetimePickerMin || formPost.get('planned').errors?.required
              "
            >
              <input
                matInput
                [ngxMatDatetimePicker]="picker"
                [formControlName]="'planned'"
                [min]="minDate"
                [readonly]="activePost.status > 0"
              />
              <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker [showSpinners]="true"> </ngx-mat-datetime-picker>
              <mat-error *ngIf="formPost.get('planned').invalid">
                <ng-container *ngIf="formPost.get('planned').errors.required">
                  {{ 'form.error.required' | translate }}
                </ng-container>
              </mat-error>
              <div class="mat-error" *ngIf="formPost.get('planned').errors?.matDatetimePickerMin ? true : false">
                {{ 'form.error.min_date_24_from_now' | translate }}
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 form-field-container">
            <div class="form-field-label">
              {{ 'form.field.description' | translate }}
            </div>
            <mat-form-field class="form-field" [class.readonly]="activePost.status > 0" [floatLabel]="'never'">
              <textarea
                matInput
                [formControlName]="'body'"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="10"
                [readonly]="activePost.status > 0"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 form-field-container">
            <div class="form-field-label">
              {{ 'form.field.hashtags' | translate }}
            </div>
            <mat-form-field class="form-field" [floatLabel]="'never'" [class.readonly]="activePost.status > 0">
              <mat-chip-list #tagsList [disabled]="activePost.status > 0">
                <mat-chip
                  *ngFor="let tag of hashtags"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeTag(tag)"
                >
                  {{ tag }}
                  <span matChipRemove class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
                </mat-chip>
                <input
                  [matChipInputFor]="tagsList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="addTag($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>

        <div class="arrows-container" *ngIf="nextPostTemplatedId || prevPostTemplatedId">
          <div class="row">
            <div class="col-12 col-md-6 text-center text-md-left mb-4 mb-md-0">
              <button
                type="button"
                class="btn btn-arrow btn-prev"
                (click)="onSubmit(prevPostTemplatedId)"
                *ngIf="prevPostTemplatedId"
              >
                <span class="icon" [inlineSVG]="'/icons/icon-prev-2.svg'"></span>
                {{ 'planning.previous_post' | translate }}
              </button>
            </div>
            <div class="col-12 col-md-6 text-center text-md-right">
              <button
                type="button"
                class="btn btn-arrow btn-next"
                (click)="onSubmit(nextPostTemplatedId)"
                *ngIf="nextPostTemplatedId"
              >
                {{ 'planning.next_post' | translate }}
                <span class="icon" [inlineSVG]="'/icons/icon-next-2.svg'"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-bottom" *ngIf="!(activePost.status > 0)">
        <div class="row">
          <div class="ml-auto col-12 col-md-4">
            <button type="submit" class="btn btn-primary d-block w-100" [disabled]="formPost.invalid">
              <ng-container *ngIf="!activePost.id; else update">
                {{ 'global.confirm' | translate }}
              </ng-container>
              <ng-template #update>
                {{ (editorPhotoSaveClicked ? "global.publish" : "global.update") | translate }}
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
