import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageCode } from '@digilize/shared/definitions/src';
import { TranslateService } from '@ngx-translate/core';
import { REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_WITH_APO } from '@digilize/shared/utils/validators/src';

import { SelectionOption } from '../form-items/select/select.component';

@Component({
  selector: 'lib-respondent-list',
  templateUrl: './respondent-list.component.html',
  styleUrls: ['./respondent-list.component.scss'],
})
export class RespondentListComponent implements OnInit, OnDestroy {
  @Input() controlArray: FormArray = this.getDefaultFormArray();
  @Input() deleteIconPath: string;
  @Input() counterEnabled = false;
  @Input() set focusLastInput(boolean: boolean) {
    setTimeout(() => {
      this.emailInputs.last?.nativeElement?.focus();
      this.focusLastInputChange.emit(false);
    }, 0);
  }
  @Input() duplicatedRespondents: string[] | null;
  @Output() focusLastInputChange = new EventEmitter();
  @ViewChildren('emailInput') emailInputs: QueryList<ElementRef>;
  showLabels: 'never' | 'auto' = 'never';
  langOptions: SelectionOption[];
  @Input() set langs(langs: LanguageCode[]) {
    this.langOptions = langs.map((lang) => {
      return { key: lang.toUpperCase(), value: lang };
    });
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.updateShowLabels();
    this.setResizeListener();
  }

  handleTrashClick(controlGroup: any) {
    if (this.controlArray.controls.length === 1) {
      this.controlArray.controls[0].patchValue({
        email: '',
        first_name: '',
        last_name: '',
        language: this.translateService.currentLang,
      });
      setTimeout(() => {
        this.emailInputs.last.nativeElement.focus();
      }, 0);
      return;
    }
    this.controlArray.controls = this.controlArray.controls.filter((el) => el !== controlGroup);
    this.controlArray.updateValueAndValidity();
  }

  setResizeListener() {
    window.addEventListener('resize', this.updateShowLabels);
  }

  private updateShowLabels() {
    const isMobileView = document.documentElement.clientWidth <= 992;
    this.showLabels = isMobileView ? 'auto' : 'never';
  }

  getDefaultFormArray() {
    /* mock data for story */
    return new FormArray([
      new FormGroup({
        email: new FormControl('mockMail1', [Validators.email, Validators.required]),
        first_name: new FormControl('mockName1', [
          Validators.pattern(REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_WITH_APO),
        ]),
        last_name: new FormControl('mockSurname1', [
          Validators.pattern(REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_WITH_APO),
        ]),
        language: new FormControl('en'),
      }),
      new FormGroup({
        email: new FormControl('mockMail2', [Validators.email, Validators.required]),
        first_name: new FormControl('mockName2', [
          Validators.pattern(REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_WITH_APO),
        ]),
        last_name: new FormControl('mockSurname2', [
          Validators.pattern(REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_WITH_APO),
        ]),
        language: new FormControl('nl'),
      }),
    ]);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateShowLabels);
  }
}

export interface ResearchRespondentForm {
  email: string;
  first_name: string;
  last_name: string;
  language: string;
}
