import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewSkillReq } from '@digilize/shared/definitions/src/lib/enums';

@Injectable({
  providedIn: 'root',
})
export class SkillsApiService {
  constructor(private http: HttpClient) {}

  addNewSkill = (req: NewSkillReq) => this.http.post('/api/v1.0/skills', req);
}
