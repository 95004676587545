import { EventUserInviteStatus } from "../enums/EventUserInviteStatus";


export const EventUserInviteStatusOptions = [
  {
    key: 'events.not_responded',
    value: EventUserInviteStatus.Invited,
  },
  {
    key: 'events.accepted',
    value: EventUserInviteStatus.Accepted,
  },
  {
    key: 'events.attended',
    value: EventUserInviteStatus.Declined,
  },
  {
    key: 'events.declined',
    value: EventUserInviteStatus.Attended,
  },
];
