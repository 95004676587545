import { Component, Input, OnInit } from '@angular/core';
import { Media } from '@digilize/shared/definitions/src';

@Component({
  selector: 'lib-profile-circle-img',
  templateUrl: './profile-circle-img.component.html',
  styleUrls: ['./profile-circle-img.component.scss'],
})
export class ProfileCircleImgComponent implements OnInit {
  @Input() image: Media;
  constructor() {}

  ngOnInit() {}
}
