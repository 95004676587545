export interface DecisionTree {
  company_id: string;
  project_group_id: string;
  name: string;
  is_subtree: boolean;
  created: string;
  updated: string;
  project_group: string;
  versions_count: number;
  id: string;
}
