<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div #videoContainer class="video-container" *ngIf="videoUrl">
      <iframe
        class="video-iframe"
        [src]="videoUrl | safe: 'resourceUrl'"
        width="100%"
        [height]="videoContainer.offsetHeight"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowTransparency="false"
        allowFullScreen="true"
        allow="autoplay"
      >
      </iframe>
    </div>
  </div>
</div>
