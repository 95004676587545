import { trigger, transition, style, query, group, animate } from '@angular/animations';

export const slider = trigger('routeAnimations', [
  transition(':increment', slideTo('right')),
  transition(':decrement', slideTo('left')),
]);

function slideTo(direction) {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100vw',
        }),
      ],
      optional
    ),
    query(':enter', [style({ [direction]: '-100%' })], optional),
    group([
      query(':leave', [animate('600ms ease-in-out', style({ [direction]: '100%' }))], optional),
      query(':enter', [animate('600ms ease-in-out', style({ [direction]: '0%' }))], optional),
    ]),
  ];
}
