import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isFormControlRequired } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-form-address',
  templateUrl: './form-address.component.html',
})
export class FormAddressComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;

  order = ['street', 'house_number', 'post_code', 'city', 'country', 'address_visible'];

  countries = [
    {
      key: 'Nederland',
      value: 'NL',
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  getFormControl(name) {
    return this.form.get(name);
  }

  countryValueChange($event) {
    this.getFormControl('country').setValue($event);
  }

  updateAddressVisibility(checked) {
    this.getFormControl('address_visible').setValue(!checked);
  }

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }
}
