import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { AppConfig } from '@digilize/shared/definitions/src';
import { APP_CONFIG } from '@digilize/shared/utils/tokens/src';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomTitleWithSuffixStrategyService extends TitleStrategy {
  constructor(
    private translateService: TranslateService,
    private title: Title,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.translateService
        .get(title)
        .pipe(first())
        .subscribe((resp) => {
          this.title.setTitle(resp + ' - ' + this.appConfig.appTitle);
        });
    }
  }
}
