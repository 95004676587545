import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CampaignApiService, GeolocationApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { TranslateService } from '@ngx-translate/core';
import {
  ALERT_SUBSCRIPTION_INACTIVE_SHOP,
  Company,
  CompanyGroup,
  Shop,
  SUBSCRIPTION_PERIODS,
  SubscriptionType,
} from '@shared/definitions';
import * as IBAN from 'iban';
import * as moment from 'moment';

@Component({
  selector: 'lib-dialog-subscription',
  templateUrl: './dialog-subscription.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogSubscriptionComponent implements OnInit {
  loading = false;
  shops: Shop[] = [];
  minDate = new Date();
  companyGroup: CompanyGroup;
  types: Record<string, { key: string; value: string; obj: SubscriptionType }[]> = {
    'subscription.popular_options': [],
    'subscription.other_options': [],
  };
  periods = [];
  company: Company;
  form: UntypedFormGroup = new UntypedFormGroup({
    start: new UntypedFormControl('', Validators.required),
    shop_id: new UntypedFormControl('', Validators.required),
    subscription_type_id: new UntypedFormControl('', Validators.required),
    renewal_period: new UntypedFormControl('', Validators.required),
    iban: new UntypedFormControl('', [Validators.required, this.ibanValidator]),
    invoice_email: new UntypedFormControl(null, [Validators.email]),
    agreement_general: new UntypedFormControl(false, Validators.required),
    agreement_additional: new UntypedFormControl(false),
    agreement_direct_debeting: new UntypedFormControl(false, Validators.required),
    inserted_by_ip: new UntypedFormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campaignApiService: CampaignApiService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private geolocationApiService: GeolocationApiService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.shops = this.data?.shops ? this.data?.shops : [];
    this.company = this.data?.company;

    if (this.company) {
      this.getSubscriptionTypes();
      this.getUserIp();
      this.getCompanyGroup(this.company.company_group_id);
    }
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  getCompanyGroup(companyGroupId) {
    this.campaignApiService.getCompanyGroup(companyGroupId).subscribe((response: any) => {
      this.companyGroup = response;
    });
  }

  getUserIp() {
    this.geolocationApiService.getUserIp().subscribe((response: any) => {
      this.form.get('inserted_by_ip').setValue(response.IPv4);
    });
  }

  getSubscriptionTypes() {
    this.campaignApiService.getSubscriptionTypes(this.company.id).subscribe(
      (response) => {
        if (response.length > 0) {
          response.forEach((type) => {
            const typeOption = {
              key: `${type.name} ${type.price_monthly ? '- ' + type.price_monthly + '€' : ''}`,
              value: type.id,
              obj: type,
            };
            if (type.is_popular) {
              this.types['subscription.popular_options'].push(typeOption);
            } else {
              this.types['subscription.other_options'].push(typeOption);
            }
          });
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setPeriods(subTypeId) {
    let subType;
    const periods = [];
    this.form.get('renewal_period').setValue(null);
    this.periods = [];
    Object.keys(this.types).forEach((key) => {
      if (!subType) {
        subType = this.types[key].find((obj) => obj.value === subTypeId)?.obj;
      }
    });

    if (subType) {
      Object.keys(subType).forEach((key) => {
        if (subType[key] !== 0 && key.includes('price_')) {
          const periodIndex = SUBSCRIPTION_PERIODS().findIndex(
            (obj) => obj.key === key.replace('price_', 'subscription.period.')
          );
          if (periodIndex > -1) {
            periods.push(SUBSCRIPTION_PERIODS()[periodIndex]);
          }
        }
      });
    }

    periods.forEach((period) => {
      const discount = subType[period.key.replace('subscription.period.', 'discount_').toLowerCase()];
      period.key = this.translateService.instant(period.key);

      if (discount) {
        period.key += ` (${discount}% ${this.translateService.instant('subscription.discount')})`;
      }
    });

    this.periods = periods;
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    this.loading = true;

    const subscription = {
      ...this.form.getRawValue(),
      company: this.company.id,
    };
    subscription.start = moment(subscription.start).toISOString();

    this.campaignApiService.addSubscription(subscription).subscribe(
      (response: any) => {
        this.closeDialog(true);
      },
      (error) => {
        this.loading = false;
        if (error.error.error.info?.kind === 'inactive' && error.error.error.info?.field === 'shop_id') {
          this.alertService.setAlert(ALERT_SUBSCRIPTION_INACTIVE_SHOP);
        } else {
          this.alertService.handleApiError(error.error.error);
        }
      }
    );
  }

  ibanValidator(formControl: UntypedFormControl) {
    if (formControl.value === '') return null;
    if (IBAN.isValid(formControl.value)) {
      return null;
    } else {
      return {
        iban: {
          valid: false,
        },
      };
    }
  }
}
