import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'lib-form-open-hours',
  templateUrl: './form-open-hours.component.html',
  styleUrls: ['./form-open-hours.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormOpenHoursComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  open = true;
  formInit;

  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  constructor() {}

  ngOnInit(): void {
    if (this.form) {
      this.initForm(this.form);
    }
  }

  initForm(form: UntypedFormGroup) {
    const formObj = form.getRawValue();

    Object.keys(formObj).forEach((key) => {
      if (key.includes('from') || key.includes('to')) {
        const date = this.getFormControl(key).value
          ? moment(this.getFormControl(key).value, 'HH:mm')
          : moment('00:00', 'HH:mm');
        this.getFormControl(key).setValue(new Date(date.format('L HH:mm')));
      }
    });

    this.formInit = true;
  }

  getFormControl(name): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }
}
