<div class="container">
  <div class="step-control-cont">
    <div class="prev-cont" *ngIf="prevBut === true" (click)="prev()">
      <span [inlineSVG]="'/icons/arrow-left-icon.svg'"></span>
    </div>
    <lib-lang-droplist
      *ngIf="langDropdown && !closeBut"
      class="lang-switcher"
      [noNative]="true"
      [dropdownIconsPath]="'/icons/icon-arrow-b-'"
    ></lib-lang-droplist>
    <div class="close-cont" *ngIf="closeBut === true" (click)="close()"><img src="/assets/icons/close-icon.svg" /></div>
  </div>

  <div class="progress-cont" *ngIf="steps && currentStep">
    <div class="progress-line" [style.width.%]="progresNum"></div>
  </div>
</div>
