import { ChartType } from '../enums';
import { Multilingual } from './Multilingual';

export interface SurveyPage {
  id: string;
  name: string;
  tree: string;
  tree360: string;
  content: BaseItem;
  labels: ChartLabel[];
  tenant_id: string;
  researches: ResearchPageData[];
}

export interface ResearchPageData {
  research_id: string;
  research_name: string;
  research_name360: string;
  research_completed_count: number;
  research_exported_count: number;
  research_group_ids: string[];
}

export interface SurveyPageWithResearchData extends SurveyPage, ResearchPageData {}

interface Item {
  name: Multilingual;
  id: string;
}

export interface ChartLabel {
  text: string;
  id: string;
}
export interface BaseItem extends Item {
  kind: 'baseitem';
  items: ItemGroup[];
}

export interface ItemGroup extends Item {
  kind: 'itemgroup';
  items: GroupItems;
}

export type GroupItems = (ItemPage | ItemText | ItemTitle)[];

export interface ItemPage extends Item {
  kind: 'itempage';
  items: (ItemText | ItemTitle | ItemSection)[];
}

interface ItemPageElBase<T> {
  id: string;
  items: T;
}

export interface ItemSection<T = ItemColumn[]> extends ItemPageElBase<T> {
  kind: 'itemsection';
}

export interface ItemColumn extends ItemPageElBase<(ItemTitle | ItemText | ItemChart)[]> {
  kind: 'itemcolumn';
  ratio: number;
}

export interface ItemChart extends ItemPageElBase<null> {
  title: Multilingual;
  body: Multilingual;
  csv: string | null;
  csv2: string | null;
  display: ItemChartDisplay;
  type: ChartType;
  labels_visible: boolean;
  query: string;
  query2: string;
  kind: 'itemchart';
}
export interface ItemText extends ItemPageElBase<null> {
  body: Multilingual;
  kind: 'itemtext';
  csv_score: string;
  csv_advice: string;
  query_score: '';
}

export interface ItemTitle extends ItemPageElBase<null> {
  title: Multilingual;
  kind: 'itemtitle';
}

export type ItemPageElKind = 'itemchart' | 'itemtext' | 'itemtitle';

export interface SurveyResearchPageIds {
  pageId: string;
  groupId: string;
  itemPageId: string | null;
}

export interface ItemChartDisplay {
  xaxis: Axis;
  yaxis: Axis;
  thresholds?: Threshold[] /* used for colorful chart */;
}

export interface Threshold {
  max: number;
  text_color: string;
  background_color: string;
}
export interface Axis {
  min: number | null;
  max: number | null;
  title: Multilingual;
}
