import { ShopPostStatus } from "../enums/ShopPostStatus";
import { Media } from "./Media";
import { Post } from "./Post";


export interface DetailedPost extends Post {
  media: Media[];
  media_ids: string[];
  status: ShopPostStatus;
}
