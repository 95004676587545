import { Media } from "./Media";
import { ProductDescriptor } from "./ProductDescriptor";


export interface ProductVariant {
  id?: string;
  sku?: string;
  barcode?: string;
  instances_available: number;
  instances_amount?: number;
  instances_amount_difference?: number;
  instances: number; // 0 - Unknown 1 = Unavailable 2 = Available
  media_ids?: string[];
  media?: Media[];
  descriptors?: ProductDescriptor[];
}
