import { EmailStatus } from "../interfaces/EmailStatus";

export const EmailStatusOptions = [
  {
    key: 'events.delivered',
    value: EmailStatus.Delivered,
  },
  {
    key: 'events.opened',
    value: EmailStatus.Opened,
  },
  {
    key: 'events.clicked',
    value: EmailStatus.Clicked,
  },
  {
    key: 'events.failed',
    value: EmailStatus.Failed,
  },
];
