<mat-form-field class="chip-list" [appearance]="appearance" [floatLabel]="myFloatLabel ? myFloatLabel : 'always'">
  <mat-label>{{ placeholder }}<sup *ngIf="requiredInLabel">*</sup></mat-label>

  <mat-chip-list #chipList>
    <ng-container *ngIf="positionOfInput === 'top'"
      ><ng-container *ngTemplateOutlet="inputTemplate"></ng-container
    ></ng-container>

    <mat-chip *ngFor="let pickedOption of pickedOptions" (removed)="removePickedOption(pickedOption)">
      {{ pickedOption }}
      <button matChipRemove>
        <span class="icon-remove" [inlineSVG]="'/icons/icon-close-no-color.svg'"></span>
      </button>
    </mat-chip>

    <ng-container *ngIf="positionOfInput === 'bottom'">
      <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
    </ng-container>
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <ng-container *ngIf="openDropdownOnClick">
      <mat-option *ngFor="let filteredOption of filteredOptions | async" [value]="filteredOption">
        {{ filteredOption }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <ng-template #inputTemplate>
    <input
      #optionInput
      [formControl]="control"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (focusout)="onInputFocusOut()"
      (click)="handleInputClick()"
    />
  </ng-template>
</mat-form-field>
