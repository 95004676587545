<div class="dialog-info dialog-info-primary" *ngIf="inviteInfo">
  <div class="row flex-nowrap">
    <div class="col-auto align-self-center">
      <span class="icon" [inlineSVG]="'/icons/icon-warning.svg'"></span>
    </div>
    <div class="col copy">
      {{ 'company.invite_login_info' | translate }}
    </div>
  </div>
</div>
<div class="dialog-panel">
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'global.signin' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <div *ngIf="withFacebook" class="auth-other-actions">
          <button type="button" class="btn btn-fb d-block w-100" (click)="facebookRedirect()">
            <span class="icon-fb" [inlineSVG]="'/icons/icon-fb.svg'"></span>
            {{ 'auth.signin_with_facebook' | translate }}
          </button>
          <div class="d-block or">
            {{ 'global.or' | translate }}
          </div>
        </div>
        <div class="form-field-container">
          <div class="form-field-label">
            {{ 'form.field.email' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput formControlName="email" required />
            <mat-error *ngIf="getFormControl('email').invalid">
              <ng-container *ngIf="getFormControl('email').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl('email').errors.email">
                {{ 'form.error.invalid_email' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field-container">
          <div class="form-field-label">
            {{ 'form.field.password' | translate }}
          </div>
          <mat-form-field class="form-field form-field-password" [floatLabel]="'never'">
            <input
              #passwordInput="matInput"
              matInput
              [type]="hidePwd ? 'password' : 'text'"
              formControlName="password"
              required
            />
            <span
              class="form-field-icon"
              [ngClass]="{ active: !hidePwd }"
              [inlineSVG]="'/icons/icon-eye.svg'"
              (click)="hidePwd = !hidePwd; $event.preventDefault()"
            ></span>
            <mat-error *ngIf="getFormControl('password').invalid">
              <ng-container *ngIf="getFormControl('password').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl('password').errors.incorrect_email_password">
                {{ 'form.error.incorrect_email_password' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="auth-other-actions">
          <button type="button" class="btn btn-forgot" (click)="handlePwdResetClicked($event)">
            {{ 'auth.forgot_password' | translate }}
          </button>
          <button
            *ngIf="!signUpCustomHtmlCode"
            type="button"
            class="btn btn-text"
            (click)="handleSignUpClicked($event)"
          >
            {{ 'auth.dont_have_account' | translate }}
            <span>
              {{ 'auth.signup_here' | translate }}
            </span>
          </button>
          <div *ngIf="signUpCustomHtmlCode" [innerHTML]="signUpCustomHtmlCode"></div>
        </div>
      </div>
      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.signin' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
