import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-event-icon',
  templateUrl: './event-icon.component.html',
  styleUrls: ['./event-icon.component.scss'],
})
export class EventIconComponent implements OnInit {
  @Input() eventType: EventType;
  constructor() {}

  ngOnInit(): void {}
}

type EventType =
  | 'Culinair'
  | 'Other'
  | 'Bussines'
  | 'Kunst & Cultuur'
  | 'Markten & Fairs'
  | 'Muziek & Theater'
  | 'Natuur'
  | 'Sport & Actief'
  | 'Voor kinderen'
  | 'Informatief & Netwerk';
