import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";
export const ALERT_MARKETPLACE_BID_ADD_SUCCESS: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.bid_added',
    translated: true,
  },
};
