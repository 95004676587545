<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <!-- MAIN -->
      <div class="row">
        <ng-container *ngFor="let key of order">
          <div
            class="col-12 col-sm-4 form-field-container"
            *ngIf="key !== 'instances_infinite' && key !== 'instances_allow_exceed_amount'; else fieldCheckbox"
            [ngClass]="{ inactive: getProductVariants().length > 0 && !voucherActive }"
          >
            <div class="form-field-label">
              {{ 'form.field.' + key | translate }}
            </div>
            <mat-form-field
              class="form-field"
              [floatLabel]="'never'"
              [ngClass]="{ readonly: key === 'instances_available' && voucherActive && !voucherGenerateCodes }"
            >
              <input
                matInput
                [type]="key === 'instances_available' ? 'number' : 'text'"
                [formControlName]="key"
                [readonly]="
                  options.length > 0 || (key === 'instances_available' && voucherActive && !voucherGenerateCodes)
                "
              />
              <mat-error *ngIf="getFormControl(key).invalid">
                <ng-container *ngIf="getFormControl(key).errors.required">
                  {{ 'form.error.required' | translate }}
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
          <ng-template #fieldCheckbox>
            <div class="col-12 form-field-container mb-2">
              <mat-checkbox
                class="checkbox d-block"
                [formControlName]="key"
                *ngIf="key === 'instances_allow_exceed_amount'"
              >
                {{ 'product.' + key | translate }}
              </mat-checkbox>
              <mat-checkbox
                class="checkbox d-block"
                [checked]="!getFormControl(key).value"
                (change)="updateTrackingAmount($event.checked)"
                *ngIf="key === 'instances_infinite'"
              >
                {{ 'product.' + key | translate }}
              </mat-checkbox>
            </div>
          </ng-template>
        </ng-container>
        <div
          class="col-12 col-sm-auto mt-3"
          [ngClass]="{ 'mb-4': options.length > 0 }"
          *ngIf="!(voucherActive && !voucherGenerateCodes)"
        >
          <button type="button" class="btn btn-secondary d-block w-100" (click)="addOption()">
            {{ 'product.add_option' | translate }}
            <div
              class="i-icon i-icon-btn"
              #tooltip="matTooltip"
              [matTooltip]="'tooltip.product.add_new_option' | translate"
              [matTooltipPosition]="'above'"
              (click)="tooltip.toggle()"
            >
              <span class="i">i</span>
            </div>
          </button>
        </div>
      </div>
      <!-- MAIN -->
    </form>

    <ng-container *ngIf="!(voucherActive && !voucherGenerateCodes)">
      <!-- OPTIONS -->
      <div class="row flex-sm-nowrap mt-2" *ngFor="let option of options; let i = index">
        <div class="col col-sm-4 form-field-container order-2 order-sm-1">
          <div class="form-field-label required">{{ 'global.option' | translate }} {{ i + 1 }}</div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput [(ngModel)]="option.name" />
          </mat-form-field>
        </div>
        <div class="col-12 col-sm form-field-container order-3 order-sm-2">
          <div class="form-field-label required">
            {{ 'global.variant' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <mat-chip-list #variantsList>
              <mat-chip
                *ngFor="let variant of option.variants"
                [selectable]="true"
                [removable]="true"
                (removed)="removeVariant(option, variant)"
              >
                {{ variant }}
                <span matChipRemove class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
              </mat-chip>
              <input
                [matChipInputFor]="variantsList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false"
                (matChipInputTokenEnd)="addVariant(option, $event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div class="col-auto col-option-remove order-1 order-sm-3">
          <button class="btn btn-remove" (click)="removeOption(option)">
            <span class="icon-remove mt-4" [inlineSVG]="'/icons/icon-close.svg'"></span>
          </button>
        </div>
      </div>
      <!-- OPTIONS -->

      <!-- VARIANTS -->
      <div
        class="row"
        *ngIf="options.length > 0 || getProductVariants().length > 0"
        [ngClass]="{ 'mt-3': options.length === 0 }"
      >
        <div class="col-12 col-sm-auto mt-2" [ngClass]="{ 'mb-3': getProductVariants().length > 0 }">
          <button
            type="button"
            class="btn d-block w-100"
            (click)="generateVariants()"
            [ngClass]="[variantsChange ? 'btn-primary' : 'btn-secondary']"
            [disabled]="!optionsCorrect()"
          >
            {{ 'product.generate_variants' | translate }}
          </button>
        </div>
        <div class="col-12 mt-3" *ngFor="let productVariant of getProductVariants().controls; let i = index">
          <form [formGroup]="productVariant | formGroup">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-2 form-field-container">
                <div class="form-field-label">#{{ i + 1 }} {{ 'form.field.variant' | translate }}</div>
                <div class="variant-descriptors">
                  <span class="variant-name" *ngFor="let descriptor of productVariant.get('descriptors').value">{{
                    descriptor.value
                  }}</span>
                </div>
              </div>

              <div class="col-6 col-sm-4 col-md-2 form-field-container">
                <div class="form-field-label">
                  {{ 'form.field.photo' | translate }}
                </div>
                <lib-photo-upload
                  [photo]="
                    productVariant.get('media').value?.length > 0 ? productVariant.get('media').value[0]?.url1x : null
                  "
                  [layout]="2"
                  [hideInfo]="true"
                  (fileChange)="photoVariantChange(productVariant, $event)"
                ></lib-photo-upload>
              </div>

              <div
                class="form-field-container"
                *ngFor="let key of variantOrder"
                [ngClass]="[key === 'instances_available' ? 'col-6 col-sm-4 col-md-2' : 'col-6 col-sm-4 col-md-3']"
              >
                <div class="form-field-label">
                  {{ 'form.field.' + key | translate }}
                </div>
                <mat-form-field class="form-field" [floatLabel]="'never'">
                  <input matInput [type]="key === 'instances_available' ? 'number' : 'text'" [formControlName]="key" />
                  <mat-error *ngIf="productVariant.get(key).invalid">
                    <ng-container *ngIf="productVariant.get(key).errors.required">
                      {{ 'form.error.required' | translate }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- VARIANTS -->
    </ng-container>
  </div>
</div>
