import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShortType } from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class ResolverApiService {
  constructor(private http: HttpClient) {}

  resolverByPermalink(permalink: string) {
    return this.http.get<{ id: string; type: ShortType }>(`/api/v1.0/resolver/${permalink}`);
  }
}
