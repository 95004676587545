export const PRODUCT_LABELS = [
  {
    key: 'product.label.none',
    value: null,
  },
  {
    key: 'product.label.locally_made',
    value: 'locally_made',
  },
  {
    key: 'product.label.delivered_today',
    value: 'delivered_today',
  },
  {
    key: 'product.label.latest_items',
    value: 'latest_items',
  },
  {
    key: 'product.label.offer',
    value: 'offer',
  },
  {
    key: 'product.label.sold_out',
    value: 'sold_out',
  },
  {
    key: 'product.label.custom',
    value: 'custom',
  },
];
