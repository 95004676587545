<div class="dialog-panel message-dialog">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div class="dialog-header">
      <h1 class="dialog-title">{{ data.title | translate }}</h1>
    </div>
    <p class="mt-3 mb-4">{{ data.confirmQuestion | translate }}</p>
    <div class="row">
      <div class="col-6">
        <button class="btn btn-primary btn-pink w-100" (click)="confirm()">{{ 'global.yes' | translate }}</button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary btn-pink w-100" (click)="closeDialog()">{{ 'global.no' | translate }}</button>
      </div>
    </div>
  </div>
</div>
