import { DeliveryType } from '../enums/DeliveryType';
import { OrderStatus } from '../enums/OrderStatus';
import { Buyer } from './Buyer';
import { CartSummary } from './CartSummary';
import { DeliveryAddress } from './Delivery';
import { Shop } from './Shop';

export interface Order {
  id: string;
  number: string;
  shop_id: string;
  user_id: string;
  inserted: string;
  status: OrderStatus;
  user_status: string;
  payment_url: string;
  context: string;
  shop: Shop;
  buyer: Buyer;
  delivery: DeliveryAddress;
  delivery_method: DeliveryType;
  summary: CartSummary;
  account_exists: boolean;
  timeslot: string;
  pay_after: boolean;
  timeslot_interval: number;
  comment: string;
  paid_at: string;
  warning: string;
  isSelected?: boolean;
  refund_status: string;
}
