import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@digilize/shared/feature/modules/auth/src/lib/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-dialog-update-fb-details',
  templateUrl: './dialog-update-fb-details.component.html',
  styleUrls: ['./dialog-update-fb-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogUpdateFbDetailsComponent implements OnInit {
  takeUntil$ = new Subject();

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
  });
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogUpdateFbDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      Object.keys(this.data).forEach((key) => {
        this.getFormControl(key)?.setValue(this.data[key]);
      });

      if (this.data.error === 'user_already_exist') {
        this.getFormControl('email').setErrors({ alreadyExist: true });
        this.getFormControl('email').markAllAsTouched();
      }
    }, 500);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      const formObj = this.form.getRawValue();
      localStorage.setItem('updateDetails', JSON.stringify(formObj));
      this.authService.facebookRedirect();
    }
  }
}
