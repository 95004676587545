import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Media, ProductDescriptor, ProductVariant } from '@shared/definitions';

@Component({
  selector: 'lib-form-product-details',
  templateUrl: './form-product-details.component.html',
  styleUrls: ['./form-product-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormProductDetailsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  @Input() variants: ProductVariant[] = [];
  @Input() descriptors: ProductDescriptor[] = [];
  @Input() productId: string;
  @Input() voucherActive: boolean;
  @Input() voucherGenerateCodes: boolean;
  open = true;
  options: Option[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  variantsChange;

  order = ['sku', 'instances_available', 'barcode', 'instances_infinite', 'instances_allow_exceed_amount'];

  variantOrder = ['sku', 'instances_available', 'barcode'];

  constructor() {}

  ngOnInit(): void {
    if (this.variants?.length > 0 && this.descriptors?.length > 0) {
      this.initDescriptors();
      this.initVariants();
    }
  }

  initDescriptors() {
    this.descriptors.forEach((descriptor) => {
      if (!this.options.some((obj) => obj.name === descriptor.name)) {
        this.options.push({
          name: descriptor.name,
          variants: [descriptor.value],
        });
      } else if (
        !this.options.find((obj) => obj.name === descriptor.name).variants.some((obj2) => obj2 === descriptor.value)
      ) {
        this.options.find((obj) => obj.name === descriptor.name).variants.push(descriptor.value);
      }
    });
  }

  initVariants() {
    this.variants.forEach((variant) => {
      this.clearMainFields();

      const productVariant: UntypedFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(variant.id),
        sku: new UntypedFormControl(variant.sku),
        barcode: new UntypedFormControl(variant.barcode),
        instances_available: new UntypedFormControl(variant.instances_available),
        media_ids: new UntypedFormControl(variant.media_ids),
        media: new UntypedFormControl(variant.media),
        descriptors: new UntypedFormControl(variant.descriptors),
      });

      this.getProductVariants().push(productVariant);
    });
  }

  getFormControl(name) {
    return this.form.get(name);
  }

  addOption() {
    this.options.push({
      name: '',
      variants: [],
    });
  }

  removeOption(option) {
    this.options = this.options.filter((obj) => obj !== option);
    this.variantsChange = true;
  }

  removeVariant(option: Option, variant) {
    option.variants = option.variants.filter((obj) => obj !== variant);
    this.variantsChange = true;
  }

  addVariant(option: Option, $event: MatChipInputEvent) {
    if (($event.value || '').trim() && !option.variants.some((obj) => obj === $event.value)) {
      option.variants.push($event.value);
    }
    $event.input.value = '';
    this.variantsChange = true;
  }

  generateVariants() {
    this.variantsChange = false;
    this.clearFormArray(this.getProductVariants());
    const input = [];
    this.options.forEach((option) => {
      input.push({
        [option.name]: option.variants,
      });
    });

    const variants = this.cartesianVariants(input, null);
    variants.forEach((variant) => {
      const descriptors: ProductDescriptor[] = [];
      Object.keys(variant).forEach((key) => {
        descriptors.push({
          name: key,
          value: variant[key],
        });
      });

      const productVariant: UntypedFormGroup = new UntypedFormGroup({
        sku: new UntypedFormControl(),
        barcode: new UntypedFormControl(),
        instances_available: new UntypedFormControl(),
        media_ids: new UntypedFormControl([]),
        media: new UntypedFormControl(),
        descriptors: new UntypedFormControl(descriptors),
      });

      this.getProductVariants().push(productVariant);
    });

    this.clearMainFields();
  }

  cartesianVariants(input, current) {
    if (!input || !input.length) {
      return [];
    }

    const head = input[0];
    const tail = input.slice(1);
    let output = [];

    for (const key of Object.keys(head)) {
      head[key].forEach((obj) => {
        const newCurrent = this.copy(current);
        newCurrent[key] = obj;
        if (tail.length) {
          const productOfTail = this.cartesianVariants(tail, newCurrent);
          output = output.concat(productOfTail);
        } else {
          output.push(newCurrent);
        }
      });
    }
    return output;
  }

  copy(obj) {
    const res = {};
    if (obj) {
      for (const p of Object.keys(obj)) {
        res[p] = obj[p];
      }
    }
    return res;
  }

  clearMainFields() {
    if (this.getProductVariants().length > 0) {
      this.getFormControl('sku').setValue(null);
      this.getFormControl('barcode').setValue(null);
      this.getFormControl('instances_available').setValue(null);
    }
  }

  getProductVariants(): UntypedFormArray {
    return this.getFormControl('variants') as UntypedFormArray;
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  photoVariantChange(variant: UntypedFormGroup | AbstractControl, $event: Media) {
    variant.get('media_ids').setValue($event ? [$event.id] : []);
    variant.get('media').setValue([$event]);
  }

  optionsCorrect(): boolean {
    let correct = true;
    this.options.forEach((option) => {
      if (option.name === '') {
        correct = false;
      } else if (option.variants.length === 0) {
        correct = false;
      }
    });

    return correct;
  }

  updateTrackingAmount(checked) {
    this.getFormControl('instances_infinite').setValue(!checked);
  }
}

export interface Option {
  name: string;
  variants: string[];
}
