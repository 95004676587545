import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignApiService } from '@digilize/shared/data/services';
import { ALERT_REQUEST_SENT } from '@shared/definitions';
import { AlertService } from '@digilize/shared/feature/modules/alert'; 

@Component({
  selector: 'lib-dialog-demo',
  templateUrl: './dialog-demo.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDemoComponent implements OnInit {
  loading = false;
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    company_name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone_number: new UntypedFormControl('', [Validators.required]),
  });
  order = ['name', 'company_name', 'email', 'phone_number'];

  constructor(
    public dialogRef: MatDialogRef<DialogDemoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private campaignApiService: CampaignApiService
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;

      this.campaignApiService.demoRequest(this.form.getRawValue()).subscribe(
        (response: any) => {
          this.alertService.setAlert(ALERT_REQUEST_SENT);
          this.closeDialog();
        },
        (error) => {
          this.alertService.handleApiError(error.error.error);
          this.loading = false;
        }
      );
    }
  }
}
