import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountData } from '../models/account';

@Injectable({
  providedIn: 'root',
})
export class SharedAccountDataService {
  private dataSubject = new BehaviorSubject<AccountData>(null);
  public data$ = this.dataSubject.asObservable();

  sendData(data: any): void {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }

  updateData(param: string, value: any) {
    const currentData = this.dataSubject.getValue();
    if (currentData && typeof currentData === 'object') {
      const updatedData = { ...currentData };
      updatedData[param] = value;
      this.dataSubject.next(updatedData);
    }
  }
}
