<div class="card-slider-container">
  <div *ngIf="!disabledArrows" class="card-slider-nav" [ngClass]="{ show: showArrows }">
    <button class="btn btn-slider btn-next" [inlineSVG]="'/icons/icon-next.svg'" (click)="changeSlide('next')"></button>
    <button class="btn btn-slider btn-prev" [inlineSVG]="'/icons/icon-prev.svg'" (click)="changeSlide('prev')"></button>
  </div>
  <div class="card-slider" *ngIf="breakpoints">
    <ngx-glide
      [type]="'slider'"
      [showArrows]="false"
      [showBullets]="showBullets"
      [dragThreshold]="false"
      [breakpoints]="breakpoints"
      [swipeThreshold]="15"
      [bound]="true"
    >
      <div class="slide" *ngFor="let item of items">
        <div class="slide-container">
          <lib-card-category *ngIf="type === 'category'" [category]="item"></lib-card-category>
          <lib-card-photo *ngIf="type === 'photo'" [img]="item" (itemClicked)="onItemClick($event)"></lib-card-photo>
          <lib-card-broadcast *ngIf="type === 'broadcast'" [broadcast]="item"></lib-card-broadcast>
          <lib-card-event *ngIf="type === 'event'" (itemClicked)="onItemClick($event)" [event]="item"></lib-card-event>
          <lib-card-product
            *ngIf="type === 'product'"
            [product]="item"
            [shop]="item.shops[0]"
            [displayShopName]="true"
            [slider]="true"
          ></lib-card-product>
        </div>
      </div>
    </ngx-glide>
  </div>
</div>
