import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataApiResponse, ResearchReminder } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ResearchRemindersApiService {
  constructor(private http: HttpClient) {}

  readonly BASE_RESEARCH_REMINDERS_URL = '/api/v1.0/research-reminders';

  addResearchReminder = (addResearchReminderBody: Partial<ResearchReminder>) =>
    this.http.post<ResearchReminder>(`${this.BASE_RESEARCH_REMINDERS_URL}`, addResearchReminderBody);

  editResearchReminder = (editResearchReminderBody: Partial<ResearchReminder>) =>
    this.http.put<ResearchReminder>(`${this.BASE_RESEARCH_REMINDERS_URL}`, editResearchReminderBody);

  getResearchReminders = (params: any) =>
    this.http.get<DataApiResponse<ResearchReminder[]>>(`${this.BASE_RESEARCH_REMINDERS_URL}`, { params });

  getResearchReminder = (id: string) => this.http.get<ResearchReminder>(`${this.BASE_RESEARCH_REMINDERS_URL}/${id}`);

  deleteResearchReminder = (id: string) => this.http.delete(`${this.BASE_RESEARCH_REMINDERS_URL}/${id}`);
}
