import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CampaignApiService } from '@digilize/shared/data/services';
import { ALERT_MAX_CAMPAIGNS, Campaign, ShopPostStatus } from '@shared/definitions';
import { addWebp } from '@digilize/shared/utils/helpers';
import { AlertService } from '@digilize/shared/feature/modules/alert/src';

@Component({
  selector: 'lib-card-campaign',
  templateUrl: './card-campaign.component.html',
  styleUrls: ['./card-campaign.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardCampaignComponent implements OnInit {
  @Input() campaign: Campaign;
  @Input() companyId: string;
  @Input() shopId: string;
  @Output() selectChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading;

  constructor(private campaignApiService: CampaignApiService, private alertService: AlertService) {}

  ngOnInit(): void {}

  setCampaign(active: boolean) {
    this.loading = true;

    if (active) {
      this.campaignApiService.selectCampaign(this.companyId, this.shopId, this.campaign.id).subscribe(
        (response: any) => {
          this.selectChange.emit(true);
          this.loading = false;
        },
        (error) => {
          if (error.error.error.info?.kind === 'maximum_quantity_has_been_reached') {
            this.alertService.setAlert(ALERT_MAX_CAMPAIGNS);
          } else {
            this.alertService.handleApiError(error.error.error);
          }
          this.selectChange.emit(false);
          this.loading = false;
        }
      );
    } else {
      this.campaignApiService.unselectCampaign(this.companyId, this.shopId, this.campaign.id).subscribe(
        (response: any) => {
          this.selectChange.emit(false);
          this.loading = false;
        },
        (error) => {
          this.alertService.handleApiError(error.error.error);
          this.selectChange.emit(true);
          this.loading = false;
        }
      );
    }
  }

  isCampaignAlertVisible(): boolean {
    const {status, photos_customer_required} = this.campaign;
    return photos_customer_required &&
     status !== ShopPostStatus.Error &&
     status !== ShopPostStatus.ToBePosted &&
     status !== ShopPostStatus.Posted
  }


  get addWebp() {
    return addWebp();
  }
}
