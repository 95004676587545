<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'product.request_info' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <p class="info">
          {{ 'product.request_info_copy' | translate }}
        </p>
        <div class="form-field-container" *ngFor="let key of order">
          <div class="form-field-label" [ngClass]="{ required: key !== 'message' }">
            {{ 'form.field.' + key | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <ng-container *ngIf="key === 'message'; else input">
              <textarea
                matInput
                [formControlName]="key"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="6"
                maxlength="500"
              ></textarea>
            </ng-container>
            <ng-template #input>
              <input matInput [formControlName]="key" required />
            </ng-template>
            <mat-error *ngIf="getFormControl(key).invalid">
              <ng-container *ngIf="getFormControl(key).errors.email">
                {{ 'form.error.invalid_email' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl(key).errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.send' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
