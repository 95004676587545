<div class="lib-select-plain-container" *ngIf="options.length > 0">
  <div class="select-primary">
    <span
      class="select-arrow"
      [inlineSVG]="
        iconBaseNameAndUrl ? iconBaseNameAndUrl : '/icons/icon-arrow-b-' + (showOptionsList ? 'up' : 'down') + '.svg'
      "
    ></span>

    <input #searchInput class="select-input" (mousedown)="showOptionsList = true" [readonly]="true" />

    <div class="input-search-placeholder">
      <span>{{ getPlaceholderText() }}</span>
    </div>

    <div
      #scrollContainer
      *ngIf="showOptionsList"
      class="manual-options-list"
      (appClickOutside)="onClickOutside($event)"
      [exception]="searchInput"
    >
      <div class="manual-option" *ngFor="let option of options" (click)="onValueChange(option)">
        <span>
          <ng-container *ngIf="translation; else noTranslate">
            {{ option.key | translate }}
          </ng-container>
          <ng-template #noTranslate>
            {{ option.key }}
          </ng-template>
        </span>
      </div>
    </div>

    <div class="error" *ngIf="errors && errors.required">
      {{ 'form.error.required' | translate }}
    </div>
  </div>
</div>
