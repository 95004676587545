import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-form-pegi',
  templateUrl: './form-pegi.component.html',
  styleUrls: ['./form-pegi.component.scss'],
})
export class FormPegiComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() required: boolean;
  open = true;
  chosenPegis = [];
  separator = '|';

  pegiCodes = ['6', '12', '14', '16', '18', 'G', 'A', 'GT', 'D', 'S', 'DA', 'AL', 'ALT', 'NR', 'T'];

  constructor() {}

  ngOnInit(): void {
    this.chosenPegis = this.getFormControl('pegis')?.value?.split(this.separator) || [];
  }

  togglePegiCode(code) {
    if (this.chosenPegis.indexOf(code) > -1) {
      this.chosenPegis = this.chosenPegis.filter((c) => c !== code);
    } else {
      this.chosenPegis.push(code);
    }

    this.updateForm(this.chosenPegis.join(this.separator));
  }

  isPegiActive(code) {
    return this.chosenPegis.indexOf(code) > -1;
  }

  getFormControl(name) {
    return this.form.get(name);
  }

  getFormKeys() {
    return Object.keys(this.form.controls);
  }

  updateForm(pegis) {
    this.form.get('pegis').setValue(pegis);
  }
}
