import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CloneDecisionTreeVersion,
  DecisionTree,
  DecisionTreeVersion,
  UpdateDecisionTreeVersionDefinition,
} from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class DecisionTreesApiService {
  readonly DECISION_TREES_BASE_URL = '/api/v1.0/decisiontrees';
  constructor(private http: HttpClient) {}

  /* Decision trees */

  postDecisionTree(version: DecisionTree) {
    return this.http.post<DecisionTree>(`${this.DECISION_TREES_BASE_URL}`, version);
  }

  putDecisionTree(version: DecisionTree) {
    return this.http.put<DecisionTree>(`${this.DECISION_TREES_BASE_URL}`, version);
  }

  getDecisionTrees() {
    return this.http.get<DecisionTree>(`${this.DECISION_TREES_BASE_URL}`);
  }

  getDecisionTree(id: string) {
    return this.http.get<DecisionTree>(`${this.DECISION_TREES_BASE_URL}/${id}`);
  }

  deleteDecisionTree(id: string) {
    return this.http.delete(`${this.DECISION_TREES_BASE_URL}/${id}`);
  }

  /* Decision tree versions */

  readonly DECISION_TREES_VERSIONS_BASE_URL = '/api/v1.0/decisiontrees/versions';
  postVersion(version: DecisionTreeVersion) {
    return this.http.post<DecisionTreeVersion>(`${this.DECISION_TREES_VERSIONS_BASE_URL}`, version);
  }

  putVersion(version: DecisionTreeVersion) {
    return this.http.put<DecisionTreeVersion>(`${this.DECISION_TREES_VERSIONS_BASE_URL}`, version);
  }

  getVersions() {
    return this.http.get<DecisionTreeVersion>(`${this.DECISION_TREES_VERSIONS_BASE_URL}`);
  }

  deleteVersion(id: string) {
    return this.http.delete(`${this.DECISION_TREES_VERSIONS_BASE_URL}/${id}`);
  }

  getVersion(id: string) {
    return this.http.get<DecisionTreeVersion>(`${this.DECISION_TREES_VERSIONS_BASE_URL}/${id}`);
  }

  getDefinition(id: string) {
    return this.http.get(`${this.DECISION_TREES_VERSIONS_BASE_URL}/${id}/definition`);
  }

  clone(cloneDecisionTreeVersion: CloneDecisionTreeVersion) {
    return this.http.post<DecisionTreeVersion>(
      `${this.DECISION_TREES_VERSIONS_BASE_URL}/clone`,
      cloneDecisionTreeVersion
    );
  }

  validate(updateDecisionTreeVersion: UpdateDecisionTreeVersionDefinition) {
    return this.http.post<DecisionTreeVersion>(
      `${this.DECISION_TREES_VERSIONS_BASE_URL}/validate`,
      updateDecisionTreeVersion
    );
  }
}
