import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum CreateProductActions {
  CreateProduct = 'createProduct',
  CreateProductFromFeed = 'createProductFromFeed',
}

@Component({
  selector: 'lib-dialog-create-product',
  templateUrl: './dialog-create-product.component.html',
  styleUrls: ['./dialog-create-product.component.scss'],
})
export class DialogCreateProductComponent implements OnInit {
  loading;

  constructor(public dialogRef: MatDialogRef<DialogCreateProductComponent>) {}

  ngOnInit(): void {}

  closeDialog(action?: CreateProductActions) {
    this.dialogRef.close(action);
  }

  get createProductActions() {
    return CreateProductActions;
  }
}
