<div class="drag-drop-info" [ngClass]="{ 'v-hidden': loading }">
  <div class="info" *ngIf="infoVisible">
    {{ 'global.add_photos' | translate }} | {{ items?.length }}
    {{ 'upload.files_used' | translate: { number: maxItems } }}
  </div>
  <div class="info" *ngIf="infoVisible">
    {{ 'upload.upload_file_note' | translate }} <strong>{{ maxFileSize }}Mb</strong>.
    {{ 'upload.upload_img_note' | translate }} <strong>{{ maxImgRes }}px</strong>
  </div>
  <div class="info error" *ngIf="error && !presentationOnly">
    {{ 'form.error.' + error | translate }}
  </div>
</div>
<div *ngIf="error && !presentationOnly" class="dialog-small-photos-error">
  <span class="info error">{{ 'form.error.' + error | translate }}</span>
</div>
<div class="spinner-container p-absolute" *ngIf="loading">
  <mat-spinner class="dark" [diameter]="20"></mat-spinner>
</div>
<div class="drag-drop-container" cdkDropListGroup [ngClass]="{ 'v-hidden': loading }">
  <div
    cdkDropList
    [cdkDropListEnterPredicate]="dropListEnterPredicate"
    (cdkDropListDropped)="dropListDropped($event)"
  ></div>
  <div class="static-image-wrapper" *ngIf="staticImage?.url1x">
    <div class="static-box">
      <div class="photo" [ngStyle]="{ 'background-image': 'url(' + staticImage?.url1x + ')' }"></div>
      <img class="frame-photo" [lazyLoad]="editorImage?.url1x" />
    </div>
  </div>
  <div
    cdkDropList
    *ngFor="let item of items"
    [cdkDropListEnterPredicate]="dropListEnterPredicate"
    (cdkDropListDropped)="dropListDropped($event)"
  >
    <div
      cdkDrag
      class="drag-drop-box"
      (click)="selectImage(item)"
      [class.selected]="selectedImageId && selectedImageId === item.id"
      (cdkDragMoved)="dragMoved($event)"
    >
      <div class="photo" [ngClass]="{ 'hover-dark': !presentationOnly && hoverDelete }" [lazyLoad]="item.url1x">
        <ng-container *ngIf="!presentationOnly">
          <button *ngIf="hoverDelete" class="btn btn-remove" type="button" (click)="removeItem(item)">
            <span class="icon" [inlineSVG]="'/icons/icon-trash.svg'"></span>
          </button>
          <button *ngIf="!hoverDelete" class="btn btn-close" type="button" (click)="removeItem(item)">
            <span class="icon" [inlineSVG]="'/icons/icon-close.svg'"></span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="btn-box-container" *ngIf="maxItems > items?.length && !presentationOnly && !photoEditorEnabled">
    <ng-container *ngIf="!alternativeLook; else alternativeLookTemplate">
      <div class="btn-box">
        <div class="btn-container">
          <button class="btn btn-add-photo" type="button" (click)="inputFiles.click()">
            <span class="icon">+</span>
          </button>
          <input #inputFiles type="file" (change)="onFileChange($event)" multiple="multiple" accept=".jpg,.png,.jpeg" />
        </div>
      </div>
    </ng-container>
    <ng-template #alternativeLookTemplate>
      <div class="alternative-btn-box" (click)="inputFiles.click()">
        <div class="btn-container">
          <button class="btn btn-add-photo" type="button">
            <span class="icon">+</span>
          </button>
          <span class="description">{{ 'global.upload_locale' | translate }}</span>
          <input #inputFiles type="file" (change)="onFileChange($event)" multiple="multiple" accept=".jpg,.png,.jpeg" />
        </div>
      </div>
    </ng-template>
  </div>
  <ng-content></ng-content>
</div>
