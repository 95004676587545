export const dutch = {
  '&Eacute;': 'É',
  '&eacute;': 'é',
  '&Euml;': 'Ë',
  '&euml;': 'ë',
  '&Iuml;': 'Ï',
  '&iuml;': 'ï',
  '&Oacute;': 'Ó',
  '&oacute;': 'ó',
};
