<div class="dialog-panel message-dialog">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div class="dialog-header">
      <h1 class="dialog-title dot">{{ data.title | translate }}</h1>
    </div>
    <p class="mt-3 mb-4">{{ data.confirmQuestion | translate }}</p>
    <div class="btn-container">
      <button class="btn btn-primary delete-btn" (click)="deleteBid()">{{ 'global.remove' | translate }}</button>
      <button class="ml-3 btn btn-primary cancel-btn" (click)="closeDialog()">{{ 'global.cancel' | translate }}</button>
    </div>
  </div>
</div>
