<div #content class="content-wrapper">
  <div (click)="handleTriggerClick($event)">
    <ng-container *ngTemplateOutlet="trigger || defaultTrigger"> </ng-container>
  </div>

  <ng-container *ngIf="isListVisible">
    <div class="content" [ngClass]="dropDirection">
      <ng-container *ngTemplateOutlet="rows"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #defaultTrigger>
  <span class="trigger" [ngClass]="{ active: isListVisible }" inlineSVG="/icons/icon-dotted-btn.svg"></span>
</ng-template>
