import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lib-nested-dropdown',
  templateUrl: './nested-dropdown.component.html',
  styleUrls: ['./nested-dropdown.component.scss'],
})
export class NestedDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('content') childComp: ElementRef;
  @Input() dropData: NestedDropdownData[] = [];
  @Input() selectedValue: boolean;
  @Output() nestedClicked = new EventEmitter();
  @Output() droplistToggle = new EventEmitter<boolean>();
  @ContentChild('customDroplist') customDroplist!: TemplateRef<any>;

  isListVisible = false;
  constructor() {}

  ngAfterViewInit(): void {
    this.childComp.nativeElement.addEventListener('click', () => {
      this.isListVisible = !this.isListVisible;
      this.droplistToggle.emit(this.isListVisible);
    });
  }

  ngOnInit(): void {}

  contentClicked() {}

  handleClickOutside() {
    this.isListVisible = false;
    this.droplistToggle.emit(false);
  }
  handleNestClick(type: any, value: any) {
    this.nestedClicked.emit({ type, value });
  }
}

export interface NestedDropdownData<T = any> {
  title: string;
  name?: string;
  type: T;
  nested: { text: string; value: string | any; selected?: boolean }[];
}
