<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form" class="form-open-hours" *ngIf="formInit">
      <div class="row">
        <div class="col-12">
          <div class="checkbox-container">
            <mat-checkbox class="checkbox" [formControl]="getFormControl('opening_hours_show')">
              <span [innerHTML]="'form.checkboxes.show_opening_hours' | translate"></span>
            </mat-checkbox>
          </div>
        </div>
        <div class="col-12" *ngIf="getFormControl('opening_hours_show').value">
          <div class="row day-container" *ngFor="let key of days">
            <div class="col-6 col-sm-auto align-self-center day">
              {{ 'days.' + key | translate }}
            </div>
            <div class="col-6 col-sm-auto text-right text-sm-left align-self-center day-toggle">
              <mat-slide-toggle [formControl]="getFormControl('opening_hours_' + key + '_open')"></mat-slide-toggle>
            </div>
            <div
              class="col-12 col-sm col-timepicker align-self-center"
              *ngIf="getFormControl('opening_hours_' + key + '_open').value; else closed"
            >
              <div class="timepicker-container">
                <ngx-mat-timepicker
                  [formControlName]="'opening_hours_' + key + '_from'"
                  [showSpinners]="false"
                  [stepHour]="1"
                  [stepMinute]="15"
                  [showSeconds]="false"
                ></ngx-mat-timepicker>
              </div>
              <span class="timepicker-seperator">-</span>
              <div class="timepicker-container">
                <ngx-mat-timepicker
                  [formControlName]="'opening_hours_' + key + '_to'"
                  [showSpinners]="false"
                  [stepHour]="1"
                  [stepMinute]="15"
                  [showSeconds]="false"
                ></ngx-mat-timepicker>
              </div>
            </div>
            <ng-template #closed>
              <div class="col-12 col-sm align-self-center closed">
                {{ 'global.closed' | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
