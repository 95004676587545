<div class="container">
  <div class="close-icon" (click)="close()"><img src="/assets/icons/close-icon.svg" /></div>
  <div class="row m-0">
    <div class="col-12">
      <h2 class="page-title">{{ 'global.create_group' | translate }}</h2>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 middle-container">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="form-label">{{ 'form.field.group_name' | translate }}</div>
            <mat-form-field [floatLabel]="'never'">
              <input matInput formControlName="name" type="text" />
              <mat-error *ngIf="form.get('name').invalid && form.get('name').touched">
                {{ 'global.please_provide_group_name' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <lib-chips-auto
              [emitRemovedItem]="true"
              [options]="label_types"
              [pickedOptions]="form.get('labels').value"
              [placeholder]="'global.labels' | translate"
              [addChipOnFocusOut]="true"
            ></lib-chips-auto>
          </div>
          <div class="col-12">
            <mat-checkbox
              [formControl]="getFormControl('auto_update')"
              (change)="autoUpdateChange($event.checked, 'auto_update')"
            >
              {{ 'global.add_and_remove_automatically_to_group' | translate }}
            </mat-checkbox>
          </div>
          <div class="col-12" *ngIf="getFormControl('auto_update').value">
            <div class="form-label">{{ 'global.filter_preset' | translate }}</div>
            <lib-single-select-search
              [options]="filterPresetOptions"
              [placeholder]="'global.name_of_the_reset' | translate"
              [myFloatLabel]="'never'"
              [wideClass]="true"
              [addDebounce]="300"
              [waitForOptionsChange]="true"
              [suggestionsOff]="true"
              [forceControl]="true"
              [iconPath]="'/icons/icon-arrow-b-'"
              (changed)="getFormControl('filters_preset_id').setValue($event.id)"
            >
            </lib-single-select-search>
            <div *ngIf="filterPresetOptions.length === 0" class="no-presets-info">
              {{ 'global.no_preset_info' | translate }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 bottom-container">
      <div class="bottom-right">
        <ul>
          <!-- <li>
            <button class="btn btn-grey" (click)="checkForm()">checkForm</button>
          </li> -->
          <!-- <li>
            <button class="btn btn-grey" (click)="close()">{{ 'global.cancel' | translate }}</button>
          </li> -->
          <li>
            <button class="btn btn-black" (click)="next()" [disabled]="!form.valid">
              {{ 'global.save' | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
