<div class="card card-product" *ngIf="product && shop" [ngClass]="{ 'card-slider': slider }">
  <div class="row">
    <div class="col-card-background" [ngClass]="[slider ? 'col-12' : 'col-4 col-md-12']">
      <div
        class="card-background"
        [lazyLoad]="product.media?.length > 0 ? product.media[0]?.url1x + '?w=350&h=350&m=2' + addWebp : ''"
      >
        <div class="card-voucher" *ngIf="product.type === 5">
          <div class="icon-voucher-container">
            <span class="icon-voucher" [inlineSVG]="'/icons/icon-voucher.svg'"></span>
          </div>
        </div>
        <div class="card-label" *ngIf="product.label || product.instances === ProductInstances.Available">
          {{ getLabel(product.label) }}
        </div>
      </div>
    </div>
    <div class="col-body" [ngClass]="[slider ? 'col-12' : 'col-8 col-md-12']">
      <div class="card-body d-flex align-items-start flex-column">
        <div class="card-head w-100">
          <div class="card-title dot mb-0">
            <span *ngIf="product.type === 5" class="icon-voucher" [inlineSVG]="'/icons/icon-voucher.svg'"></span>
            <span>{{ product.name }}</span>
          </div>
          <ng-container *ngIf="displayShopName; else productSubtitle">
            <div class="card-sub-title">
              {{ shop.name }}
            </div>
          </ng-container>
          <ng-template #productSubtitle>
            <div class="card-sub-title" *ngIf="product.subtitle">
              {{ product.subtitle }}
            </div>
          </ng-template>
        </div>
        <div class="mt-auto w-100">
          <div class="row flex-nowrap">
            <div class="col align-self-center" *ngIf="product.current_price">
              <div class="card-price" *ngIf="!product.current_price.regular_amount; else discount">
                <ng-container *ngIf="product.current_price.amount > 0 && product.type !== 4; else notk">
                  {{ product.current_price.amount | currency: 'EUR' }}
                </ng-container>
                <ng-template #notk>
                  <span *ngIf="product.current_price.amount > 0; else priceZero"
                    >v.a. {{ product.current_price.amount | currency: 'EUR' }}</span
                  >
                  <ng-template #priceZero>
                    <span *ngIf="product.current_price?.amount === 0">{{ 'global.in_consultation' | translate }}</span>
                  </ng-template>
                </ng-template>
              </div>
              <ng-template #discount>
                <div class="card-price old-price">
                  {{ product.current_price.regular_amount | currency: 'EUR' }}
                </div>
                <div class="card-price new-price">
                  {{ product.current_price.amount | currency: 'EUR' }}
                </div>
              </ng-template>
            </div>
            <div class="col-auto ml-auto align-self-center">
              <a [routerLink]="['/bedrijf', shop.permalink, product.id]" class="btn btn-card">
                {{ 'global.open' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
