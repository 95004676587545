import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Broadcast } from '@shared/definitions';
import { addWebp, getBroadcastIcon, truncateHTML } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-card-broadcast',
  templateUrl: './card-broadcast.component.html',
  styleUrls: ['../card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardBroadcastComponent implements OnInit {
  @Input() broadcast: Broadcast;
  description;

  constructor() {}

  ngOnInit(): void {
    if (this.broadcast) {
      this.description = truncateHTML(this.broadcast.description, 400);
    }
  }

  getBroadcastIcon(broadcast: Broadcast) {
    return getBroadcastIcon(broadcast.type);
  }

  get addWebp() {
    return addWebp();
  }
}
