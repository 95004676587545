<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitClose)="close()"
  (emitPrev)="goPrev()"
></bwh-lib-step-control-bar>
<div class="text-center">
  <div class="main-header">
    <h1 class="main-title">{{ 'global.general_information' | translate }}</h1>
  </div>
  <div class="form-container scrollable-content">
    <form [formGroup]="form">
      <div class="row" *ngIf="dataLoaded && genderCreated">
        <div class="col-12">
          <div class="form-label">{{ 'form.field.form_name' | translate }}<sup>*</sup></div>
          <mat-form-field
            [floatLabel]="'never'"
            [ngClass]="{ 'error-field': getFormControl('first_name').status === 'INVALID' }"
          >
            <input matInput [placeholder]="'form.field.enter_form_name' | translate" formControlName="first_name" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="form-label">{{ 'form.field.form_surname' | translate }}<sup>*</sup></div>
          <mat-form-field
            [floatLabel]="'never'"
            [ngClass]="{ 'error-field': getFormControl('last_name').status === 'INVALID' }"
          >
            <input matInput [placeholder]="'form.field.enter_form_surname' | translate" formControlName="last_name" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="form-label">{{ 'form.field.form_email' | translate }}<sup>*</sup></div>
          <mat-form-field
            [floatLabel]="'never'"
            [ngClass]="{ 'error-field': getFormControl('email').status === 'INVALID' }"
          >
            <input matInput [placeholder]="'form.field.enter_form_email' | translate" formControlName="email" />
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="form-label">{{ 'form.field.form_phone_number' | translate }}</div>
          <mat-form-field
            [floatLabel]="'never'"
            [ngClass]="{ 'error-field': getFormControl('phone').status === 'INVALID' }"
          >
            <input
              matInput
              [type]="'tel'"
              [placeholder]="'form.field.enter_phone_number' | translate"
              formControlName="phone"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-label">{{ 'form.field.form_city' | translate }}<sup>*</sup></div>
          <mat-form-field
            [floatLabel]="'never'"
            [ngClass]="{ 'error-field': getFormControl('city').status === 'INVALID' }"
          >
            <input
              matInput
              [placeholder]="'form.field.enter_form_city' | translate"
              formControlName="city"
              autocomplete="new-password"
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-label">{{ 'form.field.form_country' | translate }}<sup>*</sup></div>
          <lib-single-select-search
            [ngClass]="{ 'error-field': getFormControl('country').status === 'INVALID' }"
            [options]="countriesOptions"
            [control]="getFormControl('country')"
            [myFloatLabel]="'never'"
            [addDebounce]="300"
            [suggestionsOff]="true"
            [iconPath]="'/icons/icon-arrow-b-'"
            [optionByVal]="true"
            [waitForOptionsChange]="true"
            [placeholder]="'form.field.enter_form_country' | translate"
            (changed)="handleFilteredOptionClick($event, 'country')"
          >
          </lib-single-select-search>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-label">{{ 'form.field.form_birthday' | translate }}<sup>*</sup></div>
          <mat-form-field
            class="select-date-container"
            [floatLabel]="'never'"
            [ngClass]="{ 'error-field': getFormControl('birthday').status === 'INVALID' }"
          >
            <input
              class="formatted-date-input"
              type="text"
              formControlName="birthdayDate"
              [value]="form.get('birthdayDate').value"
              (input)="onBirthdateChange($event)"
            />
            <input
              style="display: none"
              matInput
              [matDatepicker]="birthdayPicker"
              [placeholder]="checkIfDateEmpty(form.get('birthday').value)"
              formControlName="birthday"
              (dateChange)="handleDateSelection($event)"
            />
            <span class="date-icon" [inlineSVG]="'/icons/date-icon.svg'" (click)="birthdayPicker.open()"></span>
            <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
            <mat-datepicker #birthdayPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-label">{{ 'form.field.form_gender' | translate }}</div>
          <lib-single-select-search
            [ngClass]="{ 'error-field': getFormControl('gender').status === 'INVALID' }"
            [options]="genderOptions"
            [control]="getFormControl('gender')"
            [myFloatLabel]="'never'"
            [addDebounce]="300"
            [suggestionsOff]="true"
            [setControlValue]="true"
            [iconPath]="'/icons/icon-arrow-b-'"
            [optionByVal]="true"
            [disableSearchOptionFiltering]="true"
            [placeholder]="'form.field.enter_form_gender' | translate"
            (changed)="handleFilteredOptionClick($event, 'gender')"
          >
          </lib-single-select-search>
        </div>
      </div>

      <div *ngIf="emailExists" class="email-exists-container">
        <div class="left-cont">
          {{ 'global.the_email' | translate }} <span>{{ getFormControl('email').value }}</span>
          {{ 'global.already_exists_in_the_system' | translate }}.
        </div>
        <div class="right-cont">
          <button class="btn w-xsm h-sm login-btn" (click)="redirectToLogIn()">
            {{ 'auth.login' | translate }}
          </button>
          <span class="close-icon" (click)="emailExists = false" [inlineSVG]="'/icons/close-icon.svg'"></span>
        </div>
      </div>
    </form>
  </div>
  <ul class="next-button-container">
    <li *ngIf="prevBut === true">
      <button class="btn w-md h-lg primary prev-btn" type="button" (click)="goPrev()">
        {{ 'global.previous' | translate }}
      </button>
    </li>
    <li *ngIf="!editing">
      <button class="btn w-md h-lg secondary" type="submit" [disabled]="!form.valid" (click)="onSubmit()">
        {{ 'global.next_button' | translate }}
      </button>
    </li>
    <li *ngIf="editing">
      <button class="btn w-md h-lg secondary" type="submit" [disabled]="!form.valid" (click)="onEdit()">
        {{ 'global.save' | translate }}
      </button>
    </li>
  </ul>
</div>
