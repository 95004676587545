import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TagsApiService {
  constructor(private http: HttpClient) {}

  getTags = (params: any) =>
    this.http.get('/api/v1.0/tags', {
      params,
    });
}
