import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Alignment } from '@digilize/shared/definitions/src';

@Component({
  selector: 'lib-tile-radio-btn',
  templateUrl: './tile-radio-btn.component.html',
  styleUrls: ['./tile-radio-btn.component.scss'],
})
export class TileRadioBtnComponent implements ControlValueAccessor, OnInit {
  @Input() value: any;
  @Input() isDisabled = false;
  @Input() size: 'big' | 'normal' = 'normal';
  @Input() align: Alignment = 'left';
  model: any;
  onChange = (value: any) => {};
  onTouched = () => {};
  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.ngControl.control.valueChanges.subscribe((value) => {
      // Check to ensure the value wasn't already set (Template driven forms)
      if (this.model === value) return;
      this.writeValue(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.model = value;
  }

  select() {
    if (this.model !== this.value) {
      this.model = this.value;
      this.onChange(this.model);
    }
  }
}
