<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form" *ngIf="taxes.length > 0">
      <form [formGroup]="cost | formGroup" class="form-cost" *ngFor="let cost of getCosts().controls; let i = index">
        <div class="row">
          <div class="col-12 col-sm-11">
            <div class="row">
              <div
                class="form-field-container"
                *ngFor="let key of order"
                [ngClass]="[key === 'description' ? 'col-12' : 'col-12 col-sm-4']"
              >
                <div class="form-field-label" [ngClass]="[key === 'amount' ? 'required' : '']">
                  {{ 'form.field.' + key | translate }}
                  <ng-container *ngIf="key === 'name'">
                    <div
                      class="i-icon"
                      #tooltip="matTooltip"
                      [matTooltip]="'tooltip.product.extra_cost_name' | translate"
                      [matTooltipPosition]="'above'"
                      (click)="tooltip.toggle()"
                    >
                      <span class="i">i</span>
                    </div>
                  </ng-container>
                </div>

                <mat-form-field class="form-field" [floatLabel]="'never'" *ngIf="key === 'name'">
                  <input matInput [formControlName]="key" />
                  <mat-error *ngIf="cost.get(key).invalid">
                    <ng-container *ngIf="cost.get(key).errors.required">
                      {{ 'form.error.required' | translate }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="form-field form-field-currency" [floatLabel]="'never'" *ngIf="key === 'amount'">
                  <span class="currency">€</span>
                  <input
                    matInput
                    currencyMask
                    [formControlName]="key"
                    [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }"
                  />
                  <mat-error *ngIf="cost.get(key).invalid">
                    <ng-container *ngIf="cost.get(key).errors.required">
                      {{ 'form.error.required' | translate }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <ng-container *ngIf="key === 'tax_id'">
                  <lib-select
                    [options]="taxes"
                    [value]="cost.get(key).value"
                    [errors]="cost.get(key).errors"
                    (valueChange)="cost.get(key).setValue($event)"
                  ></lib-select>
                </ng-container>

                <ng-container *ngIf="key === 'description'">
                  <lib-text-editor [control]="cost.get('description') | formControl" [charMax]="100"></lib-text-editor>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-1 col-option-remove">
            <button class="btn btn-remove d-block w-100 text-center p-2 p-sm-0" (click)="removeCost(i)">
              <span class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
            </button>
          </div>
        </div>
      </form>

      <div class="row mt-2">
        <div class="col-12 col-sm-auto">
          <button class="btn btn-secondary d-block w-100" (click)="addCost()">
            {{ 'product.add_cost' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
