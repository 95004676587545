import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import { SharedFeatureComponentsModule } from '@digilize/shared/feature/components/src';
import { CustomTitleWithSuffixStrategyService, I18nModule } from '@digilize/shared/feature/modules/i18n/src';

import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';

import { SharedUiMaterialModule } from '@digilize/shared/ui/material/src/lib/shared-ui-material.module';
import { APP_CONFIG, ENV_CONFIG } from '@digilize/shared/utils/tokens';

import { appConfig } from '../environments/app.config';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './components/shared.module';
import { MainModule } from './views/main.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { ApiInterceptor } from '@digilize/shared/feature/modules/auth/src';
import { GlobalErrorHandler, GlobalErrorHandlerWithLog } from '@digilize/shared/feature/modules/error-handling';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { GtagModule } from 'angular-gtag';

registerLocaleData(localeNl, 'nl-NL');

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets' }),
    HttpClientModule,
    I18nModule,
    SharedModule,
    RecaptchaV3Module,
    AppRoutingModule,
    MainModule,
    SharedFeatureComponentsModule,
    SharedUiComponentsModule,
    SharedUiMaterialModule,
    GtagModule.forRoot({ trackingId: 'G-EYB4RNT28F', trackPageviews: true }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: environment.production ? GlobalErrorHandlerWithLog : GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl-NL',
    },
    {
      provide: ENV_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_CONFIG,
      useValue: appConfig,
    },
    //  { provide: TitleStrategy, useClass: CustomTitleStrategyService },
    { provide: TitleStrategy, useClass: CustomTitleWithSuffixStrategyService },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
