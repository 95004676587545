import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'lib-dialog-layout-inner-x',
  templateUrl: './dialog-layout-inner-x.component.html',
  styleUrls: ['./dialog-layout-inner-x.component.scss'],
})
export class DialogLayoutInnerXComponent {
  @Input() title = '';
  @Input() btnText = '';
  @Input() pageIndicator = '';
  @Input() disableSubmit = false;
  @Input() minHeight = '300px';
  @Input() dialogWarning: string | null;
  @Input() closeIconPath: string;
  @Input() borderRadius: string;
  @ContentChild('content') content!: TemplateRef<any>;
  @ContentChild('actions') actions!: TemplateRef<any>;

  /*  TODO: rename close */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor() {}

  closeDialog() {
    this.close.emit();
  }

  handleConfirm() {
    this.confirm.emit();
  }
}
