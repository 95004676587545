export interface ResearchGroupInviteLinks {
  research_group_id: string;
  amount: number;
  language: string;
}

export interface ResearchGroupInviteLinksResult {
  research_group_id: string;
  research_group_invite_session_id: string;
}
