<div class="container">
  <div class="close-icon" (click)="close()"><img src="/assets/icons/close-icon.svg" /></div>
  <div class="row m-0">
    <div class="col-12">
      <h2 class="page-title">{{ 'global.add_labels' | translate }}</h2>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 middle-container">
      <lib-chips-auto
        *ngIf="dataLoaded"
        (valueChanged)="handleAddLabel($event)"
        (removedOption)="handleRemoveLabel($event)"
        [options]="labels"
        [pickedOptions]="[]"
        [placeholder]="'global.search_add_new_label' | translate"
        [emitAddedItem]="true"
        [emitRemovedItem]="true"
        [positionOfInput]="'top'"
      ></lib-chips-auto>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 bottom-container">
      <div class="bottom-left">
        <span>{{ data.selectedNum }}</span> {{ 'global.candidates_selected' | translate }}
      </div>
      <div class="bottom-right">
        <ul>
          <li>
            <button class="btn btn-grey" (click)="close()">{{ 'global.cancel' | translate }}</button>
          </li>
          <li>
            <button class="btn btn-black" (click)="applyAdd()">{{ 'global.apply' | translate }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
