import { Page } from './Page';

export interface FacebookPages {
  facebook_user_id: string;
  data: Page;
  warning: FacebookShopRevokedEnumerableError;
}

export interface FacebookShopRevokedEnumerableError {
  type: string;
  info: string[];
  request_id: string;
}
