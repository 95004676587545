import { Directive, ElementRef, Renderer2, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[dynamicTextColor]',
})
export class DynamicTextColorDirective implements OnInit, OnChanges {
  @Input('dynamicTextColor') backgroundColor: string;
  @Input() brightnessThreshold: number = 0.5;
  @Input() defaultTextColor: string = 'black';
  @Input() alternativeTextColor: string = 'white';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.updateTextColor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('backgroundColor' in changes) {
      this.updateTextColor();
    }
  }

  private updateTextColor() {
    const brightness = this.calculateBrightness(this.backgroundColor);
    const textColor = brightness > this.brightnessThreshold ? this.defaultTextColor : this.alternativeTextColor;
    this.renderer.setStyle(this.el.nativeElement, 'color', textColor);
  }

  private calculateBrightness(color: string): number {
    // Function to calculate brightness from a color (hex format)
    const hex = color.replace(/#/, '');
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
}
