import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicClassNameByColorService {
  constructor() {}

  getClassName(
    background: string,
    brightnessThreshold: number,
    defaultClassName: string,
    alternativeClassName: string
  ): string {
    const brightness = this.calculateBrightness(background);
    return brightness > brightnessThreshold ? defaultClassName : alternativeClassName;
  }

  private calculateBrightness(color: string): number {
    // Function to calculate brightness from a color (hex format)
    const hex = color.replace(/#/, '');
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
}
