export interface AuctionBid {
  id: string;
  amount: number;
  display_name: string;
  comment: string;
  email: string;
  inserted: string;
  user_id: string;
  declined: string;
}
