import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Research, ResearchLabelGroup } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ResearchesApiService {
  constructor(private http: HttpClient) {}

  readonly BASE_RESEARCHES_URL = '/api/v1.0/researches';

  getResearches = (props: any) => this.http.get<Research[]>(`${this.BASE_RESEARCHES_URL}`, { params: props });

  getResearchLabelGroups = (id: string) =>
    this.http.get<ResearchLabelGroup[]>(`${this.BASE_RESEARCHES_URL}/${id}/label-groups`);
}
