import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxCaptchaModule, SharedUiComponentsModule } from '@digilize/shared/ui/components';
import { SharedUiMaterialModule } from '@digilize/shared/ui/material';
import { SharedUtilsDirectivesModule } from '@digilize/shared/utils/directives';
import { SharedUtilsPipesModule } from '@digilize/shared/utils/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { SafePipeModule } from 'safe-pipe';
import { ImageCropperModule } from 'ngx-image-cropper';

import { CardCampaignComponent } from './card-campaign/card-campaign.component';
import { DialogAuctionDeleteConfirmComponent } from './dialogs/dialog-auction-delete-confirm/dialog-auction-delete-confirm.component';
import { DialogCheckTokenComponent } from './dialogs/dialog-check-token/dialog-check-token.component';
import { DialogChoosePhotosComponent } from './dialogs/dialog-choose-photos/dialog-choose-photos.component';
import { DialogConfirmComponent } from './dialogs/dialog-confirm/dialog-confirm.component';
import { DialogCreateCompanyComponent } from './dialogs/dialog-create-company/dialog-create-company.component';
import { DialogCreateProductComponent } from './dialogs/dialog-create-product/dialog-create-product.component';
import { DialogDeleteBidConfirmComponent } from './dialogs/dialog-delete-bid-confirm/dialog-delete-bid-confirm.component';
import { DialogDemoComponent } from './dialogs/dialog-demo/dialog-demo.component';
import { DialogDraftPostComponent } from './dialogs/dialog-draft-post/dialog-draft-post.component';
import { DialogEntrepreneurComponent } from './dialogs/dialog-entrepreneur/dialog-entrepreneur.component';
import { DialogInvitationPreviewComponent } from './dialogs/dialog-invitation-preview/dialog-invitation-preview.component';
import { DialogMessageComponent } from './dialogs/dialog-message/dialog-message.component';
import { DialogPhotoEditorComponent } from './dialogs/dialog-photo-editor/dialog-photo-editor.component';
import { DialogPlanCampaignComponent } from './dialogs/dialog-plan-campaign/dialog-plan-campaign.component';
import { DialogPlanPostComponent } from './dialogs/dialog-plan-post/dialog-plan-post.component';
import { DialogRequestInfoComponent } from './dialogs/dialog-request-info/dialog-request-info.component';
import { DialogScanQrComponent } from './dialogs/dialog-scan-qr/dialog-scan-qr.component';
import { DialogSelectFeedProductsComponent } from './dialogs/dialog-select-feed-products/dialog-select-feed-products.component';
import { DialogSubscriptionComponent } from './dialogs/dialog-subscription/dialog-subscription.component';
import { DialogVideoComponent } from './dialogs/dialog-video/dialog-video.component';
import { DialogVoucherParserComponent } from './dialogs/dialog-voucher-parser/dialog-voucher-parser.component';
import { DialogVoucherPreviewComponent } from './dialogs/dialog-voucher-preview/dialog-voucher-preview.component';
import { DialogVoucherComponent } from './dialogs/dialog-voucher/dialog-voucher.component';
import { DialogYesNoComponent } from './dialogs/dialog-yes-no/dialog-yes-no.component';
import { DragDropPhotosComponent } from './drag-drop-photos/drag-drop-photos.component';
import { FormEventDetailsComponent } from './form-event-details/form-event-details.component';
import { FormPhotosComponent } from './form-photos/form-photos.component';
import { FormProductDetailsComponent } from './form-product-details/form-product-details.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { PhotoUploadComponent } from './photo-upload/photo-upload.component';
import { PhotoCropComponent } from './photo-crop/photo-crop.component';

const SHARED_FEATURE_COMPONENTS = [
  DialogCreateCompanyComponent,
  DialogCheckTokenComponent,
  DialogVideoComponent,
  DialogPlanPostComponent,
  DialogPlanCampaignComponent,
  DialogSubscriptionComponent,
  DialogRequestInfoComponent,
  DialogEntrepreneurComponent,
  DialogDemoComponent,
  DialogMessageComponent,
  DialogAuctionDeleteConfirmComponent,
  DialogDeleteBidConfirmComponent,
  DialogVoucherComponent,
  DialogVoucherParserComponent,
  DialogConfirmComponent,
  DialogInvitationPreviewComponent,
  DialogDraftPostComponent,
  DialogVoucherPreviewComponent,
  DialogScanQrComponent,
  NotFoundComponent,
  PhotoUploadComponent,
  DragDropPhotosComponent,
  FormEventDetailsComponent,
  FormPhotosComponent,
  FormProductDetailsComponent,
  CardCampaignComponent,
  DialogYesNoComponent,
  DialogChoosePhotosComponent,
  DialogPhotoEditorComponent,
  PhotoEditorComponent,
  DialogCreateProductComponent,
  DialogSelectFeedProductsComponent,
  PhotoCropComponent,
];

@NgModule({
  declarations: [SHARED_FEATURE_COMPONENTS],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    InlineSVGModule,
    SharedUiMaterialModule,
    TranslateModule,
    SharedUiComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedUtilsDirectivesModule,
    CKEditorModule,
    SafePipeModule,
    NgxCaptchaModule,
    ColorPickerModule,
    SharedUtilsPipesModule,
    NgxCurrencyModule,
    RouterModule,
    SharedUiComponentsModule,
    ImageCropperModule,
  ],
  exports: [SHARED_FEATURE_COMPONENTS],
})
export class SharedFeatureComponentsModule {}
