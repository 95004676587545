import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../services/error.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthApiService, MeApiService } from '@digilize/shared/data/services/src';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { HttpHeaders } from '@angular/common/http';
import {
  differentThanOtherValidator,
  matchOtherValidator,
  requiredTrueValidator,
} from '@digilize/shared/utils/validators';
import { SharedAccountDataService } from '../../services/shared-account-data.service';
import { catchError, forkJoin, throwError } from 'rxjs';
import { UserTerms } from '@digilize/shared/definitions/src/lib/interfaces/UserTerms';

@Component({
  selector: 'bwc-manage-password',
  templateUrl: './manage-password.component.html',
  styleUrls: ['./manage-password.component.scss'],
})
export class ManagePasswordComponent implements OnInit {
  form: FormGroup;
  codeForm: FormGroup;
  updatePassError = false;
  checkYourEmail = false;
  password_changed = false;
  resendEmail = false;
  account_activated = false;
  activation_error = false;
  code_mismatch_error = false;
  inputsFields: string[] = ['', '', '', '', '', ''];
  codeChars: string[] = ['', '', '', '', '', ''];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private fb: FormBuilder,
    public errorService: ErrorService,
    private meApiService: MeApiService,
    private authApiService: AuthApiService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ManagePasswordComponent>,
    private sharedAccountDataService: SharedAccountDataService
  ) {}

  ngOnInit(): void {
    this.checkData();
    this.createForms();
  }

  checkData() {
    if (this.data.account_activated) {
      this.account_activated = true;
    }
    if (this.data.activation_error) {
      this.activation_error = true;
    }
    if (this.data.resendEmail) {
      this.resendEmail = true;
    }
    if (this.data.checkYourEmail) {
      this.checkYourEmail = true;
    }
  }

  createForms() {
    if (this.data.type === 'change') {
      this.form = this.fb.group({
        password: ['', Validators.required],
        email: [this.data.email],
        password_new: ['', [Validators.required, differentThanOtherValidator('password')]],
        password_confirm: ['', [Validators.required, matchOtherValidator('password_new')]],
      });
    } else {
      if (this.data.type === 'create') {
        this.form = this.fb.group({
          email: [this.data.email],
          password_new: ['', [Validators.required, Validators.minLength(6)]],
          password_confirm: ['', [Validators.required, matchOtherValidator('password_new')]],
          privacy_policy: [false, [requiredTrueValidator()]],
        });
      } else {
        this.form = this.fb.group({
          password: ['', Validators.required],
          email: [this.data.email],
          password_new: ['', Validators.required],
          password_confirm: ['', Validators.required],
        });
      }
    }
    this.codeForm = this.fb.group({
      dig1: ['', Validators.required],
      dig2: ['', Validators.required],
      dig3: ['', Validators.required],
      dig4: ['', Validators.required],
      dig5: ['', Validators.required],
    });
  }

  checkForm() {
    console.log('form', this.form);
  }

  createPassword() {
    const terms: UserTerms = {
      agreement1: true,
      agreement2: false,
      agreement3: false,
    };

    forkJoin({
      updatePassword: this.meApiService.updatePassword(this.form.value),
      updateTerms: this.meApiService.updateTerms(terms),
    })
      .pipe(
        catchError((error) => {
          return throwError('Error during creating password: ', error);
        })
      )
      .subscribe((data: any) => {
        this.sharedAccountDataService.updateData('password_created', true);
        const token = this.authService.getAccessToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        this.authApiService.activateProfileAndSendMailWithCode(headers).subscribe(
          (resp) => {
            this.checkYourEmail = true;
          },
          (err) => {
            console.error('Error during activation: ', err);
          }
        );
      });
  }

  changePassword() {
    this.updatePassError = false;
    this.meApiService
      .updatePassword(this.form.value)
      .pipe(
        catchError((error) => {
          this.updatePassError = true;
          return throwError(error);
        })
      )
      .subscribe((resp) => {
        this.password_changed = true;
        setTimeout(() => {
          this.dialogRef.close();
        }, 2000);
      });
  }

  resendActivationLink() {
    const token = this.authService.getAccessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.authApiService
      .activateProfileAndSendMailWithCode(headers)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((resp) => {
        this.dialogRef.close();
      });
  }

  getFormControl(formControlName: string) {
    return this.form.get(formControlName) as FormControl;
  }

  getCodeFormControl(formControlName: string) {
    return this.codeForm.get(formControlName) as FormControl;
  }

  activateAccount() {
    const code = this.codeChars.join('');
    const token = this.authService.getAccessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.authApiService
      .activateProfileWithToken({ activate_token: code }, headers)
      .pipe(
        catchError((err) => {
          if (err.error.error.info.field === 'activate_token' && err.error.error.info.kind === 'mismatch') {
            this.code_mismatch_error = true;
          }
          return throwError('Error during activateProfileWithToken', err);
        })
      )
      .subscribe((data) => {
        this.checkYourEmail = false;
        this.account_activated = true;
        this.sharedAccountDataService.updateData('activated', true);
      });
  }

  close() {
    this.dialogRef.close();
  }

  onInput(index: number, event: any): void {
    const text = event.target.value;
    if (text.length === 1) {
      this.codeChars[index] = text;
    }

    if (event.target.value !== '') {
      if (index < this.inputsFields.length - 1) {
        const nextIndex = index + 1;
        const nextInputId = `input_${nextIndex}`;
        const nextInput = document.getElementById(nextInputId);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  }

  onPaste(index: number, event: ClipboardEvent): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text');
    if (pastedText) {
      const pastedChars = pastedText.slice(0, 6).split('');

      pastedChars.forEach((char, i) => {
        if (index + i < this.codeChars.length) {
          this.codeChars[index + i] = char;
        }
      });
    }
  }
}

export interface Data {
  type: string;
  email: string;
  password_created: boolean;
  resendEmail: boolean;
  checkYourEmail: boolean;
  account_activated: boolean;
  activation_error: boolean;
}
