import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { dateBeforeNow, minArrayLengthValidator } from '@digilize/shared/utils/validators/src';
import { EventEmitterService } from '../../../services/event-emitter.service';
import { DataApiResponse, SnackbarType } from '@digilize/shared/definitions/src';
import { Schools } from '../../../models/education';
import { EducationLevels, WorkExperienceLevels } from '../../../enums/enums';
import { enumToArrOfObjs } from '../../../helpers/helpers';
import { CvService } from '../../../services/cv.service';
import { AuthApiService, CompanyApiService, MeApiService } from '@digilize/shared/data/services/src';
import { dateBeforeOtherCustomErrorValidator } from '../../../validators/date-before-custom-error.validators';
import { dateAfterOtherCustomErrorValidator } from '../../../validators/date-after-custom-error.validators';
import { AuthService } from '@digilize/shared/feature/modules/auth/src';
import { catchError, forkJoin, throwError } from 'rxjs';
import { DialogService } from '@digilize/shared/utils/services/src';
import { ManagePasswordComponent } from '../../../components/manage-password/manage-password.component';
import { SkillsService } from '../../../services/skills.service';
import { SharedAccountDataService } from '../../../services/shared-account-data.service';
import { AccountData } from '../../../models/account';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { VacaniesService } from '@bwc/src/app/services/vacanies.service';
import { DialogConfirmComponent } from '@digilize/shared/feature/components/src';
import { TranslateService } from '@ngx-translate/core';
import { CompanyColorsService } from '@bwc/src/app/services/company-colors.service';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { copyToClipboard } from '@digilize/shared/utils/helpers/src';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'bwc-profile-check',
  templateUrl: './profile-check.component.html',
  styleUrls: ['./profile-check.component.scss'],
})
export class ProfileCheckComponent implements OnInit {
  @Output() popupWindow = new EventEmitter();

  form: UntypedFormGroup;
  userProfileData: any[] = [];
  schoolOptions: DataApiResponse<Schools[]>;
  levelOptions = enumToArrOfObjs(EducationLevels);
  showEditCont = false;
  show_gen_info = false;
  show_soft_skills = false;
  show_professional_skills = false;
  show_it_skills = false;
  show_task_skills = false;
  show_your_experience = false;
  show_education = false;
  show_languages = false;
  show_account_forms = false;
  jobEdit = false;
  accFormType: string;
  isLoggedIn = false;
  isActivated = false;
  alreadyApplied = false;
  accountDataChecked = false;
  password_created = true;
  showApplyCont = false;
  showApplyOFLCont = false;
  dataLoaded = false;
  checkingIfApply = false;
  cvSharing = false;
  vacancyDetails: VacancyDetails;
  workLevels = WorkExperienceLevels;
  openFormLinkId: string;
  showUserCvId: string;
  currentDomain: string;
  userId: string;

  constructor(
    private meApiService: MeApiService,
    private authApiService: AuthApiService,
    private vacaniesService: VacaniesService,
    private cvService: CvService,
    private authService: AuthService,
    private fb: FormBuilder,
    private _eventEmitter: EventEmitterService,
    private dialogService: DialogService,
    private skillsService: SkillsService,
    private sharedAccountDataService: SharedAccountDataService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private companyApiServices: CompanyApiService,
    private companyColorsService: CompanyColorsService,
    private snackbarService: SnackbarService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private gtag: Gtag
  ) {
    this.gtag.event('screen_view', {
      app_name: 'Skilld',
      screen_name: 'Users profile',
    });

    this.form = new UntypedFormGroup({
      first_name: new UntypedFormControl('', Validators.required),
      last_name: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: new UntypedFormControl(''),
      photo: new UntypedFormControl(''),
      photo_id: new UntypedFormControl(''),
      city: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      birthday: new UntypedFormControl('', [Validators.required, dateBeforeNow()]),
      gender: new UntypedFormControl(''),
      soft_skills: new UntypedFormArray([]),
      task_skills: new UntypedFormArray([]),
      professional_skills: new UntypedFormArray([]),
      it_skills: new UntypedFormArray([]),
      skills: new UntypedFormArray([]),
      work_history: new UntypedFormArray([], minArrayLengthValidator(1)),
      education: new UntypedFormArray([]),
      languages: new UntypedFormArray([]),
    });
  }

  ngOnInit(): void {
    this.checkParams();
  }

  checkParams() {
    this.route.queryParams.subscribe((params) => {
      const activateToken = params['activate_token'];
      const applyTo = params['apply_to'];
      const applyCompanyId = params['c'];
      this.openFormLinkId = params['ofl'];
      this.showUserCvId = params['uid'];

      if (this.showUserCvId) {
        this.getUserCVData(this.showUserCvId);
        this.cvSharing = true;
        this.renderer.addClass(document.body, 'cv-sharing');
      } else {
        this.cvSharing = false;
        this.checkLogIn();
        this.getData();
        this.listenToSharedAccountData();
        this.renderer.addClass(document.body, 'profile-check');
        this.currentDomain = window.location.origin;
      }

      if (applyCompanyId) {
        this.getCompanyDetails(applyCompanyId);
      }

      if (applyTo) {
        this.checkingIfApply = true;
        forkJoin({
          vacancyDetails: this.vacaniesService.getVacancyDetails(applyTo),
          alreadyApplied: this.vacaniesService.vacancyApplied(applyTo, ''),
        }).subscribe(
          (data: any) => {
            this.checkingIfApply = false;
            const { id, job_title, work_experience_level, language_skills, education_levels } = data.vacancyDetails;
            this.vacancyDetails = { id, job_title, work_experience_level, language_skills, education_levels };
            this.showApplyCont = true;
            this.alreadyApplied = data.alreadyApplied.applied;
          },
          (error) => {
            throwError(error);
            console.error(error);
          }
        );
      }

      if (activateToken) {
        const token = this.authService.getAccessToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        var snapshot = this.route.snapshot;
        const params = { ...snapshot.queryParams };
        delete params.activate_token;
        this.router.navigate([], { queryParams: params });

        this.authApiService.activateProfileWithToken({ activate_token: activateToken }, headers).subscribe(
          (data) => {
            this.isActivated = true;
            const dialogRef = this.dialogService.openDialog(ManagePasswordComponent, {
              disableClose: false,
              width: '670px',
              data: { account_activated: true, email: this.form.value.email },
            });

            var snapshot = this.route.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.activate_token;
            this.router.navigate([], { queryParams: params });
          },
          (err) => {
            console.error('Error during activateProfileWithToken', err);
            const dialogRef = this.dialogService.openDialog(ManagePasswordComponent, {
              disableClose: false,
              width: '670px',
              data: { activation_error: true, email: this.form.value.email },
            });
          }
        );
      }
    });
  }

  private joinOpenFormLink(openId, email) {
    const cookieName = 'ofl-' + openId;
    const openFormCookie = this.cookieService.get(cookieName);

    if (openFormCookie !== email) {
      this.cookieService.set(cookieName, email, { expires: 7, path: '/', sameSite: 'Strict', secure: true });

      this.companyApiServices
        .checkOpenFormLink(openId)
        .pipe(
          catchError((err) => {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 5000,
              data: { msg: 'alert.custom.request_error', translate: true },
            });
            return throwError(err);
          })
        )
        .subscribe(
          (data) => {
            const title$ = this.translateService.get('global.you_just_applied');
            const text$ = this.translateService.get('global.ofl_info');

            forkJoin([title$, text$]).subscribe(([title, text]) => {
              const html = "<div class='popup-info'>" + title + '<span>' + text + '</span></div>';
              const safeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(html);
              this.showApplyOFLCont = false;

              this.dialogService.openDialog(DialogConfirmComponent, {
                disableClose: false,
                width: '750px',
                backdropClass: 'openformlink-backdrop',
                panelClass: 'openformlink-dialog-container',
                data: {
                  confirmBtn: this.translateService.instant('global.continue'),
                  descriptionHtml: safeHtml,
                },
              });
            });
          },
          (error) => {
            throwError(error);
            console.error(error);
          }
        );
    }
  }

  private getCompanyDetails(companyId) {
    this.companyApiServices.getCompanyStyles(companyId).subscribe(
      (data: any) => {
        this.companyColorsService.setCompanyColors(data.colors_model);
      },
      (error) => {
        throwError(error);
        console.error(error);
      }
    );
  }

  checkLogIn() {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  getUserCVData(id: string) {
    this.cvService.getCVbyId(id).subscribe(
      (data) => {
        this.patchUserValues(data, true);
        this.patchUserPhoto(data);
        this.addWorkHistoryData(data);
        this.skillsToFormArray(data, 'soft_skills', this.softSkills);
        this.skillsToFormArray(data, 'professional_skills', this.profSkills);
        this.skillsToFormArray(data, 'task_skills', this.taskSkills);
        this.skillsToFormArray(data, 'it_skills', this.itSkills);
        this.educationtoFormArray(data, 'education', this.education);
        this.languagestoFormArray(data, 'language_skills', this.languages);
      },
      (error) => {
        throwError(error);
        console.error(error);
        this.authService.logout();
      }
    );
  }

  getData() {
    forkJoin({
      getCV: this.cvService.getCV(),
      getMe: this.meApiService.getMe(),
    }).subscribe(
      (res: any) => {
        this.userId = res.getCV.id;
        this.patchUserValues(res.getMe, false);
        this.shareData(res.getMe);
        this.patchUserPhoto(res.getCV);
        this.addWorkHistoryData(res.getCV);
        this.skillsToFormArray(res.getCV, 'soft_skills', this.softSkills);
        this.skillsToFormArray(res.getCV, 'professional_skills', this.profSkills);
        this.skillsToFormArray(res.getCV, 'task_skills', this.taskSkills);
        this.skillsToFormArray(res.getCV, 'it_skills', this.itSkills);
        this.educationtoFormArray(res.getCV, 'education', this.education);
        this.languagestoFormArray(res.getCV, 'language_skills', this.languages);
        this.dataLoaded = true;
      },
      (error) => {
        throwError(error);
        console.error(error);
      }
    );
  }

  patchUserPhoto(data) {
    this.form.patchValue({
      photo: data.photo,
      photo_id: data.photo_id,
    });
  }

  listenToSharedAccountData() {
    this.sharedAccountDataService.data$.subscribe(
      (data: AccountData) => {
        this.isActivated = data?.activated;
        this.password_created = data?.password_created;
        if (this.openFormLinkId && this.isActivated) {
          setTimeout(() => {
            this.dialog.closeAll();
            this.joinOpenFormLink(this.openFormLinkId, data.email);
          }, 1500);
        }

        if (this.openFormLinkId && this.form.value.email && !this.isActivated) {
          this.checkForOpenApply(this.form.value.email);
        }
      },
      (error) => {
        throwError(error);
        console.error(error);
      }
    );
  }

  shareData(data: any) {
    const ob: AccountData = {
      activated: data.activated,
      password_created: data.password_created,
      email: data.email,
    };
    this.sharedAccountDataService.sendData(ob);
  }

  checkForOpenApply(email: string) {
    const cookieName = 'ofl-' + this.openFormLinkId;
    const openFormCookie = this.cookieService.get(cookieName);
    if (openFormCookie !== email) {
      this.showApplyOFLCont = true;
    }
  }

  patchUserValues(data, bd: boolean) {
    this.isActivated = data.activated;
    this.password_created = data.password_created;
    this.accountDataChecked = true;
    this.form.patchValue({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      city: data.city,
      country: data.country,
      birthday: bd ? data.birth_date : data.birthday,
      gender: data.gender,
    });
  }

  addWorkHistoryData(data) {
    data.work_history.forEach((data) => {
      const endDate = data.end_date === '1970-01-01T00:00:00+00:00' || data.end_date === null ? '' : data.end_date;
      const item = this.fb.group({
        position_id: data.position_id,
        position_name: [data.position_name],
        position_name_original: [data.position_name_original, [Validators.required]],
        company_name: [data.company_name],
        job_description: data.job_description,
        order: data.order,
        start_date: [data.start_date, dateBeforeOtherCustomErrorValidator('end_date', 'startDate')],
        end_date: [endDate, dateAfterOtherCustomErrorValidator('start_date', 'endDate')],
        is_current: [data.is_current],
      });
      this.work_history.push(item);
    });
  }

  checkForm() {
    console.log('form', this.form.value);
  }

  private languagestoFormArray(data: any, prop: string, formArray: FormArray, clearFormArr?: boolean): void {
    if (clearFormArr) {
      formArray.clear();
    }
    data[prop].forEach((data) => {
      const item = this.fb.group({
        id: data.id,
        skill_id: [data.skill_id],
        skill_name: [data.skill_name],
        val: data.val,
        rating: data.rating,
        is_native: data.is_native,
      });
      formArray.push(item);
    });
  }

  private educationtoFormArray(data: any, prop: string, formArray: FormArray): void {
    data[prop].forEach((data) => {
      const item = this.fb.group({
        id: data.id,
        country: data.country,
        school_id: data.school_id,
        school_name: data.school_name,
        education: data.education,
        education_name: data.education_name,
        education_category_ids: [data.education_category_ids],
        education_category_names: [data.education_category_names],
        education_level_name: data.education_level_name,
        education_level_id: data.education_level_id,
        start_date: data.start_date,
        end_date: data.end_date,
        is_current: data.is_current,
      });
      formArray.push(item);
    });
  }

  private skillsToFormArray(data: any, prop: string, formArray: FormArray, clearFormArr?: boolean): void {
    if (clearFormArr) {
      formArray.clear();
    }
    data[prop].forEach((data) => {
      const item = this.fb.group({
        id: data.id,
        skill_id: data.skill_id,
        skill_name: data.skill_name,
        rating: data.rating,
      });
      formArray.push(item);
    });
  }

  editPersonalData() {
    this.showEditCont = true;
    this._eventEmitter.setClass(true);
    this.show_gen_info = true;
  }

  editSkills(name: string) {
    this.showEditCont = true;
    this._eventEmitter.setClass(true);
    (this as any)['show_' + name] = true;
    this.popupWindow.emit(true);
  }

  changeSkillRating(req) {
    this.skillsService.postSkillsRank(req).subscribe(
      () => {},
      (error) => {
        console.error('Save Professional skills error:', error);
      }
    );
  }

  addingExp(event) {
    this.jobEdit = event;
  }

  getEditedForm(event: FormGroup) {
    this.form.patchValue(event.value);
  }

  getEditedJob(event) {
    this.work_history = event;
  }

  getEditedEducation(event) {
    const education = event.get('formSections') as FormArray;
    this.education = education;
  }

  getEditedLanguages(event) {
    const languages = event.get('selectedLanguages') as FormArray;
    this.languages = languages;
  }

  getEditedSkills(event, name: string) {
    const skillsType = (this as any)[name];
    skillsType.clear();
    event.value.forEach((data) => {
      const item = this.fb.group({
        id: data.id,
        skill_id: data.skill_id,
        skill_name: data.skill_name,
        rating: data.rating,
      });

      skillsType.push(item);
    });
  }

  closeEditCont(event?) {
    this.showEditCont = false;
    this._eventEmitter.setClass(false);
    if (event !== undefined) {
      (this as any)['show_' + event] = false;
    }

    if (
      event !== undefined &&
      ['soft_skills', 'professional_skills', 'task_skills', 'it_skills', 'languages'].includes(event)
    ) {
      const formArrays = {
        soft_skills: this.softSkills,
        professional_skills: this.profSkills,
        task_skills: this.taskSkills,
        it_skills: this.itSkills,
        languages: this.languages,
      };

      this.refreshData(event, formArrays[event]);
    }
  }

  refreshData(skillsName: string, skillsArr: FormArray) {
    if (skillsName === 'languages') {
      this.cvService.getCV().subscribe((res) => {
        this.languagestoFormArray(res, 'language_skills', skillsArr, true);
      });
    } else {
      this.cvService.getCV().subscribe((res) => {
        this.skillsToFormArray(res, skillsName, skillsArr, true);
      });
    }
  }

  saveProfile(ifCheckEmail?) {
    const dialogRef = this.dialogService.openDialog(ManagePasswordComponent, {
      disableClose: false,
      width: '670px',
      data: {
        type: 'create',
        password_created: this.password_created,
        email: this.form.value.email,
        checkYourEmail: ifCheckEmail ? true : false,
      },
    });
  }

  resendActivationLink() {
    const dialogRef = this.dialogService.openDialog(ManagePasswordComponent, {
      disableClose: false,
      width: '670px',
      data: { resendEmail: true, password_created: this.password_created, email: this.form.value.email },
    });
  }

  applyForTheJob() {
    this.vacaniesService.vacancyApply(this.vacancyDetails.id, {}).subscribe(
      (data) => {
        this.removeParameterFromURL();
        this.showApplyCont = false;
        this.dialogService.openDialog(DialogConfirmComponent, {
          disableClose: false,
          width: '670px',
          data: {
            confirmBtn: this.translateService.instant('global.continue'),
            descriptionHtml: this.translateService.instant('global.thank_you_for_applying_to_the_vacancy'),
          },
        });
      },
      (err) => {
        console.error('Error during applying for the vacancy', err);
      }
    );
  }

  checkSkillState(skillName: string) {
    if (this.form.get(skillName).value.length) {
      return true;
    } else {
      return false;
    }
  }

  removeParameterFromURL() {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    currentUrlTree.queryParams = {};
    this.router.navigateByUrl(currentUrlTree);
  }

  openPrint() {
    window.print();
  }

  shareCv() {
    const address = this.currentDomain + '/profile-check?uid=' + this.userId;
    copyToClipboard(address);
    const dialogRef = this.dialogService.openDialog(DialogConfirmComponent, {
      disableClose: false,
      width: '670px',
      data: {
        confirmBtn: this.translateService.instant('global.ok'),
        cancelBtn: false,
        descriptionHtml: this.translateService.instant('global.shared_cv_link_copied'),
      },
    });
    setTimeout(() => {
      dialogRef.close();
    }, 3500);
  }

  logIn() {
    this.showEditCont = true;
    this.show_account_forms = true;
  }

  logOut() {
    this.authService.logout();
  }

  get softSkills() {
    return this.form.get('soft_skills') as FormArray;
  }

  get profSkills() {
    return this.form.get('professional_skills') as FormArray;
  }

  get taskSkills() {
    return this.form.get('task_skills') as FormArray;
  }

  get itSkills() {
    return this.form.get('it_skills') as FormArray;
  }

  get education() {
    return this.form.get('education') as FormArray;
  }

  set education(value: FormArray) {
    this.form.setControl('education', value);
  }

  get languages() {
    return this.form.get('languages') as FormArray;
  }

  set languages(value: FormArray) {
    this.form.setControl('languages', value);
  }

  get work_history() {
    return this.form.get('work_history') as FormArray;
  }

  set work_history(value: FormArray) {
    this.form.setControl('work_history', value);
  }
}

interface VacancyDetails {
  id: string;
  job_title: string;
  work_experience_level: number;
  language_skills: LanguageSkill[];
  education_levels: EducationLevel[];
}

interface LanguageSkill {
  add: boolean;
  api_skill_name: string;
  id: string;
  is_native: boolean | null;
  is_tk_based: boolean;
  profession_id: string | null;
  rating: number;
  skill_id: string;
  skill_name: string;
}

interface EducationLevel {
  id: string;
  name: string;
}
