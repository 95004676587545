import { ShopType } from '../enums/ShopType';
import { Colors } from './Colors';
import { Media } from './Media';
import { PaymentMethod } from './PaymentMethod';
import { Publishing } from './Publishing';
import { SubCategory } from './SubCategory';

export interface Shop {
  id: string;
  permalink: string;
  active: boolean;
  colors: Colors;
  company_id: string;
  payment_service_provider_id: string;
  name: string;
  description: string;
  chamber_of_commerce_number: string;
  vatid: string;
  street: string;
  house_number: string;
  post_code: string;
  city: string;
  country: string;
  address_visible: boolean;
  phone: string;
  email: string;
  website: string;
  facebook: string;
  linked_in: string;
  instagram: string;
  youtube: string;
  opening_hours_show: boolean;
  opening_hours_monday_open: boolean;
  opening_hours_monday_from: string;
  opening_hours_monday_to: string;
  opening_hours_tuesday_open: boolean;
  opening_hours_tuesday_from: string;
  opening_hours_tuesday_to: string;
  opening_hours_wednesday_open: boolean;
  opening_hours_wednesday_from: string;
  opening_hours_wednesday_to: string;
  opening_hours_thursday_open: boolean;
  opening_hours_thursday_from: string;
  opening_hours_thursday_to: string;
  opening_hours_friday_open: boolean;
  opening_hours_friday_from: string;
  opening_hours_friday_to: string;
  opening_hours_saturday_open: boolean;
  opening_hours_saturday_from: string;
  opening_hours_saturday_to: string;
  opening_hours_sunday_open: boolean;
  opening_hours_sunday_from: string;
  opening_hours_sunday_to: string;
  payment_redirect_url: string;
  payment_service_provider_api_key: string;
  digital_cost: number;
  digital_min: number;
  digital_max: number;
  delivery_cost: number;
  delivery_min: number;
  delivery_max: number;
  delivery_allow_pay_at: boolean;
  delivery_timeslot: boolean;
  delivery_timeslot_interval: number;
  delivery_timeslot_grace_period_minutes: number;
  payment_service_provider_enabled: boolean;
  pick_up_cost: number;
  pick_up_min: number;
  pick_up_max: number;
  pick_up_allow_pay_at: boolean;
  pick_up_timeslot: boolean;
  pick_up_timeslot_interval: number;
  pick_up_timeslot_grace_period_minutes: number;
  photo_id: string;
  photo: Media;
  media_ids: string[];
  media: Media[];
  subcategory_ids: string[];
  subcategories: SubCategory[];
  type: ShopType;
  payment_method_ids: string[];
  payment_methods: PaymentMethod[];
  publishing: Publishing;
  keywords: string;
}
