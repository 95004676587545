import { HttpClient } from '@angular/common/http';
import { EnvConfig } from '@digilize/shared/definitions/src';
import { getTranslationSuffix } from '@digilize/shared/utils/helpers/src';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export function HttpLoaderFactory(httpClient: HttpClient, envConfig: EnvConfig) {
  const SUFFIX = getTranslationSuffix(envConfig);
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: `assets/i18n/`, suffix: SUFFIX },
    { prefix: 'assets/i18n/common/alerts-', suffix: SUFFIX },
    { prefix: 'assets/i18n/common/', suffix: SUFFIX },
  ]);
}
