import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, throwError } from 'rxjs';
import { SkillsService } from '../../../services/skills.service';

@Component({
  selector: 'bwc-soft-skills',
  templateUrl: './soft-skills.component.html',
  styleUrls: ['./soft-skills.component.scss'],
})
export class SoftSkillsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() editing: boolean;
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @Output() dataStep = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitSkills = new EventEmitter();

  form: FormGroup;
  formValid = false;
  minItems = 3;
  maxItems = 8;

  constructor(private skillsService: SkillsService, private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.formGroup !== undefined) {
      this.form = this.createFormGroup();
      this.getSkillsDataEdit();
      this.valChangeFormValidity(this.form);
    } else {
      this.form = this.createFormGroup();
      this.getSkillsData();
      this.valChangeFormValidity(this.form);
    }
  }

  valChangeFormValidity(form: FormGroup) {
    form.valueChanges.subscribe(() => {
      this.checkValidity(this.skillsArray.length);
    });
  }

  createFormGroup(): FormGroup<SkillsForm> {
    return this.fb.group<SkillsForm>({
      skillsArray: this.fb.array<string[]>([]),
      toAddSkillsArray: this.fb.array<string[]>([]),
    });
  }

  getSkillsDataEdit() {
    this.skillsService.getAvailableSoftSkills('').subscribe((data: any) => {
      const available_skills = this.filterDuplicates(data.data, this.softSkills.value);
      this.provideSkillsData(available_skills, this.softSkills.value);
      this.checkValidity(this.skillsArray.value.length);
    });
  }

  getSkillsData() {
    forkJoin({
      userSoftSkillsData: this.skillsService.getSoftSkills(),
      availableSkillsData: this.skillsService.getAvailableSoftSkills(''),
    }).subscribe((data: any) => {
      const available_skills = this.filterDuplicates(data.availableSkillsData.data, data.userSoftSkillsData.data);
      this.provideSkillsData(available_skills, data.userSoftSkillsData.data);
      this.checkValidity(this.skillsArray.value.length);
    });
  }

  provideSkillsData(toAdd: ToAdd[], skills: Skills[]) {
    toAdd.forEach((item) => {
      this.toAddSkillsArray.push(new FormControl({ id: item.id, skill_id: item.id, skill_name: item.name, rating: 0 }));
    });
    skills.forEach((item) => {
      this.skillsArray.push(
        new FormControl({ id: item.id, skill_id: item.skill_id, skill_name: item.skill_name, rating: item.rating })
      );
    });
  }

  filterDuplicates(toFilter, arr) {
    const skillsIds = arr.map((skill) => skill.skill_id);
    return toFilter.filter((element) => !skillsIds.includes(element.id));
  }

  checkValidity(length) {
    if (length < this.minItems || length > this.maxItems) {
      this.formValid = false;
    } else {
      this.formValid = true;
    }
  }

  goPrev() {
    if (!this.formValid) {
      this.dataStep.emit({ previousStep: this.currentStep, step: 5 });
    } else {
      this.saveSoftSkillsData(this.skillsArray.value, false, true);
    }
  }

  onSubmit() {
    if (this.formValid) {
      this.saveSoftSkillsData(this.skillsArray.value);
    }
  }

  onEdit() {
    if (this.editing && this.formValid) {
      this.saveSoftSkillsData(this.skillsArray.value, true);
    }
  }

  saveSoftSkillsData(data, close?, ifBack?) {
    const requestBody = { items: data };
    this.skillsService.putSoftSkills(requestBody).subscribe(
      () => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 5 });
        } else if (close) {
          this.emitSkills.emit(this.skillsArray);
          this.emitClose.emit('soft_skills');
        } else {
          this.dataStep.emit({ previousStep: this.currentStep, step: 7 });
        }
      },
      (error) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 5 });
        }
        throwError('Save Soft skills error:', error);
        console.error('Save Soft skills error:', error);
      }
    );
  }

  checkForm() {
    console.log('form', this.form);
  }

  close() {
    this.emitClose.emit('soft_skills');
  }

  get skillsArray() {
    return this.form.get('skillsArray') as FormArray;
  }

  get toAddSkillsArray() {
    return this.form.get('toAddSkillsArray') as FormArray;
  }

  get softSkills() {
    return this.formGroup.get('soft_skills') as FormArray;
  }
}

export interface SkillsForm {
  skillsArray: FormArray;
  toAddSkillsArray: FormArray;
}

export interface ToAdd {
  add: boolean;
  education_categories: string[];
  id: string;
  name: string;
  professions: string[];
  type: number;
}

export interface Skills {
  id: string;
  rating: number;
  skill_id: string;
  skill_name: string;
}
