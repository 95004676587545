<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitPrev)="goPrev()"
></bwh-lib-step-control-bar>
<div class="file-upload-container scrollable-content">
  <div class="main-header">
    <div class="header-titles" *ngIf="loading" [@fadeInOut]>
      <h1 class="main-title">{{ 'global.we_are_checking_your_cv' | translate }}</h1>
      <h3 class="main-subtitle">{{ 'global.it_can_take_few_seconds' | translate }}</h3>
    </div>
    <div class="header-titles" *ngIf="!loading" [@fadeInOut]>
      <h1 class="main-title">{{ 'global.do_you_have_your_cv' | translate }}</h1>
      <h3 class="main-subtitle">{{ 'global.upload_subtitle' | translate }}</h3>
    </div>
  </div>
  <ng-lottie *ngIf="loading" [options]="options" [@fadeInOut]></ng-lottie>

  <div *ngIf="!loading" [@fadeInOut]>
    <bwh-lib-selected-item
      [name]="uploadData.file.name"
      [iconStatus]="'checked'"
      (actionTriggered)="removeFile()"
      *ngIf="uploadData.file"
    ></bwh-lib-selected-item>

    <div class="error-container" *ngIf="uploadData.error">
      <img src="/assets/icons/error-icon.svg" alt="Error icon" />
      <p class="error-txt">
        <span>{{ 'global.upload_file_error_txt' | translate }}</span>
      </p>
      <p class="error-formats">
        <span *ngFor="let fileType of fileTypes">{{ fileType.label }}</span>
      </p>
    </div>

    <ul class="upload-buttons" [ngClass]="{ error: uploadData.error }">
      <li>
        <button class="btn w-lg h-lg secondary" *ngIf="!uploadData.error" (click)="startCheckingCV()">
          {{ 'global.next' | translate }}
        </button>
        <button class="btn w-lg h-lg secondary" *ngIf="uploadData.error" (click)="changeStep({ step: 1 })">
          {{ 'global.try_again' | translate }}
        </button>
      </li>
    </ul>
  </div>
</div>
