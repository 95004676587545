import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notes, NotesData } from '@digilize/shared/definitions/src';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotesApiService {
  constructor(private http: HttpClient) {}

  createNote = (req): Observable<Notes> => this.http.post<Notes>('/api/v1.0/users/notes', req);

  editNote = (req): Observable<Notes> => this.http.put<Notes>('/api/v1.0/users/notes', req);

  createNoteForVacancy = (req: any): Observable<Notes> => this.http.post<Notes>('/api/v1.0/users/vacancy/notes', req);

  editNoteForVacancy = (req: any): Observable<Notes> => this.http.put<Notes>('/api/v1.0/users/vacancy/notes', req);

  getNotes = (params): Observable<NotesData> =>
    this.http.get<NotesData>('/api/v1.0/users/notes', {
      params: params,
    });

  deleteNote = (id: number) => this.http.delete(`/api/v1.0/users/notes/${id}`);
}
