<div class="photo-upload" [ngClass]="[!layout ? 'layout-1' : 'layout-2']">
  <div
    class="photo-thumbnail"
    [ngStyle]="{ 'padding-bottom': heightRatioInPercent }"
    [lazyLoad]="media?.url1x ? media?.url1x : photo"
    [ngClass]="[media?.url1x || photo ? 'photo-loaded' : '']"
    *ngIf="reload"
  >
    <img
      *ngIf="showFullImg && (media?.url1x !== '' || photo !== '')"
      class="bg-img"
      [src]="media?.url1x ? media?.url1x : photo"
    />
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="dark" [diameter]="20"></mat-spinner>
    </div>
    <span class="sign" *ngIf="sign">{{ sign }}</span>
    <div class="btn-container" [ngClass]="{ active: media?.url1x || photo }" *ngIf="!loading">
      <button type="button" class="btn btn-photo" (click)="inputFile.click()">
        <span class="text">{{ 'global.' + uploadTxt | translate }}</span>
        <span class="icon-photo" [inlineSVG]="'/icons/icon-photo.svg'"></span>
      </button>
      <button *ngIf="cropPhoto && (media || photo)" type="button" class="btn btn-crop-photo" (click)="cropThisPhoto()">
        <span class="text">{{ 'global.' + cropImgtxt | translate }}</span>
        <span class="icon-photo" [inlineSVG]="'/icons/icon-photo.svg'"></span>
      </button>
      <button type="button" class="btn btn-remove" *ngIf="media || photo" (click)="removePhoto()">
        <span class="icon-trash" [inlineSVG]="'/icons/icon-trash.svg'"></span>
      </button>
      <input #inputFile type="file" (change)="onFileChange($event)" accept=".jpg,.png,.jpeg" />
    </div>
  </div>
  <div class="error" *ngIf="error">
    {{ 'form.error.' + error | translate }}
  </div>
  <div class="info" *ngIf="!hideInfo">
    <ng-container *ngIf="!disableFileSizeNote"
      >{{ uploadFileSizeNote | translate }} <strong>{{ maxFileSize }}Mb</strong>.</ng-container
    >
    <ng-container *ngIf="!disableImgNote">
      {{ uploadImgNote | translate }} <strong>{{ maxImgRes }}px</strong></ng-container
    >
    <ng-container *ngIf="extraNote"> {{ extraNote | translate }}</ng-container>
  </div>
  <div class="info"><ng-content></ng-content></div>
</div>
