export const PRODUCT_TYPES = [
  {
    key: 'product.type.physical',
    value: 1,
  },
  {
    key: 'product.type.service',
    value: 2,
  },
  {
    key: 'product.type.activity',
    value: 3,
  },
  {
    key: 'product.type.request_info',
    value: 4,
  },
  {
    key: 'product.type.voucher',
    value: 5,
  },
  {
    key: 'product.type.ticket',
    value: 6,
  },
  {
    key: 'product.type.digital',
    value: 7,
  },
];
