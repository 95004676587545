import { ShopPostStatus } from "../enums/ShopPostStatus";
import { Media } from "./Media";

export interface Campaign {
  name: string;
  company_group_id: string;
  campaign_period_id: string;
  description: string;
  tags: string;
  tags_list: string[];
  default_start: string;
  price: number;
  upload: {
    title: string;
    alt: string;
  };
  photos_customer_required: boolean;
  status: ShopPostStatus | null;
  media_ids: string[];
  media: Media[];
  id: string;
  platforms: string;
  is_selected: boolean;
}
