import { EnvConfig } from '@shared/definitions';

export const environment: EnvConfig = {
  production: false,
  staging: true,
  development: false,
  api: 'https://bwh-staging.azurewebsites.net',
  facebookAppId: '',
  instrumentationKey: '',
  recaptchaSiteKey: '6LejvaMlAAAAAF32NQiKjplL_vS_eO5rP_CNh37k',
};
