import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-img-radio-btn',
  templateUrl: './img-radio-btn.component.html',
  styleUrls: ['./img-radio-btn.component.scss'],
})
export class ImgRadioButtonComponent implements ControlValueAccessor, OnInit {
  @Input() value: any;
  @Input() isDisabled = false;
  @Input() img: string;
  @Input() styleClass: 'error' | 'correct' | false = false;

  model: any;
  onChange = (value: any) => {};
  onTouched = () => {};
  constructor(@Self() public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.ngControl.control.valueChanges.subscribe((value) => {
      // Check to ensure the value wasn't already set (Template driven forms)
      if (this.model === value) return;
      this.writeValue(value);
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.model = value;
  }

  select() {
    if (this.model !== this.value) {
      this.model = this.value;
      this.onChange(this.model);
    }
  }
}
