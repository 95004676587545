<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="'quiz'" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="row">
        <p class="col-12">
          Lorem ipsum dolor sit amet consecaque dolorem inventore fugiat distinctio aspernatur illum vel, assxxcdfvuas
          repellendus autem libero quo. Illo!
        </p>
      </div>
      <div class="row">
        <div class="col-6 form-field-container">
          <div class="form-field-label">
            {{ 'form.field.available_from' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input
              matInput
              [min]="quizMinDate"
              [max]="quizMaxDate"
              autocomplete="off"
              [ngxMatDatetimePicker]="startDatePicker"
              formControlName="start"
            />
            <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(startDatePicker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startDatePicker [showSpinners]="true"> </ngx-mat-datetime-picker>
            <mat-error *ngIf="getFormControl('start').invalid">
              <ng-container *ngIf="getFormControl('start').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl('start').errors.datesRange">
                {{ 'form.error.date_range' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-6 form-field-container">
          <div class="form-field-label">
            {{ 'form.field.available_to' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input
              matInput
              [min]="quizMinDate"
              [max]="quizMaxDate"
              autocomplete="off"
              [ngxMatDatetimePicker]="endDatePicker"
              formControlName="finish"
            />
            <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(endDatePicker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endDatePicker [showSpinners]="true"> </ngx-mat-datetime-picker>
            <mat-error *ngIf="getFormControl('finish').invalid">
              <ng-container *ngIf="getFormControl('finish').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl('finish').errors.datesRange">
                {{ 'form.error.date_range' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
