import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaApiService } from '@digilize/shared/data/services';
import { Media } from '@shared/definitions';
import { isFileSizeCorrect, isImgSizeCorrect } from '@digilize/shared/utils/helpers';
import { DialogService } from '@digilize/shared/utils/services/src';
import { PhotoCropComponent } from '../photo-crop/photo-crop.component';

@Component({
  selector: 'lib-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotoUploadComponent {
  @Input() photo: string;
  @Input() photoDetails: Media;
  @Input() cropPhoto = false;
  @Input() cropOnUpload = false;
  @Input() hideInfo: boolean;
  @Input() layout: number;
  @Input() sign: string;
  @Input() heightRatioInPercent = '100%';
  @Input() maxFileSize = 2; // Mb
  @Input() maxImgRes = 2000; // px;
  @Input() disableFileSizeNote = false;
  @Input() disableImgNote = false;
  @Input() uploadFileSizeNote = 'upload.upload_file_note';
  @Input() uploadImgNote = 'upload.upload_img_note';
  @Input() extraNote: string;
  @Input() uploadTxt: string = 'change';
  @Input() cropImgtxt: string = 'crop_image';
  @Input() showFullImg = false;
  @Input() cropperMinWidth = 0;
  @Input() cropperMinHeight = 0;
  @Input() cropperMaxWidth = 10000;
  @Input() cropperMaxHeight = 10000;
  @Output() fileChange: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() photoCropped = new EventEmitter();
  @ViewChild('inputFile', { static: false })
  public inputFile: ElementRef;
  loading;
  media: Media;
  error;
  reload = true;
  openPhotoCrop = false;

  constructor(private mediaApiService: MediaApiService, private dialogService: DialogService) {}

  onFileChange($event) {
    this.loading = true;
    this.error = null;
    const file = $event.target.files[0];

    if (file) {
      if (!isFileSizeCorrect(file, this.maxFileSize)) {
        this.error = 'file_too_big';
        this.loading = false;
      } else {
        isImgSizeCorrect(file, this.maxImgRes, (callback) => {
          if (this.cropPhoto && this.cropOnUpload) {
            this.cropThisPhoto(file);
            this.loading = false;
          } else if (callback) {
            this.upload(file);
          } else {
            this.error = 'img_too_big';
            this.loading = false;
          }
        });
      }
    }
  }

  upload(file, file_resolution?) {
    this.mediaApiService.uploadFile(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.loading = true;
        } else if (event instanceof HttpResponse) {
          this.loading = false;
          this.media = event.body[0];
          if (file_resolution) {
            this.media.height = file_resolution.height;
            this.media.width = file_resolution.width;
          }
          this.fileChange.emit(this.media);
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  removePhoto() {
    this.media = null;
    this.photo = null;
    this.fileChange.emit(null);
    this.reload = false;
    setTimeout(() => {
      this.reload = true;
    }, 2);
  }

  cropThisPhoto(file?: File) {
    this.openPhotoCrop = true;
    if (file) {
      this.openPhotoCropDialog(file, true);
    } else if (this.photo) {
      this.openPhotoCropDialog(this.photo);
    }
  }

  openPhotoCropDialog(imageURLorFile, imageFile?: boolean) {
    const data = {
      [imageFile ? 'imageFile' : 'imageURL']: imageURLorFile,
      imgMedia: this.photoDetails,
      cropperMinWidth: this.cropperMinWidth,
      cropperMinHeight: this.cropperMinHeight,
      cropperMaxWidth: this.cropperMaxWidth,
      cropperMaxHeight: this.cropperMaxHeight,
    };

    const dialogRef = this.dialogService.openDialog<any>(PhotoCropComponent, {
      width: '1220px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.openPhotoCrop = false;
      if (data?.saved) {
        this.media = null;
        this.photo = data.croppedImageFile;
        this.upload(data.croppedImageFile, data.croppedImageResolution);
        this.photoCropped.emit();
      }
    });
  }
}
