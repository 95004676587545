export interface Location extends LonLat {
  street: string;
  house_number: string;
  post_code: string;
  city: string;
  country: string;
}
export interface LonLat {
  lat: number;
  lon: number;
}
