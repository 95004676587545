/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';
import { SnackbarType } from '@digilize/shared/definitions/src';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { ApplicantsApiService, CompanyApiService } from '@digilize/shared/data/services/src';

@Component({
  selector: 'lib-remove-labels',
  templateUrl: './remove-labels.component.html',
  styleUrls: ['./remove-labels.component.scss'],
})
export class RemoveLabelsComponent implements OnInit {
  dataLoaded = false;
  selectedNum = 0;
  labels: string[] = [];
  labelsToRemove: string[] = [];
  usersIds: string[] = [];
  invitationIds: string[] = [];
  users = false;
  companyId;

  constructor(
    private applicantsApiService: ApplicantsApiService,
    private companyApiService: CompanyApiService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<RemoveLabelsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemoveLabelsComponent
  ) {}

  ngOnInit(): void {
    this.labels = this.data.labels;
    this.selectedNum = this.data.selectedNum;
    this.usersIds = this.data.usersIds;
    this.invitationIds = this.data.invitationIds;
    this.users = this.data.users ? this.data.users : this.users;
    this.companyId = this.data.companyId ? this.data.companyId : null;
    this.dataLoaded = true;
  }

  removePickedOption(option) {
    const indexToRemove = this.labels.indexOf(option);
    if (indexToRemove !== -1) {
      this.labels.splice(indexToRemove, 1);
    }
    this.labelsToRemove.push(option);
  }

  applyRemoved() {
    if (this.users) {
      const req = {
        user_ids: this.usersIds,
        invitation_ids: this.invitationIds,
        labels: this.labelsToRemove,
      };

      this.companyApiService
        .removeBulkLabelsFromUser(this.companyId, req)
        .pipe(
          catchError((err) => {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 5000,
              data: { msg: 'alert.custom.request_error', translate: true },
            });
            return throwError(err);
          })
        )
        .subscribe((data) => {
          this.dialogRef.close(data);
        });
    } else {
      const req = {
        applicant_ids: this.usersIds,
        labels: this.labelsToRemove,
      };
      this.applicantsApiService
        .removeBulkLabelsApplicant(req)
        .pipe(
          catchError((err) => {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 5000,
              data: { msg: 'alert.custom.request_error', translate: true },
            });
            return throwError(err);
          })
        )
        .subscribe((data) => {
          this.dialogRef.close(data);
        });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
