import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MAX_WIDTH_LG, Shop } from '@shared/definitions';
import { addWebp } from '@digilize/shared/utils/helpers';
@Component({
  selector: 'lib-card-shop',
  templateUrl: './card-shop.component.html',
  styleUrls: ['../card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardShopComponent implements OnInit, AfterContentChecked {
  @Input() shop: Shop;
  margin;

  constructor(private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {
    if (this.shop?.description) {
      this.shop.description = this.cutDescription(this.shop.description);
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  cutDescription(text): string {
    return text.length > 100 ? text.slice(0, 100) + '...' : text;
  }

  onCardClick($event) {
    const isButton = ($event.target as HTMLElement).classList.contains('btn');
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth > MAX_WIDTH_LG && !isButton) {
      $event.preventDefault();
    } else {
      this.router.navigate(['/bedrijf', this.shop.permalink]);
    }
  }

  get addWebp() {
    return addWebp();
  }
}
