export const auctionPriceUnitSelectOptions = [
  {
    key: 'marketplace.unit.hour',
    value: 1,
  },
  {
    key: 'marketplace.unit.day',
    value: 2,
  },
  {
    key: 'marketplace.unit.month',
    value: 3,
  },
  {
    key: 'marketplace.unit.square_meter',
    value: 4,
  },
  {
    key: 'marketplace.unit.unit',
    value: 5,
  },
];
