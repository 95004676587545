import { AuctionPriceType } from "../enums/AuctionPriceType";
import { AuctionPriceUnit } from "../enums/AuctionPriceUnit";

export interface MarketplaceAuctionFormDetails {
  title: string;
  group_id: string;
  description: string;
  label: string;
  delivery: boolean;
  pickup: boolean;
  price_type: AuctionPriceType;
  unit?: AuctionPriceUnit;
  price: number;
  bidding: boolean;
  bidding_start_price?: number;
}
