<div class="dialog-panel dialog-voucher">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <div [ngClass]="{ 'v-hidden': loading }">
      <div class="dialog-body p-0" *ngIf="voucher">
        <div class="row flex-nowrap">
          <div class="col voucher-title align-self-center">
            {{ voucher.product_name }}
          </div>
          <div class="col-auto align-self-center">
            <span class="logo-voucher" [inlineSVG]="'/logo/logo-mark.svg'"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <img class="voucher-barcode" [src]="barcode" />
          </div>
          <div class="col-12">
            <div class="voucher-meta-section">
              <div class="d-block voucher-meta-title">
                {{ 'vouchers.data' | translate }}
              </div>
              <div class="d-block voucher-meta">
                {{ 'vouchers.name' | translate }}: {{ voucher.buyer_display_name }}
              </div>
              <div class="d-block voucher-meta">
                {{ 'vouchers.valid_until' | translate }}: {{ voucher.voucher_available_to | date: 'dd-MM-yyyy HH:mm' }}
              </div>
            </div>
            <div class="voucher-meta-section">
              <div class="d-block voucher-meta-title">
                {{ 'vouchers.voucher_code' | translate }}
              </div>
              <div class="d-block voucher-meta">
                {{ voucher.code }}
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="voucher.photo">
            <div class="voucher-img" [lazyLoad]="voucher.photo.url1x + '?w=300&h=300&m=2' + addWebp"></div>
          </div>
          <div class="col-12 voucher-content" [innerHTML]="voucher.voucher_description"></div>
        </div>
      </div>
    </div>
  </div>
</div>
