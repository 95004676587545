import { Cart } from './Cart';

export interface Reservation {
  shop_id: string;
  product_id: string;
  product_variant_id: string;
  cart_id: string;
  cart_line_id: string;
  server_time: string;
  reserved_until: string;
  cart: Cart;
}
