<div class="title-cont">
  <div class="title">{{ title }}</div>
  <div class="edit-button" *ngIf="formArray.length > 0 && editButton && !cvSharing" (click)="edit()">
    <img src="/assets/icons/edit-icon.svg" />{{ 'global.edit' | translate }}
  </div>
</div>
<div class="skills-cont" *ngIf="formArray && formArray.length > 0">
  <div
    class="skill-row"
    [ngClass]="{ grid: control === 'education' || 'job' || 'languages' }"
    *ngFor="let skill of formArray.value; let i = index"
  >
    <ng-container
      *ngIf="
        control === 'soft_skills' ||
        control === 'professional_skills' ||
        control === 'it_skills' ||
        control === 'task_skills' ||
        control === 'tasks_skills'
      "
    >
      <span>{{ skill.skill_name }}</span>
      <lib-stars-rate
        [index]="i"
        [starsRate]="skill.rating"
        [titlesDesc]="'skill_desc_level'"
        (changeLevel)="starClicked($event)"
      ></lib-stars-rate>
    </ng-container>

    <ng-container *ngIf="control === 'languages'">
      <div class="language-name">{{ skill.skill_name }}</div>
      <div class="language-desc"></div>

      <div *ngIf="skill.is_native" class="language-native">{{ 'global.native' | translate }}</div>
      <lib-stars-rate
        *ngIf="!skill.is_native"
        [index]="i"
        [starsRate]="skill.rating"
        [titlesDesc]="'skill_desc_level'"
        (changeLevel)="starClicked($event)"
      ></lib-stars-rate>
    </ng-container>

    <ng-container *ngIf="control === 'education'">
      <div class="left-items">
        <div>{{ skill.school_name }}</div>
        <span>{{ skill.education_name }}</span>
      </div>
      <div class="item-level">{{ skill.education_level_name }}</div>
      <div class="item-years">{{ skill.start_date | monthYear }} - {{ skill.end_date | monthYear }}</div>
    </ng-container>

    <ng-container *ngIf="control === 'work_history'">
      <div class="left-items work-history">
        <div>{{ skill.position_name }}</div>
        <span>{{ skill.education_name }}</span>
      </div>
      <div class="item-level"></div>
      <div class="item-years">{{ skill.start_date | monthYear }} - {{ skill.end_date | monthYear }}</div>
    </ng-container>
  </div>
</div>

<div class="info-cont" *ngIf="(!formArray || formArray.length === 0) && editButton && !cvSharing">
  <h3>{{ infoTxt }}</h3>
  <div class="button-cont">
    <button type="text" class="btn w-lg h-lg add-button" [disabled]="blockGoToEditionBtn" (click)="edit()">
      {{ buttonTxt }}
    </button>
    <div *ngIf="additionalBtnInfo && blockGoToEditionBtn" class="additional-btn-info">
      {{ additionalBtnInfo }}
    </div>
  </div>
</div>
<div class="info-cont cv-sharing" *ngIf="cvSharing && formArray.length === 0">
  <h3>{{ 'global.skill_to_be_filled' | translate }}</h3>
</div>
