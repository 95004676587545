// countries translations sorted by the full name of the country
export const countriesTranslation = [
  { key: 'form.countries.af', value: 'AF' },
  { key: 'form.countries.al', value: 'AL' },
  { key: 'form.countries.dz', value: 'DZ' },
  { key: 'form.countries.as', value: 'AS' },
  { key: 'form.countries.ad', value: 'AD' },
  { key: 'form.countries.ao', value: 'AO' },
  { key: 'form.countries.ai', value: 'AI' },
  { key: 'form.countries.aq', value: 'AQ' },
  { key: 'form.countries.ag', value: 'AG' },
  { key: 'form.countries.ar', value: 'AR' },
  { key: 'form.countries.am', value: 'AM' },
  { key: 'form.countries.aw', value: 'AW' },
  { key: 'form.countries.au', value: 'AU' },
  { key: 'form.countries.at', value: 'AT' },
  { key: 'form.countries.az', value: 'AZ' },
  { key: 'form.countries.bs', value: 'BS' },
  { key: 'form.countries.bh', value: 'BH' },
  { key: 'form.countries.bd', value: 'BD' },
  { key: 'form.countries.bb', value: 'BB' },
  { key: 'form.countries.by', value: 'BY' },
  { key: 'form.countries.be', value: 'BE' },
  { key: 'form.countries.bz', value: 'BZ' },
  { key: 'form.countries.bj', value: 'BJ' },
  { key: 'form.countries.bm', value: 'BM' },
  { key: 'form.countries.bt', value: 'BT' },
  { key: 'form.countries.bo', value: 'BO' },
  { key: 'form.countries.ba', value: 'BA' },
  { key: 'form.countries.bw', value: 'BW' },
  { key: 'form.countries.bv', value: 'BV' },
  { key: 'form.countries.br', value: 'BR' },
  { key: 'form.countries.io', value: 'IO' },
  { key: 'form.countries.vg', value: 'VG' },
  { key: 'form.countries.bn', value: 'BN' },
  { key: 'form.countries.bg', value: 'BG' },
  { key: 'form.countries.bf', value: 'BF' },
  { key: 'form.countries.bi', value: 'BI' },
  { key: 'form.countries.kh', value: 'KH' },
  { key: 'form.countries.cm', value: 'CM' },
  { key: 'form.countries.ca', value: 'CA' },
  { key: 'form.countries.cv', value: 'CV' },
  { key: 'form.countries.ky', value: 'KY' },
  { key: 'form.countries.cf', value: 'CF' },
  { key: 'form.countries.td', value: 'TD' },
  { key: 'form.countries.cl', value: 'CL' },
  { key: 'form.countries.cn', value: 'CN' },
  { key: 'form.countries.cx', value: 'CX' },
  { key: 'form.countries.cc', value: 'CC' },
  { key: 'form.countries.co', value: 'CO' },
  { key: 'form.countries.km', value: 'KM' },
  { key: 'form.countries.cd', value: 'CD' },
  { key: 'form.countries.cg', value: 'CG' },
  { key: 'form.countries.ck', value: 'CK' },
  { key: 'form.countries.cr', value: 'CR' },
  { key: 'form.countries.ci', value: 'CI' },
  { key: 'form.countries.hr', value: 'HR' },
  { key: 'form.countries.cu', value: 'CU' },
  { key: 'form.countries.cy', value: 'CY' },
  { key: 'form.countries.cz', value: 'CZ' },
  { key: 'form.countries.dk', value: 'DK' },
  { key: 'form.countries.dj', value: 'DJ' },
  { key: 'form.countries.dm', value: 'DM' },
  { key: 'form.countries.do', value: 'DO' },
  { key: 'form.countries.ec', value: 'EC' },
  { key: 'form.countries.eg', value: 'EG' },
  { key: 'form.countries.en', value: 'EN' },
  { key: 'form.countries.sv', value: 'SV' },
  { key: 'form.countries.gq', value: 'GQ' },
  { key: 'form.countries.er', value: 'ER' },
  { key: 'form.countries.ee', value: 'EE' },
  { key: 'form.countries.et', value: 'ET' },
  { key: 'form.countries.fk', value: 'FK' },
  { key: 'form.countries.fo', value: 'FO' },
  { key: 'form.countries.fj', value: 'FJ' },
  { key: 'form.countries.fi', value: 'FI' },
  { key: 'form.countries.fr', value: 'FR' },
  { key: 'form.countries.gf', value: 'GF' },
  { key: 'form.countries.pf', value: 'PF' },
  { key: 'form.countries.tf', value: 'TF' },
  { key: 'form.countries.ga', value: 'GA' },
  { key: 'form.countries.gm', value: 'GM' },
  { key: 'form.countries.gz', value: 'GZ' },
  { key: 'form.countries.ge', value: 'GE' },
  { key: 'form.countries.de', value: 'DE' },
  { key: 'form.countries.gh', value: 'GH' },
  { key: 'form.countries.gi', value: 'GI' },
  { key: 'form.countries.gr', value: 'GR' },
  { key: 'form.countries.gl', value: 'GL' },
  { key: 'form.countries.gd', value: 'GD' },
  { key: 'form.countries.gp', value: 'GP' },
  { key: 'form.countries.gu', value: 'GU' },
  { key: 'form.countries.gt', value: 'GT' },
  { key: 'form.countries.gg', value: 'GG' },
  { key: 'form.countries.gn', value: 'GN' },
  { key: 'form.countries.gw', value: 'GW' },
  { key: 'form.countries.gy', value: 'GY' },
  { key: 'form.countries.ht', value: 'HT' },
  { key: 'form.countries.hm', value: 'HM' },
  { key: 'form.countries.hn', value: 'HN' },
  { key: 'form.countries.hk', value: 'HK' },
  { key: 'form.countries.hu', value: 'HU' },
  { key: 'form.countries.is', value: 'IS' },
  { key: 'form.countries.in', value: 'IN' },
  { key: 'form.countries.id', value: 'ID' },
  { key: 'form.countries.ir', value: 'IR' },
  { key: 'form.countries.iq', value: 'IQ' },
  { key: 'form.countries.ie', value: 'IE' },
  { key: 'form.countries.im', value: 'IM' },
  { key: 'form.countries.il', value: 'IL' },
  { key: 'form.countries.it', value: 'IT' },
  { key: 'form.countries.jm', value: 'JM' },
  { key: 'form.countries.jp', value: 'JP' },
  { key: 'form.countries.je', value: 'JE' },
  { key: 'form.countries.jo', value: 'JO' },
  { key: 'form.countries.kz', value: 'KZ' },
  { key: 'form.countries.ke', value: 'KE' },
  { key: 'form.countries.ki', value: 'KI' },
  { key: 'form.countries.xk', value: 'XK' },
  { key: 'form.countries.kw', value: 'KW' },
  { key: 'form.countries.kg', value: 'KG' },
  { key: 'form.countries.la', value: 'LA' },
  { key: 'form.countries.lv', value: 'LV' },
  { key: 'form.countries.lb', value: 'LB' },
  { key: 'form.countries.ls', value: 'LS' },
  { key: 'form.countries.lr', value: 'LR' },
  { key: 'form.countries.ly', value: 'LY' },
  { key: 'form.countries.li', value: 'LI' },
  { key: 'form.countries.lt', value: 'LT' },
  { key: 'form.countries.lu', value: 'LU' },
  { key: 'form.countries.mo', value: 'MO' },
  { key: 'form.countries.mk', value: 'MK' },
  { key: 'form.countries.mg', value: 'MG' },
  { key: 'form.countries.mw', value: 'MW' },
  { key: 'form.countries.my', value: 'MY' },
  { key: 'form.countries.mv', value: 'MV' },
  { key: 'form.countries.ml', value: 'ML' },
  { key: 'form.countries.mt', value: 'MT' },
  { key: 'form.countries.mh', value: 'MH' },
  { key: 'form.countries.mq', value: 'MQ' },
  { key: 'form.countries.mr', value: 'MR' },
  { key: 'form.countries.mu', value: 'MU' },
  { key: 'form.countries.yt', value: 'YT' },
  { key: 'form.countries.mx', value: 'MX' },
  { key: 'form.countries.fm', value: 'FM' },
  { key: 'form.countries.md', value: 'MD' },
  { key: 'form.countries.mc', value: 'MC' },
  { key: 'form.countries.mn', value: 'MN' },
  { key: 'form.countries.me', value: 'ME' },
  { key: 'form.countries.ms', value: 'MS' },
  { key: 'form.countries.ma', value: 'MA' },
  { key: 'form.countries.mz', value: 'MZ' },
  { key: 'form.countries.mm', value: 'MM' },
  { key: 'form.countries.na', value: 'NA' },
  { key: 'form.countries.nr', value: 'NR' },
  { key: 'form.countries.np', value: 'NP' },
  { key: 'form.countries.nl', value: 'NL' },
  { key: 'form.countries.an', value: 'AN' },
  { key: 'form.countries.nc', value: 'NC' },
  { key: 'form.countries.nz', value: 'NZ' },
  { key: 'form.countries.ni', value: 'NI' },
  { key: 'form.countries.ne', value: 'NE' },
  { key: 'form.countries.ng', value: 'NG' },
  { key: 'form.countries.nu', value: 'NU' },
  { key: 'form.countries.nf', value: 'NF' },
  { key: 'form.countries.kp', value: 'KP' },
  { key: 'form.countries.mp', value: 'MP' },
  { key: 'form.countries.no', value: 'NO' },
  { key: 'form.countries.om', value: 'OM' },
  { key: 'form.countries.pk', value: 'PK' },
  { key: 'form.countries.pw', value: 'PW' },
  { key: 'form.countries.ps', value: 'PS' },
  { key: 'form.countries.pa', value: 'PA' },
  { key: 'form.countries.pg', value: 'PG' },
  { key: 'form.countries.py', value: 'PY' },
  { key: 'form.countries.pe', value: 'PE' },
  { key: 'form.countries.ph', value: 'PH' },
  { key: 'form.countries.pn', value: 'PN' },
  { key: 'form.countries.pl', value: 'PL' },
  { key: 'form.countries.pt', value: 'PT' },
  { key: 'form.countries.pr', value: 'PR' },
  { key: 'form.countries.qa', value: 'QA' },
  { key: 'form.countries.re', value: 'RE' },
  { key: 'form.countries.ro', value: 'RO' },
  { key: 'form.countries.ru', value: 'RU' },
  { key: 'form.countries.rw', value: 'RW' },
  { key: 'form.countries.sh', value: 'SH' },
  { key: 'form.countries.kn', value: 'KN' },
  { key: 'form.countries.lc', value: 'LC' },
  { key: 'form.countries.pm', value: 'PM' },
  { key: 'form.countries.vc', value: 'VC' },
  { key: 'form.countries.ws', value: 'WS' },
  { key: 'form.countries.sm', value: 'SM' },
  { key: 'form.countries.st', value: 'ST' },
  { key: 'form.countries.sa', value: 'SA' },
  { key: 'form.countries.sn', value: 'SN' },
  { key: 'form.countries.rs', value: 'RS' },
  { key: 'form.countries.sc', value: 'SC' },
  { key: 'form.countries.sl', value: 'SL' },
  { key: 'form.countries.sg', value: 'SG' },
  { key: 'form.countries.sk', value: 'SK' },
  { key: 'form.countries.si', value: 'SI' },
  { key: 'form.countries.sb', value: 'SB' },
  { key: 'form.countries.so', value: 'SO' },
  { key: 'form.countries.za', value: 'ZA' },
  { key: 'form.countries.gs', value: 'GS' },
  { key: 'form.countries.kr', value: 'KR' },
  { key: 'form.countries.es', value: 'ES' },
  { key: 'form.countries.lk', value: 'LK' },
  { key: 'form.countries.sd', value: 'SD' },
  { key: 'form.countries.sr', value: 'SR' },
  { key: 'form.countries.sj', value: 'SJ' },
  { key: 'form.countries.sz', value: 'SZ' },
  { key: 'form.countries.se', value: 'SE' },
  { key: 'form.countries.ch', value: 'CH' },
  { key: 'form.countries.sy', value: 'SY' },
  { key: 'form.countries.tw', value: 'TW' },
  { key: 'form.countries.tj', value: 'TJ' },
  { key: 'form.countries.tz', value: 'TZ' },
  { key: 'form.countries.th', value: 'TH' },
  { key: 'form.countries.tl', value: 'TL' },
  { key: 'form.countries.tg', value: 'TG' },
  { key: 'form.countries.tk', value: 'TK' },
  { key: 'form.countries.to', value: 'TO' },
  { key: 'form.countries.tt', value: 'TT' },
  { key: 'form.countries.tn', value: 'TN' },
  { key: 'form.countries.tr', value: 'TR' },
  { key: 'form.countries.tm', value: 'TM' },
  { key: 'form.countries.tc', value: 'TC' },
  { key: 'form.countries.tv', value: 'TV' },
  { key: 'form.countries.um', value: 'UM' },
  { key: 'form.countries.vi', value: 'VI' },
  { key: 'form.countries.ug', value: 'UG' },
  { key: 'form.countries.ua', value: 'UA' },
  { key: 'form.countries.ae', value: 'AE' },
  { key: 'form.countries.gb', value: 'GB' },
  { key: 'form.countries.us', value: 'US' },
  { key: 'form.countries.uy', value: 'UY' },
  { key: 'form.countries.uz', value: 'UZ' },
  { key: 'form.countries.vu', value: 'VU' },
  { key: 'form.countries.va', value: 'VA' },
  { key: 'form.countries.ve', value: 'VE' },
  { key: 'form.countries.vn', value: 'VN' },
  { key: 'form.countries.wf', value: 'WF' },
  { key: 'form.countries.eh', value: 'EH' },
  { key: 'form.countries.ye', value: 'YE' },
  { key: 'form.countries.zm', value: 'ZM' },
  { key: 'form.countries.zw', value: 'ZW' },
] as const;
