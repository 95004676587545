import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { ErrorService } from '../../services/error.service';
import { MONTHS_FORMAT } from '../../consts/month-year-format';
import { Option } from '@digilize/shared/definitions/src';
import { ProfessionsService } from '../../services/professions.service';
import { createOptions } from '../../helpers/helpers';
import { Observable } from 'rxjs';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'bwc-your-experience',
  templateUrl: './your-experience.component.html',
  styleUrls: ['./your-experience.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MONTHS_FORMAT },
  ],
})
export class YourExperienceComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() prevBut: boolean;
  @Input() expIndex: number;
  @Input() jobsList: Option[];
  @Input() loadedJobsFullList;
  @Output() emitClose = new EventEmitter();
  @Output() emitExp = new EventEmitter();
  @Output() clickEdit = new EventEmitter();

  date = new FormControl(moment());
  searchValue = '';

  constructor(public errorService: ErrorService, private professionsService: ProfessionsService) {}

  ngOnInit(): void {}

  setControlValuesFromOption(option: Option, index, controlName) {
    const fullObject = this.loadedJobsFullList.find((obj) => obj.id === option.id);

    if (fullObject.profession_alias_id) {
      this.getFormControl(index, 'position_alias_id').setValue(fullObject.profession_alias_id);
    }

    if (this.getFormControl(index, 'position_name').value === '') {
      this.getFormControl(index, 'position_name').setValue(option.text);
    }

    if (Array.isArray(controlName)) {
      this.getFormControl(index, 'position_alias_name_original').setValue(option.text);
      this.getFormControl(index, controlName[0]).setValue(option.text);
      this.getFormControl(index, controlName[0]).updateValueAndValidity();
      this.getFormControl(index, controlName[1]).setValue(option.id);
    } else {
      this.getFormControl(index, controlName).setValue(option.text);
      this.getFormControl(index, controlName).updateValueAndValidity();
    }

    this.jobsList = [...this.jobsList];
  }

  createOptionsFromSearch(event, index, controlName) {
    this.searchValue = event;
    this.professionsService.getListOfProfessions(event).subscribe((response: any) => {
      const combinedArray = this.loadedJobsFullList.concat(
        response.data.filter((newObj: any) => !this.loadedJobsFullList.some((srcObj) => srcObj.id === newObj.id))
      );
      this.loadedJobsFullList = [...combinedArray];
      this.jobsList = createOptions(response.data);
    });

    this.formGroup.updateValueAndValidity();
  }

  handleScrolledToEndSearch(isVisible: boolean) {
    const params = {
      'search.value': this.searchValue,
      start: this.jobsList.length || 0,
    };

    this.getListOfProfessions(params).subscribe((data) => {
      const newOptions = createOptions(data.data);
      const mergedOptions = [...this.jobsList, ...newOptions];
      this.jobsList = mergedOptions;

      const combinedArray = this.loadedJobsFullList.concat(
        data.data.filter((newObj: any) => !this.loadedJobsFullList.some((srcObj) => srcObj.id === newObj.id))
      );
      this.loadedJobsFullList = [...combinedArray];
    });
  }

  private getListOfProfessions(overrideProps: Object = {}): Observable<any> {
    return this.professionsService.getListOfProfessionsParams({
      'search.value': '',
      length: 10,
      ...overrideProps,
    });
  }

  setControlValuesFromSearch(event, index, controlName) {
    if (Array.isArray(controlName)) {
      this.getFormControl(index, controlName[0]).setValue(event);
      this.getFormControl(index, controlName[1]).setValue(null);
    } else {
      this.getFormControl(index, controlName).setValue(event);
    }
  }

  getFormControl(index: number, controlName: string, checkForAlias?: boolean): FormControl {
    const section = this.selectedJobs.at(index) as FormGroup;
    const aliasName = section.get('position_alias_name_original').value;
    return aliasName && checkForAlias
      ? (section.get('position_alias_name_original') as FormControl)
      : (section.get(controlName) as FormControl);
  }

  stillStudingChange(checked: boolean, index, controlName): void {
    if (checked) {
      this.getFormControl(index, controlName).setValue('');
    }
  }

  setEndYearValue(i: number, controlName, value: any) {
    this.getFormControl(i, controlName).setValue(value.value);
    if (controlName === 'start_year') this.getFormControl(i, 'end_year').updateValueAndValidity();
    if (controlName === 'end_year') this.getFormControl(i, 'start_year').updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  get selectedJobs() {
    return this.formGroup.get('selectedJobs') as FormArray;
  }

  setMonthAndYear(date: Moment, i: number, controlName: string, datepicker: MatDatepicker<Moment>) {
    this.getFormControl(i, controlName).setValue(date.format('YYYY-MM'));
    this.getFormControl(i, controlName).markAsDirty();
    datepicker.close();
  }
}
