<div *ngIf="textPosition !== 'top'; else top" class="container" [ngClass]="{ opposite: textPosition === 'left' }">
  <ng-container [ngTemplateOutlet]="progressBar"></ng-container>

  <span *ngIf="type === 'percent'" class="score" [ngClass]="{ 'align-right': textPosition === 'right' }">
    {{ _current / length | percent }}
  </span>
  <span *ngIf="type === 'fraction'" class="score"> {{ _current }}/{{ length }} </span>
</div>

<ng-template #top>
  <div class="container">
    <div class="top-text">
      <ng-container [ngTemplateOutlet]="topText || defaultTopText"> </ng-container>
    </div>
  </div>

  <ng-container [ngTemplateOutlet]="progressBar"></ng-container>
</ng-template>

<ng-template #progressBar>
  <div
    class="progress-bar"
    [style.maxWidth]="maxBarWidth ? maxBarWidth + 'px' : 'none'"
    [style.width]="maxBarWidth ? maxBarWidth + 'px' : 'none'"
    [style.maxHeight]="maxBarHeight ? maxBarHeight + 'px' : 'none'"
    [style.height]="maxBarHeight ? maxBarHeight + 'px' : 'none'"
    [ngClass]="{ border: withBorder }"
  >
    <div #progress class="progress" [ngStyle]="progressColor && { backgroundColor: progressColor }"></div>
  </div>
</ng-template>

<ng-template #defaultTopText>
  <span *ngIf="type === 'percent'" class="score">
    {{ _current / length | percent }}
  </span>
  <span *ngIf="type === 'fraction'" class="score"> {{ _current }}/{{ length }} </span>
</ng-template>
