<ng-container [ngSwitch]="eventType">
  <span *ngSwitchCase="'Culinair'" class="icon" [inlineSVG]="'/icons/eventType/Culinair.svg'"></span>
  <span *ngSwitchCase="'Festivals'" class="icon" [inlineSVG]="'/icons/eventType/Festivals.svg'"></span>
  <span *ngSwitchCase="'Bussines'" class="icon" [inlineSVG]="'/icons/eventType/Bussines.svg'"></span>
  <span *ngSwitchCase="'Kunst & Cultuur'" class="icon" [inlineSVG]="'/icons/eventType/Kunst & Cultuur.svg'"></span>
  <span *ngSwitchCase="'Markten & Fairs'" class="icon" [inlineSVG]="'/icons/eventType/Markten & Fairs.svg'"></span>
  <span *ngSwitchCase="'Muziek & Theater'" class="icon" [inlineSVG]="'/icons/eventType/Muziek & Theater.svg'"></span>
  <span *ngSwitchCase="'Natuur'" class="icon" [inlineSVG]="'/icons/eventType/Natuur.svg'"></span>
  <span *ngSwitchCase="'Sport & actief'" class="icon" [inlineSVG]="'/icons/eventType/Sport & Actief.svg'"></span>
  <span *ngSwitchCase="'Voor kinderen'" class="icon" [inlineSVG]="'/icons/eventType/Voor kinderen.svg'"></span>
  <span *ngSwitchCase="'Informatief & Netwerk'" class="icon" [inlineSVG]="'/icons/eventType/Other.svg'"></span>
  <span *ngSwitchCase="'Other'" class="icon" [inlineSVG]="'/icons/eventType/Other.svg'"></span>
  <span *ngSwitchDefault class="icon" [inlineSVG]="'/icons/eventType/Other.svg'"></span>
</ng-container>
<span class="text-primary-color">&nbsp;{{ eventType }}</span>
