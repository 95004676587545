<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-body pt-0">
        <p class="info-bold text-center">
          {{ 'global.enter_token' | translate }}
        </p>
        <div class="form-field-container">
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput formControlName="token" required />
            <mat-error *ngIf="getFormControl('token').invalid">
              <ng-container *ngIf="getFormControl('token').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
              <ng-container *ngIf="getFormControl('token').errors.invalid_token">
                {{ 'form.error.invalid_token' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.activate' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
