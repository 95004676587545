import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";
export const ALERT_500: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.internal_server_error',
    translated: true
  },
};
