import { Category } from './Category';
import { Media } from './Media';

export interface Me {
  id: string;
  email: string;
  phone: string;
  password: string;
  password_confirm: string;
  first_name: string;
  last_name: string;
  role: number;
  post_code: string;
  city: string;
  country: string;
  street: string;
  street_number: string;
  agreement1: boolean;
  agreement2: boolean;
  agreement3: boolean;
  subcategory_ids: string[];
  subcategories: Category[];
  permalink: string;
  order: number;
  photo_id: string;
  photo: Media;
}
