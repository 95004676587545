import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'lib-dialog-choose-photos',
  templateUrl: './dialog-choose-photos.component.html',
  styleUrls: ['./dialog-choose-photos.component.scss'],
})
export class DialogChoosePhotosComponent implements OnInit {
  loading;

  constructor(
    public dialogRef: MatDialogRef<DialogChoosePhotosComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
