<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'social_media_connection.connect' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <div class="form-field-container">
          <div class="form-field-label">
            {{ 'form.field.page' | translate }}
          </div>
          <lib-select [options]="pagesSelect" (valueChange)="form.get('page').setValue($event)"></lib-select>
        </div>
      </div>
      <div class="dialog-bottom">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.save' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
