import { Component, Input, OnInit } from '@angular/core';
import { isTruthyOrZero, truncateHTML } from '@digilize/shared/utils/helpers';
import {
  auctionDeliveryMethodDisplayValues,
  AuctionPriceType,
  auctionPriceTypeDisplayValues,
  auctionPriceUnitDisplayValues,
  AuctionStatus,
  auctionStatusSelectOptions,
  auctionTypeSelectOptions,
  MarketplaceAuction,
} from '@shared/definitions';

@Component({
  selector: 'lib-card-auction-board-item',
  templateUrl: './card-auction-board-item.component.html',
  styleUrls: ['./card-auction-board-item.component.scss', '../card.component.scss'],
})
export class CardAuctionBoardItemComponent implements OnInit {
  @Input() marketplaceAuction: MarketplaceAuction;

  AuctionStatus = AuctionStatus;
  constructor() {}

  ngOnInit(): void {}

  getDeliveryTypeName(deliveryMethod: number) {
    return auctionDeliveryMethodDisplayValues[deliveryMethod];
  }

  getAuctionTypeName(auctionType: number) {
    return auctionTypeSelectOptions.find((option) => option.value === auctionType).key;
  }

  isValueTruthyOrZero(value) {
    return isTruthyOrZero(value);
  }

  getTextWithLimit(text: string) {
    return truncateHTML(text, 180);
  }

  statusDisplayName(value: AuctionStatus): string {
    return auctionStatusSelectOptions.find((status) => status.value === value).key || '';
  }

  get priceTypes() {
    return AuctionPriceType;
  }

  get priceTypesDisplayValues() {
    return auctionPriceTypeDisplayValues;
  }

  get priceUnitDisplayValues() {
    return auctionPriceUnitDisplayValues;
  }
}
