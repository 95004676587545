import { Colors, Media } from '@digilize/shared/definitions/src';

export interface Group {
  id: string;
  photo_id: string;
  photo: Media;
  aliases_list: string[];
  aliases: string;
  name: string;
  permalink: string;
  start: string;
  finish: string;
  hidden_in_auction: boolean;
  colors: Colors;
  tenant_id: string;
}
