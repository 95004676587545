export enum ProductType {
  'Unknown' = 0,
  'Physical' = 1,
  'Service' = 2,
  'Activity' = 3,
  'Request' = 4,
  'Voucher' = 5,
  'Ticket' = 6,
  'Digital' = 7,
}
