import { Media } from './Media';

export interface SubCategory {
  id: string;
  name: string;
  description: string;
  photo_id: string;
  photo: Media;
  permalink: string;
  order: number;
  highlighted: boolean;
  broadcast_id: string;
  broadcast_permalink: string;
  translation_ids: string[];
  translations: SubcategoryTranslation[];
  tenant_id: string;
}

export interface SubcategoryTranslation {
  id: string;
  language: string;
  name: string;
  description: string;
  tenant_id: string;
}
