import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwitchableComponent } from '@digilize/shared/definitions/src';
import {
  DialogPwdForgotComponent,
  DialogSigninComponent,
  DialogSignupComponent,
} from '@digilize/shared/feature/modules/auth/src';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-sign-in-up',
  templateUrl: './sign-in-up.component.html',
  styleUrls: ['./sign-in-up.component.scss'],
})
export class SignInUpComponent implements OnInit, OnDestroy {
  @Input() visible: Visible = 'signIn';
  @Input() signInProps: SwitchableComponent['props'];
  @Input() signUpProps: SwitchableComponent['props'];
  @Input() closeCallback: (() => void) | null = null;
  components: SwitchableComponent[];
  takeUntil$ = new Subject();

  constructor(
    @Optional() private dialogRef: MatDialogRef<DialogSignupComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private data: SignInUpData,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    if (data) {
      this.visible = this.data.visible || this.visible;
      this.signInProps = this.data.signInProps;
      this.signUpProps = this.data.signUpProps;
    }
  }

  ngOnInit(): void {
    this.setCaptchaVisibility('visible');

    this.components = [
      {
        component: DialogSigninComponent,
        events: [
          {
            emitter: 'toSignUpClicked',
            switchToIndex: 1,
          },
          { emitter: 'toPwdResetClicked', switchToIndex: 2 },
          { emitter: 'submitted', switchToIndex: -1 },
        ],
        props: this.signInProps || null,
        isVisible: this.visible === 'signIn',
      },
      {
        component: DialogSignupComponent,
        events: [
          {
            emitter: 'toSignInClicked',
            switchToIndex: 0,
          },
          {
            emitter: 'signUpFinished',
            switchToIndex: -1,
          },
        ],
        props: this.signUpProps || null,
        isVisible: this.visible === 'signUp',
      },
      {
        component: DialogPwdForgotComponent,
        events: [{ emitter: 'toSignInClicked', switchToIndex: 0 }],
        isVisible: false,
      },
    ];
  }
  handleClose() {
    this.closeCallback
      ? this.closeCallback()
      : this.dialogRef
      ? this.dialogRef.close()
      : console.error('Wrong signInUp parameters');
  }

  setCaptchaVisibility(visibility: 'visible' | 'hidden') {
    setTimeout(() => {
      const badge = this.document.querySelector('.grecaptcha-badge');
      this.renderer.setStyle(badge, 'visibility', visibility);
    }, 200); /* it takes some time for captcha to load */
  }

  ngOnDestroy(): void {
    this.setCaptchaVisibility('hidden');
    this.takeUntil$.next(true);
    this.takeUntil$.unsubscribe();
  }
}

type Visible = 'signIn' | 'signUp';
export interface SignInUpData {
  visible?: Visible;
  signInProps?: SwitchableComponent['props'];
  signUpProps?: SwitchableComponent['props'];
}
