import { Media } from "./Media";
import { BroadcastType } from "../enums/BroadcastType";


export interface Broadcast {
  photo_id: string;
  photo: Media;
  title: string;
  subtitle: string;
  description: string;
  label: string;
  type: BroadcastType;
  url: string;
  embed: string;
  start: string;
  permalink: string;
  id: string;
  photo_visible_in_details: boolean;
}
