import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'lib-form-keywords',
  templateUrl: './form-keywords.component.html',
  styleUrls: ['./form-keywords.component.scss'],
})
export class FormKeywordsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  open = true;
  keyWordsSeparator = '|';

  constructor() {}

  ngOnInit(): void {}

  addKeyword($event: MatChipInputEvent) {
    const keyWords = this.getKeywords();
    if (($event.value || '').trim() && !keyWords.some((obj) => obj === $event.value)) {
      keyWords.push($event.value);
    }
    $event.input.value = '';
    this.updateForm(keyWords.join(this.keyWordsSeparator));
  }

  updateForm(keywords: string) {
    this.form.get('keywords').setValue(keywords);
  }

  removeKeyword(keyword) {
    const keyWords = this.getKeywords().filter((k) => k !== keyword);
    this.updateForm(keyWords.join(this.keyWordsSeparator));
  }

  getKeywords(): string[] {
    const controlValue = this.form.get('keywords').value;
    return controlValue ? controlValue.split(this.keyWordsSeparator) : [];
  }
}
