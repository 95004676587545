import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";
export const ALERT_CUSTOM_POST_ADDED: Alert = {
  type: AlertType.Success,
  title: {
    message: 'Success',
    translated: false,
  },
  message: {
    message: 'custom.custom_post_added',
    translated: true,
  },
  time: 2000,
};
