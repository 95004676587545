import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bwc-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss'],
})
export class SuccessPopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SuccessPopupComponent>, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'success-popup');
  }

  getRange(count: number): number[] {
    return Array.from({ length: count }, (_, index) => index);
  }

  submit() {
    this.dialogRef.close();
  }
}
