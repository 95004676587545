export const SHOP_TIME_SLOTS = [
  {
    key: '5 min',
    value: 5,
  },
  {
    key: '10 min',
    value: 10,
  },
  {
    key: '15 min',
    value: 15,
  },
  {
    key: '20 min',
    value: 20,
  },
  {
    key: '30 min',
    value: 30,
  },
  {
    key: '1 uur',
    value: 60,
  },
  {
    key: '2 uur',
    value: 120,
  },
  {
    key: '4 uur',
    value: 240,
  },
];
