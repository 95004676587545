import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { EducationStageComponent } from '../../../components/education-stage/education-stage.component';
import { FormSectionService } from '../../../services/form-section.service';
import { minArrayLengthValidator } from '@digilize/shared/utils/validators/src';
import { UserEducation } from '../../../models/education';
import { EducationService } from '../../../services/education.service';
import { TranslateService } from '@ngx-translate/core';

import { returnCategories } from '../../../helpers/helpers';
import { Subscription, throwError } from 'rxjs';

@Component({
  selector: 'bwc-add-your-education',
  templateUrl: './add-your-education.component.html',
  styleUrls: ['./add-your-education.component.scss'],
})
export class AddYourEducationComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() editing: boolean;
  @Input() saveToDB: boolean;
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @ViewChild(EducationStageComponent) educationStageComponent: EducationStageComponent;
  @Output() dataStep = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitEducation = new EventEmitter();

  form: FormGroup<FormSections>;

  countriesOptions = [];
  maxEducationSteps = 5;
  formValid = false;
  dataLoaded = false;
  dataLoaded2 = false;
  selectedLang: string;
  subSink: Subscription[] = [];

  constructor(
    private educationService: EducationService,
    private fb: FormBuilder,
    private formSection: FormSectionService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.selectedLang = this.translateService.getDefaultLang();
    this.subSink.push(
      this.translateService.onLangChange.subscribe((langChangeEvent) => {
        this.getCountriesData(langChangeEvent.lang);
      })
    );

    if (this.formGroup !== undefined) {
      this.form = this.createFormGroup();
      this.processEducationData(this.formGroup.value.education);
      setTimeout(() => this.form.updateValueAndValidity(), 0);
    } else {
      this.form = this.createFormGroup();
      this.getUserData();
      setTimeout(() => this.form.updateValueAndValidity(), 0);
    }
    this.getCountriesData(this.selectedLang);
  }

  createFormGroup(): FormGroup<FormSections> {
    return this.fb.group<FormSections>({
      formSections: this.fb.array<UserEducation>([], minArrayLengthValidator(1)),
    });
  }

  getCountriesData(lang: string) {
    this.educationService.getCountries(lang).subscribe((data: any) => {
      const languages = data.data.map((item) => ({
        text: item.name,
        val: item.code,
      }));
      this.countriesOptions = [...languages];
      this.dataLoaded = true;
    });
  }

  checkForm() {
    console.log('form', this.form);
  }

  getUserData() {
    this.educationService.getEducationMy().subscribe((data: any) => {
      this.processEducationData(data.data);
    });
  }

  processEducationData(data) {
    if (data.length === 0) {
      this.formSections.push(
        this.formSection.createEducationFormSection({
          order: 0,
          country: '',
          school_id: '',
          school_name: '',
          education_id: null,
          education_name: '',
          education_categories: [],
          education_category_ids: [],
          education_category_names: [],
          education_level_is_general: false,
          education_level_name: '',
          education_level_id: '',
          start_date: '',
          end_date: '',
          is_current: false,
        })
      );
    } else {
      data.forEach((item) => {
        const endDate = item.end_date === '1970-01-01T00:00:00+00:00' ? '' : item.end_date;
        this.formSections.push(
          this.formSection.createEducationFormSection({
            id: item.id,
            order: item.order,
            country: item.country,
            school_id: item.school_id,
            school_name: item.school_name,
            education_id: item.education_id,
            education_name: item.education_name,
            education_categories: returnCategories(item.education_category_ids, item.education_category_names),
            education_category_ids: item.education_category_ids,
            education_category_names: item.education_category_names,
            education_level_is_general: item.education_level_is_general,
            education_level_name: item.education_level_name,
            education_level_id: item.education_level_id,
            start_date: item.start_date,
            end_date: endDate,
            is_current: item.is_current,
          })
        );
      });
    }
    this.dataLoaded2 = true;
  }

  onFormValidityChanged(validity: boolean) {
    this.formValid = validity;
  }

  goPrev() {
    if (!this.formValid) {
      this.dataStep.emit({ previousStep: this.currentStep, step: 4 });
    } else {
      this.sendEducationData(this.form.value.formSections, false, true);
    }
  }

  onSubmit() {
    if (this.formValid) {
      this.sendEducationData(this.form.value.formSections);
    }
  }

  onEdit() {
    if (this.formValid) {
      if (this.saveToDB) {
        this.sendEducationData(this.form.value.formSections, true);
      } else {
        this.emitEducation.emit(this.form);
        this.emitClose.emit('education');
      }
    }
  }

  sendEducationData(data, close?, ifBack?) {
    const educationData = data.map((item) => {
      const newItem = { ...item };

      delete newItem.id;
      if (item.education_category_names !== null) {
        newItem.education_category_names = item.education_category_names;
      } else {
        newItem.education_category_names = null;
      }
      if (item.education_category_ids !== null) {
        newItem.education_category_ids = item.education_category_ids;
      } else {
        newItem.education_category_ids = null;
      }

      if (item.start_date !== '') {
        newItem.start_date = new Date(item.start_date).toISOString();
      }
      if (item.end_date !== '') {
        newItem.end_date = new Date(item.end_date).toISOString();
      }
      return newItem;
    });

    const requestBody = { items: educationData };

    this.educationService.putEducationMy(requestBody).subscribe(
      (resp) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 4 });
        } else if (close) {
          this.emitClose.emit('education');
          this.emitEducation.emit(this.form);
        } else {
          this.dataStep.emit({ previousStep: this.currentStep, step: 6 });
        }
      },
      (error) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 4 });
        }
        throwError('EducationMy error:', error);
        console.error('EducationMy error:', error);
      }
    );
  }

  close() {
    this.emitClose.emit('education');
  }

  get formSections() {
    return this.form.get('formSections') as FormArray;
  }

  ngOnDestroy(): void {
    this.subSink.forEach((sub) => sub.unsubscribe());
  }
}

interface FormSections {
  formSections: FormArray;
}
