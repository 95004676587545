import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { TranslateModule } from '@ngx-translate/core';

import { LangDroplistComponent } from './lang-droplist/lang-droplist.component';

@NgModule({
  declarations: [LangDroplistComponent],
  imports: [CommonModule, SharedUiComponentsModule, TranslateModule],
  exports: [TranslateModule, LangDroplistComponent],
})
export class LangComponentsModule {}
