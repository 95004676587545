import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Category } from '@shared/definitions';

@Component({
  selector: 'lib-select-categories',
  templateUrl: './select-categories.component.html',
  styleUrls: ['./select-categories.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectCategoriesComponent implements OnInit, OnChanges {
  @Output() categoriesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() categories: Category[] = [];
  activeCategory: Category;
  @Input() selectedCategories: string[] = [];
  selectedCounter = 0;
  unselectActive = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.categories.length > 0) {
      if (!this.activeCategory) {
        this.activeCategory = this.categories[0];
      }
      this.updateSelectedCounter();
    }
  }

  toggleCategory(id: string) {
    if (this.selectedCategories.some((obj) => obj === id)) {
      this.selectedCategories = this.selectedCategories.filter((obj) => obj !== id);
    } else {
      this.selectedCategories.push(id);
    }
    this.updateSelectedCounter();
    this.updateCategories();
  }

  isCategorySelected(id: string): boolean {
    return this.selectedCategories.findIndex((obj) => obj === id) > -1 ? true : false;
  }

  selectAllCategories() {
    this.activeCategory.subcategories.forEach((obj) => {
      if (!this.selectedCategories.some((id) => obj.id === id)) {
        this.selectedCategories.push(obj.id);
      }
    });
    this.updateSelectedCounter();
    this.updateCategories();
  }

  unselectAllCategories() {
    this.selectedCategories = this.selectedCategories.filter(
      (id) => !this.activeCategory.subcategories.some((obj) => obj.id === id)
    );
    this.updateSelectedCounter();
    this.updateCategories();
  }

  updateSelectedCounter() {
    let counter = 0;
    this.selectedCategories.forEach((id) => {
      if (this.activeCategory.subcategories.some((obj) => obj.id === id)) {
        counter++;
      }
    });
    this.unselectActive = this.activeCategory.subcategories.length === counter ? true : false;
    this.selectedCounter = counter;
  }

  updateCategories() {
    this.categoriesChange.emit(this.selectedCategories);
  }

  getCategoryCounter(category): number {
    let counter = 0;
    this.selectedCategories.forEach((id) => {
      if (category.subcategories.some((obj) => obj.id === id)) {
        counter++;
      }
    });
    return counter;
  }
}
