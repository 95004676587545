import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Alignment } from '@digilize/shared/definitions/src';
import { getFirstControlWithError } from '@digilize/shared/utils/helpers/src';

@Component({
  selector: 'lib-multi-answer-tiles',
  templateUrl: './multi-answer-tiles.component.html',
  styleUrls: ['./multi-answer-tiles.component.scss'],
})
export class MultiAnswerTilesComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({
    mockControl1: new FormControl(),
    mockControl2: new FormControl(),
  });
  @Input() answers: { text: string; val: string; tooltip?: string }[];
  @Input() multipleCols = true;
  @Input() sameHeightElements = false; /* single col support only */
  @Input() withInnerHTML = false;
  @Input() align: Alignment = 'left';
  @Output() changed = new EventEmitter<string[]>();
  public getFirstControlWithError = getFirstControlWithError;
  constructor() {}

  ngOnInit(): void {}

  validate() {
    setTimeout(() => {
      this.formGroup.markAllAsTouched();
      this.formGroup.controls[Object.keys(this.formGroup.controls)[0]].updateValueAndValidity();
    }, 0);
  }
}
