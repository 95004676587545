<div class="desktop">
  <div class="option" *ngFor="let smile of smiles">
    <div class="smile-wrapper wrapper">
      <span class="smile" [inlineSVG]="'/smiles/' + smile.smileSrc"></span>
    </div>
    <p class="text">{{ smile.text }}</p>
  </div>
</div>

<div class="mobile">
  <div class="wrapper" *ngFor="let smile of smiles">
    <span class="smile" [inlineSVG]="'/smiles/' + smile.smileSrc"></span>
    <p class="text">{{ smile.text }}</p>
  </div>
</div>
