import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from '@digilize/shared/utils/services/src';
import { DialogCreateCompanyComponent } from '../dialog-create-company/dialog-create-company.component';

@Component({
  selector: 'lib-dialog-entrepreneur',
  templateUrl: './dialog-entrepreneur.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogEntrepreneurComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogEntrepreneurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private router: Router
  ) {}

  loading = false;

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }
  openCreateCompanyDialog($event) {
    $event.preventDefault();
    this.closeDialog();

    // Timeout to add class to body (scroll block)
    setTimeout(() => {
      this.dialogService.openDialog(DialogCreateCompanyComponent);
    }, 200);
  }
}
