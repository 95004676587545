import { Injectable } from '@angular/core';
import { Me } from '@digilize/shared/definitions/src';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserDataService {
  private userDataSubject: BehaviorSubject<Me | null> = new BehaviorSubject<Me | null>(null);

  setLoggedInUserData(userData: Me): void {
    this.userDataSubject.next(userData);
  }

  getLoggedInUserData(): Observable<Me | null> {
    return this.userDataSubject.asObservable();
  }
}
