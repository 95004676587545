<div class="words-container">
  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    #todoList="cdkDropList"
    cdkDropListSortingDisabled
    [cdkDropListData]="words"
    [cdkDropListConnectedTo]="[doneList]"
    class="word-list"
    (cdkDropListDropped)="drop($event)"
  >
    <span class="word" *ngFor="let item of words" cdkDrag> {{ item }}</span>
  </div>
</div>

<div class="words-container">
  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    #doneList="cdkDropList"
    [cdkDropListData]="wordsDragged"
    [cdkDropListConnectedTo]="[todoList]"
    class="answer-word-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="answer-line 1"></div>
    <div class="answer-line 2"></div>
    <div class="answer-line 3"></div>
    <div class="answer-line 4"></div>
    <div class="answer-line 5"></div>
    <span class="word" *ngFor="let item of wordsDragged" cdkDrag> {{ item }}</span>
  </div>
</div>
