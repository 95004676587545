import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-card-other',
  templateUrl: './card-other.component.html',
  styleUrls: ['../card.component.scss'],
})
export class CardOtherComponent implements OnInit {
  @Input() translation: string;
  @Input() img: string;
  @Input() link: string;

  constructor() {}

  ngOnInit(): void {}
}
