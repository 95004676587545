import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {  ShopApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert';

@Component({
  selector: 'lib-dialog-auction-delete-confirm',
  templateUrl: './dialog-auction-delete-confirm.component.html',
  styleUrls: ['./dialog-auction-delete-confirm.component.scss'],
})
export class DialogAuctionDeleteConfirmComponent {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAuctionDeleteConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shopApiService: ShopApiService,
    private router: Router,
    private alertService: AlertService
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  deleteAuction() {
    this.shopApiService.deleteAuction(this.data.auctionId).subscribe(
      (response: any) => {
        this.loading = false;
        this.closeDialog();
        this.router.navigate(['/marketplace']);
      },
      (error) => {
        this.alertService.handleApiError(error.error.error);
        this.loading = false;
        this.closeDialog();
      }
    );
  }
}
