import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";
export const ALERT_POST_INACTIVE_SHOP: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'custom.post_inactive_shop',
    translated: true,
  },
};
