import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailVerbEnum, EmailVerbWithTags, EmailVerbsWithTags } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class EmailVerbsApiService {
  readonly BASE_EMAIL_VERBS_URL_V1 = '/api/v1.0/email-verbs';
  readonly BASE_EMAIL_VERBS_URL_V2 = '/api/v2.0/email-verbs';
  constructor(private http: HttpClient) {}

  getEmailVerb(id: EmailVerbEnum) {
    return this.http.get<EmailVerbWithTags>(`${this.BASE_EMAIL_VERBS_URL_V1}/${id}`);
  }

  getEmailVerbs(params: EmailVerbsParams) {
    return this.http.get<EmailVerbsWithTags>(`${this.BASE_EMAIL_VERBS_URL_V2}`, { params: { ...params } });
  }

  matchSingle(params: MatchSingleParams) {
    return this.http.get<EmailVerbWithTags>(`${this.BASE_EMAIL_VERBS_URL_V2}/match`, { params: { ...params } });
  }
}

export interface MatchSingleParams {
  company?: string;
  research?: string;
  researchGroup?: string;
}

export interface EmailVerbsParams extends MatchSingleParams {
  type: EmailVerbEnum[];
}
