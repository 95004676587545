export function csvToObjArr<T = any>(str, tryToParseKeys: string[] = [], delimiter = ';'): T[] {
  if (!str) return [];
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr: T[] = rows
    .filter((row) => {
      return row !== '';
    })
    .map((row) => {
      const values = (row.split(delimiter) as string[]).map((value) => {
        const isQuoted = value[0] === '"' && value[value.length - 1] === '"';
        return isQuoted ? value.slice(1, -1) : value;
      });
      const el: T = headers.reduce((object, header, index) => {
        let value: number | string = values[index];
        if (tryToParseKeys.includes(header)) {
          const parsedText = parseFloat(values[index]);
          if (!isNaN(parsedText)) value = parsedText;
        }
        object[header] = value;
        return object;
      }, {});
      return el;
    });
  return arr;
}
