import { CartLine } from "./CartLine";
import { CartProduct } from "./CartProduct";


export interface CartSummary extends Map<string, string> {
  products: CartProduct[];
  lines: CartLine[];
  total_products: number;
  total_additional_amount: number;
  total_payment_method_cost: number;
  total_delivery_cost: number;
  total_amount: number;
}
