<img src="/assets/icons/close-icon.svg" class="close-icon" (click)="close()" />
<div class="text-center">
  <div class="main-header">
    <h1 class="main-title" *ngIf="data.type === 'create' && !checkYourEmail && !account_activated">
      {{ 'auth.create_your_password' | translate }}
    </h1>
    <h3 class="main-subtitle" *ngIf="data.type === 'create' && !checkYourEmail && !account_activated">
      {{ 'auth.create_your_password_text' | translate }}
    </h3>
    <h1 class="main-title" *ngIf="data.type === 'change' && !checkYourEmail">
      {{ 'auth.change_your_password' | translate }}
    </h1>
    <h3 class="main-subtitle" *ngIf="data.type === 'change' && !checkYourEmail">
      {{ 'auth.change_password_send_mail' | translate }}
    </h3>
    <h1 class="main-title" *ngIf="checkYourEmail">{{ 'auth.check_your_email' | translate }}</h1>
    <h3 class="main-subtitle" *ngIf="checkYourEmail">
      {{ 'auth.we_sent_you_verification_link' | translate }} {{ form.value.email }}.<br />
      {{ 'auth.check_your_email_and_activate' | translate }}
    </h3>

    <h1 class="main-title" *ngIf="resendEmail">
      {{ 'auth.resend_link' | translate }}
    </h1>
    <h3 class="main-subtitle" *ngIf="resendEmail">
      {{ 'auth.we_sent_you_verification_link' | translate }} {{ form.value.email }}.<br />
      {{ 'auth.check_your_email_and_activate' | translate }}.
    </h3>
    <h1 class="main-title" *ngIf="account_activated">
      {{ 'auth.welcome_to_bwh' | translate }}
    </h1>
    <h3 class="main-subtitle" *ngIf="account_activated">
      {{ 'auth.welcome_congratulations' | translate }}
    </h3>
    <h1 class="main-title" *ngIf="activation_error">
      {{ 'auth.welcome_to_bwh' | translate }}
    </h1>
    <h3 class="main-subtitle" *ngIf="activation_error">
      {{ 'auth.activationg_error' | translate }}
    </h3>
  </div>

  <img *ngIf="account_activated" src="/assets/icons/bwh-welcome-icon.svg" class="bwh-welcome-icon" />
  <img *ngIf="activation_error" src="/assets/icons/error-icon.svg" class="bwh-welcome-icon error-icon" />

  <form *ngIf="data.type === 'create' && !checkYourEmail && !account_activated" [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="form-label">{{ 'auth.new_password' | translate }}<sup>*</sup></div>
        <mat-form-field [floatLabel]="'never'">
          <input
            matInput
            formControlName="password_new"
            type="password"
            [placeholder]="'auth.enter_new_password' | translate"
          />
        </mat-form-field>
        <div class="form-error-label field-error" *ngIf="getFormControl('password_new').hasError('minlength')">
          {{ 'auth.password_min_length' | translate }}
        </div>
      </div>
      <div
        class="col-12"
        [ngClass]="{
          'error-field':
            getFormControl('password_confirm').status === 'INVALID' && getFormControl('password_confirm').touched
        }"
      >
        <div class="form-label">{{ 'auth.conf_new_password' | translate }}<sup>*</sup></div>
        <mat-form-field [floatLabel]="'never'">
          <input
            matInput
            formControlName="password_confirm"
            type="password"
            [placeholder]="'auth.enter_new_password' | translate"
          />
        </mat-form-field>
        <div class="error-label">{{ 'auth.password_dont_match' | translate }}</div>
      </div>
      <div class="col-12 private-policy-cont">
        <label>
          <mat-checkbox formControlName="privacy_policy">
            {{ 'global.by_singing_up_you_agree' | translate }}
            <a href="https://skilld.nl/privacy-policy" target="_blank">{{ 'global.privacy_policy' | translate }}</a>
          </mat-checkbox>
        </label>
      </div>
    </div>
  </form>

  <form *ngIf="data.type === 'change' && !checkYourEmail! && !password_changed" [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="form-label">{{ 'auth.password' | translate }}<sup>*</sup></div>
        <mat-form-field
          [floatLabel]="'never'"
          [ngClass]="{
            'error-field': getFormControl('password').status === 'INVALID' && getFormControl('password').touched
          }"
        >
          <input
            matInput
            formControlName="password"
            type="password"
            [placeholder]="'auth.enter_password' | translate"
          />
        </mat-form-field>
      </div>
      <div
        class="col-12"
        [ngClass]="{
          'error-field': getFormControl('password_new').status === 'INVALID' && getFormControl('password_new').touched
        }"
      >
        <div class="form-label">{{ 'auth.new_password' | translate }}<sup>*</sup></div>
        <mat-form-field [floatLabel]="'never'">
          <input
            matInput
            formControlName="password_new"
            type="password"
            [placeholder]="'auth.enter_new_password' | translate"
          />
        </mat-form-field>
        <div class="error-label">{{ 'auth.new_password_has_to_be_different_than_old_one' | translate }}</div>
      </div>
      <div
        class="col-12"
        [ngClass]="{
          'error-field':
            getFormControl('password_confirm').status === 'INVALID' && getFormControl('password_confirm').touched
        }"
      >
        <div class="form-label">{{ 'auth.conf_new_password' | translate }}<sup>*</sup></div>
        <mat-form-field [floatLabel]="'never'">
          <input
            matInput
            formControlName="password_confirm"
            type="password"
            [placeholder]="'auth.enter_new_password' | translate"
          />
        </mat-form-field>
        <div class="error-label">{{ 'auth.password_dont_match' | translate }}</div>
      </div>

      <div class="col-12 form-error-label" *ngIf="updatePassError">
        {{ 'auth.error_update_pass' | translate }}
      </div>
    </div>
  </form>

  <div *ngIf="data.type === 'change' && !checkYourEmail && password_changed" class="confirmation-container">
    <b>{{ 'auth.password_changed' | translate }}</b>
  </div>

  <div class="row code-container" *ngIf="checkYourEmail">
    <div class="code-box" *ngFor="let char of inputsFields; let i = index">
      <input
        type="text"
        maxlength="1"
        [ngModel]="codeChars[i]"
        [id]="'input_' + i"
        (paste)="onPaste(i, $event)"
        (input)="onInput(i, $event)"
      />
    </div>
    <div *ngIf="code_mismatch_error" class="code-error">{{ 'global.activation_code_error' | translate }}</div>
  </div>

  <ul class="next-button-container not-fixed">
    <li>
      <button
        *ngIf="data.type === 'create' && !checkYourEmail && !account_activated"
        class="btn w-lg h-lg secondary"
        type="submit"
        (click)="createPassword()"
        [disabled]="!this.form.valid"
      >
        {{ 'global.next' | translate }}
      </button>
      <button
        *ngIf="data.type === 'change' && !checkYourEmail && !password_changed"
        class="btn w-lg h-lg secondary"
        type="submit"
        (click)="changePassword()"
        [disabled]="!this.form.valid"
      >
        {{ 'global.change' | translate }}
      </button>
      <button
        *ngIf="checkYourEmail"
        class="btn w-lg h-lg primary btn-margin-top"
        type="text"
        (click)="resendActivationLink()"
      >
        {{ 'auth.resend_link' | translate }}
      </button>
      <button
        *ngIf="activation_error"
        class="btn w-lg h-lg secondary btn-margin-top"
        type="text"
        (click)="resendActivationLink()"
      >
        {{ 'auth.resend_link' | translate }}
      </button>
      <button
        *ngIf="resendEmail"
        class="btn w-lg h-lg secondary btn-margin-top"
        type="text"
        (click)="resendActivationLink()"
      >
        {{ 'auth.continue' | translate }}
      </button>
    </li>
    <li>
      <button
        *ngIf="checkYourEmail"
        class="btn w-lg h-lg secondary checkYourEmail"
        type="text"
        (click)="activateAccount()"
      >
        {{ 'auth.continue' | translate }}
      </button>
      <button *ngIf="account_activated" class="btn w-lg h-lg secondary" type="text" (click)="close()">
        {{ 'auth.continue' | translate }}
      </button>
    </li>
    <!-- <li>
      <button (click)="checkForm()">check form</button>
    </li> -->
    <li *ngIf="this.data.password_created" class="add-info">
      {{ 'global.need_some_help' | translate }}
      <a href="mailto:t.bertholet@skilld.nl"
        ><span>{{ 'global.contact_us' | translate }}</span></a
      >
    </li>
  </ul>
</div>
