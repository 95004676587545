export enum ErrorKind {
  NOT_FOUND = 'not_found',
  TOO_MANY_REQUESTS = 'too_many_requests',
  VALIDATION_ERROR = 'validation_error',
  ALREADY_EXISTS = 'already_exists',
  PAYMENT_API_KEY_INVALID = 'payment_api_key_invalid',
  CAPTCHA_INVALID = 'captcha_invalid',
  LINK_DEPLETED = 'link_depleted',
  INACTIVE = 'inactive',
  RANGE_EXCEEDED = 'range_exceeded',
  MAXIMUM_QUANTITY_HAS_BEEN_REACHED = 'maximum_quantity_has_been_reached',
  REQUIRED = 'required',
  INSTAGRAM_PAGE_ID_NOT_FOUND = 'instagram_page_id_not_found',
  RESEARCH_GROUP_FINISHED = 'research_group_finished',
  ALREADY_EXISTS_USER = 'already_exists_user',
}
