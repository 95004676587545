import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Alignment } from '@digilize/shared/definitions/src';

@Component({
  selector: 'lib-single-answer-tiles',
  templateUrl: './single-answer-tiles.component.html',
  styleUrls: ['./single-answer-tiles.component.scss'],
})
export class SingleAnswerTilesComponent implements OnInit {
  @Input() control: FormControl;
  @Input() options: { text: string; val: string; tooltip?: string }[] = [];
  @Input() withInnerHTML = false;
  @Input() sameHeightElements = false;
  @Input() align: Alignment = 'left';
  @Output() changed = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  handleClick(option: { text: string; val: string }) {
    if (this.control) {
      this.control.setValue(option.val);
    }
    this.changed.emit(option.val);
  }

  handleInfoClick(e: Event) {
    e.stopPropagation();
  }
}
