export const auctionStatusSelectOptions = [
  {
    key: 'marketplace.auction_status.active',
    value: 0,
  },
  {
    key: 'marketplace.auction_status.reserved',
    value: 1,
  },
  {
    key: 'marketplace.auction_status.sold',
    value: 2,
  },
  {
    key: 'marketplace.auction_status.inactive',
    value: 3,
  },
];
