import { ResearchReminderType } from "../enums";
import { ResearchReminderTranslation } from "./ResearchReminderTranslation";
export interface ResearchReminder {
  research_group_id: string;
  type: ResearchReminderType;
  days: number;
  translation_ids: string[];
  translations: ResearchReminderTranslation[];
  id: string;
}
