import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectGroup } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  readonly PROJECTS_TREES_BASE_URL = '/api/v1.0/projects';
  constructor(private http: HttpClient) {}

  addProject(projectGroup: ProjectGroup) {
    return this.http.post<ProjectGroup>(`${this.PROJECTS_TREES_BASE_URL}`, projectGroup);
  }

  editProject(projectGroup: ProjectGroup) {
    return this.http.put<ProjectGroup>(`${this.PROJECTS_TREES_BASE_URL}`, projectGroup);
  }

  getProjects() {
    return this.http.get<ProjectGroup>(`${this.PROJECTS_TREES_BASE_URL}`);
  }

  getProject(id: string) {
    return this.http.get<ProjectGroup>(`${this.PROJECTS_TREES_BASE_URL}/${id}`);
  }

  postDecisionTree(id: string) {
    return this.http.delete(`${this.PROJECTS_TREES_BASE_URL}/${id}`);
  }
}
