import { DataApiResponse } from './DataApiResponse';
import { MarketplaceAuction } from './MarketplaceAuction';
import { Product } from './Product';
import { ProductGroup } from './ProductGroup';
import { Shop } from './Shop';

export interface SearchResult {
  term: string;
  shops: DataApiResponse<Shop[]>;
  products: DataApiResponse<Product[]>;
  groups: DataApiResponse<ProductGroup[]>;
  auctions: DataApiResponse<MarketplaceAuction[]>;
}
