import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-stars-rate',
  templateUrl: './stars-rate.component.html',
  styleUrls: ['./stars-rate.component.scss'],
})
export class StarsRateComponent {
  @Input() starsRate: number;
  @Input() index: number;
  @Input() titlesDesc: string;
  @Input() selectedStarIcon = '/icons/selected-star-icon.svg';
  @Input() emptyStarIcon = '/icons/empty-star-icon.svg';
  @Output() changeLevel = new EventEmitter();

  stars = [0, 1, 2, 3, 4];

  constructor() {}
  starClicked(index: number, star: number) {
    this.changeLevel.emit({ index: index, rating: star });
  }
}
