import { QuizStatus } from "../enums/QuizStatus";


export const QuizStatusOptions = [
  {
    key: 'Available',
    value: QuizStatus.Available,
  },
  {
    key: 'Activated',
    value: QuizStatus.Activated,
  },
  {
    key: 'Finished',
    value: QuizStatus.Finished,
  },
];
