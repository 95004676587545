import { AbstractControl, FormGroup } from '@angular/forms';

export function addValidatorsDynamically(form: FormGroup, controlName: string, validator: any) {
  const control: AbstractControl = form.get(controlName);

  if (control) {
    const validators = control.validator ? [control.validator, validator] : [validator];
    control.setValidators(validators);
    control.updateValueAndValidity();
  } else {
    console.error(`Control with name ${controlName} not found in the form.`);
  }
}
