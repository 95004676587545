<div class="page-main">
  <!-- <button (click)="checkForm()">check form</button> -->
  <h3>{{ 'global.your_cv' | translate }}</h3>
  <button class="print-page-btn" (click)="openPrint()">{{ 'global.print_this_page' | translate }}</button>
  <bwh-lib-personal-data
    class="print-styles pagebreak"
    [form]="form"
    [cvSharing]="cvSharing"
    (editPersonalData)="editPersonalData()"
  ></bwh-lib-personal-data>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'soft_skills'"
    [title]="'global.soft_skills' | translate"
    [infoTxt]="'global.add_your_skills_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_soft_skills' | translate"
    [cvSharing]="cvSharing"
    (clickEdit)="editSkills($event)"
    (ratingChanged)="changeSkillRating($event)"
  ></bwh-lib-skills-edition>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'professional_skills'"
    [title]="'global.professional_skills' | translate"
    [infoTxt]="'global.add_your_skills_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_professional_skills' | translate"
    [blockGoToEditionBtn]="work_history.length === 0 && education.length === 0"
    [additionalBtnInfo]="'global.prof_skills_add_info_btn' | translate"
    [cvSharing]="cvSharing"
    (clickEdit)="editSkills($event)"
    (ratingChanged)="changeSkillRating($event)"
  ></bwh-lib-skills-edition>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'it_skills'"
    [title]="'global.it_skills' | translate"
    [infoTxt]="'global.add_your_skills_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_it_skills' | translate"
    [blockGoToEditionBtn]="work_history.length === 0 && education.length === 0"
    [additionalBtnInfo]="'global.prof_skills_add_info_btn' | translate"
    [cvSharing]="cvSharing"
    (clickEdit)="editSkills($event)"
    (ratingChanged)="changeSkillRating($event)"
  ></bwh-lib-skills-edition>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'task_skills'"
    [title]="'global.tasks' | translate"
    [infoTxt]="'global.add_your_skills_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_tasks' | translate"
    [blockGoToEditionBtn]="work_history.length === 0 && education.length === 0"
    [additionalBtnInfo]="'global.prof_skills_add_info_btn' | translate"
    [cvSharing]="cvSharing"
    (clickEdit)="editSkills($event)"
    (ratingChanged)="changeSkillRating($event)"
  ></bwh-lib-skills-edition>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'work_history'"
    [editName]="'your_experience'"
    [title]="'global.your_experience' | translate"
    [infoTxt]="'global.add_your_experience_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_experience' | translate"
    [cvSharing]="cvSharing"
    (clickEdit)="editSkills($event)"
    (addingExp)="addingExp($event)"
  ></bwh-lib-skills-edition>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'education'"
    [title]="'global.your_education' | translate"
    [infoTxt]="'global.add_your_education_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_education' | translate"
    [cvSharing]="cvSharing"
    [levelOptions]="levelOptions"
    (clickEdit)="editSkills($event)"
  ></bwh-lib-skills-edition>

  <bwh-lib-skills-edition
    class="print-styles pagebreak"
    [form]="form"
    [control]="'languages'"
    [title]="'global.languages' | translate"
    [infoTxt]="'global.add_your_languages_so_we_will_match_jobs_better' | translate"
    [buttonTxt]="'global.add_your_languages' | translate"
    [cvSharing]="cvSharing"
    (clickEdit)="editSkills($event)"
    (ratingChanged)="changeSkillRating($event)"
  ></bwh-lib-skills-edition>
</div>

<div class="page-right">
  <div class="spinner-container p-absolute" *ngIf="checkingIfApply">
    <mat-spinner class="primary" [diameter]="30"></mat-spinner>
  </div>
  <div class="button-cont" *ngIf="accountDataChecked && !cvSharing">
    <div class="aplly-cont not-activated" *ngIf="showApplyOFLCont">
      <div class="apply-job-title">
        {{ 'global.open_application' | translate }}
      </div>
      <div class="apply-skills">
        {{ 'global.open_application_info' | translate }}
      </div>

      <button type="text" class="btn w-lg h-lg secondary" [disabled]="true">
        {{ 'global.apply' | translate }}
      </button>

      <div class="save-profile-info">{{ 'global.save_profile_to_apply' | translate }}</div>
    </div>

    <div class="aplly-cont" *ngIf="showApplyCont" [ngClass]="{ 'not-activated': !isActivated }">
      <div class="apply-job-title">
        {{ vacancyDetails.job_title }}
      </div>
      <div class="apply-skills">
        <div class="skill-item" *ngIf="vacancyDetails.work_experience_level">
          {{ 'global.' + workLevels[vacancyDetails.work_experience_level] | translate }}
        </div>
        <div class="skill-item" *ngIf="vacancyDetails.language_skills">
          <div class="education-item" *ngFor="let item of vacancyDetails.language_skills; let i = index">
            {{ item.skill_name }}{{ i !== vacancyDetails.language_skills.length - 1 ? ',' : '' }}
          </div>
        </div>
        <div class="skill-item" *ngIf="vacancyDetails.education_levels">
          <div class="education-item" *ngFor="let item of vacancyDetails.education_levels; let i = index">
            {{ item.name }}{{ i !== vacancyDetails.education_levels.length - 1 ? ',' : '' }}
          </div>
        </div>
      </div>

      <button
        *ngIf="!alreadyApplied"
        type="text"
        class="btn w-lg h-lg secondary"
        [disabled]="!isActivated"
        (click)="applyForTheJob()"
      >
        {{ 'global.apply' | translate }}
      </button>
      <button *ngIf="alreadyApplied" type="text" class="btn w-lg h-lg secondary already-applied" [disabled]="true">
        {{ 'global.already_applied' | translate }}
      </button>
      <div class="save-profile-info" *ngIf="!isActivated">{{ 'global.save_profile_to_apply' | translate }}</div>
    </div>

    <!-- <button (click)="checkForm()">check form</button> -->

    <button
      *ngIf="!password_created"
      type="text"
      class="btn w-lg h-lg secondary save1 btn-all-width"
      (click)="saveProfile()"
    >
      {{ 'global.save_my_profile' | translate }}
    </button>
    <button
      *ngIf="password_created && !isActivated"
      type="text"
      class="btn w-lg h-lg secondary save2 btn-all-width"
      (click)="saveProfile(true)"
    >
      {{ 'global.save_my_profile' | translate }}
    </button>
    <button *ngIf="isActivated" type="text" class="btn w-lg h-lg secondary grey-btn btn-all-width" (click)="shareCv()">
      <span class="shared-btn-icon" [inlineSVG]="'/icons/share-arrow-icon.svg'"></span
      >{{ 'global.share_cv' | translate }}
    </button>
    <!-- <button
      *ngIf="password_created && !isActivated"
      type="text"
      class="btn w-lg h-lg secondary"
      (click)="resendActivationLink()"
    >
      {{ 'global.resend_activation_link' | translate }}
    </button> -->
  </div>
  <div class="skills-completion-cont">
    <div class="skill-completion-row general-info">
      <div class="skill-completion-name">{{ 'global.general_information' | translate }}</div>
      <div class="skill-completion-state">
        <span class="checked-icon" [inlineSVG]="'/icons/checked-icon-purple.svg'"></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.soft_skills' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('soft_skills') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.professional_skills' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('professional_skills') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.it_skills' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('it_skills') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.tasks' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('task_skills') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.your_experience' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('work_history') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.your_education' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('education') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
    <div class="skill-completion-row">
      <div class="skill-completion-name">{{ 'global.languages' | translate }}</div>
      <div class="skill-completion-state">
        <span
          class="checked-icon"
          [inlineSVG]="
            checkSkillState('languages') ? '/icons/checked-icon-purple.svg' : '/icons/checked-icon-empty.svg'
          "
        ></span>
      </div>
    </div>
  </div>
</div>

<div class="edit-container" *ngIf="showEditCont">
  <div class="edit-in-container">
    <bwc-general-information
      *ngIf="show_gen_info"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      (formEmit)="getEditedForm($event)"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-general-information>

    <bwc-soft-skills
      *ngIf="show_soft_skills"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      (emitSkills)="getEditedSkills($event, 'softSkills')"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-soft-skills>

    <bwc-professional-skills
      *ngIf="show_professional_skills"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      (emitSkills)="getEditedSkills($event, 'profSkills')"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-professional-skills>

    <bwc-it-skills
      *ngIf="show_it_skills"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      (emitSkills)="getEditedSkills($event, 'itSkills')"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-it-skills>

    <bwc-tasks
      *ngIf="show_task_skills"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      (emitSkills)="getEditedSkills($event, 'taskSkills')"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-tasks>

    <bwc-select-your-job
      *ngIf="show_your_experience"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [itemClickable]="true"
      (emitExp)="getEditedJob($event)"
      (emitClose)="closeEditCont($event)"
      (clickEdit)="editSkills($event)"
      class="fixed-size"
    ></bwc-select-your-job>

    <bwc-add-your-education
      *ngIf="show_education"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      [saveToDB]="true"
      (emitEducation)="getEditedEducation($event)"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-add-your-education>

    <bwc-languages-knowledge
      *ngIf="show_languages"
      [formGroup]="form"
      [prevBut]="false"
      [closeBut]="true"
      [editing]="true"
      [removeProfileCheckClass]="false"
      (emitLanguages)="getEditedLanguages($event)"
      (emitClose)="closeEditCont($event)"
      class="fixed-size"
    ></bwc-languages-knowledge>
  </div>
</div>
