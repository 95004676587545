import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-select-plain',
  templateUrl: './select-plain.component.html',
  styleUrls: ['./select-plain.component.scss'],
})
export class SelectPlainComponent implements OnInit {
  showOptionsList = false;
  selectedOption;

  @Input() options: Value[] = [];
  @Input() translation: boolean;
  @Input() value: Value;
  @Input() errors;
  @Input() placeholder = 'global.select';
  @Input() iconBaseNameAndUrl: string;
  @Output() valueChange: EventEmitter<Value> = new EventEmitter<Value>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.value && Object.keys(this.value).length !== 0) {
      this.selectedOption = this.value;
    }
  }

  onValueChange(option, dontEmit?: boolean) {
    !dontEmit ? this.valueChange.emit(option) : null;
    this.selectedOption = option;
    this.showOptionsList = false;
  }

  onClickOutside(event) {
    this.showOptionsList = false;
  }

  getPlaceholderText(): string {
    if (this.selectedOption) {
      return this.selectedOption.key;
    } else {
      return this.translateService.instant(this.placeholder);
    }
  }
}

interface Value {
  key: string;
  value: string;
}
