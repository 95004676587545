export const REGEX_URL = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@äÄÉéöÖüÜß!\$&'\(\)\*\+,;=.]+$/;

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\-\_\+\=)]).{8,}$/;

/* everything but not most common special signs */
export const REGEX_EVERYTHING_EXCEPT_SPECIAL = /^[^!@#$%^&*()_+=[\]{};:'"\|?.>,<\\\/`~]*$/;
export const REGEX_EVERYTHING_EXCEPT_SPECIAL_NOT_MINUS = /^[^-!@#$%^&*()_+=[\]{};:'"\|?.>,<\\\/`~]*$/;
export const REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS = /^[^!@#$%^&*()_+=[\]{};:'"\|?.>,<\\\/`~0123456789]*$/;
export const REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_WITH_APO =
  /^[^!@#$%^&*()_+=[\]{};:"\|?.>,<\\\/`~0123456789]*$/; /* for example surname with ' - */
export const REGEX_EVERYTHING_EXCEPT_SPECIAL_NUMBERS_NOT_MINUS = /^[^-!@#$%^&*()_+=[\]{};:'"\|?.>,<\\\/`~0123456789]*$/;
