import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-order-dnd-control',
  templateUrl: './order-dnd-control.component.html',
  styleUrls: ['./order-dnd-control.component.scss'],
})
export class OrderDndControlComponent implements OnInit {
  /* not finished, currently this solution doesn't work with multiple rows (flex-wrap)  
  which makes it a bit useless, maybe new material version will fix this issue
  */
  words = ['is', 'two', 'three', 'four', '5', 't6', '7', '8'];
  wordsDragged = [];

  constructor() {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
}
