import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";

export const ALERT_403: Alert = {
  type: AlertType.Error,
  title: {
    message: 'Error',
    translated: false,
  },
  message: {
    message: 'error.type.login_again',
    translated: true,
  },
};
