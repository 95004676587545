import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthApiService, CampaignApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { DialogService } from '@digilize/shared/utils/services/src';
import { TranslateService } from '@ngx-translate/core';
import {
  Alert,
  ALERT_POST_INACTIVE_SHOP,
  ALERT_POST_SAVED,
  ALERT_POST_UPDATED,
  AlertType,
  DetailedPlannedPost,
  DetailedPost,
  ErrorKind,
  Me,
  Media,
  PostsOverview,
  ShopPostStatus,
} from '@shared/definitions';
import * as moment from 'moment';

import { EditorMediaAndPhoto } from '../../photo-editor/photo-editor.component';
import { DialogPhotoEditorComponent } from '../dialog-photo-editor/dialog-photo-editor.component';

enum PostPlatforms {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Snapchat = 'Snapchat',
}

@Component({
  selector: 'lib-dialog-plan-post',
  templateUrl: './dialog-plan-post.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogPlanPostComponent implements OnInit {
  loading = true;
  shopId;
  campaignId;
  companyId;
  postId;
  separator = '|';
  minDate = moment().add(1, 'day').toDate(); // Min date for post is 24h from now
  posts: PostsOverview; // posts - templates, shop_posts - planned posts
  activePost: any;
  nextPostTemplatedId;
  prevPostTemplatedId;
  editorPhotoSaveClicked = false;
  me: Me;

  formPost: UntypedFormGroup;
  hashtags: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, TAB, COMMA];
  get ShopPostStatus() {
    return ShopPostStatus;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogPlanPostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campaignApiService: CampaignApiService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private authApiService: AuthApiService
  ) {
    this.authApiService.me.subscribe((me: Me) => {
      if (me) {
        this.me = me;
      }
    });
  }

  ngOnInit(): void {
    this.shopId = this.data.shopId;
    this.campaignId = this.data.campaignId;
    this.companyId = this.data.companyId;
    this.postId = this.data.postId;
    if (this.shopId && this.campaignId && this.companyId) {
      this.getPosts(this.shopId, this.campaignId, this.companyId, (callback) => {
        if (this.posts.posts.length > 0) {
          if (!this.data.postId) {
            // Set 1st unplanned post
            this.setPostByTemplate(this.posts.posts[0].id);
          } else {
            // Set post from param
            const postTemplateId = this.posts.shop_posts.find((obj) => obj.id === this.data.postId).post_id;
            this.setPostByTemplate(postTemplateId);
          }
        } else {
          this.loading = false;
        }
      });
    } else if (this.postId) {
      this.campaignApiService.getPost(this.postId).subscribe(
        (response: any) => {
          this.activePost = response;
          this.setForm(response);
          this.getPhotoEditorData(response.id);
        },
        (error) => {}
      );
    }

    this.dialogRef.afterClosed().subscribe(() => {
      this.setCampaignPlanned();
    });
  }

  getPhotoEditorData(postId: string) {
    this.campaignApiService.getPhotoEditorState(this.shopId, postId).subscribe(
      (response: any) => {
        const editorData = JSON.parse(response.state);
        if (editorData.imageAfterEdit) {
          this.activePost.photo = editorData.imageAfterEdit;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getPosts(shopId: string, campaignId: string, companyId: string, callback) {
    this.campaignApiService.getCampaign(campaignId, companyId, shopId).subscribe(
      (response: any) => {
        this.posts = response;
        callback();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  setPostByTemplate(postTemplateId) {
    this.loading = true;
    this.activePost = null;
    const index = this.posts.shop_posts.findIndex((obj) => obj.post_id === postTemplateId);
    if (index > -1) {
      // Post for this template already exist -> post will be edited
      // media fetching
      this.campaignApiService.getPost(this.posts.shop_posts[index].id).subscribe(
        (response) => {
          this.activePost = response;
          this.setNextPrevPost(this.activePost);
          this.setForm(this.activePost);
          this.getPhotoEditorData(response.post_id);
        },
        (error) => {}
      );
    } else {
      // Post for this template don't exist -> post will be added as new
      this.activePost = { ...this.posts.posts.find((obj) => obj.id === postTemplateId) };
      this.activePost.post_id = postTemplateId;
      this.activePost.id = null;
      this.setNextPrevPost(this.activePost);
      this.setForm(this.activePost);
      this.getPhotoEditorData(this.activePost.post_id);
    }
  }

  setNextPrevPost(activePost: any) {
    this.nextPostTemplatedId = this.prevPostTemplatedId = null;

    if (activePost.id) {
      // Editing some existing post
      const index = this.posts.shop_posts.findIndex((obj) => obj.post_id === activePost.post_id);
      // Check if there's next and prev planned post
      this.nextPostTemplatedId = this.posts.shop_posts[index + 1]?.post_id;
      this.prevPostTemplatedId = this.posts.shop_posts[index - 1]?.post_id;
      // If no next planned posts, check if there's next template
      if (!this.nextPostTemplatedId) {
        this.posts.posts.forEach((postTemplate) => {
          if (!this.posts.shop_posts.some((obj) => obj.post_id === postTemplate.id) && !this.nextPostTemplatedId) {
            this.nextPostTemplatedId = postTemplate.id;
          }
        });
      }
    } else {
      // New post
      const index = this.posts.posts.findIndex((obj) => obj.id === activePost.post_id);

      // Get next template
      for (let i = index + 1; i < this.posts.posts.length; i++) {
        if (!this.nextPostTemplatedId && this.posts.posts[i]) {
          if (!this.posts.shop_posts.some((obj) => obj.post_id === this.posts.posts[i].id)) {
            this.nextPostTemplatedId = this.posts.posts[i].id;
          }
        }
      }

      // Get prev template
      for (let i = index - 1; i >= 0; i--) {
        if (!this.prevPostTemplatedId && this.posts.posts[i]) {
          if (!this.posts.shop_posts.some((obj) => obj.post_id === this.posts.posts[i].id)) {
            this.prevPostTemplatedId = this.posts.posts[i].id;
          }
        }
      }

      // If no prev template, set last from planned as prev
      if (!this.prevPostTemplatedId && this.posts.shop_posts.length > 0) {
        this.prevPostTemplatedId = this.posts.shop_posts[this.posts.shop_posts.length - 1].post_id;
      }
    }
  }

  setForm(post: DetailedPlannedPost | DetailedPost) {
    this.formPost = new UntypedFormGroup({
      title: new UntypedFormControl(post.title),
      body: new UntypedFormControl(post.body),
      planned: new UntypedFormControl(
        {
          value: post.id ? (post as any).planned : (post as any).suggested_post_start,
          disabled: this.activePost.status > 0,
        },
        Validators.required
      ),
      hashtags: new UntypedFormControl(),
      media: new UntypedFormControl(post.media),
      media_ids: new UntypedFormControl(post.media_ids),
    });

    if (post.hashtags) {
      this.hashtags = post.hashtags.split('|');
      this.updateFormHashtags();
    } else {
      this.hashtags = [];
    }

    this.loading = false;
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  updateImages(event: Media[]) {
    const mediaIds: string[] = [];
    event.forEach((obj: Media) => {
      mediaIds.push(obj.id);
    });

    this.formPost.get('media')!.setValue(event);
    this.formPost.get('media_ids')!.setValue(mediaIds);
  }

  get postDoesntHaveStatus() {
    return this.activePost && this.activePost.status !== 0 && !this.activePost.status;
  }

  onSubmit(nextPostId?) {
    if (this.activePost.post_draft_id) {
      this.loading = true;
      const post = this.getEditedPost();
      this.campaignApiService.editPost(post).subscribe(
        (response: any) => {
          this.loading = false;
          this.alertService.setAlert(ALERT_POST_SAVED);
          this.closeDialog();
        },
        (error) => {
          this.handlePostError(error);
        }
      );
    } else {
      if (this.formPost.valid) {
        this.loading = true;
        const post = this.getEditedPost();
        const callback = (postStatus) => {
          const alert = postStatus < 1 ? ALERT_POST_UPDATED : ALERT_POST_SAVED;
          this.alertService.setAlert(alert);
          if (nextPostId) {
            this.setPostByTemplate(nextPostId);
          } else {
            this.closeDialog();
          }
        };

        if (this.activePost.id) {
          // Edit post
          this.campaignApiService.editPost(post).subscribe(
            (response: any) => {
              callback(response?.status);
            },
            (error) => {
              this.handlePostError(error);
            }
          );
        } else {
          // Add post
          this.campaignApiService.addPost(post).subscribe(
            (response: any) => {
              callback(response?.status);
            },
            (error) => {
              this.handlePostError(error);
            }
          );
        }
      } else if (nextPostId) {
        this.setPostByTemplate(nextPostId);
      }
    }
  }

  setCampaignPlanned() {
    this.getPosts(this.shopId, this.campaignId, this.companyId, (callback) => {
      if (this.posts.posts.length === this.posts.shop_posts.length) {
        this.campaignApiService.setCampaignPlanned(this.shopId, this.campaignId, true).subscribe(
          () => {},
          (error) => {
            this.alertService.handleApiError(error.error.error);
          }
        );
      }
    });
  }

  getEditedPost(): any {
    let post: DetailedPlannedPost = this.activePost;
    post = this.cleanNulls(post);
    const form = this.formPost.getRawValue();
    if (this.posts) {
      post.shop_campaign_id = this.posts.shop_campaign_id ? this.posts.shop_campaign_id : null;
    }
    post.shop_id = this.shopId;
    post.body = form.body;
    post.planned = form.planned;
    post.hashtags = form.hashtags;
    post.media = form.media;
    post.media_ids = form.media_ids;
    return post;
  }

  handlePostError(error) {
    this.loading = false;
    if (error.error.error.info?.kind === ErrorKind.RANGE_EXCEEDED) {
      const alert: Alert = {
        type: AlertType.Error,
        title: {
          message: 'Error',
          translated: false,
        },
        message: {
          message: `${this.translateService.instant('alert.error.kind.range_exceeded')} (min: ${moment(
            error.error.error.info?.min
          ).format('DD-MM-YYYY HH:mm')} max: ${moment(error.error.error.info?.max).format('DD-MM-YYYY HH:mm')})`,
          translated: false,
        },
      };
      this.alertService.setAlert(alert);
    } else if (error.error.error.info?.kind === ErrorKind.INACTIVE && error.error.error.info?.field === 'shop_id') {
      this.alertService.setAlert(ALERT_POST_INACTIVE_SHOP);
    } else {
      this.alertService.handleApiError(error.error.error);
    }
  }

  removeTag(tag) {
    this.hashtags = this.hashtags.filter((obj) => obj !== tag);
    this.updateFormHashtags();
  }

  addTag($event: MatChipInputEvent) {
    if (
      ($event.value || '').trim() &&
      !this.hashtags.some((obj) => obj === $event.value) &&
      !$event.value.includes('|')
    ) {
      this.hashtags.push($event.value);
    }
    $event.input.value = '';
    this.updateFormHashtags();
  }

  updateFormHashtags() {
    if (this.formPost) {
      if (this.hashtags.length > 0) {
        let tagsString = '';
        let first = true;
        this.hashtags.forEach((tag) => {
          tagsString += first ? `${tag}` : `${this.separator}${tag}`;
          first = false;
        });
        this.formPost.get('hashtags').setValue(tagsString);
      } else {
        this.formPost.get('hashtags').setValue(null);
      }
    }
  }

  openPhotoEditor() {
    const config = {
      media: this.formPost.getRawValue()?.media,
      media_ids: this.formPost.getRawValue()?.media_ids,
      post: this.activePost,
      shopId: this.shopId,
    };

    const dialog = this.dialogService.openDialog(DialogPhotoEditorComponent, { data: { config } });

    dialog.afterClosed().subscribe((editorData: EditorMediaAndPhoto) => {
      if (editorData) {
        this.updateImages(editorData.media);
        this.activePost.photo = editorData.photo;
        if (!editorData.draft) {
          this.editorPhotoSaveClicked = !!editorData.photo;
          this.setPlatformPhoto(editorData.photo);
        }
      }
    });
  }

  get isPostPhotoFromEditor() {
    return this.activePost?.photo && this.activePost?.photo?.url1x?.indexOf('framed') > -1;
  }

  get isEditorVisible() {
    const isUserAdmin = this.me.role === 999;
    const maxStatusForVisibleEditor = isUserAdmin ? 2 : 1;
    return this.activePost?.status < maxStatusForVisibleEditor;
  }

  setPlatformPhoto(photo: Media) {
    if (this.activePost.platforms?.indexOf(PostPlatforms.Facebook) > -1) {
      this.activePost.photo_facebook_feed_id = photo.id;
    }

    if (this.activePost.platforms?.indexOf(PostPlatforms.Instagram) > -1) {
      this.activePost.photo_instagram_feed_id = photo.id;
    }

    if (this.activePost.platforms?.indexOf(PostPlatforms.TikTok) > -1) {
      this.activePost.photo_tik_tok_feed_id = photo.id;
    }

    if (this.activePost.platforms?.indexOf(PostPlatforms.Snapchat) > -1) {
      this.activePost.photo_snapchat_feed_id = photo.id;
    }

    this.activePost.photo_id = photo.id;
  }

  cleanNulls(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === '') {
        delete obj[key];
      }
    });
    return obj;
  }
}
