import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MovieApiService {
  constructor(private http: HttpClient) {}

  // addCompany = (newCompany: Company) => this.http.post('/api/v1.0/companies', newCompany);

  // updateCompany = (companyForm: Company) => this.http.put<Company>(`/api/v1.0/companies/`, companyForm);

  // Movies

  getMovie = (id) => this.http.get(`/api/v1.0/movies/${id}`);

  getAvailableMovies = (params) => this.http.get('/api/v1.0/movies?movie_statuses=2', { params });

  getShopUnassignedMovies = (params) => this.http.get(`/api/v1.0/movies/unassigned?movie_statuses=2`, { params });

  movieBulkAction = (body) => this.http.post('/api/v1.0/movies/bulk', body);

  getPeople = (params) => this.http.get('/api/v1.0/movies/people/autocomplete', { params: params });

  // Shop

  getShopMovie = (id) => this.http.get(`/api/v1.0/shop-movies/${id}`);

  getShopMovies = (params) => this.http.get('/api/v1.0/shop-movies', { params: params });

  // Shows

  importShows = () => this.http.post('/api/v1.0/shows/import', {});

  getShows = (params) => this.http.get('/api/v1.0/shows', { params: params });

  removeShows = (showIds, shopId) =>
    this.http.post('/api/v1.0/shows/bulk', { ids: showIds, action: 'delete', filter: { shop: shopId } });

  setShowsStatusToImported = (showIds, shopId) =>
    this.http.post('/api/v1.0/shows/bulk', { ids: showIds, action: 'status-Imported', filter: { shop: shopId } });

  setShowsStatusToPublished = (showIds, shopId) =>
    this.http.post('/api/v1.0/shows/bulk', { ids: showIds, action: 'status-Published', filter: { shop: shopId } });

  // getCompanyShops = (params) => this.http.get('/api/v1.0/shops', { params: params });

  addShopMovie = (movie) => this.http.post('/api/v1.0/shop-movies', movie);

  editShopMovie = (movie) => this.http.put('/api/v1.0/shop-movies', movie);

  updateShopMovieStatus = (body) => this.http.post('/api/v1.0/shop-movies/bulk', body);

  removeShopMovie = (shopMovieId: string) => this.http.delete(`/api/v1.0/shop-movies/${shopMovieId}`);

  // removeCompanyUser = (companyId: string, userID: string) => this.http.delete(`/api/v1.0/companies/${companyId}/users/${userID}`);

  // Events

  getEvent = (id) => this.http.get(`/api/v1.0/events/${id}`);

  getEvents = (params) => this.http.get('/api/v1.0/events', { params });

  addEvent = (body) => this.http.post('/api/v1.0/events', body);

  editEvent = (body) => this.http.put('/api/v1.0/events', body);

  removeEvent = (id) => this.http.delete(`/api/v1.0/events/${id}`);

  inviteUserToAnEvent = (eventId, body) => this.http.post(`/api/v1.0/events/${eventId}/users`, body);

  resendInvite = (inviteId) => this.http.post(`/api/v1.0/events/invite/${inviteId}/resend`, {});

  getEventUsers = (eventId, params) => this.http.get(`/api/v1.0/events/${eventId}/users`, { params });

  removeEventUser = (eventId, userId) => this.http.delete(`/api/v1.0/events/${eventId}/users/${userId}`);

  removeInvitation = (eventId, userEmail) =>
    this.http.delete(`/api/v1.0/events/${eventId}/invite`, { params: { email: userEmail } });

  getInvitationPreview = (eventId, body) =>
    this.http.post(`/api/v1.0/events/${eventId}/invite/preview`, body, { responseType: 'text' });

  bulkEventInviteAction = (body) => this.http.post(`/api/v1.0/events/invite/bulk`, body);

  exportInvites = (body) => this.http.post(`/api/v1.0/events/invite/export`, body, { responseType: 'text' });

  // Quizzes

  getUnassignedQuizzes = (params) => this.http.get('/api/v1.0/quizzes/unassigned', { params });

  getQuiz = (quizId) => this.http.get(`/api/v1.0/quizzes/${quizId}`);

  getShopQuizzes = (params) => this.http.get('/api/v1.0/shop-quizzes', { params });

  getShopQuiz = (quizId) => this.http.get(`/api/v1.0/shop-quizzes/${quizId}`);

  assignQuiz = (quiz) => this.http.post(`/api/v1.0/shop-quizzes`, quiz);

  editShopQuiz = (quiz) => this.http.put(`/api/v1.0/shop-quizzes`, quiz);

  removeShopQuiz = (params) => this.http.get('/api/v1.0/shop-quizzes', { params });
  updateShopQuizStatus = (params) => this.http.get('/api/v1.0/shop-quizzes', { params });
}
