<div class="card card-category" (click)="onCardClick($event)" *ngIf="category">
  <div
    class="card-background"
    [ngStyle]="{ 'background-image': 'url(' + category.photo?.url1x + '?w=350&h=200&m=2' + addWebp + ')' }"
  ></div>
  <div class="card-body">
    <div class="card-title dot">
      <span>{{ category.name }}</span>
    </div>
    <a [routerLink]="['/categorie', category.permalink]" class="btn btn-card xs-hide sm-hide md-hide">
      {{ 'global.open' | translate }}
    </a>
  </div>
</div>
