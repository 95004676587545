<div class="text-center">
  <div class="main-header">
    <h1 class="main-title">
      {{ 'global.sorry_link_expired' | translate }}
    </h1>
    <h3 class="main-subtitle">
      {{ 'global.cant_apply_anymore' | translate }}
    </h3>
  </div>
</div>
