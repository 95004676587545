import { Media } from "./Media";

export interface Acquisition {
  photo_id: string;
  photo: Media;
  photo_mobile_id: string;
  photo_mobile: Media;
  company_group_id: string;
  subscription_type_id: string;
  subscription_duration_days: number;
  name: string;
  available_from: string;
  available_to: string;
  show_register: boolean;
  show_request_demo: boolean;
  id: string;
}

