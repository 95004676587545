import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { APP_CONFIG } from '@digilize/shared/utils/tokens';
import { AppConfig } from '@digilize/shared/definitions/src';
@Injectable({
  providedIn: 'root',
})
export class VacaniesService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig) {}

  setVacancyId(id: string) {
    const vacancyIdExpiration = moment().add(1, 'minutes');

    localStorage.setItem(this.appConfig.localStorageAuthPrefix + 'vacancyId', id);
    localStorage.setItem(
      this.appConfig.localStorageAuthPrefix + 'vacancyIdExpiration',
      JSON.stringify(vacancyIdExpiration.valueOf())
    );
  }

  checkVacancyExpiration() {
    const vacancyIdExpiration = localStorage.getItem('bwd-vacancyIdExpiration');
    if (Number(vacancyIdExpiration) > moment.now()) {
      return true;
    }
    return false;
  }

  getVacancyDetails = (vacancyId) => this.http.get(`/api/v1.0/vacancy/${vacancyId}`);

  vacancyApply = (vacancyId, req: any) => this.http.post(`/api/v1.0/vacancy/${vacancyId}/apply`, req);

  vacancyApplied = (vacancyId, req: any) => this.http.post(`/api/v1.0/vacancy/${vacancyId}/applied`, req);

  vacancySearch = (params: any) =>
    this.http.get('/api/v1.0/vacancy/search', {
      params,
    });

  getVacanciesByCompany = (params: any) =>
    this.http.get('/api/v1.0/vacancy/bycompany/', {
      params,
    });

  getVacancyApplicants = (params: any) =>
    this.http.get('/api/v1.0/applicants', {
      params,
    });

  getVacancyProfessionsData = (vacancyId) => this.http.get(`/api/v1.0/vacancy/${vacancyId}/professions`);

  putVacancyProfessions = (req: any) => this.http.put('/api/v1.0/vacancy/professions/', req);

  getManualVacancy = (req: any) => this.http.post('/api/v1.0/manualvacancy', req);

  getListOfProfessions = (searchValue: string) =>
    this.http.get('/api/v1.0/professions/', {
      params: {
        'search.value': searchValue,
        length: 10,
      },
    });

  uploadVacancy = (vacancy) => this.http.post('/api/v1.0/vacancy', vacancy);

  getProfessionsMy = () => this.http.get('/api/v1.0/professions/my');

  putProfessionsMy = (req: any) => this.http.put('/api/v1.0/professions/my', req);

  getLevels = (searchValue: string, code: string) =>
    this.http.get('/api/v1.0/education/levels', {
      params: {
        'search.value': searchValue,
        country: code,
        length: 115,
      },
    });
}
