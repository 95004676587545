<lib-dialog-layout-inner-x *ngIf="closeable" (close)="dialogRef.close()" [title]="'auth.session_expired' | translate">
  <ng-template #content>
    <p>{{ 'auth.your_session_expired' | translate }}</p>
  </ng-template>
  <ng-template #actions>
    <button (click)="dialogRef.close()" class="btn btn-primary">
      <span inlineSVG="/icons/icon-arrow-current-color.svg"></span>
      {{ 'auth.signin' | translate }}
    </button>
  </ng-template>
</lib-dialog-layout-inner-x>

<div class="dialog-panel" *ngIf="!closeable" (close)="dialogRef.close()">
  <h1>
    {{ 'auth.session_expired' | translate }}
  </h1>

  <p>{{ 'auth.your_session_expired_from_origin' | translate }}</p>
</div>
