export interface EnvConfig {
  production: boolean;
  staging: boolean;
  development: boolean;
  acceptance?: boolean;
  api: string;
  api2?: string;
  facebookAppId: string;
  instrumentationKey: string;
  recaptchaSiteKey: string;
  surveyStartUrl?: string;
  surveyFinishUrl?: string;
}
