import { Media } from './Media';

export interface Tip {
  title: string;
  body: string;
  from: string;
  to: string;
  onboarding: boolean;
  order: number;
  id: string;
  youtube: string;
  photo: Media;
}
