<div class="mat-form-field-container lib-select-container" #mffContainer *ngIf="options.length > 0 || groups">
  <mat-form-field
    [hideRequiredMarker]="false"
    appearance="standard"
    class="select-primary"
    [floatLabel]="myFloatLabel ? myFloatLabel : 'never'"
    [ngClass]="{
      'select-open': select.panelOpen,
      'no-infix': noInfix,
      'no-underline': noUnderline,
      'no-background': noBackground,
      'with-label': label
    }"
    *ngIf="!native"
  >
    <mat-label *ngIf="label"
      >{{ translateLabel ? (label | translate) : label }}<sup *ngIf="requiredInLabel">*</sup></mat-label
    >
    <span
      class="select-arrow"
      [inlineSVG]="
        (dropdownIconsPath ? dropdownIconsPath : '/icons/icon-arrow-') + (select.panelOpen ? 'up' : 'down') + '.svg'
      "
    ></span>
    <mat-select
      [required]="required"
      [disabled]="disabled"
      #select
      (openedChange)="onOpenedChange($event)"
      [(ngModel)]="value"
      disableOptionCentering
      [ngClass]="{ 'offset-left': offsetToLeft }"
      [panelClass]="'select-options-primary ' + customPanelClass"
      [placeholder]="placeholder | translate"
      (selectionChange)="onValueChange($event)"
    >
      <mat-select-trigger *ngIf="withTrigger">
        <ng-content></ng-content>
      </mat-select-trigger>
      <ng-container *ngIf="groups">
        <ng-container *ngFor="let group of $any(groups) | keyvalue: unsorted">
          <mat-optgroup *ngIf="group.value.length > 0" [label]="group.key | translate">
            <ng-container *ngTemplateOutlet="matOptions; context: { options: group.value }"></ng-container>
          </mat-optgroup>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!groups">
        <ng-container *ngTemplateOutlet="matOptions; context: { options: options }"></ng-container>
      </ng-container>

      <ng-template #matOptions let-options="options">
        <ng-container *ngFor="let option of options">
          <mat-option
            [class.hidden]="option.hidden"
            (click)="stayOpenAfterPick && select.open()"
            [value]="option.value"
            [ngStyle]="{ width: mffContainer.getBoundingClientRect().width - 1 + 'px' }"
          >
            <ng-container *ngIf="option.img">
              <img class="img-option" [style.width]="optionImgWidth" [src]="option.img" />
            </ng-container>
            <ng-container *ngIf="translation; else noTranslate">
              {{ (translationPrefix ? translationPrefix + option.key : option.key) | translate }}
            </ng-container>
            <ng-template #noTranslate>
              {{ option.key }}
            </ng-template>
          </mat-option>
        </ng-container>
      </ng-template>
    </mat-select>
    <div class="mat-error" *ngIf="errors">
      <ng-container *ngIf="errors.required">
        {{ 'form.error.required' | translate }}
      </ng-container>
    </div>
  </mat-form-field>

  <mat-form-field
    [ngClass]="{ 'no-infix': noInfix, 'no-underline': noUnderline, 'no-background': noBackground, 'with-label': label }"
    class="select-primary"
    [floatLabel]="'never'"
    *ngIf="native"
  >
    <span class="select-arrow" [inlineSVG]="'/icons/icon-arrow-down.svg'"></span>
    <select
      #select
      matNativeControl
      [(ngModel)]="value"
      [placeholder]="'global.select' | translate"
      (change)="onValueChange($event, true)"
    >
      <ng-container *ngIf="groups">
        <ng-container *ngFor="let group of $any(groups) | keyvalue: unsorted">
          <optgroup *ngIf="group.value.length > 0" [label]="group.key | translate">
            <ng-container *ngTemplateOutlet="matOptionsNative; context: { options: group.value }"></ng-container>
          </optgroup>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!groups">
        <ng-container *ngTemplateOutlet="matOptionsNative; context: { options: options }"></ng-container>
      </ng-container>

      <ng-template #matOptionsNative let-options="options">
        <option *ngFor="let option of options" [value]="option.value">
          <ng-container *ngIf="translation; else noTranslate">
            {{ option.key | translate }}
          </ng-container>
          <ng-template #noTranslate>
            {{ option.key }}
          </ng-template>
        </option>
      </ng-template>
    </select>
    <div class="mat-error" *ngIf="errors">
      <ng-container *ngIf="errors.required">
        {{ 'form.error.required' | translate }}
      </ng-container>
    </div>
  </mat-form-field>
</div>
