export enum WorkExperienceLevels {
  student = 0,
  starter = 1,
  experienced = 2,
  expert = 3,
}
export enum MessageTypes {
  activate_dns_email = 0,
  activate_account = 1,
  activate_account_by_code = 2,
  contact_user = 3,
  password_forgotten = 4,
  company_invite = 5,
  login_by_code = 6,
  new_account = 7,
  password_reset = 8,
  contact_applicant = 9,
  notify_recruiter = 10,
}
