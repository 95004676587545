import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductAdditionalCost } from '@shared/definitions';

@Component({
  selector: 'lib-form-extra-costs',
  templateUrl: './form-extra-costs.component.html',
  styleUrls: ['./form-extra-costs.component.scss'],
})
export class FormExtraCostsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  @Input() costs: ProductAdditionalCost[] = [];
  @Input() taxes: any[] = [];
  open = true;

  order = ['name', 'tax_id', 'amount', 'description'];

  constructor() {}

  ngOnInit(): void {
    if (this.costs?.length > 0) {
      this.initCosts();
    }
  }

  initCosts() {
    this.costs.forEach((cost) => {
      this.addCost(cost);
    });
  }

  addCost(cost?: ProductAdditionalCost) {
    const costForm: UntypedFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(cost?.id),
      tax_id: new UntypedFormControl(cost?.tax_id ? cost.tax_id : this.taxes[0].value, [Validators.required]),
      name: new UntypedFormControl(cost?.name),
      amount: new UntypedFormControl(cost?.amount, [Validators.required]),
      description: new UntypedFormControl(cost?.description),
    });

    this.getCosts().push(costForm);
  }

  removeCost(index) {
    this.getCosts().removeAt(index);
  }

  getFormControl(name) {
    return this.form.get(name);
  }

  getCosts(): UntypedFormArray {
    return this.getFormControl('additional_costs') as UntypedFormArray;
  }
}
