
export enum AuctionPriceType {
  Unknown = 0,
  AskingPrice = 1,
  BestOffer = 2,
  ToBeDecided = 3,
  OnRequest = 4,
  Free = 5,
  PricePerUnit = 6
}
