import { ResearchGroupType, ResearchResultsVisibility, ResearchType360 } from '../enums';
import { ResearchGroupTranslation } from './ResearchTranslation';

export interface ResearchGroupReq {
  name: string;
  labels: string;
  type: ResearchGroupType;
  type360: ResearchType360;
  results_visibility: ResearchResultsVisibility;
  label_groups: Record<string, { text: string }[]>;
  to_invite_after: number;
  finish: Date;
  /*   enText: string; fields like these will be added depending on cms setting
  nlText: string;
  esText: string;
  deText: string; */
  research_id: string;
  email_verb_ids: string[];
  translations: ResearchGroupTranslation[];
}
