<bwh-lib-step-control-bar
  [langDropdown]="true"
  [prevBut]="prevBut"
  [closeBut]="closeBut"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
  (emitClose)="close()"
  (emitPrev)="goPrev()"
></bwh-lib-step-control-bar>
<div class="text-center">
  <div class="main-header">
    <h1 class="main-title">{{ 'global.professional_skills' | translate }}</h1>
  </div>
  <div class="scrollable-content">
    <div class="main-header">
      <h3 class="select-txt">{{ 'global.prof_skills_description' | translate }}</h3>
      <h3 class="select-txt two-lines">{{ 'global.prof_skills_second_description' | translate }}</h3>
    </div>
    <form *ngIf="jobsAdded && dataLoaded && searchDataLoaded" [formGroup]="form">
      <lib-single-select-search
        #searchAutocomplete
        [options]="availableSkills"
        [placeholder]="'global.search_skill' | translate"
        [iconPath]="'/icons/search-icon.svg'"
        [staticIcon]="true"
        [myFloatLabel]="'never'"
        [addDebounce]="300"
        [clearInput]="true"
        [waitForOptionsChange]="true"
        [suggestionsOff]="true"
        [detectScrollToEnd]="true"
        [customOption]="searchSkillCustom"
        (changed)="handleFilteredOptionClick($event)"
        (valueChanged)="handleValueChangedSearch($event)"
        (scrolledToEnd)="handleScrolledToEndSearch($event)"
      >
      </lib-single-select-search>

      <bwh-lib-skills-list
        class="added-list"
        data-skill="added-skills"
        *ngIf="jobsAdded && dataLoaded"
        [form]="form"
        [otherFormGroup]="form"
        [skillsArrayName]="'added_skills'"
        [otherArrayName]="'suggested_prof_skills'"
        [removeAction]="true"
        [maxItems]="maxItems"
        [removeToIndex]="true"
        [backToArray]="'jobsArray'"
        (remove)="itemRemoved()"
      ></bwh-lib-skills-list>
      <bwh-lib-skills-list
        class="added-list"
        data-skill="added-skills"
        *ngIf="!jobsAdded && dataLoaded"
        [form]="form"
        [otherFormGroup]="form"
        [skillsArrayName]="'added_skills'"
        [removeAction]="true"
        [maxItems]="maxItems"
        (remove)="itemRemoved()"
      ></bwh-lib-skills-list>

      <div class="jobs-title">{{ 'global.suggested_prof_skills_explain_txt' | translate }}</div>
      <div class="job-container" formArrayName="jobsArray">
        <div class="card-slider-container">
          <div class="card-slider-nav" [ngClass]="{ show: showArrows }">
            <div
              class="btn-slider btn-next"
              (click)="changeSlide('next')"
              [ngClass]="{ blocked: blockSlideBtns }"
              *ngIf="selectedSlide !== jobsArray.value.length - 1"
            >
              <span class="arrow-cont">
                <span class="icon" [inlineSVG]="'/icons/right-arrow-icon.svg'"></span>
              </span>
              <span class="btn-title">{{ selectedSlide | libsGetJobNameByIndex: jobsArray.value:'next' }} </span>
            </div>
            <div
              class="btn-slider btn-prev"
              (click)="changeSlide('prev')"
              [ngClass]="{ blocked: blockSlideBtns }"
              *ngIf="selectedSlide !== 0"
            >
              <span class="btn-title">{{ selectedSlide | libsGetJobNameByIndex: jobsArray.value:'prev' }}</span>
              <span class="arrow-cont">
                <span class="icon" [inlineSVG]="'/icons/left-arrow-icon.svg'"></span>
              </span>
            </div>
          </div>
          <div class="case-slider" [ngClass]="{ 'no-jobs': !jobsAdded }">
            <ngx-glide
              #glide1
              data-attr="glide1-slider"
              [type]="'slider'"
              [showArrows]="false"
              [showBullets]="false"
              [swipeThreshold]="false"
              [rewind]="false"
            >
              <div class="slide" *ngFor="let section of jobsArray.controls; let i = index">
                <bwh-lib-selected-item
                  [name]="section.value.job"
                  [index]="i + 1"
                  [listIcon]="false"
                  [indexIcon]="true"
                  [noDelete]="true"
                >
                </bwh-lib-selected-item>
              </div>
            </ngx-glide>

            <ngx-glide
              #glide2
              data-attr="glide2-slider"
              [type]="'slider'"
              [showArrows]="false"
              [showBullets]="false"
              [swipeThreshold]="false"
              [rewind]="false"
            >
              <div class="slide" *ngFor="let section of jobsArray.controls; let i = index">
                <div class="skills-container">
                  <bwh-lib-skills-list
                    class="proposed-list"
                    data-skill="suggested-skills"
                    [form]="getFormGroup(i)"
                    [otherFormGroup]="form"
                    [skillsArrayName]="'suggested_prof_skills'"
                    [otherArrayName]="'added_skills'"
                    [addAction]="true"
                    [maxItems]="maxItems"
                    [removeToIndex]="false"
                    [backToArray]="'jobsArray'"
                  ></bwh-lib-skills-list>
                </div>
              </div>
            </ngx-glide>
          </div>
          <div *ngIf="hideLoadMore()" class="load-more-btn-cont">
            <div
              class="load-more"
              [ngClass]="{ loading: checkForLoading(selectedSlide) }"
              (click)="loadMore(selectedSlide)"
            >
              {{ 'global.load_more' | translate }}
            </div>
            <div class="spinner-container p-absolute" *ngIf="checkForLoading(selectedSlide)">
              <mat-spinner class="primary" [diameter]="30"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="skills-sum">{{ this.addedSkillsArray.length }} {{ 'global.skills_selected' | translate }}</div> -->
  <ul class="next-button-container">
    <!-- <button (click)="checkForm()">check form</button> -->
    <li *ngIf="prevBut === true">
      <button class="btn w-md h-lg primary prev-btn" type="button" (click)="goPrev()">
        {{ 'global.previous' | translate }}
      </button>
    </li>
    <li>
      <button
        *ngIf="!editing"
        class="btn w-md h-lg secondary"
        type="submit"
        (click)="onSubmit()"
        [disabled]="!this.form.valid"
      >
        {{ 'global.next_button' | translate }}
      </button>
      <button
        *ngIf="editing"
        class="btn w-md h-lg secondary"
        type="submit"
        (click)="onEdit()"
        [disabled]="!this.form.valid"
      >
        {{ 'global.save' | translate }}
      </button>
    </li>
  </ul>
</div>
