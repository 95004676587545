import { Media } from "./Media";

export interface Post {
  body: string;
  campaign: string;
  campaign_id: string;
  company_group: any;
  company_group_id: string;
  days_after_start: number;
  hashtags: string;
  hashtags_list: any[];
  id: string;
  movie: string;
  photo: Media;
  photo_facebook_feed: Media;
  photo_facebook_feed_id: string;
  photo_facebook_story: Media;
  photo_facebook_story_id: string;
  photo_id: string;
  photo_instagram_feed: Media;
  photo_instagram_feed_id: string;
  photo_instagram_reels: Media;
  photo_instagram_reels_id: string;
  photo_instagram_story: Media;
  photo_instagram_story_id: string;
  photo_tik_tok_feed: Media;
  photo_tik_tok_feed_id: string;
  platforms: string;
  platforms_list: any[];
  post_time: string;
  post_id: string;
  suggested_post_start: string;
  shop_id: string;
  title: string;
}
