<div class="container" (click)="select()">
  <div class="img-container">
    <img
      [ngClass]="{
        active: model === value,
        disabled: ngControl.disabled,
        correct: styleClass === 'correct',
        error: styleClass === 'error'
      }"
      [src]="img"
    />
    <span class="style-icon" *ngIf="styleClass === 'correct'" inlineSVG="/icons/icon-success-4.svg"></span>
    <span class="style-icon" *ngIf="styleClass === 'error'" inlineSVG="/icons/icon-cancel-dark-red.svg"></span>
  </div>
</div>
