import { ResearchGroupType, ResearchStatus, ResearchType360 } from '../enums';
import { ResearchGroupTranslation } from './ResearchTranslation';

export interface ResearchGroup {
  research_id: string | null;
  research_name?: string;
  research_invitation_types: string /* types of ResearchInvitationType separated "|", eg: 'manual| import'*/;
  research_name360?: string;
  invited_completed: number;
  invited_exported: number;
  invited_in_progres: number;
  invited_total: number;
  name: string;
  label_groups: Record<string, { text: string }[]>;
  labels?: string | string[];
  finish?: Date;
  status: ResearchStatus;
  type: ResearchGroupType;
  type360: ResearchType360;
  to_invite_after?: number;
  translation_ids?: string[];
  translations: ResearchGroupTranslation[];
  id: string;
  email_verb_ids: string[];
}

export type ResearchInvitationType = 'manual' | 'import' | 'anonymous' | 'link';
