import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SingleSelectSearchComponent } from '@digilize/shared/ui/components/src/lib/form-items/single-select-search/single-select-search.component';
import { minArrayLengthValidator } from '@digilize/shared/utils/validators';
import { NgxGlideComponent } from 'ngx-glide';
import { CvService } from '../../../services/cv.service';
import { SkillsService } from '../../../services/skills.service';
import { createOptions } from '../../../helpers/helpers';
import { LoadMore, SkillsArray } from '@bwc/src/app/models/skills';
import { NewSkillReq, SkillType } from '@digilize/shared/definitions/src/lib/enums';
import { SkillsApiService } from '@digilize/shared/data/services/src';
import { throwError } from 'rxjs';

@Component({
  selector: 'bwc-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() prevBut: boolean;
  @Input() closeBut: boolean;
  @Input() editing: boolean;
  @Input() previousStep: number;
  @Input() currentStep: number;
  @Input() steps: number;
  @Output() dataStep = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitSkills = new EventEmitter();
  @ViewChild('glide1') glide1: NgxGlideComponent;
  @ViewChild('glide2') glide2: NgxGlideComponent;
  @ViewChild('searchAutocomplete') searchAutocomplete: SingleSelectSearchComponent;

  form: FormGroup;
  allAvailableSkills = [];
  availableSkills = [];
  maxItems = 999;
  selectedSlide = 0;
  slidesShowed = [0];
  searchLength = 10;
  loadMoreData: LoadMore[] = [];
  showArrows = true;
  searchAdded = false;
  jobsAdded = true;
  dataLoaded = false;
  searchDataLoaded = false;
  searchValue = '';
  blockSlideBtns = false;
  searchSkillCustom = false;

  constructor(
    private cvService: CvService,
    private skillsService: SkillsService,
    private fb: FormBuilder,
    private skillsApiService: SkillsApiService
  ) {}

  ngOnInit(): void {
    if (this.formGroup !== undefined) {
      this.form = this.createFormGroup();
      this.remSuggAndAvailSkillsAfterAdd();
      this.getDataEdit();
      this.getSearchSkills();
    } else {
      this.form = this.createFormGroup();
      this.remSuggAndAvailSkillsAfterAdd();
      this.getData();
      this.getSearchSkills();
    }
  }

  createFormGroup(): FormGroup {
    return this.fb.group<any>({
      jobsArray: this.fb.array<JobsArray[]>([]),
      added_skills: this.fb.array<SkillsArray[]>([], minArrayLengthValidator(4)),
    });
  }

  remSuggAndAvailSkillsAfterAdd() {
    this.addedSkillsArray.valueChanges.subscribe(() => {
      this.remDuplSkillsFromSuggestedArrays(this.addedSkillsArray, this.jobsArray);
      this.availableSkills = this.removeDuplicates(this.availableSkills, this.addedSkillsArray.value);
    });
  }

  changeSlide(param: string) {
    this.blockSlideBtns = true;
    setTimeout(() => (this.blockSlideBtns = false), 400);
    const perView = 1;
    if (this.jobsArray.length > perView) {
      if (param === 'next') {
        this.glide1.go('>');
        this.glide2.go('>');
        this.selectedSlide++;
        if (!this.slidesShowed.includes(this.selectedSlide)) {
          this.getSkillsForItem(this.selectedSlide, true);
          this.slidesShowed.push(this.selectedSlide);
        }
      } else if (param === 'prev') {
        this.glide1.go('<');
        this.glide2.go('<');
        this.selectedSlide--;
      }
    }
  }

  getSearchSkills() {
    const obj = {
      searchValue: '',
      start: 0,
      length: this.searchLength,
      draw: this.searchLength,
    };

    this.skillsService.getTasksSkillsByJob(obj).subscribe((skills: any) => {
      this.availableSkills = createOptions(skills.data);
      this.searchDataLoaded = true;
    });
  }

  getData() {
    this.cvService.getCV().subscribe((data: any) => {
      if (data.task_skills.length > 0) {
        data.task_skills.forEach((item) => {
          this.addedSkillsArray.push(
            this.fb.control({
              index: null,
              id: item.id,
              skill_id: item.skill_id,
              skill_name: item.skill_name,
              rating: item.rating,
            })
          );
        });
      }
      if (data.work_history.length > 0) {
        data.work_history.forEach((item, index) => {
          this.jobsArray.push(this.createFormSection(item.position_name, [], index, 'work', item.position_id));
        });
      }
      if (data.education.length > 0) {
        data.education.forEach((item, index) => {
          this.jobsArray.push(this.createFormSection(item.education_name, [], index, 'education', item.education_id));
        });
      }
      if (data.work_history.length > 0 || data.education.length > 0) {
        this.getSkillsForItem(0);
      } else {
        this.jobsAdded = false;
        this.dataLoaded = true;
      }
    });
  }

  getDataEdit() {
    if (this.taskSkills.getRawValue().length > 0) {
      this.taskSkills.getRawValue().forEach((item) => {
        this.addedSkillsArray.push(
          this.fb.control({
            index: null,
            id: item.id,
            skill_id: item.skill_id,
            skill_name: item.skill_name,
            rating: item.rating,
          })
        );
      });
    }
    if (this.workHistory.getRawValue().length > 0) {
      this.workHistory.getRawValue().forEach((item, index) => {
        this.jobsArray.push(this.createFormSection(item.position_name, [], index, 'work', item.position_id));
      });
    }
    if (this.education.getRawValue().length > 0) {
      this.education.getRawValue().forEach((item, index) => {
        this.jobsArray.push(this.createFormSection(item.education_name, [], index, 'education', item.education_id));
      });
    }
    if (this.workHistory.getRawValue().length > 0 || this.education.getRawValue().length > 0) {
      this.getSkillsForItem(0);
    } else {
      this.jobsAdded = false;
      this.dataLoaded = true;
    }
  }

  prepareSearch(selectedSlide, id) {
    if (this.loadMoreData[selectedSlide] === undefined) {
      this.loadMoreData[selectedSlide] = { loadedData: 0, totalData: null };
    }
    return {
      searchValue: '',
      start: this.loadMoreData[selectedSlide].loadedData,
      length: this.searchLength,
      draw: this.searchLength,
      id: id,
    };
  }

  saveLoadMoreData(item, saveloadingData) {
    const thisLoadedData = this.loadMoreData[this.selectedSlide].loadedData;
    if (saveloadingData || thisLoadedData === 0) {
      this.loadMoreData[this.selectedSlide] = {
        loadedData: thisLoadedData === 0 ? this.searchLength : thisLoadedData + this.searchLength,
        totalData: item.recordsTotal,
      };
    }
  }

  getSkillsForItem(selectedSlide, saveloadingData?) {
    const jobAtInd = this.jobsArray.at(selectedSlide);
    const id = jobAtInd.get('id').value;
    const type = jobAtInd.get('type').value;
    const sugg_task_skills_fa = jobAtInd.get('suggested_tasks_skills') as FormArray;
    if (type === 'work') {
      const obj = this.prepareSearch(selectedSlide, id);

      this.skillsService.getTasksSkillsByJob(obj).subscribe((item: any) => {
        const filtr_suggested_skills = this.filterDuplicates(item.data, this.addedSkillsArray.getRawValue());
        this.pushSkillsToSuggFAWithInd(filtr_suggested_skills, sugg_task_skills_fa, selectedSlide);

        this.saveLoadMoreData(item, saveloadingData);
        this.dataLoaded = true;
        this.jobsArray.at(selectedSlide).get('loading').setValue(false);
      });
    } else if (type === 'education') {
      const obj = this.prepareSearch(selectedSlide, id);

      this.skillsService.getTasksSkillsByEducation(obj).subscribe((item: any) => {
        const filtr_suggested_skills = this.filterDuplicates(item.data, this.addedSkillsArray.getRawValue());
        this.pushSkillsToSuggFAWithInd(filtr_suggested_skills, sugg_task_skills_fa, selectedSlide);

        this.saveLoadMoreData(item, saveloadingData);
        this.dataLoaded = true;
        this.jobsArray.at(selectedSlide).get('loading').setValue(false);
      });
    }
  }

  loadMore(selected) {
    this.jobsArray.at(selected).get('loading').setValue(true);
    this.getSkillsForItem(this.selectedSlide, true);
  }

  hideLoadMore() {
    if (this.loadMoreData[this.selectedSlide].loadedData >= this.loadMoreData[this.selectedSlide].totalData) {
      return false;
    } else {
      return true;
    }
  }

  pushSkillsToSuggFAWithInd(data, formArray, index) {
    data.forEach((item) => {
      formArray.push(
        this.fb.control({ index: index, id: item.id, skill_id: item.id, skill_name: item.name, rating: 0 })
      );
    });
  }

  filterDuplicates(toFilter, arr) {
    const skillsIds = arr.map((skill) => skill.skill_id);
    return toFilter.filter((element) => !skillsIds.includes(element.id));
  }

  createFormSection(
    job = '',
    suggested_skills: SkillsArray[] = [],
    index: number,
    type: string,
    id: string
  ): FormGroup {
    const formGroup = this.fb.group({
      job: [job],
      suggested_tasks_skills: this.fb.array(
        suggested_skills.map((skill) =>
          this.fb.control({ index: index, id: skill.id, skill_id: skill.id, skill_name: skill.name, rating: 0 })
        )
      ),
      type: type,
      id: id,
      loading: false,
    });
    return formGroup;
  }

  removeDuplicates(allAvailable, addedSkills) {
    const skillsToRemove = addedSkills.map((skill) => skill.id);
    const filteredSkills = allAvailable.filter((skill) => !skillsToRemove.includes(skill.id));
    return filteredSkills;
  }

  remDuplSkillsFromSuggestedArrays(addedSkillsArray: SkillsArray[] | FormArray, jobsArray: FormArray) {
    let addedSkills: SkillsArray[];
    if (Array.isArray(addedSkillsArray)) {
      addedSkills = addedSkillsArray;
    } else {
      addedSkills = addedSkillsArray.value;
    }
    const suggestedSkills = jobsArray.value.reduce((acc, job) => {
      return acc.concat(job.suggested_tasks_skills);
    }, []);
    const uniqueSkills = suggestedSkills.filter((skill) => {
      return !addedSkills.some((addedSkill) => addedSkill.id === skill.id);
    });

    jobsArray.controls.forEach((jobControl, index) => {
      if (index !== this.selectedSlide || this.searchAdded) {
        const suggestedSkillsArray = jobControl.get('suggested_tasks_skills') as FormArray;

        suggestedSkillsArray.controls.forEach((skillControl, skillIndex) => {
          if (!uniqueSkills.includes(skillControl.value)) {
            suggestedSkillsArray.removeAt(skillIndex);
          }
        });
      }
    });
    this.searchAdded = false;
  }

  getFormGroup(index: number) {
    return this.jobsArray.at(index) as FormGroup;
  }

  handleFilteredOptionClick(option: any) {
    if (option.id === null) {
      const req: NewSkillReq = {
        name: option.text,
        type: SkillType.Tasks,
        profession_id: null,
        education_id: null,
      };
      this.skillsApiService.addNewSkill(req).subscribe((data: any) => {
        this.addedSkillsArray.push(
          this.fb.control({ index: null, id: data.id, skill_id: data.id, skill_name: data.name, rating: 0 })
        );
      });
    } else {
      this.searchAdded = true;
      this.addedSkillsArray.push(
        this.fb.control({ index: null, id: option.id, skill_id: option.id, skill_name: option.text, rating: 0 })
      );
    }
  }

  handleValueChangedSearch(event) {
    this.searchValue = event;
    const obj = {
      searchValue: event,
      start: 0,
      length: this.searchLength,
      draw: this.searchLength,
    };
    this.skillsService.getTasksSkillsByJob(obj).subscribe((skills: any) => {
      this.availableSkills = this.removeDuplicates(createOptions(skills.data), this.addedSkillsArray.value);
      if (this.availableSkills.length === 0) {
        this.searchSkillCustom = true;
        this.availableSkills = [{ id: null, text: event, val: null }];
      } else {
        this.searchSkillCustom = false;
      }
    });
  }

  handleScrolledToEndSearch(isVisible: boolean) {
    const obj = {
      searchValue: this.searchValue,
      start: this.availableSkills.length,
      length: this.searchLength,
    };
    this.skillsService.getTasksSkillsByJob(obj).subscribe((skills: any) => {
      const newOptions = createOptions(skills.data);
      const mergedOptions = [...this.availableSkills, ...newOptions];
      this.availableSkills = mergedOptions;
    });
  }

  itemRemoved() {
    const obj = {
      searchValue: '',
      start: 0,
      length: this.searchLength,
      draw: this.searchLength,
    };
    this.skillsService.getTasksSkillsByJob(obj).subscribe((skills: any) => {
      this.availableSkills = this.removeDuplicates(createOptions(skills.data), this.addedSkillsArray.value);
    });
  }

  getRandomSkills(items: number) {
    const shuffled = this.availableSkills.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, items);
  }

  close() {
    this.emitClose.emit('task_skills');
  }

  checkForm() {
    console.log('form', this.form);
  }

  goPrev() {
    if (!this.form.valid) {
      this.dataStep.emit({ previousStep: this.currentStep, step: 8 });
    } else {
      this.saveTaskSkillsData(this.addedSkillsArray.value, false, true);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.saveTaskSkillsData(this.addedSkillsArray.value);
    }
  }

  onEdit() {
    if (this.editing && this.form.valid) {
      this.saveTaskSkillsData(this.addedSkillsArray.value, close);
    }
  }

  saveTaskSkillsData(data, close?, ifBack?) {
    const requestBody = { items: data };
    this.skillsService.putTasksSkills(requestBody).subscribe(
      () => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 8 });
        } else if (close) {
          this.emitSkills.emit(this.addedSkillsArray);
          this.emitClose.emit('task_skills');
        } else {
          this.dataStep.emit({ previousStep: this.currentStep, step: 10 });
        }
      },
      (error) => {
        if (ifBack) {
          this.dataStep.emit({ previousStep: this.currentStep, step: 8 });
        }
        throwError('Save Tasks skills error:', error);
        console.error('Save Tasks skills error:', error);
      }
    );
  }

  checkForLoading(selected) {
    return this.jobsArray.at(selected).get('loading').value;
  }

  get jobsArray() {
    return this.form.get('jobsArray') as FormArray;
  }

  get addedSkillsArray() {
    return this.form.get('added_skills') as FormArray;
  }

  get taskSkills() {
    return this.formGroup.get('task_skills') as FormArray;
  }

  get workHistory() {
    return this.formGroup.get('work_history') as FormArray;
  }

  get education() {
    return this.formGroup.get('education') as FormArray;
  }
}

export interface JobsArray {
  job: string;
  suggested_tasks_skills: SkillsArray;
}
