import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { FloatLabelType } from '@angular/material/form-field';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';
import { Option } from '@digilize/shared/definitions/src';
import { createAndObserveIntersection } from '@digilize/shared/utils/helpers/src';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-multi-select-chips-checkbox-search',
  templateUrl: './multi-select-chips-checkbox-search.component.html',
  styleUrls: ['./multi-select-chips-checkbox-search.component.scss'],
})
export class MultiSelectChipsCheckboxSearchComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() options: string[] | Option[] | any[] = [];
  @Input() myFloatLabel: FloatLabelType;
  @Input() iconPath: string;
  @Input() dropdownIconsPath: string;
  @Input() removeIconPath: string;
  @Input() removeItem = false;
  @Input() useRemoveFromOptions = false;
  @Input() disableSearch = false;
  @Input() showSelectedPlaceholder = false;
  @Input() optionObj: boolean;
  @Input() emitSelectedItemsOnSelect = false;
  @Input() emitRemovedItem: boolean;
  @Input() control: FormControl;
  @Input() waitForOptionsChange: boolean;
  @Input() placeholder = '';
  @Input() addDebounce: number;
  @Input() showTooltip = false;
  @Input() disableHidingList = false;
  @Input() showSearchPlaceholder = false;
  @Input() searchPlaceholderForAll;
  @Input() hideChips = false;
  @Input() searchPlaceholderText: string;
  @Output() changed = new EventEmitter<Option | any>();
  @Output() valueChanged = new EventEmitter<string>();
  @Output() emitRemove = new EventEmitter();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @Input() detectScrollToEnd = false;
  @Input() autoScrollOptions: ScrollOptions;
  @Input() blockScrollEmitOnInputFocus = false;
  @Input() alwaysEmitSelected = false;
  @ViewChild('endOfList') endOfList: ElementRef;
  @Output() scrolledToEnd = new EventEmitter<boolean>();
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  private inputValueChanged = new Subject<string>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new FormControl('');
  filteredOptions: Observable<any[]>;
  selectedChips: any = [];
  userInputChange = false;
  showInputPlaceholder = true;
  showOptionsList = false;
  blockScrollEmit = false;

  @Input() filterFn: (options: Option[]) => Option[] = (options) => options;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.waitForOptionsChange) {
      this.initControl();
    }
  }

  ngOnInit() {
    if (this.control) {
      this.selectedChips = this.control.value;
      this.control.valueChanges.subscribe((newValue) => {
        this.selectedChips = newValue;
      });
    }

    this.initControl();

    if (this.addDebounce) {
      this.emitDebouncedTimeValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.detectScrollToEnd) {
      createAndObserveIntersection(this.endOfList).subscribe((isVisible) => {
        if (isVisible && !this.blockScrollEmit) {
          this.scrolledToEnd.emit(true);
        }
      });
    }
  }

  private initControl() {
    this.filteredOptions = this.inputCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || '')),
      map((options) => {
        if (this.autoScrollOptions) {
          const scroll = (options.length - this.autoScrollOptions.optionsPerFetch) / options.length;
          setTimeout(() => {
            this.scrollToBottom(scroll);
          }, 0);
        }
        return this.filterFn(options);
      })
    );
  }

  onFocusAction() {
    if (this.blockScrollEmitOnInputFocus) {
      this.blockScrollEmit = true;
    }
  }

  scrollToBottom(scrollHeight?: number) {
    const container = this.scrollContainer?.nativeElement;
    const positionOfScroll = container.scrollHeight * scrollHeight - this.autoScrollOptions.heightOfScrollCont;
    container.scrollTop = positionOfScroll;
  }

  private emitDebouncedTimeValue() {
    this.inputValueChanged.pipe(debounceTime(this.addDebounce), distinctUntilChanged()).subscribe((value: string) => {
      this.valueChanged.emit(value);
    });
  }

  onInputValueChanged($event) {
    if (this.userInputChange) {
      if (this.addDebounce) {
        this.inputValueChanged.next(this.searchInput?.nativeElement.value);
      } else {
        setTimeout(() => this.valueChanged.emit(this.searchInput?.nativeElement.value), 0);
      }
    }
  }

  private filter(value: any): Option[] {
    if (typeof value !== 'string') {
      value = value.text;
    }

    if (this.options.length > 0) {
      const filterValue = value.toLowerCase();
      return this.options.filter((option) => option.text.toLowerCase().includes(filterValue));
    } else {
      return [];
    }
  }

  add(event: MatChipInputEvent): void {
    if (this.optionObj) {
      const value = { id: null, text: event.value, val: null };
      if (value) {
        this.changed.emit(value);
        this.selectedChips.push(value);
      }
    } else {
      const value = (event.value || '').trim();
      if (value) {
        this.changed.emit(value);
        this.selectedChips.push(value);
      }
    }

    event.chipInput?.clear();
    this.inputCtrl.setValue(null);
  }

  remove(event: Option): void {
    this.options = this.options.map((item: any) => (item.id === event.id ? { ...item, selected: false } : item));
    const index = this.selectedChips.indexOf(event);
    if (index >= 0) {
      this.selectedChips.splice(index, 1);
      if (this.emitRemovedItem) {
        this.emitRemove.emit(event);
      } else {
        this.emitRemove.emit(this.selectedChips);
      }
    }
    this.clearSearchInput();
  }

  onClickOutside(event) {
    this.showOptionsList = false;
  }

  onRemoveItem(event) {
    this.emitRemove.emit(event);
  }

  selected(event): void {
    if (!event.selected && this.alwaysEmitSelected) {
      this.changed.emit(event);
    }
    if (event.selected) {
      this.selectedChips.push(event);
      if (this.emitSelectedItemsOnSelect) {
        this.changed.emit(this.selectedChips);
      } else {
        this.changed.emit(event);
      }
      if (this.searchInput.nativeElement) {
        this.searchInput.nativeElement.value = '';
      }

      this.inputCtrl.setValue(null);
      this.clearSearchInput();
      setTimeout(() => {
        this.showInputPlaceholder = true;
        if (this.searchInput.nativeElement) {
          this.searchInput.nativeElement.blur();
        }
      }, 0);
    } else {
      const index = this.selectedChips.indexOf(event);
      if (index >= 0) {
        this.selectedChips.splice(index, 1);
        if (this.emitRemovedItem) {
          this.emitRemove.emit(event);
        } else {
          this.emitRemove.emit(this.selectedChips);
        }
      }
      this.clearSearchInput();
    }
  }

  getPlaceholderText(): string {
    if (this.showSelectedPlaceholder && this.searchPlaceholderForAll) {
      const allOptionsSelected = this.options.length === this.selectedChips?.filter((item) => item.selected).length;

      if (allOptionsSelected) {
        return this.translateService.instant(this.searchPlaceholderForAll);
      }
    }

    if (this.showSelectedPlaceholder && this.selectedChips?.every((item) => !item.selected)) {
      return this.translateService.instant(this.searchPlaceholderText);
    }

    return this.showSelectedPlaceholder
      ? this.selectedChips?.map((item) => item.text).join(', ')
      : this.translateService.instant(this.searchPlaceholderText);
  }

  clearSearchInput(): void {
    this.inputCtrl.setValue('');
    this.inputCtrl.markAsTouched();
    this.inputCtrl.markAsDirty();
    this.inputCtrl.reset();
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.blur();
  }
}

interface ScrollOptions {
  optionsPerFetch: number;
  heightOfScrollCont: number;
}
