export interface UserService {
  service_id: string;
  service_name: string;
  order_id: string;
  order_line_id: string;
  order_line_service_id: string;
  order_line_service_finish: string;
  order_line_service_start: string;
  order_line_service_is_finished: boolean;
  order_line_service_is_started: boolean;
  product_id: string;
  product_name: string;
  product_subtitle: string;
  product_description: string;
}
