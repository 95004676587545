import { UntypedFormControl } from '@angular/forms';

export function differentThanOtherValidator(otherControlName: string) {
  let thisControl: UntypedFormControl;
  let otherControl: UntypedFormControl;

  return function differentThanOtherValidate(control: UntypedFormControl) {
    if (!control.parent) {
      return null;
    }

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as UntypedFormControl;
      if (!otherControl) {
        throw new Error('differentThanOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value === thisControl.value) {
      return {
        matchOther: true,
      };
    }

    return null;
  };
}
