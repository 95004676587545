<bwh-lib-step-control-bar
  [langDropdown]="true"
  [currentStep]="currentStep"
  [previousStep]="previousStep"
  [steps]="steps"
></bwh-lib-step-control-bar>
<lib-file-upload
  class="scrollable-content"
  [title]="'global.upload_title' | translate"
  [subTitle]="'global.upload_subtitle' | translate"
  [fileTypes]="fileTypes"
  (fileUploaded)="getUploadedFile($event)"
>
  <ng-template #buttons>
    <ul class="upload-buttons">
      <input type="file" #fileDropRef id="fileDropRef" (change)="handleFileUpload($event)" class="d-none" />
      <li>
        <button class="btn w-lg h-lg secondary" (click)="fileDropRef.click()">
          {{ 'global.upload_from_my_device' | translate }}
        </button>
      </li>
      <li>
        <button class="btn w-lg h-lg primary" (click)="manualCv()">
          {{ 'global.i_dont_have_cv' | translate }}
        </button>
      </li>
    </ul>
  </ng-template>
</lib-file-upload>
