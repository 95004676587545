<div *ngIf="sameHeightElements" [formGroup]="formGroup" class="children-same-height">
  <label
    (click)="validate()"
    class="wrapper"
    [ngClass]="{ picked: formGroup.controls[answer.val].value }"
    *ngFor="let answer of answers"
  >
    <mat-checkbox class="checkbox d-block" [formControl]="formGroup.controls[answer.val] | formControl">
      <span class="wide-span" [ngStyle]="{ textAlign: align }" *ngIf="!withInnerHTML">{{ answer.text }}</span>
      <span class="wide-span" [ngStyle]="{ textAlign: align }" *ngIf="withInnerHTML" [innerHTML]="answer.text"></span>
    </mat-checkbox>
    <div
      #tooltip="matTooltip"
      *ngIf="answer.tooltip && answer.tooltip !== ''"
      (click)="tooltip.toggle(); $event.preventDefault()"
      [matTooltip]="answer.tooltip"
      class="info-icon"
      inlineSVG="/icons/icon-info.svg"
    ></div>
  </label>
</div>
<div *ngIf="!sameHeightElements" [formGroup]="formGroup" class="layout" [ngClass]="{ multi: multipleCols }">
  <label
    (click)="validate()"
    class="wrapper"
    [ngClass]="{ picked: formGroup.controls[answer.val].value }"
    *ngFor="let answer of answers"
  >
    <mat-checkbox class="checkbox d-block" [formControl]="formGroup.controls[answer.val] | formControl">
      <span *ngIf="!withInnerHTML">{{ answer.text }}</span>
      <span *ngIf="withInnerHTML" [innerHTML]="answer.text"></span>
    </mat-checkbox>
    <div
      #tooltip="matTooltip"
      *ngIf="answer.tooltip && answer.tooltip !== ''"
      (click)="tooltip.toggle(); $event.preventDefault()"
      [matTooltip]="answer.tooltip"
      class="info-icon"
      inlineSVG="/icons/icon-info.svg"
    ></div>
  </label>
</div>
<mat-error *ngIf="formGroup.touched && getFirstControlWithError('min', formGroup) as control">
  {{ control.errors?.message }}
</mat-error>
<mat-error *ngIf="formGroup.touched && getFirstControlWithError('max', formGroup) as control">
  {{ control.errors?.message }}
</mat-error>
