import { AuctionPriceType } from "../enums/AuctionPriceType";
import { AuctionDeliveryMethod } from "../enums/AuctionDeliveryMethod";
import { AuctionStatus } from "../enums/AuctionStatus";
import { AuctionType } from "../enums/AuctionType";
import { ProductGroup } from "./ProductGroup";
import { Media } from "./Media";

export interface MarketplaceAuction {
  id: string;
  user_id: string;
  group_id: string;
  inserted: string;
  status: AuctionStatus;
  type: AuctionType;
  title: string;
  description: string;
  label: string;
  delivery_method: AuctionDeliveryMethod;
  price_type: AuctionPriceType;
  unit: number;
  price: number;
  bidding: boolean;
  bidding_start_price: number;
  bidding_highest_price: number;
  seller_display_name: string;
  seller_email: string;
  seller_location: string;
  media_ids: string[];
  media: Media[];
  group: ProductGroup;
  liked: boolean;
}
