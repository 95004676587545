export interface QuizSessionAnswerRequest {
  shop_quiz_id?: string;
  answer_single?: AnswerSingle;
  answer_text?: AnswerText;
  answer_order?: AnswerOrder;
  play_again?: boolean /*  is expected only when user chose to restart the quiz */;
  quiz_theme_id?: string /* is expected only when selecting theme */;
  quiz_question_skip_id?: string /* is expected only when skipping answer for a question */;
}

export interface AnswerOrder {
  quiz_question_id: string;
  answer: string[];
}

export interface AnswerSingle {
  quiz_question_answer_id: string;
}

export interface AnswerText {
  quiz_question_id: string;
  answer: string;
}
