<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form" *ngIf="taxes.length > 0">
      <div class="row" *ngFor="let price of getPrices().controls; let i = index">
        <div class="col-12 mt-2">
          <form [formGroup]="$any(price)">
            <div class="row" [ngClass]="{ inactive: isPriceInactive(price) }">
              <div class="col-10 col-sm-11 order-2 order-md-1">
                <div class="row">
                  <div class="form-field-container col-12 col-sm-6 col-md-3" *ngFor="let key of priceOrder">
                    <div class="form-field-label" *ngIf="i < 1" [ngClass]="{ required: key !== 'name' }">
                      {{ 'form.field.' + key | translate }}

                      <ng-container *ngIf="key === 'available_from'">
                        <div
                          class="i-icon"
                          #tooltip="matTooltip"
                          [matTooltip]="'tooltip.product.price_' + key | translate"
                          [matTooltipPosition]="'above'"
                          (click)="tooltip.toggle()"
                        >
                          <span class="i">i</span>
                        </div>
                      </ng-container>
                    </div>

                    <mat-form-field class="form-field" [floatLabel]="'never'" *ngIf="key === 'name'">
                      <input matInput [formControlName]="key" [readonly]="isPriceInactive(price)" />
                      <mat-error *ngIf="price.get(key).invalid">
                        <ng-container *ngIf="price.get(key).errors.required">
                          {{ 'form.error.required' | translate }}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field
                      class="form-field form-field-currency"
                      [floatLabel]="'never'"
                      *ngIf="key === 'amount'"
                    >
                      <input
                        matInput
                        currencyMask
                        [formControlName]="key"
                        [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }"
                        [readonly]="isPriceInactive(price | formGroup)"
                      />
                      <span class="currency">€</span>
                      <mat-error *ngIf="price.get(key).invalid">
                        <ng-container *ngIf="price.get(key).errors.required">
                          {{ 'form.error.required' | translate }}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>

                    <ng-container *ngIf="key === 'tax_id'">
                      <lib-select
                        [options]="taxes"
                        [value]="price.get(key).value"
                        [errors]="price.get(key).errors"
                        (valueChange)="price.get(key).setValue($event)"
                      ></lib-select>
                    </ng-container>

                    <mat-form-field class="form-field" [floatLabel]="'never'" *ngIf="key === 'available_from'">
                      <input matInput [ngxMatDatetimePicker]="picker" [formControlName]="key" readonly />
                      <mat-datepicker-toggle
                        class="btn-datepicker"
                        matSuffix
                        [for]="$any(picker)"
                      ></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker #picker [showSpinners]="true"></ngx-mat-datetime-picker>
                      <mat-error *ngIf="price.get(key).invalid">
                        <ng-container *ngIf="price.get(key).errors.required">
                          {{ 'form.error.required' | translate }}
                        </ng-container>
                        <ng-container *ngIf="price.get(key).errors.price_date_after_product_start">
                          Price start date is set after product start date
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-2 col-sm-1 col-option-remove text-right order-1 order-md-2">
                <button
                  class="btn btn-remove mt-2"
                  [ngClass]="{ 'v-hidden': i === 0 || isPriceInactive(price) }"
                  (click)="removePrice(i)"
                >
                  <span class="icon-remove d-block" [inlineSVG]="'/icons/icon-close.svg'"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-sm-auto">
          <button class="btn btn-secondary d-block w-100" (click)="addPrice()">
            {{ 'product.add_price' | translate }}
          </button>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 col-sm-4 form-field-container">
          <div class="form-field-label">
            {{ 'form.field.regular_amount' | translate }}
            <div
              class="i-icon"
              #tooltip="matTooltip"
              [matTooltip]="'tooltip.product.price_regular_amount' | translate"
              [matTooltipPosition]="'above'"
              (click)="tooltip.toggle()"
            >
              <span class="i">i</span>
            </div>
          </div>
          <mat-form-field class="form-field form-field-currency" [floatLabel]="'never'">
            <span class="currency">€</span>
            <input
              matInput
              currencyMask
              [formControlName]="'regular_amount'"
              [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }"
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
