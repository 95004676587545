import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { fileTypes } from '@digilize/shared/definitions/src/lib/interfaces/fileTypes';
@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() title = '';
  @Input() subTitle = '';
  @Input() fileTypes: fileTypes[];
  @Output() fileUploaded = new EventEmitter();
  @ContentChild('buttons') buttons!: TemplateRef<any>;
  @ViewChild('fileDropRef') fileDropRef: ElementRef<HTMLInputElement>;

  isDragOver = false;

  constructor() {}

  ngOnInit(): void {}

  handleFileUpload(event: Event) {
    const fileList: FileList = this.fileDropRef.nativeElement.files;
    this.fileUploaded.emit(fileList);
  }

  handleFileDrop(fileList: FileList | any) {
    this.fileUploaded.emit(fileList);
  }
}
