<div class="dialog-panel dialog-post">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>

    <div class="dialog-header">
      <h1 class="dialog-title dot">{{ 'planning.plan_post' | translate }}</h1>
    </div>

    <form [formGroup]="formDraftPost" class="form-draft-post" (submit)="onSubmit()" *ngIf="formDraftPost">
      <div class="row">
        <div class="col-12 form-field-container" [class.chip-list-empty]="!selectedShops.length">
          <div class="form-field-label">
            {{ 'form.field.upload_media' | translate }}
          </div>
          <div class="d-flex">
            <div class="w-100">
              <lib-drag-drop-photos
                [maxImgRes]="4000"
                [maxFileSize]="20"
                [items]="getFormControl('media').value ? getFormControl('media').value : []"
                (filesChange)="updateFormWithImages($event)"
              >
              </lib-drag-drop-photos>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-field-container chip-list-form-field" [class.chip-list-empty]="!selectedShops.length">
          <div class="form-field-label required">
            {{ 'form.field.choose_shop' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'" *ngIf="shops.length">
            <mat-select
              (selectionChange)="addShop($event.value)"
              [value]="selectedShops[0]"
              panelClass="draft-shop-shop-select"
            >
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip
                    *ngFor="let shop of selectedShops"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeShop(shop)"
                  >
                    <span>{{ shop.name }}</span>
                    <span matChipRemove class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option *ngFor="let shop of shops" [value]="shop">
                <span>{{ shop.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="selectedShops && !selectedShops.length">
            <ng-container>
              {{ 'form.error.required' | translate }}
            </ng-container>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-field-container">
          <div class="form-field-label required">
            {{ 'form.field.post_title' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput [formControlName]="'title'" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-field-container">
          <div class="form-field-label">
            {{ 'form.field.description' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <textarea
              matInput
              [formControlName]="'body'"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-field-container chip-list-form-field col-12" [class.chip-list-empty]="!getHashtags().length">
          <div class="form-field-label">
            {{ 'form.field.add_hashtags' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <mat-chip-list #hashtagList>
              <mat-chip
                *ngFor="let hashtag of getHashtags()"
                [selectable]="true"
                [removable]="true"
                (removed)="removeHashtag(hashtag)"
              >
                <span>{{ hashtag }}</span>
                <span matChipRemove class="icon-remove" [inlineSVG]="'/icons/icon-close.svg'"></span>
              </mat-chip>
              <input
                [matChipInputFor]="hashtagList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="false"
                (matChipInputTokenEnd)="addHashtag($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-field-container col-md-5">
          <div class="form-field-label">
            {{ 'form.field.start_date' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput [ngxMatDatetimePicker]="picker" [formControlName]="'planned'" [min]="minDate" />
            <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="true"> </ngx-mat-datetime-picker>
            <mat-error *ngIf="formDraftPost.get('planned').invalid">
              <ng-container *ngIf="formDraftPost.get('planned').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
            </mat-error>
            <div class="mat-error" *ngIf="formDraftPost.get('planned').errors?.matDatetimePickerMin ? true : false">
              {{ 'form.error.min_date_24_from_now' | translate }}
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-field-container">
          <div class="form-field-label required">
            {{ 'form.field.select_platform' | translate }}
          </div>
          <div class="socials-container row">
            <div class="col-12 social-platform justify-content-between" *ngFor="let platform of platforms">
              <div class="social-info">
                <img class="social-img" [lazyLoad]="'/assets/social/' + platform.key.toLowerCase() + '.png'" />
                <span class="social-name">{{ platform.key }}</span>
              </div>
              <mat-slide-toggle
                (change)="updatePlatforms(platform.key, $event.checked)"
                [checked]="getSocialPlatforms().indexOf(platform.key) > -1"
              ></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-bottom">
        <div class="row">
          <div class="ml-auto col-12 col-md-4">
            <button
              type="submit"
              class="btn btn-primary d-block w-100"
              [disabled]="formDraftPost.invalid || !selectedShops.length"
            >
              <ng-container *ngIf="!draftPostId; else update">
                {{ 'global.confirm' | translate }}
              </ng-container>
              <ng-template #update>
                {{ 'global.update' | translate }}
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
