import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getYtVideoId } from '@digilize/shared/utils/helpers';

@Component({
  selector: 'lib-dialog-video',
  templateUrl: './dialog-video.component.html',
  styleUrls: ['../dialog-small.component.scss', '../dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogVideoComponent implements OnInit {
  videoUrl;

  constructor(public dialogRef: MatDialogRef<DialogVideoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data.video) {
      this.videoUrl = this.getVideoUrl(this.data.video);
    }
  }

  getVideoUrl(video: string) {
    const videoId = getYtVideoId(video);
    return `https://www.youtube-nocookie.com/embed/${videoId}?playsinline=1&autoplay=1`;
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }
}
