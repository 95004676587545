import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthApiService } from '@digilize/shared/data/services';
import { EnvConfig, ErrorKind, SnackbarType } from '@digilize/shared/definitions/src';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { getCaptchaHeaders } from '@digilize/shared/utils/helpers/src';
import { ENV_CONFIG } from '@digilize/shared/utils/tokens/src';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, switchMap } from 'rxjs';

@Component({
  selector: 'lib-dialog-pwd-reset',
  templateUrl: './dialog-pwd-reset.component.html',
  styleUrls: ['./dialog-pwd-reset.component.scss'],
})
export class DialogPwdForgotComponent implements OnInit {
  @Output() toSignInClicked = new EventEmitter();
  takeUntil$ = new Subject();

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  loading = false;
  resetSuccess;

  constructor(
    private alertService: AlertService,
    private authApiService: AuthApiService,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(ENV_CONFIG) private envConfig: EnvConfig,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {}

  getFormControl(name: string) {
    return this.form.get(name);
  }

  onSubmit() {
    this.loading = true;
    this.recaptchaV3Service
      .execute('passwordReset')
      .pipe(
        switchMap((captchaToken) => {
          return this.authApiService.passwordForgot(
            this.form.get('email').value,
            getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey)
          );
        })
      )
      .subscribe(
        (response) => {
          this.resetSuccess = true;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          if (error.error.error.type === ErrorKind.CAPTCHA_INVALID) {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 15000,
              data: { msg: 'alert.custom.seems_you_are_bot', translate: true },
            });
          }
          if (error.error.error.type === ErrorKind.NOT_FOUND) {
            this.getFormControl('email').setErrors({ no_user: true });
          } else {
            this.alertService.handleApiError(error.error.error);
          }
        }
      );
  }

  handleToSignIn($event) {
    $event.preventDefault();
    this.toSignInClicked.emit();
  }
}
