import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";

export const ALERT_POST_UPDATED: Alert = {
  type: AlertType.Success,
  title: {
      message: 'Success',
      translated: false
  },
  message: {
      message: 'custom.post_updated',
      translated: true
  },
  time: 2000
};
