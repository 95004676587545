import { Component, ContentChild, EventEmitter, forwardRef, Input, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => AmountComponent) }],
})
export class AmountComponent implements ControlValueAccessor {
  @Input() decrementLimit: false | number = false;
  @Input() value = 0;
  @Input() externalValue = false;
  @Input() maxAmount: number = null;
  @Output() increment = new EventEmitter();
  @Output() decrement = new EventEmitter();
  @ContentChild('value', { read: TemplateRef })
  valueTemplate?: TemplateRef<any>;
  touched = false;
  disabled = false;
  onChange = (value) => {};
  onTouched = () => {};

  constructor() {}

  onValueChange(event): void {
    this.value = event.target.value;
    this.markAsTouched();
    this.onChange(this.value);
  }

  handleIncrement(): void {
    if (!this.disabled) {
      if (!this.externalValue) {
        this.value++;
      }
      this.markAsTouched();
      this.onChange(this.value);
      this.increment.emit();
    }
  }

  handleDecrement(): void {
    if (!this.disabled) {
      if (!this.externalValue) {
        this.value--;
      }
      this.markAsTouched();
      this.onChange(this.value);
      this.decrement.emit();
    }
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // ControlValueAccessor interface
  writeValue(value: number): void {
    this.value = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
