import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../../services/event-emitter.service';

@Component({
  selector: 'bwc-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  popupClass: string;

  constructor(private _eventEmitter: EventEmitterService) {}

  ngOnInit(): void {
    this.setPopupClass();
  }

  setPopupClass() {
    this._eventEmitter.dataClass.subscribe((data) => {
      this.popupClass = data;
    });
  }
}
