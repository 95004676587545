import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopApiService } from '@digilize/shared/data/services/src';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, startWith } from 'rxjs/operators';
import { ApiDataResponse, ExternalFeedProduct } from '@digilize/shared/definitions/src';

@Component({
  selector: 'lib-dialog-select-feed-products',
  templateUrl: './dialog-select-feed-products.component.html',
  styleUrls: ['./dialog-select-feed-products.component.scss'],
})
export class DialogSelectFeedProductsComponent implements OnInit {
  loading: boolean;
  companyId: string | undefined;
  selectedShopId: string;
  productsSearchValue$ = new BehaviorSubject<string>('');
  selectedShopId$ = new Subject<string>();
  options = [];
  searchValue = '';
  productsList: ExternalFeedProduct[] = [];
  productsListTotal: number;
  productsListStart = 0;
  numberOfItemsToLoad = 4;

  constructor(
    public dialogRef: MatDialogRef<DialogSelectFeedProductsComponent>,
    private shopApiService: ShopApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getShops();

    combineLatest([this.selectedShopId$, this.productsSearchValueChange$.pipe(startWith(''))]).subscribe(
      ([shopId, searchValue]) => {
        this.selectedShopId = shopId;
        this.searchValue = searchValue;
        this.getFeedProducts();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onScroll(event) {
    const el = event?.target;
    if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
      if (!this.loading) {
        if (this.canLoadMoreFeed) {
          this.loadMoreProducts();
        }
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSearchChange(event) {
    this.productsSearchValue$.next(event.target.value);
  }

  onShopChange(event) {
    this.productsList = [];
    this.productsSearchValue$.next('');
    this.selectedShopId$.next(event);
  }

  getShops() {
    this.shopApiService.getShops({ company: this.data.companyId }).subscribe(
      (response: any) => {
        if (response?.data?.length) {
          this.options = response?.data?.map((shop) => ({ key: shop.name, value: shop.id }));
          this.selectedShopId$.next(response?.data[0]?.id);
          this.selectedShopId = response?.data[0]?.id;
          this.getFeedProducts();
        }
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  getFeedProducts() {
    const queryParams = {
      term: this.searchValue,
      shop: this.selectedShopId,
      start: this.productsListStart,
      length: this.numberOfItemsToLoad,
    };

    this.shopApiService.getFeedProducts(queryParams).subscribe(
      (response: ApiDataResponse<ExternalFeedProduct[]>) => {
        this.productsListTotal = response.recordsTotal;
        this.productsList.push(...response?.data?.map((product) => ({ ...product, isChecked: false })));
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  get selectedProductIds(): string[] {
    return this.productsList.filter((product) => product.isChecked).map((product) => product.id) || [];
  }

  createProducts() {
    const body = {
      shop_id: this.selectedShopId,
      entry_ids: this.selectedProductIds,
    };

    this.importProducts(body);
  }

  createProductsAsDraft() {
    const body = {
      shop_id: this.selectedShopId,
      entry_ids: this.selectedProductIds,
      as_draft: true,
    };

    this.importProducts(body);
  }

  importProducts(body) {
    this.loading = true;
    // this.snetchApiService.importFeedProducts(body).subscribe(
    //   () => {
    //     this.loading = false;
    //     this.alertService.setAlert(ALERT_SUCCESS);
    //     this.closeDialog();
    //   }, error => {
    //     this.alertService.handleApiError(error.error.error);
    //     this.loading = false;
    //   }
    // );
  }

  toggleProductChoice(checked: boolean, product: ExternalFeedProduct) {
    product.isChecked = checked;
  }

  loadMoreProducts() {
    this.loading = true;
    this.productsListStart = this.productsListStart + this.numberOfItemsToLoad;
    this.getFeedProducts();
  }

  get canLoadMoreFeed() {
    return this.productsList && this.productsList.length > 0 && this.productsList.length < this.productsListTotal;
  }

  get productsSearchValueChange$(): Observable<string> {
    return this.productsSearchValue$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.productsList = [];
        this.productsListStart = 0;
      })
    );
  }
}
