<div class="card card-broadcast" *ngIf="broadcast">
  <div class="row">
    <div class="col-12 col-sm-5 col-md-12 col-xl-auto mb-3 mb-sm-0 mb-md-3 mb-xl-0">
      <div
        class="card-background"
        [ngStyle]="{ 'background-image': 'url(' + broadcast.photo?.url1x + '?w=300&h=300&m=2' + addWebp + ')' }"
      >
        <div class="card-broadcast-label" *ngIf="broadcast.label">
          {{ broadcast.label }}
        </div>
      </div>
    </div>
    <div class="col-12 col-sm">
      <div class="card-broadcast-body d-flex flex-column">
        <div class="row m-0 flex-nowrap w-100">
          <div class="col p-0 align-self-center">
            <div class="card-title dot">
              <span>{{ broadcast.title }}</span>
            </div>
          </div>
          <div class="col-auto p-0">
            <div class="broadcast-type-icon ml-1">
              <img class="icon" [src]="'/assets/icons/' + getBroadcastIcon(broadcast) + '.svg'" />
            </div>
          </div>
        </div>
        <div class="row mb-auto">
          <div class="col-12">
            <div class="card-sub-title" *ngIf="broadcast.subtitle">
              {{ broadcast.subtitle }}
            </div>
            <div class="card-description mt-0" *ngIf="description">
              <div [innerHTML]="description"></div>
            </div>
          </div>
        </div>
        <div class="mt-auto w-100 text-right">
          <a class="btn btn-secondary" [routerLink]="['/actueel', broadcast.permalink]">
            <ng-container *ngIf="broadcast.type === 3; else play">
              {{ 'global.read_more' | translate }}
            </ng-container>
            <ng-template #play>
              <img class="icon-play" [src]="'/assets/icons/icon-play.svg'" />
              {{ 'global.play' | translate }}
            </ng-template>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
