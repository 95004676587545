import { ISize } from '@shared/definitions';
import { fromEvent } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const getImgSize = (imageSrc: string) => {
  const mapLoadedImage = (event): ISize => {
    return {
      width: event.target.width,
      height: event.target.height,
    };
  };
  const image = new Image();
  const $loadedImg = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
  image.src = imageSrc;
  return $loadedImg;
};
