<lib-dialog-layout-inner-x
  *ngIf="!pwdChanged; else info"
  [btnText]="'auth.reset_password' | translate"
  (confirm)="resetPassword()"
  [title]="'auth.reset_password' | translate"
  [disableSubmit]="form?.invalid"
>
  <ng-template #content>
    <lib-reset-password-form (formInstance)="form = $event"></lib-reset-password-form>
  </ng-template>
</lib-dialog-layout-inner-x>

<ng-template #info>
  <lib-dialog-layout-inner-x
    [btnText]="'global.signin' | translate"
    [title]="'auth.reset_password' | translate"
    (confirm)="close()"
    [disableSubmit]="form?.invalid"
  >
    <ng-template #content>
      <p>{{ 'auth.password_changed' | translate }}</p>
    </ng-template>
  </lib-dialog-layout-inner-x>
</ng-template>
