<lib-dialog-layout-inner-x
  minHeight="100px"
  (close)="closeDialog()"
  [title]="data.title"
  [closeIconPath]="data.closeIconPath"
>
  <ng-template #content>
    <ng-container *ngIf="data?.descriptionHtml">
      <span class="description" [innerHTML]="data.descriptionHtml"></span>
    </ng-container>
  </ng-template>
  <ng-template #actions>
    <div class="btn-container">
      <button class="btn btn-primary" (click)="handleConfirm()">{{ data.confirmBtn }}</button>
      <button *ngIf="!hidecancelBtn" class="btn btn-secondary" (click)="closeDialog()">
        {{ 'global.cancel' | translate }}
      </button>
    </div>
  </ng-template>
</lib-dialog-layout-inner-x>
