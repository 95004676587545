import { ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

export const createAndObserveIntersection = (element: ElementRef): Observable<boolean> => {
  return new Observable((observer) => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      observer.next(entries);
    });

    if (element?.nativeElement) {
      intersectionObserver.observe(element.nativeElement);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }).pipe(
    mergeMap((entries: any) => entries),
    map((entry: any) => entry.isIntersecting),
    distinctUntilChanged()
  );
};
