import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WIDTH_MD } from '@shared/definitions';

@Component({
  selector: 'lib-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() value;
  @Input() errors;
  @Input() translate: boolean;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  native: boolean;
  searchQuery = '';
  optionsFiltered = [];

  constructor() {}

  ngOnInit(): void {
    this.native = this.isNative();
    this.optionsFiltered = this.options;
  }

  onSearch(value: string) {
    this.optionsFiltered = this.options.filter((option) => option.key.toLowerCase().indexOf(value.toLowerCase()) > -1);
  }

  isNative(): boolean {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return windowWidth < WIDTH_MD;
  }

  onValueChange($event, native?) {
    this.valueChange.emit(native ? $event.target.value : $event.value);
    this.searchQuery = '';
    this.optionsFiltered = this.options;
  }
}
