import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorPlaceholder } from '@digilize/shared/definitions/src';

@Component({
  selector: 'lib-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('editorElement', { static: false }) editorElement;

  @Input() label: false | string = false;
  @Input() charMax = 1000;
  @Input() translation = true;
  @Input() ckconfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockquote'],
    link: {
      addTargetToExternalLinks: true,
    },
  };
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() placeholders: EditorPlaceholder[] = [];
  @Input() placeholdersNoBraces = false;

  public editor = ClassicEditor;
  charCounter;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.control.value) {
      this.validateDescription(this.htmlToString(this.control.value).length);
      this.cdr.detectChanges();
    }
  }

  onEditorChange($event) {
    this.charCounter = this.htmlToString($event.editor.getData()).length;
    this.validateDescription(this.charCounter);
  }

  addPlaceholder(placeholder) {
    const selection = this.editorElement.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    placeholder = placeholder.replace(/\{|\}/g, ''); /* from backend values already have {} */
    this.editorElement.editorInstance.model.change((writer) => {
      this.placeholdersNoBraces
        ? writer.insert(`${placeholder}`, range.start)
        : writer.insert(`{${placeholder}}`, range.start);
    });
  }

  setCounter() {
    this.charCounter = this.htmlToString(this.control.value).length;
  }

  validateDescription(charCounter: number) {
    const invalid = charCounter > this.charMax;
    this.control.setErrors({ charMax: invalid });
    if (!invalid) {
      this.control.updateValueAndValidity();
    }
  }

  htmlToString(html: string): string {
    let text = html.replace(/<[^>]*>/g, '');
    text = text.replace('&nbsp', '');
    return text;
  }
}
