import { UntypedFormControl } from '@angular/forms';

export function dateAfterOtherValidator(otherControlName: string) {
  let thisControl: UntypedFormControl;
  let otherControl: UntypedFormControl;

  return function validateDates(control: UntypedFormControl) {
    if (!control.parent) {
      return null;
    }

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as UntypedFormControl;
      if (!otherControl) {
        throw new Error('dateAfterOtherValidator(): other control is not found in parent group');
      }
      // otherControl.valueChanges.subscribe(() => {
      //     thisControl.updateValueAndValidity();
      // });
    }

    if (!otherControl) {
      return null;
    }

    const otherAfterThis = new Date(otherControl.value)?.getTime() > new Date(thisControl.value)?.getTime();

    if (otherControl.value && thisControl.value && otherAfterThis) {
      return {
        datesRange: true,
      };
    }

    return null;
  };
}
