import { NgModule } from '@angular/core';

import { DidDatePassPipe } from './didDatePass.pipe';
import { FilterCallbackPipe } from './filterCallbackPure.pipe';
import { FormControlPipe } from './formControl.pipe';
import { FormGroupPipe } from './formGroup.pipe';
import { GetTranslationPipe } from './getTranslation.pipe';
import { FirstLetterLowercasePipe } from './firstLetterLowercase.pipe';

const PIPES = [
  FormControlPipe,
  FormGroupPipe,
  FilterCallbackPipe,
  GetTranslationPipe,
  DidDatePassPipe,
  FirstLetterLowercasePipe,
];

@NgModule({
  imports: [],
  declarations: [PIPES],
  exports: [PIPES],
})
export class SharedUtilsPipesModule {}
