<div class="panel panel-form" [ngClass]="{ open: open }">
  <lib-form-header [open]="open" [header]="header" [required]="required" (openChange)="open = $event"></lib-form-header>
  <div class="panel-content" *ngIf="open && form">
    <form [formGroup]="form">
      <div class="row">
        <div class="form-field-container col-12">
          <div
            class="form-field-label"
            [ngClass]="{
              required: isFormControlRequired(form.get('name'))
            }"
          >
            {{ 'form.field.name' | translate }}
          </div>
          <mat-form-field class="form-field" [floatLabel]="'never'">
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.get('name').invalid">
              <ng-container *ngIf="form.get('name').errors.required">
                {{ 'form.error.required' | translate }}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-field-container">
        <div
          class="form-field-label"
          [ngClass]="{
            required: isFormControlRequired(form.get('products'))
          }"
        >
          {{ 'form.field.products' | translate }}
        </div>

        <lib-multi-select
          noOptionPlaceholder="scan_app.no_product_available"
          [options]="productsOptions"
          [control]="form.get('products') | formControl"
        ></lib-multi-select>
      </div>
    </form>
  </div>
</div>
