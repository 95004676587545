import { Media } from './Media';
import { SubCategory } from './SubCategory';

export interface Category {
  id: string;
  name: string;
  description: string;
  photo_id: string;
  photo: Media;
  permalink: string;
  subcategories: SubCategory[];
  order: number;
  tenant_id: string;
}
