import { LanguageCode } from '../types';

export interface AppConfig {
  app: string;
  appTitle: string;
  email: string;
  gtm: string;
  logo: {
    light: string;
    dark: string;
    mark: string;
  };
  isCaptchaEnabled?: boolean;
  localStorageAuthPrefix?: string;
  localStorageAuthPrefix2?: string;
  language: LanguageConfig;
  disabledAuthPopup?: boolean;
  helpdeskMail?: string;
  disableChangePasswordPatternReq?: boolean;
}

interface LanguageConfig {
  defaultLanguage: LanguageCode;
  languages: { code: LanguageCode; iconFlagPath: string }[];
}
