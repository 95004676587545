<div class="dialog-panel">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>
    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="form" (submit)="onSubmit()">
      <div class="dialog-header">
        <h1 class="dialog-title dot">{{ 'company.create_company' | translate }}</h1>
      </div>
      <div class="dialog-body">
        <p class="info">
          {{ 'company.create_company_info' | translate }}
        </p>

        <div class="form-field-container" *ngFor="let key of order">
          <ng-container *ngIf="!(config.app === 'flixit' && key === 'photo_id')">
            <!-- Label -->
            <div class="form-field-label" [ngClass]="{ required: isFormControlRequired(getFormControl(key)) }">
              <ng-container *ngIf="key === 'photo_id'; else defaultLabel">
                {{ 'upload.upload_foto' | translate }}
              </ng-container>
              <ng-template #defaultLabel>
                {{ 'form.field.' + key | translate }}
              </ng-template>
            </div>
            <!-- Label -->

            <ng-container [ngSwitch]="key">
              <!-- Photo upload -->
              <div class="row mt-2" *ngSwitchCase="'photo_id'">
                <div class="col-12 col-sm-6 col-photo align-self-center">
                  <lib-photo-upload
                    [photo]="getFormControl('photo')?.value ? getFormControl('photo').value.url1x : null"
                    (fileChange)="fileChange($event)"
                  ></lib-photo-upload>
                </div>
              </div>
              <!-- Photo upload -->

              <!-- Country select -->
              <lib-select
                *ngSwitchCase="'country'"
                [options]="countries"
                [value]="getFormControl(key).value"
                [errors]="getFormControl(key).errors"
                (valueChange)="countryValueChange($event)"
              ></lib-select>
              <!-- Country select -->

              <!-- Input for all other fields -->
              <mat-form-field *ngSwitchDefault class="form-field" [floatLabel]="'never'">
                <input matInput [formControlName]="key" [required]="isFormControlRequired(getFormControl(key))" />
                <mat-error *ngIf="getFormControl(key).invalid">
                  <ng-container *ngIf="getFormControl(key).errors.required">
                    {{ 'form.error.required' | translate }}
                  </ng-container>
                </mat-error>
              </mat-form-field>
              <!-- Input for all other fields -->
            </ng-container>
          </ng-container>
        </div>
        <p *ngIf="config.app === 'films-nl'" class="mb-0">
          {{ 'company.by_signing_up' | translate }}
          <span class="text-red text-bold cta-btn"> {{ 'company.terms_for_business' | translate }} </span
          >{{ 'company.and' | translate }}
          <span class="text-red text-bold cta-btn"> {{ 'company.privacy' | translate }}</span>
        </p>
      </div>
      <div class="dialog-bottom create-company">
        <button type="submit" class="btn btn-primary d-block w-100" [disabled]="form.invalid">
          {{ 'global.continue' | translate }}
        </button>
        <button *ngIf="showGobackBtn" (click)="goToLoginPage()" type="text" class="btn btn-primary d-block w-100">
          {{ 'global.go_back_to_login' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
