import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'bwh-lib-offers-main-page',
  templateUrl: './offers-main-page.component.html',
  styleUrls: ['./offers-main-page.component.scss'],
})
export class OffersMainPageComponent implements OnChanges {
  form: UntypedFormGroup;
  @Input() companyId;
  @Input() vacancyDetailsLoaded;
  @Input() loadOffers;
  @Input() companyBaner;
  @Input() logoImg;
  @Input() loadingData;
  @Input() companyName;
  @Input() companyDesc;
  @Input() offersList;
  @Input() ifOpenApplicationEnabled = false;
  @Input() openApplicationEnabled;
  @Input() openApplicationId = '';
  @Input() openApplicationTitle = '';
  @Input() openApplicationTxt = '';
  @Input() openApplicationBtnBlock = false;
  @Output() redirectTriggered = new EventEmitter();
  @Output() redirectToLogin = new EventEmitter();

  constructor(private gtag: Gtag) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('openApplicationEnabled' in changes) {
      if (this.openApplicationEnabled === 'true') {
        this.ifOpenApplicationEnabled = true;
      }
    }
  }

  checkForm() {
    console.log('form', this.form.value);
  }

  openApplicationApply() {
    if (this.openApplicationId !== '' && !this.openApplicationBtnBlock) {
      const url = window.location.origin + '/login?ofl=' + this.openApplicationId;
      window.open(url, '_blank');
    }
  }

  redirectToVacancySummary(vacancyId, jobTitle) {
    this.gtag.event('button_clicked', {
      app_name: 'Skilld',
      event_category: 'User Actions',
      event_label: `Offers list - User clicked on vacancy named - ${jobTitle}`,
    });

    this.redirectTriggered.emit(vacancyId);
  }

  navigateToLogin() {
    this.redirectToLogin.emit();
  }
}
