export interface NotesData {
  draw: number;
  records_total: number;
  records_filtered: number;
  data: Notes[];
}

export interface Notes {
  id: string;
  text: string;
  status: string;
  type: number;
  tags: string[];
  creator: string;
  creator_name: string;
  creator_last_name: string;
  inserted: string;
  updated?: string;
  user_id: string;
  company_id: string;
}
