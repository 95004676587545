<div class="select-categories" *ngIf="categories.length > 0 && activeCategory">
  <div class="row">
    <div class="col-4 text-center" *ngFor="let category of categories">
      <div
        class="menu-item"
        [ngClass]="[activeCategory.id === category.id ? 'active' : '']"
        (click)="activeCategory = category; this.updateSelectedCounter()"
      >
        <div class="name-container">
          <span class="name">{{ category.name }}</span>
          <div class="badge-counter-container">
            <div class="badge-counter">
              <span class="counter">
                {{ getCategoryCounter(category) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="categories-container">
    <ng-scrollbar track="vertical" appearance="compact" visibility="hover">
      <div class="row categories-info">
        <div class="col-6 selected-counter">
          {{ 'global.selected' | translate }}:
          <span class="number">
            {{ selectedCounter }}
          </span>
        </div>
        <div class="col-6 text-right">
          <button type="button" class="btn btn-select-all" (click)="selectAllCategories()" *ngIf="!unselectActive">
            {{ 'global.select_all' | translate }}
          </button>
          <button type="button" class="btn btn-select-all" (click)="unselectAllCategories()" *ngIf="unselectActive">
            {{ 'global.unselect_all' | translate }}
          </button>
        </div>
      </div>
      <ul class="categories" *ngIf="activeCategory">
        <li
          *ngFor="let subCategory of activeCategory.subcategories"
          [ngClass]="{ active: isCategorySelected(subCategory.id) }"
          (click)="toggleCategory(subCategory.id)"
        >
          {{ subCategory.name }}
        </li>
      </ul>
    </ng-scrollbar>
  </div>
</div>
