import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { InfoSnackbarComponent } from '../info-snackbar/info-snackbar.component';
import { SnackbarData } from '../snackbar.service';

@Component({
  selector: 'lib-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss', '../snackbar.scss'],
})
export class ErrorSnackbarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private snackBarRef: MatSnackBarRef<InfoSnackbarComponent>
  ) {}

  ngOnInit(): void {}

  close() {
    this.snackBarRef.dismiss();
  }
}
