import { AlertType } from "../../enums/AlertType";
import { Alert } from "../../interfaces/Alert";
export const ALERT_HIGHLIGHTED_WARNING: Alert = {
  type: AlertType.Warning,
  title: {
    message: 'Warning',
    translated: false,
  },
  message: {
    message: 'custom.highlighted_warning',
    translated: true,
  },
};
