<div class="summary-item" *ngIf="product">
  <div class="row">
    <!-- ITEM PHOTO -->
    <div class="col-auto">
      <div class="item-photo" [lazyLoad]="product.photo?.url1x + '?w=120&h=120&m=2' + addWebp"></div>
    </div>
    <!-- ITEM PHOTO -->

    <!-- ITEM TITLE -->
    <div class="col md-hide lg-hide">
      <div class="row h-100">
        <div class="col-12">
          <div class="d-flex align-items-start flex-column h-100">
            <div class="w-100">
              <ng-container *ngTemplateOutlet="productTitle"></ng-container>
            </div>
            <div class="item-variants w-100 mt-auto" *ngIf="product.variant?.descriptors.length > 0">
              <ng-container *ngTemplateOutlet="productVariants"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ITEM TITLE -->

    <div class="col-12 col-md">
      <div class="row h-100">
        <!-- ITEM TITLE VARIANTS -->
        <div class="col-12 col-lg-8 xs-hide sm-hide">
          <div class="d-flex align-items-start flex-column h-100">
            <div class="w-100">
              <ng-container *ngTemplateOutlet="productTitle"></ng-container>
            </div>
            <div class="item-variants w-100 mt-auto" *ngIf="product.variant?.descriptors.length > 0">
              <ng-container *ngTemplateOutlet="productVariants"></ng-container>
            </div>
          </div>
        </div>
        <!-- ITEM TITLE VARIANTS -->

        <!-- ITEM QUANTITY PRICE -->
        <div class="col-12 col-lg-4 align-self-center mt-md-3 mt-lg-0">
          <div class="row">
            <div class="line"></div>
            <div class="col-6 align-self-center text-left text-lg-center">
              <div class="counter">
                <span class="counter-number">{{ product.quantity }}</span>
              </div>
            </div>
            <div class="col-6 price align-self-center text-right">
              {{ product.total | currency: 'EUR' }}
            </div>
          </div>
        </div>
        <!-- ITEM QUANTITY PRICE  -->
      </div>
    </div>
  </div>
</div>

<ng-template #productVariants>
  <ul class="variants-list">
    <li *ngFor="let descriptor of product.variant.descriptors">{{ descriptor.value }}</li>
  </ul>
</ng-template>

<ng-template #productTitle>
  <div class="item-title dot">{{ product.title }}</div>
  <div class="item-sub-title" *ngIf="product.subtitle">
    {{ product.subtitle }}
  </div>
</ng-template>
