<div class="education-stage-container">
  <form [formGroup]="form">
    <div *ngIf="dataLoaded" class="container">
      <div
        cdkDropList
        [cdkDropListData]="formSections.controls"
        class="selected-jobs scrollbar"
        formArrayName="formSections"
        (cdkDropListDropped)="changePositionInJobsList($event)"
      >
        <div
          cdkDrag
          [cdkDragData]="section"
          *ngFor="let section of formSections.controls; let i = index"
          class="education-step"
          [ngClass]="{ 'item-clickable': itemClickable }"
          (click)="editJob(i)"
        >
          <ng-container [formGroupName]="i">
            <bwh-lib-selected-item
              cdkDragHandle
              [name]="section.get('education_name').value"
              [listIcon]="true"
              [twoIcons]="true"
              [openIcon]="true"
              [index]="i + 1"
              [expIndex]="exp_index + 1"
              [iconStatus]="validityChecking(i, section.status)"
              (actionTriggered)="removeItem(i)"
            >
            </bwh-lib-selected-item>
            <div class="edu-exp-container" [ngClass]="{ opened: i === exp_index }">
              <div
                class="drag-hide"
                [ngClass]="{ opened: exp_opened }"
                cdkDragPreview
                (click)="stopPropagation($event)"
              >
                <div class="row no-gutters">
                  <div class="col full">
                    <span>{{ 'global.study' | translate }}<sup>*</sup></span>
                    <lib-single-select-search
                      [options]="studyOptions[i] ?? []"
                      [control]="getFormControl(i, 'education_name')"
                      [placeholder]="'global.enter_study_name' | translate"
                      [myFloatLabel]="'never'"
                      [wideClass]="true"
                      [addDebounce]="300"
                      [waitForOptionsChange]="true"
                      [suggestionsOff]="true"
                      [forceControl]="true"
                      [iconPath]="'/icons/icon-arrow-b-'"
                      [ngClass]="{
                        'error-field': getFormControl(i, 'education_name').status === 'INVALID',
                        'warning-field':
                          getFormControl(i, 'education_id').value === null &&
                          getFormControl(i, 'education_name').value !== ''
                      }"
                      (changed)="handleFilteredOptionClick($event, i, 'education_name')"
                      (valueChanged)="handleValueChanged($event, i, [], 'education_name')"
                      (inputFocused)="handleInputFocused(i, studyOptions, 'education_name')"
                    >
                    </lib-single-select-search>
                    <div
                      *ngIf="
                        getFormControl(i, 'education_id').value === null &&
                        getFormControl(i, 'education_name').value !== ''
                      "
                      class="warning-container"
                    >
                      {{ 'global.studies_warning_text' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col full">
                    <span>{{ 'form.field.form_country' | translate }}</span>
                    <lib-single-select-search
                      [options]="countriesOptions"
                      [control]="getFormControl(i, 'country')"
                      [placeholder]="'form.field.enter_form_country_education' | translate"
                      [myFloatLabel]="'never'"
                      [addDebounce]="300"
                      [suggestionsOff]="true"
                      [optionByVal]="true"
                      [waitForOptionsChange]="true"
                      [iconPath]="'/icons/icon-arrow-b-'"
                      (changed)="handleFilteredOptionClick($event, i, 'country')"
                      (valueChanged)="handleValueChanged($event, i, countriesOptions, 'country')"
                    >
                    </lib-single-select-search>
                    <div class="error-message" *ngIf="getFormControl(i, 'country').invalid">
                      <span *ngFor="let error of errorService.mapErrors(getFormControl(i, 'country'))">
                        {{ 'global.' + error | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col full">
                    <span>{{ 'global.level' | translate }}<sup>*</sup></span>
                    <lib-single-select-search
                      [options]="levelOptions[i] ?? []"
                      [control]="getFormControl(i, 'education_level_name')"
                      [placeholder]="'global.enter_level' | translate"
                      [myFloatLabel]="'never'"
                      [waitForOptionsChange]="true"
                      [suggestionsOff]="true"
                      [forceControl]="true"
                      [forceControlByOptions]="true"
                      [disableSearchOptionFiltering]="true"
                      [setControlValue]="true"
                      [iconPath]="'/icons/icon-arrow-b-'"
                      [ngClass]="{ 'error-field': getFormControl(i, 'education_level_name').status === 'INVALID' }"
                      (changed)="handleFilteredOptionClick($event, i, ['education_level_name', 'education_level_id'])"
                      (valueChanged)="
                        handleValueChanged($event, i, levelOptions[i], ['education_level_name', 'education_level_id'])
                      "
                      (inputFocused)="handleInputFocused(i, levelOptions, 'education_level_name')"
                    >
                    </lib-single-select-search>
                    <div class="error-message" *ngIf="getFormControl(i, 'education_level_name').touched">
                      <span *ngFor="let error of errorService.mapErrors(getFormControl(i, 'education_level_name'))">
                        {{ 'global.' + error | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col full" [ngClass]="{ 'disabled-field': eduCatDisabled }">
                    <span>{{ 'global.field' | translate }}<sup>*</sup></span>
                    <lib-multi-select
                      [options]="educationCategoriesOptions ?? []"
                      [control]="getFormControl(i, 'education_categories')"
                      [optionObj]="true"
                      [openIconPath]="'/icons/icon-arrow-b-'"
                      [iconPath]="'/icons/close-white-icon.svg'"
                      [ngClass]="{ 'error-field': getFormControl(i, 'education_category_names').status === 'INVALID' }"
                      (changed)="handleMultiSelectClick($event, i)"
                    ></lib-multi-select>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col full">
                    <span>{{ 'global.school' | translate }}</span>
                    <lib-single-select-search
                      [options]="schoolsOptions[i] ?? []"
                      [control]="getFormControl(i, 'school_name')"
                      [placeholder]="'global.enter_school_name' | translate"
                      [myFloatLabel]="'never'"
                      [wideClass]="true"
                      [addDebounce]="300"
                      [waitForOptionsChange]="true"
                      [suggestionsOff]="true"
                      [forceControl]="true"
                      [iconPath]="'/icons/icon-arrow-b-'"
                      (changed)="handleFilteredOptionClick($event, i, ['school_name', 'school_id'])"
                      (valueChanged)="handleValueChanged($event, i, schoolsOptions, 'school_name')"
                      (inputFocused)="handleInputFocused(i, schoolsOptions, 'school_id')"
                    >
                    </lib-single-select-search>
                  </div>
                </div>

                <div class="row no-gutters">
                  <div
                    class="col half"
                    [ngClass]="{ 'error-field': getFormControl(i, 'start_date').status === 'INVALID' }"
                  >
                    <span>{{ 'global.start_date' | translate }}</span>
                    <mat-form-field [floatLabel]="'never'">
                      <input
                        matInput
                        readonly
                        [matDatepicker]="startDatePicker"
                        [placeholder]="'global.enter_start_date' | translate"
                        (click)="startDatePicker.open()"
                        [value]="getFormControl(i, 'start_date').value"
                      />
                      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker
                        #startDatePicker
                        startView="multi-year"
                        (monthSelected)="setMonthAndYear($event, i, 'start_date', startDatePicker)"
                      ></mat-datepicker>
                    </mat-form-field>
                    <div class="error-message" *ngIf="getFormControl(i, 'start_date').status === 'INVALID'">
                      <span *ngFor="let error of errorService.mapErrors(getFormControl(i, 'start_date'))">
                        {{ 'global.' + error | translate }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="col half"
                    [ngClass]="{
                      'disabled-field': getFormControl(i, 'is_current').value,
                      'error-field': getFormControl(i, 'end_date').status === 'INVALID'
                    }"
                  >
                    <span>{{ 'global.end_date' | translate }}</span>
                    <mat-form-field [floatLabel]="'never'">
                      <input
                        matInput
                        readonly
                        [matDatepicker]="endDatePicker"
                        [placeholder]="'global.enter_end_date' | translate"
                        (click)="endDatePicker.open()"
                        [value]="getFormControl(i, 'end_date').value"
                      />
                      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker
                        #endDatePicker
                        startView="multi-year"
                        (monthSelected)="setMonthAndYear($event, i, 'end_date', endDatePicker)"
                      ></mat-datepicker>
                    </mat-form-field>
                    <div class="error-message" *ngIf="getFormControl(i, 'end_date').status === 'INVALID'">
                      <span *ngFor="let error of errorService.mapErrors(getFormControl(i, 'end_date'))">
                        {{ 'global.' + error | translate }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row no-gutters">
                  <div class="col half checkbox-col">
                    <label>
                      <mat-checkbox
                        [formControl]="getFormControl(i, 'is_current')"
                        (change)="stillStudingChange($event.checked, i, 'end_date')"
                      >
                        {{ 'global.still_studing_here' | translate }}
                      </mat-checkbox>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <button *ngIf="dataLoaded" class="add-education" (click)="addEducation()" [disabled]="isAddEducationDisabled">
      {{ 'global.add_education' | translate }}
    </button>
  </form>
</div>
