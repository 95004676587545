<div class="top-menu" *ngIf="topMenu" [ngClass]="{ 'no-header': !companyBaner }">
  <div class="logo" *ngIf="logoImg">
    <div [style.background-image]="'url(' + logoImg + ')'" class="logo-img"></div>
  </div>
  <!-- <button class="log-in-btn" (click)="navigateToLogin()">{{ 'auth.login' | translate }}</button> -->
</div>
<div *ngIf="companyBaner" class="header-container"><img class="img-header" [src]="companyBaner + '?w=1920'" /></div>
<div [formGroup]="form" class="summary-container" [ngClass]="{ 'no-top-menu': !topMenu }">
  <div class="summary-main-block">
    <div class="top-info">{{ 'global.is_this_vacancy_match' | translate }}</div>

    <div class="left-cont">
      <div class="job-title">{{ form.get('job_title').value }}</div>

      <div class="job-description" [innerHTML]="jobDescription"></div>
    </div>
    <div class="right-cont">
      <div class="right-row edu-icon-row">
        <div class="education-data">
          <div class="education-row short-row" *ngIf="form.get('work_experience_level').value">
            <div class="education-cont work-experience">
              <span class="span-icon" [inlineSVG]="'/icons/work-exp-lvl-icon.svg'"></span>
              <div class="education-item">
                {{ 'global.' + workLevels[form.get('work_experience_level').value] | translate }}
              </div>
            </div>
          </div>
          <div class="education-row short-row" *ngIf="form.get('language_skills').value.length > 0">
            <div class="education-cont languages">
              <span class="span-icon" [inlineSVG]="'/icons/language-lvl-icon.svg'"></span>
              <div class="education-item" *ngFor="let item of form.get('language_skills').value; let i = index">
                {{ item.skill_name }}{{ i !== form.get('language_skills').value.length - 1 ? ',' : '' }}
              </div>
            </div>
          </div>
          <div class="education-row" *ngIf="form.get('education_levels').value.length > 0">
            <div class="education-cont education-levels">
              <span class="span-icon" [inlineSVG]="'/icons/education-lvl-icon.svg'"></span>
              <div class="education-item" *ngFor="let item of form.get('education_levels').value; let i = index">
                {{ item.name }}{{ i !== form.get('education_levels').value.length - 1 ? ',' : '' }}
              </div>
            </div>
          </div>
          <div class="education-row" *ngIf="form.get('location').value !== undefined">
            <div class="education-cont location">
              <span class="span-icon" [inlineSVG]="'/icons/location-icon.svg'"></span>
              <div class="education-item">
                {{ form.get('location').value }}
              </div>
            </div>
          </div>
          <div class="education-row" *ngIf="form.get('employment_type').value !== undefined">
            <div class="education-cont employment">
              <span class="span-icon" [inlineSVG]="'/icons/type-of-contract-icon.svg'"></span>
              <div class="education-item">
                {{ 'global.' + employmentType[form.get('employment_type').value] | translate }} ({{
                  'global.' + numberOfCandidates[form.get('number_of_candidates').value] | translate
                }})
              </div>
            </div>
          </div>
          <div
            class="education-row"
            *ngIf="
              form.get('salary_type').value !== undefined &&
              ((form.get('salary_currency').value !== null && form.get('salary_per_hour').value !== null) ||
                (form.get('salary_range_max').value !== null && form.get('salary_range_min').value !== null))
            "
          >
            <div class="education-cont salary">
              <span class="span-icon" [inlineSVG]="'/icons/salary-icon.svg'"></span>
              <div class="education-item">
                <ng-container *ngIf="form.get('salary_type').value === 0">
                  {{ getCurrencySymbol(form.get('salary_currency').value) }}{{ form.get('salary_per_hour').value }}
                  {{ 'global.curr_type_' + getCurrencyType(form.get('salary_type').value) | translate }}
                </ng-container>
                <ng-container *ngIf="form.get('salary_type').value === 1">
                  {{ getCurrencySymbol(form.get('salary_currency').value) }}{{ form.get('salary_range_min').value }} -
                  {{ getCurrencySymbol(form.get('salary_currency').value) }}{{ form.get('salary_range_max').value }}
                </ng-container>
              </div>
            </div>
          </div>
          <div class="education-row" *ngIf="form.get('hours_range').value">
            <div class="education-cont hours-and-shifts">
              <span class="span-icon" [inlineSVG]="'/icons/hours-icon.svg'"></span>
              <div class="education-item">
                {{ 'global.' + hoursRange[form.get('hours_range').value] | translate }}
                {{ 'global.hours_per_week' | translate
                }}<ng-container *ngFor="let item of form.get('shifts').value; let i = index"
                  >{{ i === 0 ? ',' : '' }}
                  {{ 'global.' + shiftsOptions[item] | translate }}
                  {{ i !== form.get('shifts').value.length - 1 ? ',' : '' }}
                </ng-container>
              </div>
            </div>
          </div>
          <div class="education-row" *ngIf="form.get('remote_work').value !== undefined">
            <div class="education-cont employment">
              <span class="span-icon" [inlineSVG]="'/icons/type-of-employment-icon.svg'"></span>
              <div class="education-item">
                {{ 'global.' + workHome[form.get('remote_work').value] | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="logo-container">
          <div *ngIf="logoImg" [style.background-image]="'url(' + logoImg + ')'" class="logo-img"></div>
        </div>
      </div>

      <div class="right-row bonuses-row" *ngIf="form.get('benefits').value.length > 0">
        <div class="bonuses-title">
          <span class="span-icon" [inlineSVG]="'/icons/bonus-icon.svg'"></span>
          {{ 'global.bonuses' | translate }}:
        </div>
        <div class="bonuses-container">
          <div class="bonus-item skill-name" *ngFor="let bonus of form.get('benefits').value">{{ bonus }}</div>
        </div>
      </div>
      <!-- <button (click)="checkFrom()">check form</button> -->
      <button type="text" class="btn w-lg h-sm secondary btn-apply btn-first" (click)="buttonClick()">
        {{ 'global.' + buttonTxt | translate }}
      </button>
    </div>
  </div>

  <div class="description-block">
    <div class="backquotes-block">
      <div *ngFor="let item of blockDescriptions" class="backquotes-cont">
        <div [innerHTML]="item"></div>
      </div>

      <div class="backquotes-cont">
        <div>
          <h4>{{ 'global.skills' | translate }}</h4>
        </div>
        <div *ngIf="form.get('soft_skills').value.length > 0" class="skills-cont">
          <div class="skills-title">{{ 'global.soft_skills' | translate }}:</div>
          <div class="skill-names-cont">
            <div *ngFor="let skill of form.get('soft_skills').value" class="skill-name">{{ skill.skill_name }}</div>
          </div>
        </div>
        <div *ngIf="form.get('professional_skills').value.length > 0" class="skills-cont">
          <div class="skills-title">{{ 'global.professional_skills' | translate }}:</div>
          <div class="skill-names-cont">
            <div *ngFor="let skill of form.get('professional_skills').value" class="skill-name">
              {{ skill.skill_name }}
            </div>
          </div>
        </div>
        <div *ngIf="form.get('it_skills').value.length > 0" class="skills-cont">
          <div class="skills-title">{{ 'global.it_skills' | translate }}:</div>
          <div class="skill-names-cont">
            <div *ngFor="let skill of form.get('it_skills').value" class="skill-name">{{ skill.skill_name }}</div>
          </div>
        </div>
      </div>

      <div class="backquotes-cont">
        <div>
          <h4>{{ 'global.requirements' | translate }}</h4>
          <div class="skill-names-cont">
            <div *ngFor="let skill of form.get('tasks_skills').value" class="skill-name">{{ skill.skill_name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-cont">
    <div class="posted-cont">{{ 'global.job_posted' | translate }}: {{ getDate(form.get('activated').value) }}</div>
    <button *ngIf="showBottomBtn" type="text" class="btn w-lg h-sm secondary btn-apply" (click)="buttonClick()">
      {{ 'global.' + buttonTxt | translate }}
    </button>
  </div>
</div>
