<div class="dialog-panel dialog-post">
  <div class="icon-close" [inlineSVG]="'/icons/icon-close.svg'" (click)="closeDialog()"></div>
  <div class="dialog-content">
    <div class="spinner-container p-absolute" *ngIf="loading">
      <mat-spinner class="primary" [diameter]="30"></mat-spinner>
    </div>

    <div class="dialog-header">
      <h1 class="dialog-title dot">{{ 'planning.planning_campaign' | translate }}</h1>
    </div>

    <form [ngClass]="{ 'v-hidden': loading }" [formGroup]="formCampaign" (submit)="onSubmit()">
      <div class="dialog-body">
        <div class="row" *ngIf="campaigns.length > 0">
          <div class="col-12 form-field-container">
            <div class="form-field-label">
              {{ 'planning.select_campaign' | translate }}
            </div>
            <lib-select
              [value]="formCampaign.get('campaign').value"
              [options]="campaigns"
              (valueChange)="setCampaign($event)"
            ></lib-select>
          </div>
        </div>

        <!-- SELECTED CAMPAIGN -->
        <div class="active-campaign-container" *ngIf="selectedCampaign">
          <div class="row flex-md-nowrap">
            <div class="col-4 col-sm-auto align-self-center" *ngIf="selectedCampaign.media?.length > 0">
              <div
                class="photo-thumbnail"
                [lazyLoad]="selectedCampaign.media[0].url1x + '?w=150&h=150&m=2' + addWebp"
              ></div>
            </div>
            <div class="col-8 col-sm align-self-center campaign-copy">
              {{ selectedCampaign.name }}
            </div>
            <div class="col-12 col-md-auto align-self-center" *ngIf="selectedCampaign.platforms">
              <div class="socials">
                <img
                  class="social-img"
                  *ngFor="let platform of selectedCampaign.platforms.split('|')"
                  [lazyLoad]="'/assets/social/' + platform.toLowerCase() + '.png'"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- SELECTED CAMPAIGN -->

        <!-- SELECTED CAMPAIGN DATE -->
        <div class="date-container" *ngIf="selectedCampaign">
          <div class="row">
            <div class="form-field-container col-12 col-md-5">
              <div class="form-field-label">
                {{ 'form.field.start_date' | translate }}
              </div>
              <mat-form-field class="form-field" [floatLabel]="'never'">
                <input matInput [ngxMatDatetimePicker]="picker" [formControlName]="'date'" />
                <mat-datepicker-toggle class="btn-datepicker" matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="true"> </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- SELECTED CAMPAIGN DATE -->
      </div>

      <div class="dialog-bottom" *ngIf="campaigns.length > 0">
        <div class="row">
          <div class="ml-auto col-4">
            <button type="submit" class="btn btn-primary d-block w-100" [disabled]="formCampaign.invalid">
              {{ 'global.select' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
