import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-voucher-preview',
  templateUrl: './dialog-voucher-preview.component.html',
  styleUrls: ['./dialog-voucher-preview.component.scss'],
})
export class DialogVoucherPreviewComponent implements OnInit {
  loading;

  constructor(
    public dialogRef: MatDialogRef<DialogVoucherPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.loading = true;
    const dstFrame = document.getElementById('preview-iframe') as any;
    const dstDoc = dstFrame.contentDocument || dstFrame.contentWindow.document;
    dstDoc.write(this.data.htmlPreview);
    this.loading = false;
  }
}
