export enum QuestionAnswerMode {
  Unknown = 0,
  SingleSheet = 1,
  Text = 2,
  Multiselect = 3,
  Number = 4,
  Date = 5,
  Time = 6,
  DateTime = 7
}
