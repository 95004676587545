import { Media } from './Media';

export interface QuizTheme {
  id: string;
  photo_id: string;
  photo: Media;
  name: string;
  admin_name: string;
  description: string;
  question_count: number;
  tenant_id: string;
}
