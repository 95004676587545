import { EventStatus } from "../interfaces/EventStatus";

export const EventStatusOptions = [
  {
    key: 'events.draft',
    value: EventStatus.Draft,
  },
  {
    key: 'events.published',
    value: EventStatus.Published,
  },
  {
    key: 'events.archived',
    value: EventStatus.Archived,
  },
];
