import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthApiService, CampaignApiService } from '@digilize/shared/data/services';
import { AlertService } from '@digilize/shared/feature/modules/alert/src';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { getCaptchaHeaders } from '@digilize/shared/utils/helpers/src';
import { APP_CONFIG, ENV_CONFIG } from '@digilize/shared/utils/tokens';
import { AppConfig, EnvConfig, ErrorKind, SnackbarType } from '@shared/definitions';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { Subject, switchMap } from 'rxjs';

import { AuthService } from '../auth.service';
import { AuthSharedDataService } from '@digilize/shared/utils/services/src';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-dialog-signin',
  templateUrl: './dialog-signin.component.html',
  styleUrls: ['./dialog-signin.component.scss'],
})
export class DialogSigninComponent implements OnInit, AfterViewInit {
  @Output() toSignUpClicked = new EventEmitter();
  @Output() toPwdResetClicked = new EventEmitter();
  @Output() submitted = new EventEmitter();
  @Input() afterLoginRoute: string;
  @Input() inviteInfo: any;
  @Input() withFacebook = true;
  takeUntil$ = new Subject();

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  hidePwd = true;
  loading = false;
  signUpCustomHtmlCode;
  clientsAuth;
  signUpCustomMethodToCall: (event?: any) => void = () => {};

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(ENV_CONFIG) private envConfig: EnvConfig,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private authApiService: AuthApiService,
    private campaignApiService: CampaignApiService,
    private cookieService: CookieService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private snackbarService: SnackbarService,
    private alertService: AlertService,
    private authSharedDataService: AuthSharedDataService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  ngOnInit(): void {
    this.form.controls.email.valueChanges.subscribe((email) => {
      this.form.patchValue({ email: email.trim() }, { emitEvent: false });
      this.form.get('password').setErrors(null);
    });

    this.checkForCustomSignUpBtn();
    this.checkForClientsAuth();
  }

  checkForCustomSignUpBtn() {
    this.authSharedDataService.blockHtmlCode$.subscribe((isBlocked) => {
      if (isBlocked) {
        return;
      }

      this.authSharedDataService.signUpCustomHtmlCode$.subscribe((htmlCode) => {
        if (htmlCode) {
          this.signUpCustomHtmlCode = this.sanitizer.bypassSecurityTrustHtml(htmlCode);
        }
      });

      this.authSharedDataService.signUpCustomMethodToCall$.subscribe((method) => {
        if (method) {
          this.signUpCustomMethodToCall = method;
        }
      });
    });
  }

  checkForClientsAuth() {
    this.authSharedDataService.clientsAuth$.subscribe((value) => {
      this.clientsAuth = value;
    });
  }

  ngAfterViewInit(): void {
    this.addCustomBtnsEventListeners();
  }

  private addCustomBtnsEventListeners(): void {
    const button = this.el.nativeElement.querySelector('.custom-signup-method-call');
    if (button) {
      this.renderer.listen(button, 'click', () => {
        this.signUpCustomMethodToCall();
      });
    }
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  login() {
    if (this.form.valid) {
      this.loading = true;
      const formData = this.form.getRawValue();

      this.recaptchaV3Service
        .execute('login')
        .pipe(
          switchMap((captchaToken) => {
            if (this.clientsAuth) {
              return this.authApiService.clientsLogin(
                formData,
                getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey)
              );
            } else {
              return this.authApiService.login(
                formData,
                getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey)
              );
            }
          })
        )
        .subscribe(
          (response: any) => {
            this.authService.setSession(response, () => {
              const acquisitionId = this.cookieService.get('acquisition_id');
              if (acquisitionId) {
                this.registerAcquisition(acquisitionId);
              } else {
                this.navigateOut();
              }
            });
          },
          (error) => {
            if (error.error.error.type === ErrorKind.CAPTCHA_INVALID) {
              this.snackbarService.openSnackbar(SnackbarType.Error, {
                duration: 15000,
                data: { msg: 'alert.custom.seems_you_are_bot', translate: true },
              });
            }
            if (error.status === 403) {
              this.form.get('password').setErrors({ incorrect_email_password: true });
            } else {
              this.snackbarService.openSnackbarWithApiError(error.error.error);
            }
            this.loading = false;
          }
        );
    }
  }

  onSubmit() {
    this.login();
  }

  navigateOut() {
    if (this.appConfig.app === 'flixit' && !window.location.pathname.includes('/admin')) {
      if (this.afterLoginRoute) {
        this.router.navigate((this.afterLoginRoute as string).split('/').slice(1));
      } else {
        window.location.href = '/admin/c/0/shops';
      }
    } else if (this.appConfig.app === 'be-work-happy-client' && !window.location.pathname.includes('/admin')) {
      if (this.afterLoginRoute) {
        const path = (this.afterLoginRoute as string).split('/').slice(1).join('/');
        this.document.body.classList.remove('body-page-auth');
        this.router.navigateByUrl(path);
      } else {
        window.location.href = '/profile-check';
      }
    } else if (this.afterLoginRoute && !this.afterLoginRoute.includes('/auth')) {
      const path = (this.afterLoginRoute as string).split('/').slice(1).join('/');
      this.document.body.classList.remove('body-page-auth');
      this.router.navigateByUrl(path);
    } else {
      this.router.navigate(['/']);
    }

    // ensures router.navigate finishes
    setTimeout(() => {
      this.submitted.emit();
    }, 0);
  }

  registerAcquisition(id) {
    this.loading = true;
    this.campaignApiService.registerAcquisition(id).subscribe(
      (response) => {
        this.cookieService.delete('acquisition_id');
        this.navigateOut();
      },
      (error) => {
        this.loading = false;
        if (error.status === 400) {
          this.cookieService.delete('acquisition_id');
        }

        if (error.error?.error?.info?.kind === 'already_exists') {
          this.navigateOut();
        } else {
          this.router.navigate(['niet-gevonden']);
        }
      }
    );
  }

  handlePwdResetClicked($event) {
    $event.preventDefault();
    this.toPwdResetClicked.emit();
  }

  handleSignUpClicked($event) {
    $event.preventDefault();
    this.toSignUpClicked.emit();
  }

  facebookRedirect() {
    this.authService.facebookRedirect();
  }
}
