
export interface DecisionTreeVersion {
	decision_tree_id: string;
	version_number: number;
	comment: string;
	is_valid: boolean;
	is_production_ready: boolean;
	created: string;
	updated: string;
	id: string;
}

