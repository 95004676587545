import { HSL } from '@digilize/shared/definitions/src';

export const getGeneratedHSLDifferentThanOthers = (differentThan: HSL[]): HSL => {
  let counter = 0;
  const MAX_ATTEMPTS = 50;
  let color: HSL;

  do {
    /* we avoid very bright and dark colors */
    const h = Math.random() * 360; /* 0-360 */
    const s = Math.random() * 80 + 20; /* 20-100 */
    const l = Math.random() * 60 + 20; /* 20- 80 */
    color = { h, s, l };
    counter++;
  } while (counter <= MAX_ATTEMPTS && !isColorDifferentEnough(color, differentThan));

  return color;
};

export const isColorDifferentEnough = (color: HSL, differentThan: HSL[]): boolean => {
  if (!color) return false;
  differentThan.every((uniqueColor) => {
    let hDiff = Math.abs(uniqueColor.h - color.h);
    let sDiff = Math.abs(uniqueColor.s - color.s);
    let lDiff = Math.abs(uniqueColor.l - color.l);
    return hDiff > 20 || sDiff > 12 || lDiff > 12;
  });
};
