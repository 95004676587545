import { Injectable } from '@angular/core';
import { ChartLabel, ItemGroup, ItemPage, SurveyPage, SurveyResearchPageIds } from '@digilize/shared/definitions/src';
import { findNestedObj } from '@digilize/shared/utils/helpers/src';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PagesApiService } from './pages-api.service';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  private _pages = new BehaviorSubject<SurveyPage[]>(null);
  pages$ = this._pages.asObservable().pipe(filter((pages) => !!pages));
  private _surveyResearchPageIds = new BehaviorSubject<SurveyResearchPageIds>(null);
  surveyResearchPageIds$ = this._surveyResearchPageIds.asObservable().pipe(filter((pages) => !!pages));
  constructor(private pagesApiService: PagesApiService) {
    this.pagesApiService.getByResearchV2().subscribe(({ data }) => {
      this._pages.next(data);
    });
  }

  getSurveyPage(pageId: string): Observable<SurveyPage> {
    return this.pages$.pipe(
      map((surveyPages) => {
        return surveyPages.find((page) => page.id === pageId);
      })
    );
  }

  getSurveyGroup(pageId: string, groupId: string): Observable<ItemGroup> {
    return this.getSurveyPage(pageId).pipe(
      map((surveyPage) => {
        return surveyPage.content.items.find((item) => item.id === groupId);
      })
    );
  }

  getSurveyGroupByResearchId(researchId: string): Observable<{ surveyPage: SurveyPage; itemGroup: ItemGroup }> {
    return this.pages$.pipe(
      map((surveyPages) => {
        const surveyPage = surveyPages.find((surveyPage) => {
          return surveyPage.researches.some((research) => research.research_id === researchId);
        });
        return { surveyPage, itemGroup: surveyPage.content.items[0] };
      })
    );
  }

  getSurveyLabels(pageId: string): Observable<ChartLabel[]> {
    return this.getSurveyPage(pageId).pipe(map((page) => page.labels));
  }

  getItemPage(pageId: string, groupId: string, itemPageId: string): Observable<ItemPage> {
    return this.getSurveyGroup(pageId, groupId).pipe(
      map((surveyGroup) => {
        return findNestedObj(surveyGroup.items, 'id', itemPageId);
      })
    );
  }

  setSurveyResearchPageIds(surveyResearchPageIds: SurveyResearchPageIds) {
    this._surveyResearchPageIds.next(surveyResearchPageIds);
  }
}
