import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isFormControlRequired } from '@digilize/shared/utils/helpers/src';
import { CompanyWithLocation, Location } from '@shared/definitions';

@Component({
  selector: 'lib-form-company-location',
  templateUrl: './form-company-location.component.html',
  styleUrls: ['./form-company-location.component.scss'],
})
export class FormCompanyLocationComponent implements OnInit {
  companiesKeyValue: { key: string; value: Location }[] = [];
  open = true;
  detailsVisible = false;
  locationFields: string[] = [];
  selectedCompanyLocation: Location | undefined = undefined;
  keyValueCountries: { key: string; value: string }[] = [{ key: 'Netherlands', value: 'NL' }];
  @Input() form: UntypedFormGroup;
  @Input() header: string;
  @Input() required: boolean;
  @Input() set companies(companies: CompanyWithLocation[]) {
    this.companiesKeyValue = companies ? this.getCompaniesKeysValues(companies) : [];
  }
  constructor() {}

  ngOnInit(): void {
    this.locationFields = Object.keys(this.form.controls);
    this.selectedCompanyLocation = this.getSelectedCompanyLocation();
    if (this.selectedCompanyLocation) {
      this.setLocation(this.selectedCompanyLocation);
    } else {
      this.detailsVisible = true;
    }
  }

  getSelectedCompanyLocation(): Location | undefined {
    if (!this.isEditing()) {
      return this.companiesKeyValue[0].value;
    }
    const controlCity = this.form.controls.city.value;
    const controlCountry = this.form.controls.country.value;
    const controlHouseNumber = this.form.controls.house_number.value;
    const controlPostCode = this.form.controls.post_code.value;
    const controlStreet = this.form.controls.street.value;
    return this.companiesKeyValue.find((companyKeyValue) => {
      const { city, country, house_number, post_code, street } = companyKeyValue.value;
      return (
        controlCity === city &&
        controlCountry === country &&
        controlHouseNumber === house_number &&
        controlPostCode === post_code &&
        controlStreet === street
      );
    })?.value;
  }

  isEditing() {
    return this.form.controls.city.value && this.form.controls.city.value !== '';
  }

  getCompaniesKeysValues(companies: CompanyWithLocation[]) {
    return companies.map((company) => {
      const { city, country, house_number, lat, lon, post_code, street } = company;
      return {
        key: company?.official_name || '',
        value: { city, country, house_number, lat, lon, post_code, street },
      };
    });
  }

  isFormControlRequired(formControl): boolean {
    return isFormControlRequired(formControl);
  }

  groupRequiredWhenNoDetails(): boolean {
    if (this.detailsVisible) return false;
    let atLeastOneRequired = false;
    for (const control in this.form.controls) {
      if (Object.prototype.hasOwnProperty.call(this.form.controls, control)) {
        const element = this.form.controls[control];
        if (element.errors?.required) {
          atLeastOneRequired = true;
        }
      }
    }
    return atLeastOneRequired;
  }

  setLocation(location: Location) {
    this.selectedCompanyLocation = location;
    Object.keys(location).forEach((name) => {
      if (this.form.controls[name]) {
        this.form.controls[name].patchValue(location[name], {
          onlySelf: false,
          emitEvent: true,
        });
      }
    });
  }

  toggleDetails(checked: boolean) {
    if (!checked) {
      this.setLocation(this.selectedCompanyLocation || this.companiesKeyValue[0].value);
    } else {
      this.selectedCompanyLocation = undefined;
    }
    this.detailsVisible = checked;
  }
}
