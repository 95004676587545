import { Campaign } from "./Campaign";
import { PlannedPost } from "./PlannedPost";
import { Post } from "./Post";

export interface PostsOverview {
  campaign: Campaign;
  posts: Post[];
  shop_posts: PlannedPost[];
  shop_campaign_id: string;
}
