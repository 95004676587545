<cdk-virtual-scroll-viewport itemSize="75" [ngClass]="{ big: controlArray.controls.length > 20 }">
  <table>
    <thead>
      <tr>
        <th *ngIf="counterEnabled" class="desktop"></th>
        <th>{{ 'global.email' | translate }}*</th>
        <th>{{ 'global.first_name' | translate }}</th>
        <th>{{ 'global.surname' | translate }}</th>
        <th>{{ 'global.language' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *cdkVirtualFor="let controlGroup of controlArray.controls; let i = index"
        [formGroup]="controlGroup | formGroup"
        class="respondent-form"
      >
        <td *ngIf="counterEnabled" class="desktop">
          <span>{{ i + 1 }}</span>
        </td>
        <td>
          <mat-form-field [floatLabel]="showLabels">
            <mat-label floatLabel="auto"> {{ 'global.email' | translate }}</mat-label>
            <input class="red-err-bg" #emailInput [formControl]="controlGroup.get('email') | formControl" matInput />
            <mat-error *ngIf="controlGroup.get('email').errors && controlGroup.get('email').dirty">{{
              'form.error.invalid_email' | translate
            }}</mat-error>
            <span
              class="warning form-field-warning"
              *ngIf="duplicatedRespondents?.includes(controlGroup.get('email').value)"
              >{{ 'research_groups.duplicated_email' | translate }}</span
            >
          </mat-form-field>
        </td>
        <td>
          <mat-form-field [floatLabel]="showLabels">
            <mat-label floatLabel="auto"> {{ 'global.first_name' | translate }}</mat-label>
            <input class="red-err-bg" [formControl]="controlGroup.get('first_name') | formControl" matInput />
            <mat-error
              *ngIf="controlGroup.get('first_name').errors?.['pattern'] && controlGroup.get('first_name').dirty"
              >{{ 'form.error.use_only_latin_alphabet' | translate }}</mat-error
            >
          </mat-form-field>
        </td>
        <td>
          <mat-form-field [floatLabel]="showLabels">
            <mat-label floatLabel="auto"> {{ 'global.surname' | translate }}</mat-label>
            <input class="red-err-bg" [formControl]="controlGroup.get('last_name') | formControl" matInput />
            <mat-error
              *ngIf="controlGroup.get('last_name').errors?.['pattern'] && controlGroup.get('last_name').dirty"
              >{{ 'form.error.use_only_latin_alphabet' | translate }}</mat-error
            >
          </mat-form-field>
        </td>
        <td>
          <lib-select
            class="lang-select"
            [options]="langOptions"
            [noBackground]="true"
            [noUnderline]="false"
            [offsetToLeft]="true"
            [value]="controlGroup.get('language').value"
            (valueChange)="controlGroup.patchValue({ language: $event })"
            [errors]="controlGroup.get('language').errors"
          ></lib-select>
        </td>
        <td>
          <span
            (click)="handleTrashClick(controlGroup)"
            class="icon-trash ml-2"
            [inlineSVG]="deleteIconPath ? deleteIconPath : '/icons/icon-trash-v2.svg'"
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
</cdk-virtual-scroll-viewport>
