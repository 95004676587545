import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { GetTextByValPipe } from './get-text-by-val.pipe';
import { MonthYearPipe } from './month-year.pipe';
import { AgePipe } from './age.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';

@NgModule({
  declarations: [AgePipe, GetTextByValPipe, MonthYearPipe, CapitalizeFirstPipe],
  imports: [CommonModule],
  exports: [AgePipe, GetTextByValPipe, MonthYearPipe, CapitalizeFirstPipe],
  providers: [DatePipe],
})
export class LibPipesModule {}
