import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaApiService {
  constructor(private http: HttpClient) {}

  MEDIA_BASE_HREF = '/api/v1.0/media';

  getBroadcasts = () => this.http.get(`${this.MEDIA_BASE_HREF}/broadcasts`);

  getStaticPages = () => this.http.get('/api/v2.0/broadcasts', { params: { broadcast_types: '4' } });

  getBroadcastByPermalink = (permalink: string) => this.http.get(`${this.MEDIA_BASE_HREF}/broadcasts/${permalink}`);

  getMedia = (id: string) => this.http.get(`#${this.MEDIA_BASE_HREF}/${id}`);

  getMediaV = (id: string, v: string) => this.http.get(`#${this.MEDIA_BASE_HREF}/${v}/${id}`);

  uploadFile(file: File, reportProgress = true): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('files', file);

    const req = new HttpRequest('POST', `${this.MEDIA_BASE_HREF}/upload`, formData, { reportProgress });
    return this.http.request(req);
  }

  removeMediaVariants = () => this.http.get(`#${this.MEDIA_BASE_HREF}/invalidate`);

  removeMediumMediaVariants = (id: string) => this.http.get(`#${this.MEDIA_BASE_HREF}/${id}/invalidate`);
}
