import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResearchLabel } from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ResearchLabelsApiService {
  constructor(private http: HttpClient) {}

  readonly BASE_RESEARCH_REMINDERS_URL = '/api/v1.0/research-labels';

  getAll = (company: string) =>
    this.http.get<ResearchLabel[]>(this.BASE_RESEARCH_REMINDERS_URL, { params: { company } });
}
