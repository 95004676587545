import { ShopPostStatus } from "../enums";

export interface PostTimetable {
  campaign_id: string;
  end: string;
  id: string;
  planned: string;
  status: ShopPostStatus | null;
  platforms: string;
  shop_id: string;
  start: string;
  title: string;
  draft: boolean;
}
