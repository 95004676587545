import { LanguageCode } from '../types';

export interface ResearchGroupLinkRequest {
  research_group_id: string;
  prefix: string;
  language: LanguageCode;
  available_to: Date;
  max: number;
}

export interface ResearchGroupLinkResponse {
  id: string;
  research_group_id: string;
  prefix: string;
  language: LanguageCode;
  url: string;
  available_to: Date;
}
