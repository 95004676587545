import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthSharedDataService {
  private signUpCustomHtmlCodeSource = new BehaviorSubject<string>('');
  signUpCustomHtmlCode$ = this.signUpCustomHtmlCodeSource.asObservable();
  private signUpCustomMethodToCallSource = new BehaviorSubject<() => void>(() => {});
  signUpCustomMethodToCall$ = this.signUpCustomMethodToCallSource.asObservable();
  private clientsAuthSource = new BehaviorSubject<boolean>(false);
  clientsAuth$ = this.clientsAuthSource.asObservable();

  private blockHtmlCode = new BehaviorSubject<boolean>(false);
  blockHtmlCode$ = this.blockHtmlCode.asObservable();

  constructor() {}

  setHtmlCodeAndMethod(htmlCode: string, method: () => void) {
    this.signUpCustomHtmlCodeSource.next(htmlCode);
    this.signUpCustomMethodToCallSource.next(method);
  }

  setClientsAuth(value: boolean) {
    this.clientsAuthSource.next(value);
  }

  blockAnyHtmlCode(value: boolean) {
    this.blockHtmlCode.next(value);
  }
}
