import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'bwh-lib-skills-list',
  templateUrl: './skills-list.component.html',
  styleUrls: ['./skills-list.component.scss'],
})
export class SkillsListComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() otherFormGroup: FormGroup;
  @Input() skillsArrayName: string;
  @Input() otherArrayName: string;
  @Input() addAction: boolean;
  @Input() removeAction: boolean;
  @Input() swapChipsColors: boolean;
  @Input() minItems: number;
  @Input() maxItems: number;
  @Input() removeToIndex = false;
  @Input() backToArray: string;
  @Output() remove = new EventEmitter();
  @Output() added = new EventEmitter();

  hideRemove = false;
  hideAdd = false;
  skillGoBackToIndex = 0;

  constructor() {}

  ngOnInit(): void {
    if (this.form) {
      let arrayLength: number;
      if (this.skillsArray && this.otherArray) {
        arrayLength = this.removeAction ? this.skillsArray.length : this.otherArray.length;
        this.hideShow(arrayLength);
      }

      this.form.valueChanges.subscribe(() => {
        arrayLength = this.removeAction ? this.skillsArray.length : this.otherArray.length;
        this.hideShow(arrayLength);
      });
    }
  }

  emitRemove() {
    this.remove.emit();
  }

  clickAction(index: number, skillIndex?: number) {
    if (skillIndex !== null) {
      if (skillIndex !== undefined) {
        this.skillGoBackToIndex = skillIndex;
        this.otherArray.push(this.skillsArray.at(index));
        this.added.emit();
      } else if (this.otherArrayName !== undefined) {
        this.otherArray.push(this.skillsArray.at(index));
        this.added.emit();
      }
    }
    this.skillsArray.removeAt(index);
  }

  get skillsArray() {
    return this.form.get(this.skillsArrayName) as FormArray;
  }

  get otherArray() {
    if (this.otherFormGroup && this.removeToIndex) {
      return (this.form.get(this.backToArray) as FormArray)
        .at(this.skillGoBackToIndex)
        .get(this.otherArrayName) as FormArray;
    } else {
      return (this.otherFormGroup || this.form).get(this.otherArrayName) as FormArray;
    }
  }

  hideShow(arrayLength: number): void {
    if (this.removeAction) {
      if (arrayLength <= this.minItems) {
        this.hideRemove = true;
      } else {
        this.hideRemove = false;
      }
    }

    if (this.addAction) {
      if (arrayLength >= this.maxItems) {
        this.hideAdd = true;
      } else {
        this.hideAdd = false;
      }
    }
  }
}
