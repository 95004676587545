import { Media } from './Media';
import { ProductAdditionalCost } from './ProductAdditionalCost';
import { ProductVariant } from './ProductVariant';

export interface CartProduct {
  product_id: string;
  title: string;
  subtitle: string;
  quantity: number;
  amount: number;
  total: number;
  additional_costs: ProductAdditionalCost[];
  variant: ProductVariant;
  product_variant_id: string;
  photo_id: string;
  photo: Media;
}
