import { AbstractControl } from "@angular/forms";

export const isFormControlRequired = (abstractControl: any): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  if (abstractControl.controls) {
    for (const controlName in abstractControl.controls) {
      if (abstractControl.controls[controlName]) {
        if (isFormControlRequired(abstractControl.controls[controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};
