import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lib-progress-bar-text',
  templateUrl: './progress-bar-text.component.html',
  styleUrls: ['./progress-bar-text.component.scss'],
})
export class ProgressBarTextComponent implements OnInit, AfterViewInit {
  @Input() length: number;
  _current: number;
  @Input() set current(curr: number) {
    this._current = curr > 100 ? 100 : curr;
    if (this.progress) this.calcProgress();
  }
  @Input() progressColor;
  @Input() type: 'percent' | 'fraction' = 'fraction';
  @Input() textPosition: 'left' | 'right' | 'top';
  @Input() maxBarWidth: number;
  @Input() maxBarHeight: number;
  @Input() withBorder = false;
  @ViewChild('progress') progress;
  @ContentChild('topText', { read: TemplateRef }) topText;
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.calcProgress();
  }

  calcProgress() {
    const width = this.length === 0 ? '0%' : `${(this._current / this.length) * 100}%`;
    this.renderer.setStyle(this.progress.nativeElement, 'width', width);
  }
}
