import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthApiService, CampaignApiService, MeApiService, ShopApiService } from '@digilize/shared/data/services';
import { DialogCreateCompanyComponent, DialogEntrepreneurComponent } from '@digilize/shared/feature/components/src';
import { AlertService } from '@digilize/shared/feature/modules/alert';
import { AuthService } from '@digilize/shared/feature/modules/auth/src/lib/auth.service';
import { SnackbarService } from '@digilize/shared/feature/modules/snackbar/src';
import { getCaptchaHeaders } from '@digilize/shared/utils/helpers/src';
import { DialogService } from '@digilize/shared/utils/services/src';
import { APP_CONFIG, ENV_CONFIG } from '@digilize/shared/utils/tokens';
import { matchOtherValidator, REGEX_PASSWORD } from '@digilize/shared/utils/validators';
import { AppConfig, EnvConfig, ErrorKind, Me, SnackbarType, UserTerms } from '@shared/definitions';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { catchError, debounceTime, distinctUntilChanged, of, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'lib-dialog-signup',
  templateUrl: './dialog-signup.component.html',
  styleUrls: ['./dialog-signup.component.scss'],
})
export class DialogSignupComponent implements OnInit, OnDestroy {
  @Output() toSignInClicked = new EventEmitter();
  @Output() signUpFinished = new EventEmitter();
  @Input() withFacebook = true;
  step;
  takeUntil$ = new Subject();
  loading = false;
  companyInfo;
  headers = {
    1: 'signup',
    2: 'conditions',
    3: 'interests',
  };

  signUpFormOrder = [
    'email',
    'first_name',
    'last_name',
    'nickname',
    'city',
    'country',
    'gender',
    'password',
    'password_confirm',
  ];
  forms = {
    1: new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, Validators.pattern(REGEX_PASSWORD)]),
      password_confirm: new UntypedFormControl('', [Validators.required, matchOtherValidator('password')]),
      first_name: new UntypedFormControl('', [Validators.required]),
      last_name: new UntypedFormControl('', [Validators.required]),
    }),
    2: new UntypedFormGroup({
      terms: new UntypedFormControl(false, [Validators.required]),
      privacy: new UntypedFormControl('', [Validators.required]),
      newsletter: new UntypedFormControl(''),
    }),
    3: new UntypedFormGroup({
      subcategory_ids: new UntypedFormControl('', [Validators.required]),
    }),
  };
  hidePwd = {
    password: true,
    password_confirm: true,
  };
  me: Me;
  categories = [];
  appTitle;

  constructor(
    public dialogRef: MatDialogRef<DialogSignupComponent>,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(ENV_CONFIG) private envConfig: EnvConfig,
    @Inject(MAT_DIALOG_DATA) public data: { signInDialog: ComponentType<any>; step: number; companyInfo: any }, // only used for direct dialog open, not via componentSwitcher
    private authApiService: AuthApiService,
    private dialogService: DialogService,
    private alertService: AlertService,
    private shopApiService: ShopApiService,
    private cookieService: CookieService,
    private profileApiService: MeApiService,
    private campaignApiService: CampaignApiService,
    private router: Router,
    private authService: AuthService, // would be best to get rid of this dep
    private recaptchaV3Service: ReCaptchaV3Service,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private snackbarService: SnackbarService
  ) {
    this.authApiService.me.subscribe((me: Me) => {
      this.me = me;
    });
  }

  setCaptchaVisibility(visibility: 'visible' | 'hidden') {
    setTimeout(() => {
      const badge = this.document.querySelector('.grecaptcha-badge');
      this.renderer.setStyle(badge, 'visibility', visibility);
    }, 200);
  }
  ngOnInit(): void {
    this.setEmailListener();
    this.setCaptchaVisibility('visible');
    this.step = this.step || 1;

    if (this.appConfig.app === 'films-nl') {
      this.forms[1].addControl('nickname', new UntypedFormControl('', Validators.required));
      this.forms[1].addControl('city', new UntypedFormControl('', Validators.required));
      this.forms[1].addControl('country', new UntypedFormControl('', Validators.required));
      this.forms[1].addControl('gender', new UntypedFormControl('', Validators.required));
    }

    if (this.step === 3) {
      if (this.appConfig.app === 'uit-westerveld') {
        this.getCategories();
      } else {
        this.signUpFinished.emit(); // Flixit config don't need categories for profile
      }
    }

    this.appTitle = this.appConfig.appTitle;
  }

  setEmailListener() {
    this.forms[1]
      .get('email')
      .valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((val) => {
          return this.recaptchaV3Service.execute('exists').pipe(
            switchMap((captchaToken) => {
              return this.authApiService.doesEmailExists(
                val,
                getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey)
              );
            }),
            catchError((err) => {
              console.error(err);
              return of({ exists: false });
            })
          );
        })
      )
      .subscribe((resp) => {
        if (resp.exists) {
          this.snackbarService.openSnackbar(SnackbarType.Information, {
            duration: 10000,
            data: { msg: 'alert.custom.you_already_have_acc', translate: true },
          });
        }
      });
  }
  getCategories() {
    this.shopApiService.getCategories().subscribe(
      (response: any) => {
        this.categories = response;
      },
      (error) => {}
    );
  }

  getFormControl(form, name: string) {
    return form.get(name);
  }

  triggerSignAction() {
    if (this.forms[this.step].valid) {
      switch (this.step) {
        case 1: {
          this.createAccount(this.forms[1]);
          break;
        }
        case 2: {
          this.updateTerms(this.forms[2]);
          break;
        }
        case 3: {
          this.updateCategories(this.forms[3]);
          break;
        }
      }
    }
  }

  onSubmit() {
    this.triggerSignAction();
  }

  categoriesChange($event) {
    this.forms[3].get('subcategory_ids').setValue($event);
  }

  openSigninDialog($event) {
    $event.preventDefault();
    this.toSignInClicked.emit();
  }

  createAccount(form: UntypedFormGroup) {
    this.loading = true;
    const registerForm: any = form.getRawValue();

    this.recaptchaV3Service
      .execute('register')
      .pipe(
        switchMap((captchaToken) => {
          return this.authApiService.register(
            registerForm,
            getCaptchaHeaders(captchaToken, this.envConfig.recaptchaSiteKey)
          );
        })
      )
      .subscribe(
        (response: any) => {
          this.authService.setSession(response, () => {
            this.signUpFinished.emit(); // No need to change step becuase there's global trigger
            const acquisitionId = this.cookieService.get('acquisition_id');
            if (acquisitionId && this.me.agreement1 && this.me.agreement2) {
              this.registerAcquisition(acquisitionId);
            } else {
              window.location.reload();
            }
            this.loading = false;
          });
        },
        (error) => {
          this.loading = false;
          if (error.error.error.type === ErrorKind.CAPTCHA_INVALID) {
            this.snackbarService.openSnackbar(SnackbarType.Error, {
              duration: 15000,
              data: { msg: 'alert.custom.seems_you_are_bot', translate: true },
            });
          }

          if (error.error.error.type === ErrorKind.ALREADY_EXISTS) {
            this.forms[1].get('email').setErrors({ alreadyExist: true });
          } else {
            this.alertService.handleApiError(error.error.error);
          }
        }
      );
  }

  navigateAfterRegister() {
    if (this.appConfig.app === 'flixit' && !window.location.pathname.includes('/admin')) {
      window.location.href = '/admin/c/0/shops';
    } else {
      window.location.reload();
    }
  }

  registerAcquisition(id) {
    this.loading = true;
    this.campaignApiService.registerAcquisition(id).subscribe(
      (response) => {
        this.cookieService.delete('acquisition_id');
        this.navigateAfterRegister();
      },
      (error) => {
        this.loading = false;
        if (error?.status === 400) {
          this.cookieService.delete('acquisition_id');
        }

        if (error.error?.error?.info?.kind === 'already_exists') {
          this.navigateAfterRegister();
        } else {
          this.router.navigate(['niet-gevonden']);
        }
      }
    );
  }

  updateTerms(form: UntypedFormGroup) {
    this.loading = true;
    const formObj = form.getRawValue();
    const terms: UserTerms = {
      agreement1: formObj.terms,
      agreement2: formObj.privacy,
      agreement3: formObj.newsletter ? formObj.newsletter : false,
    };
    this.profileApiService.updateTerms(terms).subscribe(
      (response) => {
        if (this.me.subcategory_ids.length > 0) {
          window.location.reload();
          this.signUpFinished.emit();
          this.loading = false;
        } else {
          // Flixit config don't need categories for profile
          if (this.appConfig.app === 'uit-westerveld') {
            this.step = 3;
            this.getCategories();
          } else {
            const acquisitionId = this.cookieService.get('acquisition_id');
            if (acquisitionId) {
              this.registerAcquisition(acquisitionId);
            } else {
              this.handleCreatingCompany();
            }
          }

          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.handleApiError(error.error.error);
      }
    );
  }

  updateCategories(form: UntypedFormGroup) {
    this.loading = true;
    const formObj = form.getRawValue();
    this.profileApiService.updateInterests(formObj).subscribe(
      (response) => {
        this.handleCreatingCompany();
      },
      (error) => {
        this.loading = false;
        this.alertService.handleApiError(error.error.error);
      }
    );
  }

  handleCreatingCompany() {
    const createCompany = this.cookieService.get('create_company');
    if (createCompany || this.appConfig.app === 'flixit') {
      this.cookieService.delete('create_company', '/');
      this.signUpFinished.emit();
      // Timeout to add class to body (scroll block)
      setTimeout(() => {
        this.dialogService.openDialog(DialogCreateCompanyComponent);
      }, 200);
    } else {
      this.signUpFinished.emit();
      // Timeout to add class to body (scroll block)
      setTimeout(() => {
        this.dialogService.openDialog(DialogEntrepreneurComponent);
      }, 200);
    }
  }

  facebookRedirect() {
    this.authService.facebookRedirect();
  }

  ngOnDestroy(): void {
    this.setCaptchaVisibility('hidden');
  }
}
